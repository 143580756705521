// Question enums
export const QUESTION_CUSTOM_LEVELS = 'question_custom_levels';
export const QUESTION_CULTURE_CONTINUUM = 'question_culture_continuum';
export const QUESTION_EXCITEMENT = 'question_excitement';
export const PERCENTILE_QUESTION_IDENTIFIER = 'question_peer_rank';
export const TWO_OPTION_SLIDER_TYPE = 'twoOptionSlider';
export const MULTI_STEP_PILL = 'multiStepPill';
export const QUESTION_CUSTOM_LEVEL = 'questionCustomLevel';
export const QUESTION_MSMC_AND_FILTER = 'question_msmc_and_filter';
export const QUESTION_Y_N = 'question_y_n';
export const QUESTION_SLIDING_SCALE = 'question_sliding_scale';
export const QUESTION_AUTOGENERATED_SKILLMAP_QUESTION = 'autogenerated_skillmap_question';
export const QUESTION_MULTIPLE_CHOICE_VERTICAL = 'question_multiple_choice_vertical';
export const QUESTION_DROPDOWN = 'question_dropdown';
export const DROPDOWN_QUESTION_TYPE = 'question_dropdown';
export const CHECKBOX_QUESTION_TYPE = 'question_checkbox';
export const SKILL_MAP = 'skillMap';
export const QUESTION_MULTI_SELECT_MULTIPLE_CHOICE = 'question_multi_select_multiple_choice';
export const QUESTION_ONBOARDING_CHECKLIST = 'question_onboarding_checklist';
export const QUESTION_OPEN_ENDED_TEXT_SMALL = 'question_open_ended_text_small';
export const QUESTION_OPEN_ENDED_NUMBER_SMALL = 'question_open_ended_number_small';
export const QUESTION_OPEN_ENDED_TEXT_MEDIUM = 'question_open_ended_text_medium';
export const QUESTION_OPEN_ENDED_TEXT_LARGE = 'question_open_ended_text_large';
export const QUESTION_NO_ANSWER = 'question_no_answer';

export const QUESTIONS_CULTURE_CONTINUUM = [QUESTION_CULTURE_CONTINUUM];
export const QUESTIONS_CUSTOM_LEVELS = [QUESTION_CUSTOM_LEVELS];
export const CONTINUUM_QUESTION_TYPES = [TWO_OPTION_SLIDER_TYPE];

export enum FormComponentType {
  CHECKBOX = 'checkbox',
  CHECKBOX_ARRAY = 'checkboxArray',
  DROPDOWN = 'dropdown',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  CUSTOM = 'custom',
  RICH_TEXT = 'richText',
}

export const BARCHART_QUESTION_TYPES = [
  QUESTION_CUSTOM_LEVEL,
  QUESTION_CUSTOM_LEVELS,
  QUESTION_SLIDING_SCALE,
  QUESTION_MULTIPLE_CHOICE_VERTICAL,
  QUESTION_AUTOGENERATED_SKILLMAP_QUESTION,
  'slideScale',
  FormComponentType.CHECKBOX,
  'pillVertical',
];

// Admin only
export const questionTypeNameToInputType = {
  [QUESTION_OPEN_ENDED_TEXT_SMALL]: FormComponentType.TEXT,
  [QUESTION_OPEN_ENDED_NUMBER_SMALL]: 'number',
  [QUESTION_SLIDING_SCALE]: 'slideScale',
  [QUESTION_AUTOGENERATED_SKILLMAP_QUESTION]: 'slideScale',
  [QUESTION_OPEN_ENDED_TEXT_MEDIUM]: FormComponentType.TEXT,
  [QUESTION_OPEN_ENDED_TEXT_LARGE]: FormComponentType.TEXT,
  [QUESTION_MULTIPLE_CHOICE_VERTICAL]: 'pillVertical',
  [QUESTION_MSMC_AND_FILTER]: MULTI_STEP_PILL,
  [QUESTION_CUSTOM_LEVELS]: QUESTION_CUSTOM_LEVEL,
  [QUESTION_MULTI_SELECT_MULTIPLE_CHOICE]: 'pill',
  [DROPDOWN_QUESTION_TYPE]: FormComponentType.CHECKBOX,
  [CHECKBOX_QUESTION_TYPE]: FormComponentType.DROPDOWN,
  [QUESTION_CULTURE_CONTINUUM]: TWO_OPTION_SLIDER_TYPE,
};

export const AVAILABLE_ANSWER_TYPES = Object.values(questionTypeNameToInputType);

export const TYPES_TO_SHOW_ANSWER_OPTIONS = [
  QUESTION_CUSTOM_LEVELS,
  'question_multiple_choice_vertical',
];

export const summaryQuestionIdentifiers = ['question_single_sentence', 'question_open_ended'];

export const referenceRelationshipQuestions = {
  referencerTitle: 'question_referencer_role',
  referencerEmail: 'question_referencer_email',
  companyName: 'question_common_employer',
  timeOverlap: 'question_time_overlap',
  phoneNumber: 'question_phone_number',
  candidateRole: 'question_candidate_role',
  workRelationship: 'question_relationship',
};

export const TYPES_TO_SHOW_ANSWER_W_OPTIONS = [
  'slideScale',
  'pillVertical',
  QUESTION_SLIDING_SCALE,
  'question_sliding_scale_7',
  QUESTION_EXCITEMENT,
  'questionCustomLevel',
  QUESTION_CUSTOM_LEVELS,
  QUESTION_AUTOGENERATED_SKILLMAP_QUESTION,
];

export const strengthComponentIdentifiers = [
  'question_strengths_multiple_choice',
  'question_strengths_multiple_choice_thinkific',
  'question_strengths_multiple_choice_limited',
  'strengths_choice',
  'question_strengths_choice',
];

export const growthAreasComponentIdentifiers = [
  'question_growth_areas_multiple_choice',
  'question_growth_areas_multiple_choice_thinkific',
  'question_growth_areas',
];

export const companyQuestionIdentifiers = ['question_share_recommendation_company_list'];

export const workMotivationsComponentIdentifiers = ['question_work_motivations'];
