import { makeVar } from '@apollo/client';

import { QuestionComponent, QuestionComponentsPaging, QuestionComponentsPagingInput } from 'types';
import { EmptyObject, KeyFieldsContext } from 'types/interfaces';
import { getPagingQueryField } from './helpers';

export const questionComponentsPagingInputVar = makeVar<
  QuestionComponentsPagingInput | EmptyObject
>({});

export default {
  keyFields: (
    { questionComponentId: id }: QuestionComponent,
    { typename }: KeyFieldsContext
  ): string => `${typename}_${id}`,
  fields: {
    questionComponentId: {
      read(questionComponentId: string): number {
        return +questionComponentId;
      },
    },
  },
};

export const questionComponentsPaging = getPagingQueryField<
  QuestionComponent,
  QuestionComponentsPaging,
  QuestionComponentsPagingInput
>(['keyword'], 'questionComponentId');
