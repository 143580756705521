import { gql } from '@apollo/client';

import { srPartnerDetails, workdayPartnerDetails, ashbyPartnerDetails } from './sharedApiConstants';

export const QUERY_ATS_PARTNERS_FOR_COMPANY = gql`
  query queryAtsPartnersForCompany($input: QueryAtsPartnersForCompanyInput!) {
    queryAtsPartnersForCompany(input: $input) {
      Lever {
        leverPartnerKey
      }
      Greenhouse {
        greenhousePartnerKey
      }
      SmartRecruiters {
        ${srPartnerDetails}
      }
      Workday {
        ${workdayPartnerDetails}
      }
      Ashby {
        ${ashbyPartnerDetails}
      }
    }
  }
`;

export const CREATE_WORKDAY_PARTNER = gql`
  mutation createWorkdayPartner($input: CreateWorkdayPartnerInput!) {
    createWorkdayPartner(input: $input) {
      ${workdayPartnerDetails}
    }
  }
`;

export const UPDATE_WORKDAY_PARTNER = gql`
  mutation updateWorkdayPartner($input: UpdateWorkdayPartnerInput!) {
    updateWorkdayPartner(input: $input) {
      ${workdayPartnerDetails}
    }
  }
`;

export const CREATE_SR_PARTNER = gql`
  mutation createSrPartner($input: CreateSrPartnerInput!) {
    createSrPartner(input: $input) {
      ${srPartnerDetails}
    }
  }
`;

export const UPDATE_SR_PARTNER = gql`
  mutation updateSrPartner($input: UpdateSrPartnerInput!) {
    updateSrPartner(input: $input) {
      ${srPartnerDetails}
    }
  }
`;
