import { HirerPositionRelation } from 'types';
import { KeyFieldsContext } from 'types/interfaces';

export default {
  keyFields: (
    { hirerPositionRelationId: id }: HirerPositionRelation,
    { typename }: KeyFieldsContext
  ): string => `${typename}_${id}`,
  fields: {
    hirerPositionRelationId: {
      read(hirerPositionRelationId: string): number {
        return +hirerPositionRelationId;
      },
    },
  },
};

export const hirerPositionRelationCacheRef = (hirerPositionRelationId: string | number): string =>
  `HirerPositionRelation_${hirerPositionRelationId}`;
