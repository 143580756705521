import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface CompanyState {
  companyKey: string;
  setCompanyKey: (key: string) => void;
  deleteEverything: () => void;
}

const useCompanyStore = create<CompanyState>()(
  devtools(
    persist(
      set => ({
        companyKey: '',
        deleteEverything: () =>
          set({
            companyKey: '',
          }),
        setCompanyKey: (key: string) => set(() => ({ companyKey: key })),
      }),
      { name: 'company-storage' }
    )
  )
);

export { useCompanyStore };
