import get from 'lodash/get';
import { ApolloCache } from '@apollo/client';

import { BENCHMARK_REQUEST_COMMENT_FRAGMENT } from 'api/fragments';
import {
  BenchmarkRequestComment,
  Query,
  QueryFetchUnattachedBenchmarkRequestCommentsArgs,
} from 'types';
import { FETCH_UNATTACHED_BENCHMARK_REQUEST_COMMENTS } from 'api/benchmarks_api';

export default {
  keyFields: (
    { benchmarkRequestCommentKey: id }: BenchmarkRequestComment,
    {
      typename,
    }: {
      typename: string;
    }
  ): string => `${typename}_${id}`,
};

export const benchmarkRequestCommentKeyCacheRef = (
  benchmarkRequestCommentKey: string | number
): string => `BenchmarkRequestComment_${benchmarkRequestCommentKey}`;

export const readUnattachedComments = (
  cache: ApolloCache<unknown>,
  benchmarkId: number
): Array<BenchmarkRequestComment> => {
  const res = cache.readQuery<Query, QueryFetchUnattachedBenchmarkRequestCommentsArgs>({
    query: FETCH_UNATTACHED_BENCHMARK_REQUEST_COMMENTS,
    variables: {
      input: {
        benchmarkId,
      },
    },
  });

  return res?.fetchUnattachedBenchmarkRequestComments || [];
};

export const writeUnattachedComments = (
  cache: ApolloCache<unknown>,
  benchmarkId: number,
  updated: Array<BenchmarkRequestComment>
): void => {
  cache.writeQuery<
    Pick<Query, 'fetchUnattachedBenchmarkRequestComments'>,
    QueryFetchUnattachedBenchmarkRequestCommentsArgs
  >({
    query: FETCH_UNATTACHED_BENCHMARK_REQUEST_COMMENTS,
    variables: {
      input: {
        benchmarkId,
      },
    },
    data: {
      fetchUnattachedBenchmarkRequestComments: updated,
    },
  });
};

export const addUnattachedBenchmarkRequestComment = (
  cache: ApolloCache<unknown>,
  data: BenchmarkRequestComment,
  benchmarkId: number
): void => {
  try {
    const cached = readUnattachedComments(cache, benchmarkId);
    const updated = [...cached, data];

    writeUnattachedComments(cache, benchmarkId, updated);
  } catch (e) {}
};

export const updateBenchmarkRequestComment = (
  cache: ApolloCache<unknown>,
  data: BenchmarkRequestComment
): void => {
  const id = benchmarkRequestCommentKeyCacheRef(get(data, 'benchmarkRequestCommentKey'));

  cache.writeFragment<BenchmarkRequestComment>({
    id,
    fragment: BENCHMARK_REQUEST_COMMENT_FRAGMENT,
    data,
  });
};
