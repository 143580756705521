import { gql } from '@apollo/client';
import { LEVELS_ON_SKILL_MAP } from './fragments';
import { SKILL_MAP_DETAILS, SKILL_MAP_LEVEL_DETAILS } from './sharedApiConstants';

export const FETCH_PUBLIC_SKILL_MAPS = gql`
  query fetchPublicSkillMaps($input: FetchPublicSkillMapsInput!) {
    fetchPublicSkillMaps(input: $input) {
      totalCount
      page
      pageSize

      data {
        skillMapId
        componentText
        standardJobFamilyKey
        standardJobFamily {
          name
          standardJobFamilyKey
          standardDepartment {
            name
            standardDepartmentKey
          }
        }
        standardJobCareerTrackKey
        standardJobCareerTrack {
          name
          standardJobCareerTrackKey
        }
        isPublic
        description
        ...skillMapLevels
      }
    }
  }
  ${LEVELS_ON_SKILL_MAP}
`;

export const CREATE_SKILL_MAP_FOR_COMPANY = gql`
  mutation createSkillMapForCompany($input: CreateSkillMapForCompanyInput!) {
    createSkillMapForCompany(input: $input) {
      ${SKILL_MAP_DETAILS}
    }
  }
`;

export const UPDATE_SKILL_MAP = gql`
  mutation updateSkillMap($input: UpdateSkillMapInput!) {
    updateSkillMap(input: $input) {
      ${SKILL_MAP_DETAILS}
    }
  }
`;

export const FETCH_SKILL_MAPS_FOR_COMPANY = gql`
  query fetchSkillMapsForCompany($input: FetchSkillMapsForCompanyInput!) {
    fetchSkillMapsForCompany(input: $input) {
      totalCount
      page
      pageSize
      data {
        skillMapId
        componentText
        isPublic
        inUse
        createdAt
        updatedAt
        description

        positions {
          positionId
        }
        benchmarks {
          benchmarkId
        }
        
        standardJobFamily {
          name
          standardJobFamilyKey
          standardDepartment {
            name
            standardDepartmentKey
          }
        }
        standardJobCareerTrackKey
        standardJobCareerTrack {
          name
          standardJobCareerTrackKey
        }

        skillMapLevels {
          ${SKILL_MAP_LEVEL_DETAILS}
        }
      }
    }
  }
`;

export const FETCH_SKILL_MAP_BY_ID = gql`
  query fetchSkillMapById ($input: FetchSkillMapByIdInput!) {
    fetchSkillMapById (input: $input) {
      ${SKILL_MAP_DETAILS}
      description
      positions {
        positionId
        positionTitle
      }

      benchmarks {
        benchmarkId
        benchmarkTitle
      }
    }
  }
`;

export const DUPLICATE_SKILL_MAP_FOR_COMPANY = gql`
  mutation duplicateSkillMapForCompany($input: DuplicateSkillMapForCompanyInput!) {
    duplicateSkillMapForCompany(input: $input) {
      skillMapId
    }
  }
`;

export const UPDATE_LOCKED_SKILL_MAP = gql`
  mutation updateLockedSkillMap($input: UpdateLockedSkillMapInput!) {
    updateLockedSkillMap(input: $input) {
      skillMapId
      componentText
      description
    }
  }
`;
