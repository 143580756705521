import { ApolloCache } from '@apollo/client';

import {
  MakeRequired,
  SrPartner as SrPartnerType,
  WorkdayPartner as WorkdayPartnerType,
} from 'types';
import { SR_PARTNER, WORKDAY_PARTNER } from 'api/fragments';

export const workdayPartnerKeyCacheRef = (workdayPartnerKey: string): string =>
  `WorkdayPartner_${workdayPartnerKey}`;

export const WorkdayPartner = {
  keyFields: (
    { workdayPartnerKey: id }: WorkdayPartnerType,
    {
      typename,
    }: {
      typename: string;
    }
  ): string => `${typename}_${id}`,
};

export const SrPartner = {
  keyFields: (
    { srPartnerKey: id }: SrPartnerType,
    {
      typename,
    }: {
      typename: string;
    }
  ): string => `${typename}_${id}`,
};

export const updateWorkdayPartnerInCache = (
  cache: ApolloCache<unknown>,
  data?: MakeRequired<WorkdayPartnerType, 'workdayPartnerKey'>
): void => {
  if (!data) {
    return;
  }
  try {
    cache.writeFragment({
      id: workdayPartnerKeyCacheRef(data.workdayPartnerKey!),
      fragment: WORKDAY_PARTNER,
      data,
    });
  } catch (e) {}
};

export const updateSrPartnerInCache = (
  cache: ApolloCache<unknown>,
  data?: MakeRequired<SrPartnerType, 'srPartnerKey'>
): void => {
  if (!data) {
    return;
  }
  try {
    cache.writeFragment({
      id: workdayPartnerKeyCacheRef(data.srPartnerKey!),
      fragment: SR_PARTNER,
      data,
    });
  } catch (e) {}
};
