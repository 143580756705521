import { ApolloCache, makeVar } from '@apollo/client';

import {
  CompanyRole,
  CompanyRolesPaging,
  FetchCompanyRolesForCompanyPagingInput,
  MakeRequired,
  Query,
} from 'types';
import { FETCH_COMPANY_ROLES_FOR_COMPANY } from 'api/companyRoles_api';
import { EmptyObject } from 'types/interfaces';
import { COMPANY_ROLE_FRAGMENT } from 'api/fragments';
import { getPagingQueryField } from './helpers';

export default {
  keyFields: (
    { companyRoleKey: id }: CompanyRole,
    {
      typename,
    }: {
      typename: string;
    }
  ): string => `${typename}_${id}`,
};

export const fetchCompanyRolesForCompanyPagingInputVar = makeVar<
  FetchCompanyRolesForCompanyPagingInput | EmptyObject
>({});

export const companyRoleKeyCacheRef = (companyRoleKey: string): string =>
  `CompanyRole_${companyRoleKey}`;

export const addCompanyRoleToCache = (
  cache: ApolloCache<unknown>,
  companyRole: CompanyRole
): void => {
  try {
    const input = fetchCompanyRolesForCompanyPagingInputVar();
    const data = cache.readQuery<Pick<Query, 'fetchCompanyRolesForCompany'>>({
      query: FETCH_COMPANY_ROLES_FOR_COMPANY,
      variables: {
        input,
      },
    }) as Query;
    const { fetchCompanyRolesForCompany } = data || {};
    cache.writeQuery({
      query: FETCH_COMPANY_ROLES_FOR_COMPANY,
      variables: {
        input,
      },
      data: {
        fetchCompanyRolesForCompany: {
          ...fetchCompanyRolesForCompany,
          data: [companyRole, ...fetchCompanyRolesForCompany.data],
          totalCount: (fetchCompanyRolesForCompany?.totalCount || 0) + 1,
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateCompanyRole = (
  cache: ApolloCache<unknown>,
  data: MakeRequired<CompanyRole, 'companyRoleKey'> | void,
  companyRoleKey: string
): void => {
  if (!data) {
    return;
  }
  try {
    cache.writeFragment({
      id: `CompanyRole_${companyRoleKey}`,
      fragment: COMPANY_ROLE_FRAGMENT,
      data,
    });
  } catch (e) {}
};

export const fetchCompanyRolesForCompanyPaging = getPagingQueryField<
  CompanyRole,
  CompanyRolesPaging,
  FetchCompanyRolesForCompanyPagingInput
>(['companyKey', 'keyword', 'sortDirection', 'sortField'], 'companyRoleKey');
