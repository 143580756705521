import { ApolloClient, ApolloLink, HttpLink, concat, split, from } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

import localRateLimiting from 'util/localRateLimiting';
import cache from '../cache';

const apiUrl = (operationName: string) =>
  // On local, append the operation name for easier debugging
  {
    localRateLimiting.increment();
    return process.env.REACT_APP_API === 'local'
      ? `http://localhost:5000/graphql/${operationName}`
      : `${process.env.REACT_APP_API}/graphql`;
  };

// For IE, we need to specify fetch (from js-polyfill)
const httpLink = new HttpLink({
  uri: ({ operationName }) => apiUrl(operationName),
  fetch: global.fetch,
});

const wsLink = new WebSocketLink({
  uri:
    process.env.REACT_APP_API === 'local'
      ? 'ws://localhost:5000/subscriptions'
      : `${process.env.REACT_APP_API!.replace('https', 'wss')}/subscriptions`,
  options: {
    reconnect: true,
    lazy: true,
    inactivityTimeout: 5 * 1000 * 60,
    connectionParams: () => ({
      authorization: localStorage.getItem('token') || null,
    }),
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: localStorage.getItem('token') || null,
      device_id: localStorage.getItem('deviceId'),
    },
  });

  return forward(operation);
});

const cleanTypename = new ApolloLink((operation, forward) => {
  // Clean __typename from input
  if (operation.variables) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation).map(data => data);
});

const apolloClient = new ApolloClient({
  link: from([cleanTypename, concat(authMiddleware, splitLink)]),
  cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first',
    },
  },
});

export default apolloClient;
