export const DEFAULT_REFERENCER_RESPONSE_DAYS = 5;
export const DEFAULT_CANDIDATE_RESPONSE_DAYS = 5;
export const DEFAULT_REQUIRED_REFERENCES = 3;
export const REQUIRED_SUBMITTED_REFERENCES = 2;
export const DEFAULT_RECOMMENDED_REFERENCES = 15;
export const DEFAULT_HIRER_INVITE_TO_CANDIDATE =
  'Hi, thank you for your application. The next step in our process is to learn more about your background from the people who know you best.';

export const mobileWidth = 800;

// Referencer Constants
export const declinedStatus = 'declined';

export const pendingStatus = 'pending';

export const withdrawnStatus = 'withdrawn';

export const activeStatus = 'active';

export const invitedStatus = 'invited';

export const acceptedStatus = 'accepted';

export const undeliveredStatus = 'undelivered';

export const referencesIncompleteStatus = 'referencesIncomplete';

export const savedStatus = 'saved';

export const submittedStatus = 'submitted';

export const errorStatus = 'inviteError';

export const hiredStatus = 'hired';

export const offeredStatus = 'offered';

export const dismissedStatus = 'dismissed';

export const ADMIN_PERMISSION = 'admin';

export const ADMIN_PAGES = {
  Home: {
    label: 'Home',
    url: 'admin',
  },
  Companies: {
    label: 'Companies',
    url: 'companies',
  },
  Users: {
    label: 'Users',
    url: 'users',
  },
  Surveys: {
    label: 'Surveys',
    url: 'forms',
  },
  Questions: {
    label: 'Questions',
    url: 'questions',
  },
  ReferenceRequests: {
    label: 'Reference Requests',
    url: 'referencerequests',
  },
  CultureDefinitions: {
    label: 'Culture Definitions',
    url: 'culture-definitions',
  },
  SkillMaps: {
    label: 'Job Architecture',
    url: 'skill-maps',
  },
} as Record<string, { url: string; label: string }>;

export const resumeExpansionQuestionIdentifier = 'question_resume_expansion';

export const secondDegreeReferencerFirstQuestionIdentifier =
  'question_second_degree_reference_firstname';

export const secondDegreeReferencerRelationshipQuestionIdentifier =
  'question_second_degree_reference_relationship';

export const NPSquestionIdentifier = 'question_nps';

export const singleSentenceQuestionIdentifier = 'question_single_sentence';

export const preferencesQuestionIdentifiers = [
  'question_additional_employers',
  'question_approve_viewers',
  'question_informed_by_accepted_job',
];

export const REFERENCER_NPS_FORM_IDENTIFIER = 'nps-referencer';

export const question_tags = {
  action: 'action_button',

  checkbox: 'question_checkbox',

  open_ended_small: 'question_open_ended_text_small',

  open_ended_medium: 'question_open_ended_text_medium',

  open_ended_large: 'question_open_ended_text_large',

  sliding_scale: 'question_sliding_scale',

  yes_no: 'question_y_n',

  multiple_choice_responsive: 'question_multiple_choice_responsive',

  multiple_choice_vertical: 'question_multiple_choice_vertical',

  contact_information: 'question_contact_information',

  nps: 'question_nps',

  dropdown: 'question_dropdown',

  open_90_char: 'question_90_char_limit',
};

export const relationship_answer_option_group_id = 5;

// Candidate Constants

export const candidateEditPermissionIdentifier = 'candidateEdit';

export const educationExperienceIdentifier = 'Education';

export const industryExperienceIdentifier = 'Work';

export const otherExperienceIdentifier = 'Other';

export const introductionProcessIdentifier = 'candidate_introduction_process';

// Landing Page Constants

export const LandingPageProductComponents = [
  {
    title: 'Know Your Candidate',
    description:
      '<p>Gather insights from those who already know what it&#8217;s like to work with your candidate. Our software gathers an average of 3, and as many as 10, perspectives per candidate.</p>',
  },
  {
    title: 'Recruit a Diverse & Inclusive Team',
    description:
      '<p>Searchlight gathers insights on past performance to qualify more candidates with diverse backgrounds. Pinpoint the best candidates in your funnel that you currently overlook.</p>',
  },
  {
    title: 'Save Time',
    description:
      "<p>Searchlight's automated solution quickly identifies the top percentile of your applicant pool. We save employers an average of 50 hours per position.</p>",
  },
  {
    title: 'Improve Your Candidate Experience',
    description:
      '<p>Our product was designed by candidates for a supportive candidate experience. All support gathered on Searchlight is saved for the candidate to use and share.</p>',
  },
];

export const LandingPageProductSteps = [
  {
    title: '1',
    description: 'Invite candidates to Searchlight using a custom link.',
  },
  {
    title: '2',
    description: 'Candidates invite referencers for contextualized work experiences.',
  },
  {
    title: '3',
    description: 'Referencers submit their feedback online through a custom questionnaire.',
  },
  {
    title: '4',
    description:
      'Searchlight algorithmically analyzes and summarizes references to assess candidate-position compatibility.',
  },
];

export const forgotLoginHTML = '<div><a href="/requestpasswordreset">I forgot my login.<a></div>';

// hirer constants
export const SET_CANDIDATE_ACTIVE_UPDATE = 'setCandidateActive';
export const SET_CANDIDATE_INACTIVE_UPDATE = 'setCandidateInactive';
export const PERCENTILE_SCORE_TYPE = 'percentile';
export const EXCITEMENT_SCORE_TYPE = 'excitement';
export const SLIDING_SCALE_SCORE_TYPE = 'sliding_scale';

export const timeOverlapOptions = [
  { value: '< 1 year', title: '< 1 year' },
  { value: '1 year', title: '1 year' },
  { value: '2 years', title: '2 years' },
  { value: '3 years', title: '3 years' },
  { value: '4 years', title: '4 years' },
  { value: '5 years', title: '5 years' },
  { value: '6 - 10 years', title: '6 - 10 years' },
  { value: '10+ years', title: '10+ years' },
];
