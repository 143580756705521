import { FragmentMap } from '@apollo/client/utilities';
import { SelectionSetNode } from 'graphql';

import { Scalars, BenchmarkRequest, FormComponentGroup, MakeOptional, Maybe } from './apollo';
import { BenchmarkReportWrapper } from 'components/ReportComponents/ReportWrappers/BenchmarkReportWrapper';
import { unitOfTime } from 'moment';
import {
  PRIMARY_COLORS,
  SUCCESS_COLORS,
  SECONDARY_COLORS,
  CANDIDATE_ASSESSMENT_COLORS,
} from 'components/constants';
import {
  companyQuestionIdentifiers,
  growthAreasComponentIdentifiers,
  strengthComponentIdentifiers,
} from '../enums/questionTypes';
import colors from 'sl-colors';

// Other enums
export const STRENGTHS = 'strengths';

export const COACHABLE_AREAS = 'coachableAreas';

export const COMPANY = 'company';

export const WORK_MOTIVATIONS = 'workMotivations';

export const TAG_COMPONENT_IDENTIFIERS = {
  [STRENGTHS]: strengthComponentIdentifiers,
  [COACHABLE_AREAS]: growthAreasComponentIdentifiers,
  [COMPANY]: companyQuestionIdentifiers,
};

export const PRIORITY_RELATIONSHIPS: string[] = ['Direct Manager', 'Supervisor / Indirect Manager'];

export const HIRER_RELATIONSHIP: ReferenceType = {
  title: 'Hirer',
  shortenedTitle: 'Hirer',
  pdfTitle: 'Hirer',
  relevanceOrder: 7,
  color: CANDIDATE_ASSESSMENT_COLORS[1],
  typeText: 'H',
};

export const REFERENCE_TYPES_MAP: ReferenceType[] = [
  {
    title: 'Direct Manager',
    shortenedTitle: 'Manager',
    pdfTitle: 'Direct Manager',
    relevanceOrder: 0,
    color: SECONDARY_COLORS[0],
    className: '',
    typeText: 'DM',
  },
  {
    title: 'Supervisor / Indirect Manager',
    shortenedTitle: 'Supervisor',
    pdfTitle: 'Supervisor',
    relevanceOrder: 1,
    color: SUCCESS_COLORS[0],
    typeText: 'S',
  },
  {
    title: 'Direct Report',
    shortenedTitle: 'Report',
    pdfTitle: 'Direct Report',
    relevanceOrder: 2,
    color: colors.primary[400],
    typeText: 'DR',
  },
  {
    title: 'Peer / Colleague',
    shortenedTitle: 'Peer',
    pdfTitle: 'Peer',
    relevanceOrder: 3,
    color: PRIMARY_COLORS[0],
    typeText: 'P',
  },
  {
    title: 'Stakeholder',
    shortenedTitle: 'Stakeholder',
    pdfTitle: 'Stakeholder',
    relevanceOrder: 3.5,
    color: SUCCESS_COLORS[1],
    typeText: 'SH',
  },
  {
    title: 'Professor',
    shortenedTitle: 'Professor',
    pdfTitle: 'Professor',
    relevanceOrder: 4,
    color: PRIMARY_COLORS[1],
    typeText: 'PR',
  },
  {
    title: 'Personal',
    shortenedTitle: 'Personal',
    pdfTitle: 'Personal',
    relevanceOrder: 5,
    color: colors.error[400],
    typeText: 'PE',
  },
  {
    title: 'Other',
    shortenedTitle: 'Other',
    pdfTitle: 'Other',
    relevanceOrder: 6,
    color: colors.warning[400],
    typeText: 'O',
  },
  HIRER_RELATIONSHIP,
  {
    title: 'Participant',
    shortenedTitle: 'Participant',
    pdfTitle: 'Participant',
    relevanceOrder: 8,
    color: CANDIDATE_ASSESSMENT_COLORS[0],
    typeText: 'C',
  },
  // We used to spell "supervisor" wrong so adding this as a stopgap until all those users churn
  {
    title: 'Superviser / Indirect Manager',
    shortenedTitle: 'Super.',
    pdfTitle: 'Superviser',
    relevanceOrder: 1.5,
    color: SUCCESS_COLORS[0],
    typeText: 'S',
  },
];

export const READY = 7;

// Table constants
export const DEFAULT_PAGE_SIZE = 10;

export type RecordObject = Record<string, unknown>;

export type ReferenceType = {
  title: string;
  shortenedTitle: string;
  pdfTitle: string;
  relevanceOrder: number;
  color: string;
  className?: string;
  typeText: string;
};

export type RelationshipDetails = {
  referencerTitle: string;
  referencerEmail: string;
  companyName: string;
  timeOverlap: number;
  phoneNumber: string;
  candidateRole: string;
  workRelationship: string;
};

export type WorkRelationship =
  | 'Supervisor / Indirect'
  | 'Manager'
  | 'Direct'
  | 'Manager'
  | 'Peer / Colleague'
  | 'Direct'
  | 'Report'
  | 'Personal'
  | 'Professor'
  | 'Stakeholder';

export type SrPartnerRoles =
  | 'coordinator'
  | 'hiring_manager'
  | 'recruiter'
  | 'interview_team'
  | 'executive';

export type EmptyObject = Record<string, never>;

export type PagedData<T, E = unknown> =
  | {
      data: Array<T>;
      totalCount: number;
      averageQoh?: number | null;
      extraContent?: E;
    }
  | EmptyObject;

export type HookOutput<K> = {
  data?: K;
  loading?: boolean;
  error?: Error;
  refetch?(): void;
};

export type BenchmarkDataFromBenchmarkRequests = {
  submittedBenchmarkRequestWrappers: null | BenchmarkReportWrapper[];
  positionForm: null | FormComponentGroup;
  filteredIcpResponses: string[];
  pendingIcpResponses: BenchmarkRequest[];
  benchmarkRequests?: BenchmarkRequest[];
  loading?: boolean;
};

export type AnyObject = {
  [key: string]: string | number | boolean;
};

export type KeyFieldsContext = {
  typename: string;
  selectionSet?: SelectionSetNode;
  fragmentMap?: FragmentMap;
};

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export interface PagingData {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  keyword: Scalars['String'];
  sortOrder?: Scalars['String'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
  enabled?: Scalars['Boolean'];
}

export interface PagedResults<T> extends MakeOptional<PagingData, 'keyword' | 'page' | 'pageSize'> {
  totalCount: Scalars['Int'];
  data: Array<T>;
}

export type MakeRequired<T, K extends keyof T> = Omit<Partial<T>, K> & { [SubKey in K]: T[SubKey] };

export enum ChecklistAnswerStatus {
  INCOMPLETE = 'Not Started',
  IN_PROGRESS = 'In Progress',
  COMPLETE = 'Complete',
}

export interface SelectedDateRange {
  startDate: Maybe<Date>;
  endDate: Maybe<Date>;
}

export type RangeTypeId = 'custom' | 'all' | unitOfTime.DurationConstructor;

export interface RangeType {
  id: RangeTypeId;
  label: string;
}

export type Size = 'none' | 'xs' | 'sm' | 'md' | 'lg';

export type Color =
  | 'transparent'
  | 'white'
  | 'secondary'
  | 'primary'
  | 'success'
  | 'default'
  | 'warning'
  | 'error'
  | 'info';

export interface ReferenceWrapperMetaData {
  id: number;
  name: string;
  workRelationship?: string;
}

export const sizeValue: Record<Size, number> = {
  none: 0,
  xs: 2,
  sm: 4,
  md: 8,
  lg: 12,
};

export interface OptionType {
  id: string | number;
  name: string;
}
