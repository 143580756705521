import { makeVar } from '@apollo/client';
import {
  HirerReviewGroupsForCompanyInput,
  PositionPaging,
  BenchmarkRequest,
  HirerPositionRelation,
  Position,
  PositionKeywordPagingSearchForHirerInput,
} from 'types';
import { EmptyObject, KeyFieldsContext } from 'types/interfaces';
import { getPagingQueryField, makeVarWithWindowUpdate } from './helpers';

export default {
  keyFields: ({ positionId: id }: Position, { typename }: KeyFieldsContext): string =>
    `${typename}_${id}`,
  fields: {
    positionId: {
      read(positionId: string): number {
        return +positionId;
      },
    },
    hirerPositionRelations: {
      merge(
        _existing: HirerPositionRelation[],
        incoming: HirerPositionRelation[]
      ): HirerPositionRelation[] {
        return incoming;
      },
    },
    benchmarkRequests: {
      merge(_existing: BenchmarkRequest[], incoming: BenchmarkRequest[]): BenchmarkRequest[] {
        return incoming;
      },
    },
  },
};

const _positionKeywordPagingSearchForHirerInput = makeVar<
  PositionKeywordPagingSearchForHirerInput | EmptyObject
>({});

export const positionKeywordPagingSearchForHirerInput = makeVarWithWindowUpdate(
  _positionKeywordPagingSearchForHirerInput,
  ['isArchived', 'positionId', 'companyId']
);

export const positionCacheRefId = (positionId: string | number): string | void =>
  positionId ? `Position_${positionId}` : void 0;

export const hirerReviewGroupsForCompanyPaging = getPagingQueryField<
  Position,
  PositionPaging,
  HirerReviewGroupsForCompanyInput
>(['companyKey', 'reviewCycleKey', 'keyword', 'sortField', 'sortOrder'], 'positionKey');

export const searchKeywordPagingPositionsForHirer = getPagingQueryField<
  Position,
  PositionPaging,
  PositionKeywordPagingSearchForHirerInput
>(['isArchived', 'keyword', 'sortField', 'sortOrder'], 'positionId');
