import { PagingData } from 'types';
import colors from '../sl-colors';

export const LOGO_S3_BASE_URL =
  'https://searchlight-landing.s3.amazonaws.com/assets/ecosystem_companies';

export const RECOMMENDER_TYPE = 'recommender';
export const CANDIDATE_TYPE = 'candidate';
export const HIRER_TYPE = 'hirer';
export const ANONYMOUSE_TYPE = 'Anonymous';
export const DEFAULT_LANGUAGE = 'en';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const PYTHIA_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'MM/DD/YY hh:mm A';
export const MONTH_YEAR_FORMAT = 'MMM YY';
export const NUM_MONTH_YEAR_FORMAT = 'MM/YYYY'; // Numerical month/year format, ex: 10/2021
export const FR = 'fr';
export const MAX_TEXT_LENGTH = 90;

export const MD_BREAKPOINT = 768;

export const defaultPagingData: PagingData = {
  page: 1,
  pageSize: 10,
  keyword: '',
};

export const BASE_FILTERS: {
  keyword: string;
  page: number;
  pageSize: number;
} = {
  keyword: '',
  page: 1,
  pageSize: 10,
};

export const PRIMARY_COLORS = [colors.primary[400], colors.primary[300]];

export const SUCCESS_COLORS = [colors.success.DEFAULT, colors.success[400]];

export const SECONDARY_COLORS = [colors.secondary.DEFAULT, colors.secondary[400]];

export const WARNING_COLORS = [colors.warning.DEFAULT, colors.warning[400]];

export const ERROR_COLORS = [colors.error[400], colors.error[300]];

export const CANDIDATE_ASSESSMENT_COLORS = [colors.primary.DEFAULT, colors.primary[400]];

export const BENCHMARK_COLOR = colors.secondary.DEFAULT;

export const REFERENCES_COLOR = colors.success.DEFAULT;

export const MANAGER_COLOR = colors.info.DEFAULT;

export const EMPLOYEE_COLOR = colors.primary[400];

export const BASE_COLORS = [
  SUCCESS_COLORS[0],
  SECONDARY_COLORS[0],
  PRIMARY_COLORS[0],
  SECONDARY_COLORS[0],
  SUCCESS_COLORS[0],
  WARNING_COLORS[0],
  ERROR_COLORS[0],
  CANDIDATE_ASSESSMENT_COLORS[0],
];

export const BASE_COLORS_LIGHTER = [
  SUCCESS_COLORS[1],
  SECONDARY_COLORS[1],
  PRIMARY_COLORS[1],
  SECONDARY_COLORS[1],
  SUCCESS_COLORS[1],
  WARNING_COLORS[1],
  ERROR_COLORS[1],
  CANDIDATE_ASSESSMENT_COLORS[1],
];
