import { makeVar } from '@apollo/client';
import {
  PositionTemplatesForCompanyPaging,
  PositionTemplatesForCompanyPagingInput,
  PositionTemplate,
} from 'types';
import { EmptyObject, KeyFieldsContext } from 'types/interfaces';
import { getPagingQueryField, makeVarWithWindowUpdate } from './helpers';

export default {
  keyFields: (
    { positionTemplateId: id }: PositionTemplate,
    { typename }: KeyFieldsContext
  ): string => `${typename}_${id}`,
  fields: {
    positionTemplateId: {
      read(positionTemplateId: string): number {
        return +positionTemplateId;
      },
    },
  },
};

const _positionTemplatesForCompanyPagingInput = makeVar<
  PositionTemplatesForCompanyPagingInput | EmptyObject
>({});

export const positionTemplatesForCompanyPagingInput = makeVarWithWindowUpdate(
  _positionTemplatesForCompanyPagingInput,
  ['isArchived']
);

export const positionTemplatesForCompanyPaging = getPagingQueryField<
  PositionTemplate,
  PositionTemplatesForCompanyPaging,
  PositionTemplatesForCompanyPagingInput
>(['keyword', 'companyId'], 'positionTemplateId');
