import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { ReflectionPerformanceBucket, Reviewee, SoftSkillsAnalyticsComparisonView } from 'types';

interface SoftSkillAnalyticsStore {
  reflectionKey: string;
  selectedView: SoftSkillsAnalyticsComparisonView;
  setReflectionKey: (key: string) => void;
  setSelectedView: (view: SoftSkillsAnalyticsComparisonView) => void;
  resetStoreState: () => void;
  minTopPerformers: number;
  setMinTopPerformers: (minTopPerformers: number) => void;
  performersMap: Record<string, Reviewee>;
  updatePerformersMap: (performer: Reviewee) => void;
  removePerformerFromMap: (revieweeKey: string) => void;
  setTopAttributeKeysByReflection: (reflectionKey: string, topAttributeKeys: string[]) => void;
  topAttributeKeysByReflectionKey: Record<string, string[]>;
}

const useSoftSkillAnalyticsStore = create<SoftSkillAnalyticsStore>()(
  devtools(
    persist(
      set => ({
        reflectionKey: '',
        selectedView: SoftSkillsAnalyticsComparisonView.WholeTeam,
        setReflectionKey: key => set({ reflectionKey: key }),
        setSelectedView: view => set({ selectedView: view }),
        performersMap: {},
        updatePerformersMap: (reviewee: Reviewee) =>
          set(state => ({
            performersMap: { ...state.performersMap, [reviewee.revieweeKey]: reviewee },
          })),
        removePerformerFromMap: (revieweeKey: string) => {
          set(state => {
            const newPerformersMap = { ...state.performersMap };
            newPerformersMap[revieweeKey] = {
              ...newPerformersMap[revieweeKey],
              performanceBucket: undefined as unknown as ReflectionPerformanceBucket,
            };
            return { performersMap: newPerformersMap };
          });
        },
        minTopPerformers: 0,
        setMinTopPerformers: minTopPerformers => set({ minTopPerformers }),
        resetStoreState: () =>
          set({
            reflectionKey: '',
            selectedView: SoftSkillsAnalyticsComparisonView.WholeTeam,
            performersMap: {},
            minTopPerformers: 0,
          }),
        topAttributeKeysByReflectionKey: {},
        setTopAttributeKeysByReflection: (reflectionKey: string, topAttributeKeys: string[]) =>
          set(state => ({
            ...state,
            topAttributeKeysByReflectionKey: {
              ...state.topAttributeKeysByReflectionKey,
              [reflectionKey]: topAttributeKeys,
            },
          })),
      }),
      { name: 'soft-skill-analytics-storage' }
    )
  )
);

export { useSoftSkillAnalyticsStore };
