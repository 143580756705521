import { Permission } from 'types';
import { KeyFieldsContext } from 'types/interfaces';

export default {
  keyFields: ({ permissionId }: Permission, { typename }: KeyFieldsContext): string =>
    `${typename}_${permissionId}`,
  fields: {
    permissionId: {
      read(permissionId: string): number {
        return +permissionId;
      },
    },
  },
};
