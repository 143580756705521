import { ApolloCache, FieldFunctionOptions, makeVar } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import { HIRER_COMPANY_RELATIONS_FRAGMENT, HIRER_PROFILE_FRAGMENT } from 'api/fragments';
import { HIRER_COMPANY_RELATIONS_FOR_COMPANY } from 'api/hirer_api';
import { ADMIN_PERMISSION } from 'config/AppConfig';
import {
  HirerCompanyRelation,
  HirerCompanyRelationPaging,
  HirerProfile,
  QueryHirerCompanyRelationPagingForCompanyInput,
} from 'types';
import { EmptyObject, KeyFieldsContext, MakeRequired } from 'types/interfaces';
import { getPagingQueryField } from './helpers';

export const hirerCompanyRelationsForCompanyVar = makeVar<
  QueryHirerCompanyRelationPagingForCompanyInput | EmptyObject
>({});

export const updateHirerCompanyRelationInCache = (
  cache: ApolloCache<unknown>,
  hirerCompanyRelation: MakeRequired<HirerCompanyRelation, 'hirerCompanyRelationId'>,
  toDelete = false
): void => {
  const { hirerCompanyRelationId, hirerProfileId } = hirerCompanyRelation;
  if (!toDelete) {
    cache.writeFragment({
      id: `HirerCompanyRelation_${hirerCompanyRelationId}`,
      fragment: HIRER_COMPANY_RELATIONS_FRAGMENT,
      fragmentName: 'hirerCompanyRelationDetails',
      data: hirerCompanyRelation,
    });
  }
  cache.evict({
    id: 'ROOT_QUERY',
    fieldName: getOperationName(HIRER_COMPANY_RELATIONS_FOR_COMPANY) as string,
  });
  try {
    const { hirerCompanyRelations = [], hirerPositionRelations = [] } =
      cache.readFragment<HirerProfile>({
        id: `HirerProfile_${hirerProfileId}`,
        fragment: HIRER_PROFILE_FRAGMENT,
      }) || {};
    const idx = toDelete
      ? 0
      : hirerCompanyRelations.findIndex(
          hcr => hcr.hirerCompanyRelationId === hirerCompanyRelationId
        );
    let data;
    if (toDelete) {
      data = hirerPositionRelations.filter(
        r => r.benchmarkRequest?.benchmarkRequestId !== hirerCompanyRelationId
      );
    } else if (idx < 0) {
      data = [hirerCompanyRelation, ...hirerCompanyRelations];
    }
    // We don't need to write fragment on updates.
    if (data) {
      cache.writeFragment({
        id: `HirerProfile_${hirerProfileId}`,
        fragment: HIRER_PROFILE_FRAGMENT,
        data,
      });
    }
  } catch (e) {}
};

export default {
  keyFields: (
    { hirerCompanyRelationId: id }: HirerCompanyRelation,
    { typename }: KeyFieldsContext
  ): string => `${typename}_${id}`,
  fields: {
    hirerCompanyRelationId: {
      read(hirerCompanyRelationId: string): number {
        return +hirerCompanyRelationId;
      },
    },
    isAdmin: {
      read(_existing: boolean, options: FieldFunctionOptions): boolean {
        return ((options.readField('permissions') as string[]) || []).includes(ADMIN_PERMISSION);
      },
    },
    permissions: {
      merge(_exisiting: string[], incoming: string[]): string[] {
        return incoming;
      },
    },
  },
};

export const hirerCompanyRelationCacheRef = (hirerCompanyRelationId: string | number): string =>
  `HirerCompanyRelation_${hirerCompanyRelationId}`;

export const hirerCompanyRelationsForCompany = getPagingQueryField<
  HirerCompanyRelation,
  HirerCompanyRelationPaging,
  QueryHirerCompanyRelationPagingForCompanyInput
>(['companyId', 'keyword', 'permissions'], 'hirerCompanyRelationId');
