import { gql } from '@apollo/client';

import { benchmarkCollaborator } from './sharedApiConstants';

export const FETCH_BENCHMARK_COLLABORATORS_PAGING = gql`
  query fetchBenchmarkCollaboratorsPaging($input: FetchBenchmarkCollaboratorsPagingInput!) {
    fetchBenchmarkCollaboratorsPaging(input: $input) {
      benchmarkId
      page
      pageSize
      totalCount
      keyword
      data {
        ${benchmarkCollaborator}
      }
    }
  }
`;

export const INVITE_BENCHMARK_COLLABORATOR = gql`
  mutation inviteBenchmarkCollaborator($input: InviteBenchmarkCollaboratorInput!) {
    inviteBenchmarkCollaborator(input: $input) {
      ${benchmarkCollaborator}
    }
  }
`;

export const UPDATE_BENCHMARK_COLLABORATOR_NOTIFICATIONS = gql`
  mutation updateBenchmarkCollaboratorNotifications(
    $input: UpdateBenchmarkCollaboratorNotificationsInput!
  ) {
    updateBenchmarkCollaboratorNotifications(input: $input) {
      benchmarkCollaboratorKey
      receivesNotifications
    }
  }
`;

export const DELETE_BENCHMARK_COLLABORATOR = gql`
  mutation deleteBenchmarkCollaborator($input: DeleteBenchmarkCollaboratorInput!) {
    deleteBenchmarkCollaborator(input: $input) {
      benchmarkCollaboratorKey
      receivesNotifications
    }
  }
`;
