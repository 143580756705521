import { gql } from '@apollo/client';

import {
  candidateProfileDetails,
  companyDetails,
  experienceSectionDetails,
  formComponentGroupDetails,
  positionTemplateDetails,
  recommendationCompanyDetails,
  submittedAnswerGroups,
  subsectionComponentDetails,
  userDetails,
  statusType,
  CPR_ATTRIBUTE,
  submittedAnswerGroup,
  draftAnswerGroup,
  SKILL_MAP_CELL_DETAILS,
  SKILL_MAP_LEVEL_DETAILS,
  SKILL_MAP_RESPONSES,
  companyFeatureFlags,
  greenhousePartnerDetails,
  companyComm,
  benchmarkRequestComment,
  benchmarkCollaborator,
  automationSetting,
  workdayPartnerDetails,
  srPartnerDetails,
  referenceRequirementSyntax,
  behaviorScores,
  behaviorProfileDetails,
  benchmarkMatchDetails,
} from './sharedApiConstants';

/* Fragments */
export const QUESTION_RATING_FRAGMENT = gql`
  fragment questionRatingDetails on QuestionRating {
    questionRatingId
    hirerProfileId
    candidatePositionRelationId
    questionComponentId
    rating
  }
`;

export const HIRER_HIGHLIGHTED_ANSWERS_FRAGMENT = gql`
  fragment hirerHighlightedAnswers on CandidatePositionRelation {
    candidatePositionRelationId
    hirerHighlightedAnswers {
      hirerHighlightId
      hirerProfileId
      candidatePositionRelationId
      answerId
    }
  }
`;

export const CANDIDATE_EXPERIENCE_DETAILS_FRAGMENT = gql`
  fragment candidateExperienceDetails on CandidateExperience {
    candidateExperienceId
    order
    positionTitle
    companyName
    companyLogo
    locationCity
    locationState
    locationCountry
    startMonth
    startYear
    endMonth
    endYear
    isPresent
    details
    reviewCycleId
    experienceType {
      experienceTypeId
    }
    experienceSection {
      experienceSectionId
    }
    experienceDetails {
      experienceDetailId
    }
    candidateReferencerRelations {
      candidateReferencerRelationId
    }
  }
`;

export const SHARED_CANDIDATE_DETAILS_FRAGMENT = gql`
  fragment sharedCandidateDetails on SharedCandidateDetails {
    candidateProfileId
    candidateFirstName
    candidateLastName
    candidateEmail
    candidatePhoto
    vanityLinkText
    isPublic
  }
`;

export const CANDIDATE_PROFILE_DETAILS_FRAGMENT = gql`
  fragment CandidateProfileDetails on CandidateProfile {
    ${candidateProfileDetails}
  }
`;

export const USER_DETAILS_FRAGMENT = gql`
  fragment userDetails on User {
    ${userDetails}
  }
`;

export const HIRER_PROFILE_FRAGMENT = gql`
  fragment hirerProfileDetails on HirerProfile {
    hirerProfileId
    # hirerProfileKey
    hirerEmail
    hirerPhoneNumber
    hirerInfoPending
    isArchived

    hirerCompanyRelations {
      hirerCompanyRelationId
    }
    hirerPositionRelations {
      hirerPositionRelationId
    }
    excludedCandidatePositionRelations {
      hirerExcludedApplicationId
    }
    hirerCompanyRoles {
      hirerCompanyRoleKey
    }
  }
`;

export const UPDATED_COMPANY_FRAGMENT = gql`
  fragment updatedCompany on Company {
    ${companyDetails}
    notificationEvents {
      notificationEventName
      notificationEventId
    }
  }
`;

export const UPDATED_CANDIDATE_PROFILE_FRAGMENT = gql`
  fragment updatedCandidateProfile on CandidateProfile {
    ${candidateProfileDetails}
  }  
`;

export const UPDATED_SECTION_FRAGMENT = gql`
  fragment updatedSection on ExperienceSection {
    ${experienceSectionDetails}
  }  
`;

export const CANDIDATE_REFERENCER_RELATION_DETAILS_FRAGMENT = gql`
  fragment CandidateReferencerRelationDetails on CandidateReferencerRelation {
    candidateReferencerRelationId
    candidateExperienceId
    referencerTitle
    timeOverlap
    workRelationship
    referencerEmail
    referencerFirstName
    referencerLastName
    isArchived
    isPublic
    overlapStartDate
    overlapEndDate
    contextForReference
    hasRecommendation
    details
    referenceRequests {
      referenceRequestId
    }
    referencerProfile {
      referencerProfileId
    }
    recommendation {
      recommendationId
    }
  }
`;

export const UPDATED_QUESTION_COMPONENT_FRAGMENT = gql`
  fragment updatedQuestionComponent on QuestionComponent {
    questionComponentId
    questionComponentKey
    componentText
    subtitleText
    componentIdentifier
    hasAnswerOptions

    questionType {
      questionTypeId
    }
    answerOptionGroup {
      answerOptionGroupId
    }
  }
`;

export const UPDATED_SUBSECTION_FRAGMENT = gql`
  fragment updatedSubsectionComponent on SubsectionComponent {
    ${subsectionComponentDetails}
  }
`;

export const UPDATED_FCG_FRAGMENT = gql`
  fragment updatedFormComponentGroup on FormComponentGroup {
    ${formComponentGroupDetails}
  }
`;

export const HIRER_POSITION_RELATION_DETAILS_FRAGMENT = gql`
  fragment hirerPositionRelationDetails on HirerPositionRelation {
    hirerPositionRelationId
    receivesReferenceNotificationEmails
    hirerProfile {
      hirerProfileId
    }
    benchmarkRequest {
      benchmarkRequestId
      benchmarkRequestKey
    }
  }
`;

export const POSITION_TEMPLATE_FRAGMENT = gql`
  fragment PositionTemplateDetails on PositionTemplate {
    ${positionTemplateDetails}
  }
`;

export const UPDATE_RECOMMENDATION_COMPANY_FRAGMENT = gql`
  fragment updatedRecommendationCompany on RecommendationCompany {
    ${recommendationCompanyDetails}
  }
`;

export const BENCHMARK_REQUEST_FRAGMENT = gql`
  fragment benchmarkRequestDetails on BenchmarkRequest {
    benchmarkRequestId
    benchmarkRequestKey
    benchmarkId
    submissionDate
    ${statusType}
    mostRecentlySubmittedAnswerGroup {
      ${submittedAnswerGroups}
    }
  }
`;

export const NOTIFICATION_TYPE_FRAGMENT = gql`
  fragment notificationTypeDetails on NotificationType {
    notificationTypeId
    notificationTypeName
  }
`;

export const CANDIDATE_POSITION_RELATION_DETAILS_FRAGMENT = gql`
  fragment candidatePositionRelationDetails on CandidatePositionRelation {
    candidatePositionRelationId
    candidateFirstName
    candidateLastName
    candidateEmail
    candidatePhoneNumber
    candidateInviteFromHirer
    referencerInviteFromHirer
    referencerResponseDays
    uniqueCode
    statusTypeId
    referenceStatusId
    candidateProfileId
    positionId
    details
    isArchived
    candidateInvitedDate
    createdAt
    referencesCompletedDate
    excitement_score
    percentile_score
    competencies_score
    numReferencesCompleted
    numReferencesInvited
    weighted_score
    searchlightScoreText
    searchlightScoreSubtitle
    opportunityId
    emailReminderDays
    shouldInviteReviewers

    ${statusType}
    referenceStatus {
      statusTypeId
      typeName
    }
    position {
      positionId
    }
    candidateProfile {
      candidateProfileId
    }
    greenhouseRequest {
      greenhouseRequestId
    }
    leverRequest {
      leverRequestId
    }
    hirerHighlightedAnswers {
      hirerHighlightId
    }
    cprAttributes {
      cprAttributeId
    }
    pendingAndSubmittedReferenceRequests {
      submittedReferenceRequests {
        referenceRequestId
      }
      pendingReferenceRequests {
        referenceRequestId
      }
    }
    ${behaviorScores}
    behaviorProfile {
      ${behaviorProfileDetails}
    }
    reviewee {
      revieweeId
    }
    skillMaps {
      skillMapId
    }
    skillMapRelations {
      cprSkillMapRelationId
    }
    benchmarkMatches {
      ${benchmarkMatchDetails}
    }
    candidateAssessment {
      candidateAssessmentId
    }
    newHireFit {
      candidatePositionRelationId
      score
      scoreText
    }
  }
`;

export const STATUS_TYPE_FRAGMENT = gql`
  fragment statusTypeDetails on StatusType {
    statusTypeId
    typeName
  }
`;

export const HIRER_COMPANY_RELATIONS_FRAGMENT = gql`
  fragment hirerCompanyRelationDetails on HirerCompanyRelation {
    hirerCompanyRelationId
    permissions
    company {
      companyId
    }
    hirerProfile {
      hirerProfileId
    }
  }
`;

export const LEVER_PARTNERS_FRAGMENT = gql`
  fragment leverPartnerDetails on LeverPartner {
    leverPartnerId
    leverUserId
    leverApiKey
    signingToken
    defaultPositionTemplateId
    webhookUrls
    hasLeverOAuthEnabled
    stageNameTrigger
    defaultPositionTemplate {
      positionTemplateId
    }
  }
`;

export const CANDIDATE_EXPERIENCE_ON_EXPERIENCE_SECTION = gql`
  fragment candidateExperiences on ExperienceSection {
    ${experienceSectionDetails}
    candidateExperiences {
      candidateExperienceId
    }
  }
`;

export const CPR_ATTRIBUTE_ON_CPR = gql`
  fragment cprAttributes on CandidatePositionRelation {
    cprAttributes {
      ${CPR_ATTRIBUTE}
    }
  }
`;

export const FULL_CANDIDATE_POSITION_RELATION_DETAILS_FRAGMENT = gql`
  fragment fullCandidatePositionRelationDetails on CandidatePositionRelation {
    candidatePositionRelationId
    candidateFirstName
    candidateLastName
    candidateEmail
    candidatePhoneNumber
    candidateInviteFromHirer
    referencerInviteFromHirer
    referencerResponseDays
    uniqueCode
    details
    isArchived
    candidateInvitedDate
    greenhouseRequest
    leverRequest
    referencesCompletedDate
    excitement_score
    percentile_score
    competencies_score
    numReferencesCompleted
    numReferencesInvited
    weighted_score
    opportunityId
    emailReminderDays
    positionId
    candidateProfileId
    ${statusType}
    referenceStatus {
      statusTypeId
      typeName
    }
  }
`;

export const CANDIDATE_POSITION_RELATION_PAGING_FRAGMENT = gql`
  fragment candidatePositionRelationPaging on CandidatePositionRelationPaging {
    page
    pageSize
    totalCount
    sortField
    sortDirection
    keyword
    data {
      ...candidatePositionRelationDetails
    }
  }
  ${FULL_CANDIDATE_POSITION_RELATION_DETAILS_FRAGMENT}
`;

export const BENCHMARK_REQUEST_ON_BENCHMARK = gql`
  fragment benchmarkBenchmarkRequests on Benchmark {
    benchmarkRequests {
      ...benchmarkRequestDetails
    }
  }
  ${BENCHMARK_REQUEST_FRAGMENT}
`;

export const BENCHMARK_FRAGMENT = gql`
  fragment benchmarkDetails on Benchmark {
    benchmarkId
    benchmarkTitle
    requisitionIds
    isArchived
    requisitionIds
    externalJobIds
    externalJobUrls
    benchmarkRequests {
      benchmarkRequestId
      benchmarkRequestKey
    }
    benchmarkPositionRelations {
      benchmarkPositionRelationsId
    }
  }
`;

export const SKILL_MAP_CELL_FRAGMENT = gql`
  fragment skillMapCellDetails on SkillMapCell {
    ${SKILL_MAP_CELL_DETAILS}
  }
`;

export const SKILL_MAP_LEVEL_FRAGMENT = gql`
  fragment skillMapLevelDetails on SkillMapLevel {
    ${SKILL_MAP_LEVEL_DETAILS}
  }
`;

export const SKILL_MAP_COMPETENCY_DETAILS_FRAGMENT = gql`
  fragment skillMapCompetencyDetails on SkillMapCompetency {
    skillMapCompetencyId
    componentText
    skillMapId
    rank
  }
`;

export const CELLS_ON_SKILL_MAP_COMPETENCY = gql`
  fragment skillMapCells on SkillMapCompetency {
    skillMapCells {
      ...skillMapCellDetails
    }
  }
  ${SKILL_MAP_CELL_FRAGMENT}
`;

export const LEVELS_ON_SKILL_MAP = gql`
  fragment skillMapLevels on SkillMap {
    skillMapLevels {
      ...skillMapLevelDetails
    }
  }
  ${SKILL_MAP_LEVEL_FRAGMENT}
`;

export const COMPETENCIES_ON_SKILL_MAP = gql`
  fragment skillMapCompetencies on SkillMap {
    skillMapCompetencies {
      ...skillMapCompetencyDetails
      ...skillMapCells
    }
  }
  ${SKILL_MAP_COMPETENCY_DETAILS_FRAGMENT}
  ${CELLS_ON_SKILL_MAP_COMPETENCY}
`;

export const SKILL_MAP_DETAILS_FRAGMENT = gql`
  fragment skillMapDetails on SkillMap {
    skillMapId
    componentText
    isPublic
    companyId
    scoreUnits
    description
  }
`;

export const REFERENCE_REQUEST_FRAGMENT = gql`
  fragment referenceRequestDetails on ReferenceRequest {
    referenceRequestId
    draftSkillMapResponses {
      ${SKILL_MAP_RESPONSES}
      skillMapCells {
        ${SKILL_MAP_CELL_DETAILS}
      }
    }
    draftAnswerGroup {
      ${draftAnswerGroup}
    }
    submittedAnswerGroups {
      answerGroupId
      submissionDate
    }
    mostRecentlySubmittedAnswerGroup {
      ${submittedAnswerGroup}
    }
  }
`;

export const CANDIDATE_ASSESSMENT_FRAGMENT = gql`
  fragment candidateAssessment on CandidateAssessment {
    candidateAssessmentId
    submissionDate
    ${statusType}
    answerGroup {
      ${draftAnswerGroup}
    }
    submittedSkillMapResponses {
      ${SKILL_MAP_RESPONSES}
      skillMapCells {
        ${SKILL_MAP_CELL_DETAILS}
      }
    }
    draftSkillMapResponses {
      ${SKILL_MAP_RESPONSES}
      skillMapCells {
        ${SKILL_MAP_CELL_DETAILS}
      }
    }
  }
`;

export const COMPANY_FEATURE_FLAGS_FRAGMENT = gql`
  fragment companyFeatureFlags on CompanyFeatureFlags {
    ${companyFeatureFlags}
    }
`;

export const GREENHOUSE_PARTNER_DETAILS = gql`
  fragment greenhousePartnerDetails on GreenhousePartner {
    ${greenhousePartnerDetails}
    }
`;

export const COMM_TYPE_FOR_COMPANY = gql`
  fragment commTypeForCompanyDetails on CommTypeForCompany {
    commTypeKey
    companyCommKey
    commType {
      commTypeKey
    }
    companyComm {
      companyCommKey
    }
  }
`;

export const COMPANY_COMM = gql`
  fragment companyCommDetails on CompanyComm {
    ${companyComm}
  }
`;

export const ANSWER_FRAGMENT = gql`
  fragment answerDetails on Answer {
    answerId
    answerBody
    subsectionQuestionRelationId
  }
`;

export const QUESTION_ANSWER_FRAGMENT = gql`
  fragment questionAnswerDetails on QuestionAnswer {
    answerId
    answerText
    subsectionQuestionRelationId
  }
`;

export const BENCHMARK_REQUEST_COMMENT_FRAGMENT = gql`
  fragment benchmarkRequestCommentDetails on BenchmarkRequestComment {
    ${benchmarkRequestComment}
  }
`;

export const BENCHMARK_COLLABORATORS = gql`
  fragment benchmarkCollaborator on BenchmarkCollaborator {
    ${benchmarkCollaborator}
  }
`;

export const AUTOMATION_SETTING_FRAGMENT = gql`
  fragment automationSetting on AutomationSetting {
    ${automationSetting}
  }
`;

export const FULL_POSITION_FRAGMENT = gql`
  fragment positionDetails on Position {
    positionId
    positionTitle
    positionIdentifier
    candidateInviteFromHirer
    referencerInviteFromHirer
    referencerResponseDays
    numRequiredReferences
    isOpen
    isArchived
    details
    candidateViewFullReport
    numCandidatePositionRelations
    isCandidateInitiatedPosition
    isAssessment360Position
    externalJobId
    reviewCycleId
    referenceRequirementSyntax {
      ${referenceRequirementSyntax}
    }
    candidateFormComponentGroup {
      formComponentGroupId
    }
    company {
      companyId
    }
    candidates {
      candidateProfileId
    }
    candidatePositionRelations {
      candidatePositionRelationId
    }
    formComponentGroup {
      formComponentGroupId
    }
    positionReferenceRequirements {
      positionReferenceRequirementId
    }
    hirerPositionRelations {
      hirerPositionRelationId
    }
    benchmarkRequests {
      benchmarkRequestId
      benchmarkRequestKey
    }
    externalJobSource {
      externalJobSourceId
      sourceName
    }
    positionTemplate {
      positionTemplateId
    }
    automationSetting {
      automationSettingKey
    }
    hirerPositionRelations {
      hirerPositionRelationId
    }
    candidatePositionRelations {
      candidatePositionRelationId
    }
  }
`;

export const UPCOMING_INVITATION_FRAGMENT = gql`
  fragment upcomingInvitationDetails on UpcomingInvitation {
    upcomingInvitationKey
    invitationDate
    invitationDateOverride
  }
`;

export const AUTOMATED_INVITE_HISTORY = gql`
  fragment automatedInviteHistory on AutomatedInviteHistory {
    automatedInviteHistoryKey
    status
    invitationDate
    employee {
      employeeName
      employeeEmail
      employeeStartDate
    }
  }
`;

export const WORKDAY_PARTNER = gql`
  fragment workdayPartnerDetails on WorkdayPartner {
    ${workdayPartnerDetails}
  }
`;

export const SR_PARTNER = gql`
  fragment srPartnerDetails on SrPartner {
    ${srPartnerDetails}
  }
`;

export const COMPANY_ROLE_FRAGMENT = gql`
  fragment companyRoleDetails on CompanyRole {
    companyRoleKey
    name
    roleDescription
    company {
      companyKey
    }
    creatorHirerProfile {
      # hirerProfileKey
      hirerProfileId
    }
    accessPermissions {
      accessPermissionKey
    }
  }
`;
