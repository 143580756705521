import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import isUndefined from 'lodash/isUndefined';

interface ScreenApplicantsStore {
  ratingSearch?: number;
  setRatingSearch: (rating: number) => void;
  applicationStatus?: Array<string>;
  setApplicationStatus: (applicationStatus?: Array<string>) => void;
  keywordSearch?: string;
  setKeywordSearch: (keyword?: string) => void;
  totalCounter?: number;
  setTotalCounter: (totalCounterInput: number) => void;
  filteredTotalCounter?: number;
  setFilteredTotalCounter: (filteredCounterInput: number) => void;
  counters: {
    neutralCounter?: number;
    aiRecommendedCounter?: number;
    notAFitCounter?: number;
  };
  setCounters: (updatedCounters: { bucket: number; count: number }[]) => void;
  filteredCounters: {
    neutralCounter?: number;
    aiRecommendedCounter?: number;
    notAFitCounter?: number;
  };
  setFilteredCounters: (updatedCounters: { bucket: number; count: number }[]) => void;
  reset: () => void;
}

const useScreenApplicantsStore = create<ScreenApplicantsStore>()(
  devtools(
    persist(
      set => ({
        applicationStatus: ['needs-review'],
        setApplicationStatus: applicationStatus => set({ applicationStatus }),
        setKeywordSearch: keyword => set({ keywordSearch: keyword }),
        setRatingSearch: rating => set({ ratingSearch: rating }),
        setTotalCounter: totalCounterInput =>
          set(state => {
            const { totalCounter } = state;

            if (isUndefined(totalCounter)) {
              return { ...state, totalCounter: totalCounterInput };
            }
            return state;
          }),
        totalCounter: undefined,
        counters: {
          aiRecommendedCounter: undefined,
          neutralCounter: undefined,
          notAFitCounter: undefined,
          totalCounter: undefined,
        },
        setCounters: updatedCounters =>
          set(state => {
            const {
              counters: { aiRecommendedCounter, neutralCounter, notAFitCounter },
            } = state;

            if (
              isUndefined(aiRecommendedCounter) &&
              isUndefined(neutralCounter) &&
              isUndefined(notAFitCounter)
            ) {
              let notAFitCounter = 0;
              let neutralCounter = 0;
              let aiRecommendedCounter = 0;
              for (const { bucket, count } of updatedCounters) {
                switch (bucket) {
                  case 0:
                    notAFitCounter += count;
                    break;
                  case 1:
                    neutralCounter += count;
                    break;
                  case 2:
                    aiRecommendedCounter += count;
                    break;
                  default:
                    break;
                }
              }

              const newState = state;
              newState.counters.notAFitCounter = notAFitCounter;
              newState.counters.neutralCounter = neutralCounter;
              newState.counters.aiRecommendedCounter = aiRecommendedCounter;
              return newState;
            }
            return state;
          }),
        filteredCounters: {
          aiRecommendedCounter: undefined,
          neutralCounter: undefined,
          notAFitCounter: undefined,
          totalCounter: undefined,
        },
        setFilteredTotalCounter: totalCounterInput =>
          set(state => ({ ...state, filteredTotalCounter: totalCounterInput })),
        filteredTotalCounter: undefined,
        setFilteredCounters: updatedCounters =>
          set(state => {
            let notAFitCounter = 0;
            let neutralCounter = 0;
            let aiRecommendedCounter = 0;
            for (const { bucket, count } of updatedCounters) {
              switch (bucket) {
                case 0:
                  notAFitCounter += count;
                  break;
                case 1:
                  neutralCounter += count;
                  break;
                case 2:
                  aiRecommendedCounter += count;
                  break;
                default:
                  break;
              }
            }

            const newState = state;
            newState.filteredCounters.notAFitCounter = notAFitCounter;
            newState.filteredCounters.neutralCounter = neutralCounter;
            newState.filteredCounters.aiRecommendedCounter = aiRecommendedCounter;
            return newState;
          }),
        reset: () =>
          set({
            ratingSearch: undefined,
            keywordSearch: undefined,
            applicationStatus: ['needs-review'],
            counters: {
              aiRecommendedCounter: undefined,
              neutralCounter: undefined,
              notAFitCounter: undefined,
            },
            filteredCounters: {
              aiRecommendedCounter: undefined,
              neutralCounter: undefined,
              notAFitCounter: undefined,
            },
          }),
      }),
      { name: 'screen-applicants-store' }
    )
  )
);

export { useScreenApplicantsStore };
