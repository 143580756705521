import { DEFAULT_LANGUAGE } from 'components/constants';
import { messages as componentMessagesEn } from 'locales/components/en/messages.mjs';
import { messages as formMessagesEn } from 'locales/forms/en/messages.mjs';

import { i18n } from '@lingui/core';

i18n.load(DEFAULT_LANGUAGE, {
  ...componentMessagesEn,
  ...formMessagesEn,
});
i18n.loadLocaleData(DEFAULT_LANGUAGE, { plurals: () => ({}) });

export { i18n };
