import React from 'react';
import moment from 'moment';
import head from 'lodash/head';
import startCase from 'lodash/startCase';
import uniqBy from 'lodash/uniqBy';
import memoize from 'lodash/memoize';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';

import {
  declinedStatus,
  pendingStatus,
  activeStatus,
  invitedStatus,
  acceptedStatus,
  timeOverlapOptions,
  savedStatus,
  undeliveredStatus,
} from '../config/AppConfig';
import { LOGO_S3_BASE_URL, MAX_TEXT_LENGTH } from './constants';
import IntercomWrapper from 'util/intercom';

const DEFAULT_BASE_FILTERS = {
  keyword: '',
  page: 1,
  pageSize: 10,
  sortDirection: 'desc',
};

export function validateFetchResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.status !== 200) {
      response
        .json()
        .then(json => {
          reject(Error(json.message));
        })
        .catch(error => {
          reject(error);
        });
    } else {
      response
        .json()
        .then(json => {
          resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    }
  });
}

export function isAndroid() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isAndroid = userAgent.indexOf('android') !== -1;
  return isAndroid;
}

export function replaceWithCandidateInformation(
  string,
  candidateFirstName,
  candidateFullName,
  referencerFirstName,
  referencerEmail
) {
  if (string) {
    let updatedString = string.replace(/:candidateFirstName/g, candidateFirstName);
    updatedString = updatedString.replace(/:candidateFullName/g, candidateFullName);
    updatedString = updatedString.replace(/:referencerEmail/g, referencerEmail);
    updatedString = updatedString.replace(/:referencerFirstName/g, referencerFirstName);
    return updatedString;
  }
  return null;
}

export function flattenArrayOfArrays(arr) {
  return arr.reduce((acc, result) => acc.concat(result));
}

function recurse(cur, prop, result, key, relations) {
  if (React.isValidElement(cur)) {
    return result;
  }
  if (typeof cur === 'object' && cur !== null && cur[key]) {
    relations.push(cur);
  }
  if (Object(cur) !== cur) {
    result[prop] = cur;
  } else if (Array.isArray(cur)) {
    let l = cur.length;
    for (let i = 0; i < l; i++) {
      recurse(cur[i], prop + '[' + i + ']', result, key, relations);
      l = cur.length;
    }
    if (l === 0) {
      result[prop] = [];
    }
  } else {
    let isEmpty = true;
    for (let p in cur) {
      isEmpty = false;
      recurse(cur[p], prop ? prop + '.' + p : p, result, key, relations);
    }
    if (isEmpty && prop) {
      result[prop] = {};
    }
  }
}

export function flattenObjectsWithKey(data, key) {
  const relations = [];

  recurse(data, '', {}, key, relations);
  return relations;
}

// this function returns an array of <li> components with experience details
export const returnExperienceDetails = experienceDetails => {
  const renderedDetails = [];
  for (let i = 0; i < experienceDetails.length; i++) {
    renderedDetails.push(<li key={i}>{experienceDetails[i].componentText}</li>);
  }
  return renderedDetails;
};

// this function returns the location string for a candidate experience
export const returnExperienceLocation = experience => {
  let location = '';
  if (experience.locationCity) {
    location += experience.locationCity;
  }
  if (experience.locationState) {
    if (location.length > 0) {
      location += `, ${experience.locationState}`;
    } else {
      location += experience.locationState;
    }
  }
  if (experience.locationCountry) {
    if (location.length > 0) {
      location += `, ${experience.locationCountry}`;
    } else {
      location += experience.locationCountry;
    }
  }
  return location;
};

export function formatDatetimeToLocale(dateTimeString) {
  // the date is stored as UTC and should be read as UTC
  const date = moment.utc(dateTimeString).local().format('MM/DD/YY hh:mm A');
  return date;
}

export function formatDatetimeToDate(dateTimeString) {
  // the date is stored as UTC and should be read as UTC
  const date = moment.utc(dateTimeString);
  let month = (date.month() + 1).toString(10);
  if (month.length < 2) {
    // pad with 0
    month = '0' + month;
  }
  return `${month}/${date.year()}`;
}

export function formatDatetimeToDayMonthYear(dateTimeString) {
  // the date is stored as UTC and should be read as UTC
  const date = moment.utc(dateTimeString);
  let month = (date.month() + 1).toString(10);
  if (month.length < 2) {
    // pad with 0
    month = '0' + month;
  }
  let day = date.date().toString(10);
  if (day.length < 2) {
    // pad with 0
    day = '0' + day;
  }
  return `${month}/${day}/${date.year()}`;
}

export function formatResumeDate(month, year) {
  if (month && year) {
    return `${month}/${year}`;
  } else if (year) {
    return year;
  }
  return null;
}

export function transformSubsectionQuestionRelationApiOutputs(relations) {
  return relations.map(relation => ({
    ...relation,
    subsectionQuestionRelationId: +relation.subsectionQuestionRelationId,
  }));
}

export function transformCandidatePositionRelationsApiOutputs(relations) {
  return relations.map(relation => ({
    ...relation,
    candidatePositionRelationId: +relation.candidatePositionRelationId,
    candidateProfileId: +relation.candidateProfileId,
    positionId: +relation.positionId,
  }));
}

export function getStatusFromRequest(request) {
  const status = request.statusType.typeName;
  if (status === savedStatus) return 'in progress';
  if (status === undeliveredStatus) return undeliveredStatus;

  if (
    status !== pendingStatus &&
    status !== activeStatus &&
    status !== declinedStatus &&
    status !== invitedStatus
  )
    return 'completed';
  return status;
}

export function getStatusFromRelation(relation) {
  if (relation.hasRecommendation) {
    return acceptedStatus;
  }
  if (!relation.referenceRequests) return pendingStatus;
  const request = head(relation.referenceRequests);
  const status = request ? getStatusFromRequest(request) : pendingStatus;
  return status.toLowerCase();
}

export function returnValidReferenceRequestsForRelations(relations) {
  const relationsWithReferenceRequests = relations.filter(
    relation => !relation.isArchived && relation.referenceRequests.length > 0
  );
  return relationsWithReferenceRequests;
}

export function transformTimeOverlap(timeOverlap) {
  // transform the timeOverlap value into one of the options
  const timeOverlapValues = timeOverlapOptions.map(option => option.value);
  if (timeOverlapValues.indexOf(timeOverlap) > -1) return timeOverlap;
  // else: must transform
  let timeOverlapInt = +timeOverlap;

  switch (true) {
    case timeOverlapInt < 1:
      return timeOverlapOptions[0].value;
    case timeOverlapInt === 1:
      return timeOverlapOptions[1].value;
    // 1 - 2.99 = 2
    case timeOverlapInt < 3:
      return timeOverlapOptions[2].value;
    // 3 - 3.99
    case timeOverlapInt < 4:
      return timeOverlapOptions[3].value;
    // 4 - 4.99
    case timeOverlapInt < 5:
      return timeOverlapOptions[4].value;
    // 5 - 3.99
    case timeOverlapInt === 5:
      return timeOverlapOptions[5].value;
    case timeOverlapInt <= 10:
      return timeOverlapOptions[6].value;
    case timeOverlapInt > 10:
      return timeOverlapOptions[7].value;
    default:
      return null;
  }
}

function typesToCounts(arr) {
  return arr.reduce((a, b) => ({ ...a, [b]: (a[b] || 0) + 1 }), {});
}

export function prepareAttributeData(
  attributes,
  attributesSelected,
  attributesProps,
  hideCount = false
) {
  const totalAttributeCount = uniqBy(attributes, a => a.attributeId).length;

  // Filter by only selected attributes if selected
  if (attributesSelected.length > 0) {
    attributes = attributes.filter(a => attributesSelected.includes(+a.attributeId));
  }

  const counts = attributesProps
    ? typesToCounts(
        attributes.map(a =>
          startCase(
            attributesProps.find(aa => aa.id === +a.attributeId) &&
              attributesProps.find(aa => aa.id === +a.attributeId).label
          )
        )
      )
    : {};

  // sort in DESC order
  const sortedTuples = Object.keys(counts)
    .map(key => [key, counts[key]])
    .sort((a, b) => b[1] - a[1]);

  let countsString = sortedTuples
    .splice(0, 3)
    .reduce((total, tuple) => (total += `${tuple[0]}${hideCount ? '' : ` (${tuple[1]})`}, `), '');

  if (totalAttributeCount > 3) {
    countsString += `+ ${totalAttributeCount - 3} others`;
  } else {
    // Cut off the trailing comma
    countsString = countsString.slice(0, countsString.length - 2);
  }

  return countsString;
}

export const buildTitleForHirer = hirerProfile => {
  if (hirerProfile.hirerInfoPending) {
    return hirerProfile.hirerEmail;
  }
  const { user = {} } = hirerProfile;
  const { firstName = '', lastName = '' } = user;
  return `${firstName} ${lastName}`;
};

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function clearUserSession() {
  localStorage.clear();
  window.analytics.reset();
  IntercomWrapper('shutdown');
}

export function getLogoImageUrl(companyName) {
  const logo = companyName
    .toLowerCase()
    .replace(' ', '_')
    .replace('.', '_')
    .replace(/[^\w\s]/gi, '');

  return `${LOGO_S3_BASE_URL}/${logo}.png`;
}

export const questionIsAvailable = question =>
  // If it's a current question and one answered and it's not a conditional question
  // don't show it
  question.deprecatedQuestion ||
  question.answers.find(a => a.notAnswered === false) ||
  question.isConditional;

export function scrollTo(...params) {
  if (window.scrollTo) {
    window.scrollTo(...params);
  } else {
    // "Edge" case
    window.scrollTop = params[0];
  }
}

export function getCoordinatesForPercent(rad) {
  const x = Math.cos(rad);
  const y = Math.sin(rad);

  return [x.toFixed(4), y.toFixed(4)];
}

export function computeCentroid(startAngle, endAngle) {
  const alpha = endAngle - startAngle;
  // get coordinates of centroid if startAngle = 0
  const xbar = (2 / 3) * (1 / alpha) * Math.sin(alpha);
  const ybar = (-2 / 3) * (1 / alpha) * (Math.cos(alpha) - 1);
  // rotate coordinates about (0, 0) by startAngle
  const xCoord = xbar * Math.cos(startAngle) - ybar * Math.sin(startAngle);
  const yCoord = ybar * Math.cos(startAngle) + xbar * Math.sin(startAngle);

  return [xCoord, yCoord];
}

function calculateWordWidths(words, width, height) {
  // Calculate length of each word to be used to determine number of words per line
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  svg.setAttribute('height', height);
  svg.setAttribute('width', width);
  svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
  text.setAttribute('font-size', '14px');
  svg.appendChild(text);
  document.body.appendChild(svg);

  const wordsWithComputedWidth = words.map(word => {
    text.textContent = word;
    // Having issues with JSDom (for testing)
    return { word, width: text.getComputedTextLength ? text.getComputedTextLength() : 0 };
  });

  text.textContent = '\u00A0'; // Unicode space
  // Having issues with JSDom (for testing)
  const spaceWidth = text.getComputedTextLength ? text.getComputedTextLength() : 0;

  document.body.removeChild(svg);

  return { wordsWithComputedWidth, spaceWidth };
}

function calculateLines(wordsWithComputedWidth, spaceWidth, lineWidth) {
  const wordsByLines = wordsWithComputedWidth.reduce((result, { word, width }) => {
    const lastLine = result[result.length - 1] || { words: [], width: 0 };
    if (lastLine.words.length === 0) {
      // First word on line
      const newLine = { words: [word], width };
      result.push(newLine);
    } else if (lastLine.width + width + lastLine.words.length * spaceWidth < lineWidth) {
      // Word can be added to an existing line
      lastLine.words.push(word);
      lastLine.width += width;
    } else {
      // Word too long to fit on existing line
      const newLine = { words: [word], width };
      result.push(newLine);
    }

    return result;
  }, []);

  return wordsByLines.map(line => line.words.join(''));
}

export const computeLabelLines = memoize((label, lineWidth, height) => {
  if (!label) {
    return [''];
  }
  const { wordsWithComputedWidth, spaceWidth } = calculateWordWidths(
    label.split(/(\s|\W)/),
    lineWidth,
    height
  );

  return calculateLines(wordsWithComputedWidth, spaceWidth, lineWidth);
});

export function getCandidateDisplayNameByCandidateProfileOrPositionRelation(
  candidateProfile,
  relation
) {
  let name = '';
  if (candidateProfile && candidateProfile.user) {
    name = `${candidateProfile.user.firstName} ${candidateProfile.user.lastName}`;
  } else if (relation && relation.candidateFirstName && relation.candidateLastName) {
    name = `${relation.candidateFirstName} ${relation.candidateLastName}`;
  } else {
    name = relation ? relation.candidateEmail : '';
  }
  return name;
}

export const truncateText = (text, maxLength = MAX_TEXT_LENGTH) =>
  text.length < maxLength ? text : `${text.substring(0, maxLength)}...`;

export const pullKeysFromObject = (keys, object) =>
  keys.reduce((acc, key) => {
    acc[key] = object[key];
    return acc;
  }, {});

export const copyTextToClipboard = text => {
  let input = document.createElement('input');
  input.setAttribute('value', text);
  document.body.appendChild(input);
  input.select();
  let result = document.execCommand('copy');
  document.body.removeChild(input);
  return result;
};

export function getCleanedPropsForInput(props) {
  return Object.entries(props).reduce((acc, [key, propValue]) => {
    if (
      key === 'children' ||
      (/[A-Z]/.test(key) &&
        !/fullWidth|maxRows|minRows|onHeightChange|cacheMeasurements|readOnly|onBlur|onKeyDown/.test(
          key
        ))
    ) {
      return acc;
    }
    acc[key] = propValue;
    return acc;
  }, {});
}

export const numberToLetters = memoize(num => {
  let letters = '';
  while (num >= 0) {
    letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters;
    num = Math.floor(num / 26) - 1;
  }
  return letters;
});

export function getQueryStringParams(query) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value) : '';
        return params;
      }, {})
    : {};
}

export function filterMultiSelectedItem(contents, selected, idKey = 'id') {
  return contents.filter(({ [idKey]: id }) =>
    Array.isArray(selected)
      ? selected.find(option => option[idKey] === id || option === id)
      : id === selected || id === get(selected, idKey)
  );
}

export const returnQueryParams = (keysToIgnore = []) => {
  const params = qs.parse(window.location.search.slice(1));
  const args = Object.keys(params).reduce(
    (acc, key) => {
      const value = params[key];
      // Also ignore default values
      if (isEmpty(value) || keysToIgnore.includes(key)) {
        delete params[key];
      }
      return acc;
    },
    { ...params }
  );
  if (Object.keys(params).length > 0) {
    return `?${decodeURIComponent(
      qs.stringify({
        ...args,
      })
    ).replace(/\[\d+\]/g, '')}`;
  }
  return '';
};

// Update the window url based on input vars
export const setWindowState = (args, keysToIgnore = [], skipLocalStorage = false) => {
  const allKeysToIgnore = keysToIgnore.concat(['companyId', 'companyKey']);
  const searchParams = qs.parse(window.location.search);
  const params = Object.keys(args).reduce(
    (acc, key) => {
      const value = args[key];
      // Also ignore default values
      if (isEmpty(value) || allKeysToIgnore.includes(key) || DEFAULT_BASE_FILTERS[key] === value) {
        delete searchParams[key];
      }
      return acc;
    },
    { ...args }
  );
  let newUrl = `${window.location.pathname}${window.location.hash}`;
  const paramsString = qs.stringify({
    ...searchParams,
    ...params,
  });
  if (Object.keys(params).length > 0) {
    newUrl = `${window.location.pathname}?${paramsString}${window.location.hash}`;
  }
  setTimeout(() => {
    window.history.replaceState(null, document.title, newUrl);
  }, 10);

  if (skipLocalStorage === true) {
    return;
  }

  const savedState = JSON.parse(window.localStorage.getItem('filters') || '{}');
  const {
    keyword: _keyword,
    page: _page,
    pageSize: _pageSize,
    sortField: _sortField,
    ...rest
  } = {
    ...savedState,
    ...params,
  };
  window.localStorage.setItem('filters', JSON.stringify(rest));
};

export const updateUrlWhenLoadData = (
  page,
  pageSize,
  keyword,
  selectedPositions = [],
  selectedStrengths = [],
  selectedGrowthAreas = [],
  selectedStatuses = [],
  hash = ''
) => {
  const location = window.location;
  const params = qs.parse(location.search);
  if (isNil(page)) {
    delete params.page;
  } else {
    params.page = page;
  }

  params.pageSize = pageSize;

  if (isNil(keyword) || keyword === '') {
    delete params.keyword;
  } else {
    params.keyword = keyword;
  }

  delete params.selectedPositions;
  if (selectedPositions && selectedPositions.length > 0) {
    params.selectedPositions = selectedPositions.join(',');
  }

  delete params.selectedStrengths;
  if (selectedStrengths && selectedStrengths.length > 0) {
    params.selectedStrengths = selectedStrengths.join(',');
  }

  delete params.selectedGrowthAreas;
  if (selectedGrowthAreas && selectedGrowthAreas.length > 0) {
    params.selectedGrowthAreas = selectedGrowthAreas.join(',');
  }

  delete params.selectedStatuses;
  if (selectedStatuses && selectedStatuses.length > 0) {
    params.selectedStatuses = selectedStatuses.join(',');
  }

  const paramsString = qs.stringify(params);

  let newUrl = `${location.pathname}${hash}`;
  if (Object.keys(params).length > 0) {
    newUrl = `${location.pathname}?${paramsString}${hash}`;
  }
  setTimeout(() => {
    window.history.replaceState(params, document.title, newUrl);
  }, 10);
};

export const isHexColor = color => /(#[a-z0-9]{6})|hsl\(.+\)/i.test(color || '');
