export default {
  keyFields: ({ greenhousePartnerId: id }, { typename }) => `${typename}_${id}`,
  fields: {
    greenhousePartnerId: {
      read(greenhousePartnerId) {
        return +greenhousePartnerId;
      },
    },
  },
};
export const greenhousePartnerCacheRef = greenhousePartnerId =>
  greenhousePartnerId ? `GreenhousePartner_${greenhousePartnerId}` : null;
