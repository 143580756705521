export type GenderOptions = 'MALE' | 'FEMALE' | 'NON-BINARY' | 'OTHER' | 'PREFER_NOT_TO_DISCLOSE';

export type EthnicityOptions =
  | 'AMERICAN_INDIAN_OR_ALASKA_NATIVE'
  | 'ASIAN_OR_INDIAN_SUBCONTINENT'
  | 'BLACK_OR_AFRICAN_AMERICAN'
  | 'HISPANIC_OR_LATINO'
  | 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER'
  | 'TWO_OR_MORE_RACES'
  | 'WHITE'
  | 'PREFER_NOT_TO_DISCLOSE';

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  'NON-BINARY' = 'NON-BINARY',
  OTHER = 'OTHER',
  PREFER_NOT_TO_DISCLOSE = 'PREFER_NOT_TO_DISCLOSE',
}

export enum EthnicityEnum {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  ASIAN_OR_INDIAN_SUBCONTINENT = 'ASIAN_OR_INDIAN_SUBCONTINENT',
  BLACK_OR_AFRICAN_AMERICAN = 'BLACK_OR_AFRICAN_AMERICAN',
  HISPANIC_OR_LATINO = 'HISPANIC_OR_LATINO',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  TWO_OR_MORE_RACES = 'TWO_OR_MORE_RACES',
  WHITE = 'WHITE',
  PREFER_NOT_TO_DISCLOSE = 'PREFER_NOT_TO_DISCLOSE',
}
