import { ApolloCache, makeVar } from '@apollo/client';

import {
  FetchPublicSkillMapsInput,
  FetchSkillMapsForCompanyInput,
  PagingSkillMaps,
  SkillMap,
  SkillMapLevel,
  MakeOptional,
} from 'types';
import { EmptyObject, KeyFieldsContext } from 'types/interfaces';
import {
  CELLS_ON_SKILL_MAP_COMPETENCY,
  COMPETENCIES_ON_SKILL_MAP,
  LEVELS_ON_SKILL_MAP,
  SKILL_MAP_CELL_FRAGMENT,
  SKILL_MAP_COMPETENCY_DETAILS_FRAGMENT,
  SKILL_MAP_DETAILS_FRAGMENT,
  SKILL_MAP_LEVEL_FRAGMENT,
} from 'api/fragments';
import { getPagingQueryField, readAndWritePagindatedDataToCache } from './helpers';
import { FETCH_SKILL_MAPS_FOR_COMPANY } from 'api/skillMap_api';

export default {
  keyFields: ({ skillMapId: id }: SkillMap, { typename }: KeyFieldsContext): string =>
    `${typename}_${id}`,
  fields: {
    skillMapId: {
      read(skillMapId: string): number {
        return +skillMapId;
      },
    },
    skillMapLevels: {
      merge(_existing: Array<SkillMapLevel>, incoming: Array<SkillMapLevel>): Array<SkillMapLevel> {
        return incoming;
      },
    },
  },
};

export const fetchSkillMapsVar = makeVar<
  MakeOptional<FetchSkillMapsForCompanyInput, 'companyId'> | EmptyObject
>({});

export const fetchPublicSkillMaps = getPagingQueryField<
  SkillMap,
  PagingSkillMaps,
  FetchPublicSkillMapsInput
>(
  [
    'keyword',
    'sortDirection',
    'sortField',
    'standardDepartmentKeys',
    'standardJobCareerTrackKeys',
    'standardJobFamilyKeys',
  ],
  'skillMapId'
);

export const fetchSkillMapsForCompany = getPagingQueryField<
  SkillMap,
  PagingSkillMaps,
  FetchSkillMapsForCompanyInput
>(
  [
    'keyword',
    'companyId',
    'isArchived',
    'sortDirection',
    'sortField',
    'standardDepartmentKeys',
    'standardJobCareerTrackKeys',
    'standardJobFamilyKeys',
  ],
  'skillMapId'
);

export const updateSkillMapInCache = (cache: ApolloCache<unknown>, data: SkillMap): void => {
  const { skillMapId, skillMapLevels, skillMapCompetencies, ...skillMap } = data;

  cache.writeFragment({
    id: `SkillMap_${skillMapId}`,
    fragment: SKILL_MAP_DETAILS_FRAGMENT,
    data: { skillMapId: +skillMapId, ...skillMap },
  });

  if (skillMapLevels) {
    skillMapLevels.forEach(({ skillMapLevelId, ...skillMapLevel }) => {
      cache.writeFragment({
        id: `SkillMapLevel_${skillMapLevelId}`,
        fragment: SKILL_MAP_LEVEL_FRAGMENT,
        data: {
          ...skillMapLevel,
          skillMapLevelId: +skillMapLevelId,
        },
      });
    });

    cache.writeFragment({
      fragment: LEVELS_ON_SKILL_MAP,
      fragmentName: 'skillMapLevels',
      id: `SkillMap_${skillMapId}`,
      data: {
        skillMapLevels: skillMapLevels.map(({ skillMapLevelId, ...skillMapLevel }) => ({
          ...skillMapLevel,
          skillMapLevelId: +skillMapLevelId,
        })),
      },
    });
  }
  if (skillMapCompetencies) {
    skillMapCompetencies.forEach(
      ({ skillMapCompetencyId, skillMapCells, ...skillMapCompetency }) => {
        // Update details
        cache.writeFragment({
          id: `SkillMapCompetency_${skillMapCompetencyId}`,
          fragment: SKILL_MAP_COMPETENCY_DETAILS_FRAGMENT,
          data: {
            ...skillMapCompetency,
            skillMapCompetencyId: +skillMapCompetencyId,
          },
        });
        // Update cells
        cache.writeFragment({
          id: `SkillMapCompetency_${skillMapCompetencyId}`,
          fragment: CELLS_ON_SKILL_MAP_COMPETENCY,
          fragmentName: 'skillMapCells',
          data: {
            skillMapCells:
              skillMapCells?.map(({ skillMapCellId, ...skillMapCell }) => ({
                skillMapCellId: +skillMapCellId,
                ...skillMapCell,
              })) || [],
          },
        });
        skillMapCells?.forEach(({ skillMapCellId, ...skillMapCell }) => {
          cache.writeFragment({
            id: `SkillMapCell_${skillMapCellId}`,
            fragment: SKILL_MAP_CELL_FRAGMENT,
            data: {
              ...skillMapCell,
              skillMapCellId: +skillMapCellId,
            },
          });
        });
      }
    );

    cache.writeFragment({
      fragment: COMPETENCIES_ON_SKILL_MAP,
      fragmentName: 'skillMapCompetencies',
      id: `SkillMap_${skillMapId}`,
      data: {
        skillMapCompetencies: skillMapCompetencies.map(
          ({ skillMapCompetencyId, ...skillMapCompetency }) => ({
            ...skillMapCompetency,
            skillMapCompetencyId: +skillMapCompetencyId,
          })
        ),
      },
    });
  }
};

export const addSkillMapToCache = (cache: ApolloCache<unknown>, data?: SkillMap): void => {
  if (!data) {
    return;
  }
  const skillMap = {
    ...data,
    skillMapId: +data.skillMapId,
  };
  const { companyId, keyword } = fetchSkillMapsVar() as FetchSkillMapsForCompanyInput;
  if (companyId) {
    const input = {
      companyId,
      keyword,
    };
    readAndWritePagindatedDataToCache(input, [skillMap], FETCH_SKILL_MAPS_FOR_COMPANY, cache);
  }
};
