import { CompanyFeatureFlags } from 'types';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export const COMPANY_FEATURE_FLAGS_EMPTY_STATE: CompanyFeatureFlags = {
  companyId: -1,
  analyticsDenyDefaultPreviewMode: false,
  candidateAssessmentsEnabled: false,
  candidateInvitations: false,
  candidateViewFullReport: false,
  companyCommsEnabled: false,
  customizeFormsToSkillMaps: false,
  defaultPositionsPageEnabled: false,
  enableCustomSurveysByRelationship: false,
  enableReviewSpecificComms: false,
  hasStandaloneICPs: false,
  idealCandidateProfilesEnabled: false,
  isGreenhousePartner: false,
  isLeverPartner: false,
  isTrialCompany: false,
  participantViewFullReport: false,
  reviewCyclesEnabled: false,
  showAllReferencesTable: false,
  showReferencerProspects: false,
  skillMapsEnabled: false,
  standalonePositionTemplatesEnabled: false,
  userProvisioning: false,
  usePostHireDataForTalentModels: false,
  useSearchlightScoreNumber: false,
  talentModelBenchmarksDisabled: false,
  demoJobFlowEnabled: false,
  defaultMyTeamsAnalyticsToPreview: false,
  searchlightScoreV2Enabled: false,
  disableFreeTrialExpiration: false,
  enableAtsApplicantFiltering: false,
  enableSoftSkillsAnalytics: false,
};

interface CompanyFeatureFlagState {
  companyFeatureFlags: CompanyFeatureFlags;
  deleteEverything: () => void;
  setCompanyFeatureFlags: (featureFlags?: CompanyFeatureFlags) => void;
}

const useCompanyFeatureFlagStore = create<CompanyFeatureFlagState>()(
  devtools(
    persist(
      set => ({
        companyFeatureFlags: COMPANY_FEATURE_FLAGS_EMPTY_STATE,
        deleteEverything: () => set({ companyFeatureFlags: COMPANY_FEATURE_FLAGS_EMPTY_STATE }),
        setCompanyFeatureFlags: (featureFlags?: CompanyFeatureFlags) =>
          set(() => ({
            companyFeatureFlags: featureFlags ? featureFlags : COMPANY_FEATURE_FLAGS_EMPTY_STATE,
          })),
      }),
      {
        name: 'feature-flag-storage',
      }
    )
  )
);

export { useCompanyFeatureFlagStore };
