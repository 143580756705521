import { Answer, QuestionAnswer as QuestionAnswerType } from 'types';

export default {
  keyFields: ({ answerId: id }: Answer, { typename }: { typename: string }): string =>
    `${typename}_${id}`,
  fields: {
    answerId: {
      read(answerId: string): number {
        return +answerId;
      },
    },
  },
};

export const QuestionAnswer = {
  keyFields: ({ answerId: id }: QuestionAnswerType, { typename }: { typename: string }): string =>
    `${typename}_${id}`,
  fields: {
    answerId: {
      read(answerId: string): number {
        return +answerId;
      },
    },
  },
};

export function answerCacheRef(answerId: string | number): string | null {
  return answerId ? `Answer_${answerId}` : null;
}

export function questionAnswerCacheRef(answerId: string | number): string {
  return `QuestionAnswer_${answerId}`;
}
