import { ApolloCache, makeVar } from '@apollo/client';

import {
  FETCH_BENCHMARK_REQUESTS_CREATED_BY_ME,
  FETCH_BENCHMARK_REQUEST_FOR_ME,
  FETCH_STANDALONE_BENCHMARKS_FOR_COMPANY,
} from 'api/benchmarks_api';
import { BENCHMARK_FRAGMENT } from 'api/fragments';
import {
  Benchmark,
  BenchmarkRequest,
  BenchmarksForCompanyPaging,
  FetchStandaloneBenchmarksForCompanyInput,
  FetchSharedBenchmarksForMeInput,
  Query,
  MakeRequired,
} from 'types';
import { EmptyObject, KeyFieldsContext } from 'types/interfaces';
import {
  fetchBenchmarkRequestsCreatedByMeVar,
  benchmarkRequestCacheRef,
  fetchBenchmarkRequestsForMeVar,
} from './benchmarkRequest';
import {
  getPagingQueryField,
  makeVarWithWindowUpdate,
  readAndWritePagindatedDataToCache,
} from './helpers';
import { hasuraClient } from 'clients/hasuraClient';

type FetchStandaloneBenchmarksForCompanyInputCacheInput = Pick<
  FetchStandaloneBenchmarksForCompanyInput,
  'keyword' | 'companyId' | 'createdByHirerId'
>;

export const benchmarkCacheRef = (benchmarkId: string | number | void): string | null =>
  benchmarkId ? `Benchmark_${benchmarkId}` : null;

const _fetchStandaloneBenchmarksForCompanyVar = makeVar<
  FetchStandaloneBenchmarksForCompanyInput | EmptyObject
>({});

export const fetchSharedBenchmarksForMeVar = makeVar<FetchSharedBenchmarksForMeInput | EmptyObject>(
  {}
);

export const fetchStandaloneBenchmarksForCompanyVar = makeVarWithWindowUpdate(
  _fetchStandaloneBenchmarksForCompanyVar,
  ['createdByHirerId']
);

function updateFetchStandaloneBenchmarksForCompanyQuery(
  cache: ApolloCache<unknown>,
  input: FetchStandaloneBenchmarksForCompanyInputCacheInput,
  benchmarkOrBenchmarkIdToDelete: Benchmark | number
) {
  try {
    const cachedFetchStandaloneBenchmarksForCompany = cache.readQuery<Query>({
      query: FETCH_STANDALONE_BENCHMARKS_FOR_COMPANY,
      variables: {
        input,
      },
    });
    if (cachedFetchStandaloneBenchmarksForCompany) {
      const { fetchStandaloneBenchmarksForCompany } = cachedFetchStandaloneBenchmarksForCompany;
      const deleting =
        typeof +benchmarkOrBenchmarkIdToDelete === 'number' &&
        !Number.isNaN(+benchmarkOrBenchmarkIdToDelete);

      cache.writeQuery({
        query: FETCH_STANDALONE_BENCHMARKS_FOR_COMPANY,
        variables: { input },
        data: {
          fetchStandaloneBenchmarksForCompany: {
            ...fetchStandaloneBenchmarksForCompany,
            totalCount: fetchStandaloneBenchmarksForCompany.totalCount + (deleting ? -1 : 1),
            data: deleting
              ? fetchStandaloneBenchmarksForCompany.data.filter(
                  ({ benchmarkId: id }) => +benchmarkOrBenchmarkIdToDelete !== +id
                )
              : [benchmarkOrBenchmarkIdToDelete, ...fetchStandaloneBenchmarksForCompany.data],
          },
        },
      });
    }
  } catch (e) {
    console.error(e);
  }
}

export function updateBenchmarkInCache(
  cache: ApolloCache<unknown>,
  benchmarkOrBenchmarkIdToDelete: Benchmark | number
): void {
  try {
    const { page: _page, pageSize: _pageSize, ...vars } = fetchStandaloneBenchmarksForCompanyVar();
    if (vars.companyId) {
      const { createdByHirerId, ...input } = vars;
      updateFetchStandaloneBenchmarksForCompanyQuery(
        cache,
        input as FetchStandaloneBenchmarksForCompanyInputCacheInput,
        benchmarkOrBenchmarkIdToDelete
      );
      if (createdByHirerId) {
        updateFetchStandaloneBenchmarksForCompanyQuery(
          cache,
          vars as FetchStandaloneBenchmarksForCompanyInputCacheInput,
          benchmarkOrBenchmarkIdToDelete
        );
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export function addBenchmarkRequestsToFetchBenchmarkRequestsForMe(
  cache: ApolloCache<unknown>,
  benchmarkRequests: BenchmarkRequest[],
  loggedInHirerProfileId: number
): void {
  const myBenchmarkRequests = benchmarkRequests.filter(
    ({ hirerProfile: { hirerProfileId } }) => +hirerProfileId === +loggedInHirerProfileId
  );
  const { companyId, keyword } = fetchBenchmarkRequestsForMeVar();
  const input = {
    companyId,
    keyword,
  };
  readAndWritePagindatedDataToCache(
    input,
    myBenchmarkRequests,
    FETCH_BENCHMARK_REQUEST_FOR_ME,
    cache
  );
}

export function addBenchmarkRequestsToFetchBenchmarkRequestsCreatedByMe(
  cache: ApolloCache<unknown>,
  benchmarkRequests: BenchmarkRequest[]
): void {
  const { companyId, keyword } = fetchBenchmarkRequestsCreatedByMeVar();
  const input = {
    companyId,
    keyword,
  };
  readAndWritePagindatedDataToCache(
    input,
    benchmarkRequests,
    FETCH_BENCHMARK_REQUESTS_CREATED_BY_ME,
    cache
  );
}

export function addBenchmarkToCache(
  cache: ApolloCache<unknown>,
  benchmark: Benchmark,
  loggedInHirerProfileId: number
): void {
  const { benchmarkRequests = [] } = benchmark;

  addBenchmarkRequestsToFetchBenchmarkRequestsForMe(
    cache,
    benchmarkRequests,
    loggedInHirerProfileId
  );
  addBenchmarkRequestsToFetchBenchmarkRequestsCreatedByMe(cache, benchmarkRequests);
  updateBenchmarkInCache(cache, benchmark);
}

export const removeBenchmarkFromCache = (
  cache: ApolloCache<unknown>,
  benchmark: Benchmark
): void => {
  const { benchmarkId, benchmarkRequests = [] } = benchmark;
  let id = benchmarkCacheRef(benchmarkId);
  id &&
    cache.evict({
      id,
    });

  updateBenchmarkInCache(cache, +benchmarkId);

  benchmarkRequests.forEach(({ benchmarkRequestId }) => {
    id = benchmarkRequestCacheRef(benchmarkRequestId);
    id &&
      cache.evict({
        id,
      });
  });
};

export const updateBenchmark = (
  cache: ApolloCache<unknown>,
  benchmark: MakeRequired<Benchmark, 'benchmarkId'>
): void => {
  cache.writeFragment({
    id: `Benchmark_${benchmark.benchmarkId}`,
    fragment: BENCHMARK_FRAGMENT,
    data: benchmark,
  });
  benchmark.benchmarkKey &&
    hasuraClient.cache.writeFragment({
      id: `Benchmarks_${benchmark.benchmarkKey}`,
      fragment: BENCHMARK_FRAGMENT,
      data: benchmark,
    });
};

export const fetchStandaloneBenchmarksForCompany = getPagingQueryField<
  Benchmark,
  BenchmarksForCompanyPaging,
  FetchStandaloneBenchmarksForCompanyInput
>(['createdByHirerId', 'companyId', 'keyword'], 'benchmarkId');

export const fetchSharedBenchmarksForMe = getPagingQueryField<
  Benchmark,
  BenchmarksForCompanyPaging,
  FetchSharedBenchmarksForMeInput
>(['companyId', 'keyword'], 'benchmarkId');

export default {
  keyFields: ({ benchmarkId: id }: Benchmark, { typename }: KeyFieldsContext): string =>
    `${typename}_${id}`,
  fields: {
    benchmarkId: {
      read(benchmarkId: string): number {
        return +benchmarkId;
      },
    },
    benchmarkRequests: {
      merge(_exisiting: unknown, incoming: BenchmarkRequest[]): BenchmarkRequest[] {
        return incoming;
      },
    },
    requisitionIds: {
      merge(_exisiting: unknown, incoming: string[]): string[] {
        return incoming;
      },
    },
  },
};
