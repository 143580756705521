import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { FilterData } from 'types/apollo';
import { CustomFilterOption } from 'components/HirerComponents/AnalyticsHub/TeamHiring/components/CustomFilter';

interface TeamHiringFiltersState {
  filters: Record<string, FilterData[] | FilterData>;
  // 👋 this can probably be done with a set, but for testing purposes I'm doing it with []
  // we should just plug another logic here if needed, and the component will behave the same
  addToFilters: (type: string, selectedFilters: FilterData[] | FilterData) => void;
  setFilters: (filters: Record<string, FilterData[] | FilterData>) => void;
  deleteFilter: (type: string) => void;
  deleteEverything: () => void;
  customFilters: Record<string, CustomFilterOption>;
  setCustomFilter: (name: string, value: CustomFilterOption) => void;
  deleteCustomFilter: (name: string) => void;
  employeeCount: number;
  setEmployeeCount: (count: number) => void;
}

const useTeamHiringFilterStore = create<TeamHiringFiltersState>()(
  devtools(
    set => ({
      filters: {},
      deleteEverything: () => set({ filters: {}, customFilters: {}, employeeCount: 0 }),
      addToFilters: (type: string, selectedFilters: FilterData[] | FilterData) =>
        set(state => ({
          filters: {
            ...state.filters,
            [`${type}`]: selectedFilters,
          },
        })),
      setFilters: (filters: Record<string, FilterData[] | FilterData>) =>
        set({
          filters,
        }),
      deleteFilter: (type: string) =>
        set(state => {
          const newFilters = state.filters;
          delete newFilters[type];
          return {
            filters: newFilters,
          };
        }),
      customFilters: {},
      setCustomFilter: (name: string, value: CustomFilterOption) =>
        set(state => ({ customFilters: { ...state.customFilters, [name]: value } })),
      deleteCustomFilter: (name: string) =>
        set(state => {
          const newFilters = state.customFilters;
          delete newFilters[name];
          return {
            customFilters: newFilters,
          };
        }),
      employeeCount: 0,
      setEmployeeCount: (count: number) => set(() => ({ employeeCount: count })),
    }),
    { name: 'team-hiring-filters' }
  )
);

export { useTeamHiringFilterStore };
