export type Maybe<T> = T | null | void;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  JSON: any;
};

export type AtsApplicationDetails = {
  __typename?: 'ATSApplicationDetails';
  ATSApplicationKey: Scalars['ID'];
  atsJobPostTitle: Scalars['String'];
  atsStageId: Scalars['String'];
  atsStageName: Scalars['String'];
  candidateName: Scalars['String'];
  currentStage: GreenhouseApplicationStage;
  email: Scalars['String'];
  lastScoredAt: Scalars['Date'];
  lastUpdatedScoringInformationAt: Scalars['Date'];
  previousCompany: Scalars['String'];
  previousTitle: Scalars['String'];
  rating: Scalars['Int'];
  rejectedAt: Scalars['Date'];
  rejectionReason: RejectionReason;
  resumeLink: Scalars['String'];
  status: Scalars['String'];
};

export type AtsJobCandidateListItem = {
  __typename?: 'ATSJobCandidateListItem';
  ATSApplicationKey: Scalars['ID'];
  atsJobPostTitle: Scalars['String'];
  atsStageId: Scalars['String'];
  atsStageName: Scalars['String'];
  candidateName: Scalars['String'];
  classifierRating: Scalars['Int'];
  currentStage: GreenhouseApplicationStage;
  email: Scalars['String'];
  previousCompany: Scalars['String'];
  previousTitle: Scalars['String'];
  rejectedAt: Scalars['Date'];
  rejectionReason: RejectionReason;
  resumeLink: Scalars['String'];
  status: Scalars['String'];
};

export type AtsJobCandidatePagingExtraContent = {
  __typename?: 'ATSJobCandidatePagingExtraContent';
  aiRecommendedCount: Scalars['Int'];
  allCount: Scalars['Int'];
  neutralCount: Scalars['Int'];
  notAFitCount: Scalars['Int'];
};

export type AtsJobCandidatesPaging = {
  __typename?: 'ATSJobCandidatesPaging';
  atsJobKey: Scalars['ID'];
  companyKey: Scalars['ID'];
  data: Array<AtsJobPostCandidateInformation>;
  extraContent: AtsJobCandidatePagingExtraContent;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AtsJobInformation = {
  __typename?: 'ATSJobInformation';
  acceptedCounter: Scalars['Int'];
  atsCreatedAt: Scalars['Date'];
  atsIsLive: Scalars['Boolean'];
  atsJobKey: Scalars['ID'];
  atsOpenedAt: Scalars['Date'];
  atsUpdatedAt: Scalars['Date'];
  companyKey: Scalars['ID'];
  competencyCriteria: Array<HiringCompetencyCriterion>;
  externalJobId: Scalars['String'];
  jobNotes: Scalars['String'];
  jobPostDescription: Scalars['String'];
  jobQuestionCriteria: Array<HiringJobQuestionCriterion>;
  jobTitle: Scalars['String'];
  lastBackfillApplicationsCompletedDate: Scalars['Date'];
  lastBackfillApplicationsRequestedDate: Scalars['Date'];
  lastProcessedAt: Scalars['Date'];
  lastUpdatedHiringCriteriaAt: Scalars['Date'];
  latestScoringJobPostKey: Scalars['String'];
  needsReviewCounter: Scalars['Int'];
  questions: Array<JobQuestion>;
  relevantExperienceCriteria: Array<HiringRelevantExperienceCriterion>;
  searchlightCreatedAt: Scalars['Date'];
  searchlightIsArchived: Scalars['Boolean'];
  searchlightUpdatedAt: Scalars['Date'];
  shouldScreenApplicants: Scalars['Boolean'];
  softSkillCriteria: Array<HiringSoftSkillCriterion>;
  sourceAts: Scalars['String'];
  status: Scalars['String'];
};

export type AtsJobInformationPaging = {
  __typename?: 'ATSJobInformationPaging';
  companyKey: Scalars['ID'];
  data: Array<AtsJobInformation>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AtsJobPostCandidateInformation = {
  __typename?: 'ATSJobPostCandidateInformation';
  ATSApplicationKey: Scalars['ID'];
  appliedAt: Scalars['Date'];
  candidateName: Scalars['String'];
  classifierRating: Scalars['Int'];
  competencyBucket: Scalars['Int'];
  isProcessing: Scalars['Boolean'];
  jobQuestionsSubscore: Scalars['Int'];
  lastUpdatedScoringInformationAt: Scalars['Date'];
  latestCompany: Scalars['String'];
  latestTitle: Scalars['String'];
  rejectedAt: Scalars['Date'];
  rejectionDetail: Scalars['String'];
  rejectionReason: RejectionReason;
  relevantExperienceBucket: Scalars['Int'];
  resumeLink: Scalars['String'];
  softSkillsBucket: Scalars['Int'];
  status: Scalars['String'];
};

export type AtsJobPostCandidatesPaging = {
  __typename?: 'ATSJobPostCandidatesPaging';
  companyKey: Scalars['ID'];
  data: Array<AtsJobPostCandidateInformation>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AtsJobPostInformation = {
  __typename?: 'ATSJobPostInformation';
  acceptedCounter: Scalars['Int'];
  atsCreatedAt: Scalars['Date'];
  atsIsLive: Scalars['Boolean'];
  atsJobPostKey: Scalars['ID'];
  atsOpenedAt: Scalars['Date'];
  atsUpdatedAt: Scalars['Date'];
  companyKey: Scalars['ID'];
  competencyCriteria: Array<HiringCompetencyCriterion>;
  externalJobId: Scalars['String'];
  externalJobPostId: Scalars['String'];
  jobPostDescription: Scalars['String'];
  jobPostTitle: Scalars['String'];
  jobQuestionCriteria: Array<HiringJobQuestionCriterion>;
  lastProcessedAt: Scalars['Date'];
  needsReviewCounter: Scalars['Int'];
  questions: Array<JobQuestion>;
  relevantExperienceCriteria: Array<HiringRelevantExperienceCriterion>;
  shouldScreenApplicants: Scalars['Boolean'];
  softSkillCriteria: Array<HiringSoftSkillCriterion>;
  sourceAts: Scalars['String'];
};

export type AtsJobPostInformationPaging = {
  __typename?: 'ATSJobPostInformationPaging';
  companyKey: Scalars['ID'];
  data: Array<AtsJobPostInformation>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AcceptRecommendationForCandidateInput = {
  candidateEmail?: Scalars['String'];
  firstName?: Scalars['String'];
  isCreate: Scalars['Boolean'];
  lastName?: Scalars['String'];
  password: Scalars['String'];
  uniqueCode: Scalars['String'];
  username: Scalars['String'];
};

export type AcceptRecommendationForCandidateWithGoogleInput = {
  googleCode: Scalars['String'];
  recommendationCode: Scalars['String'];
};

export type AcceptedAnswer = {
  __typename?: 'AcceptedAnswer';
  acceptedLabel: Scalars['ID'];
  acceptedValue: Scalars['ID'];
};

export type AccessPermission = {
  __typename?: 'AccessPermission';
  accessPermissionKey: Scalars['ID'];
  createdAt?: Scalars['Date'];
  description: Scalars['String'];
  internalIdentifier: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
  userType: Scalars['String'];
};

export type AccessPermissionsPaging = {
  __typename?: 'AccessPermissionsPaging';
  companyKey: Scalars['ID'];
  data: Array<AccessPermission>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection: Scalars['String'];
  sortField: Scalars['String'];
};

export type ActivateReferencerUserInput = {
  firstName?: Scalars['String'];
  lastName?: Scalars['String'];
  password: Scalars['String'];
  primaryEmail?: Scalars['String'];
  referencerProfileId: Scalars['Int'];
  username: Scalars['String'];
};

export type ActivityForUser = {
  __typename?: 'ActivityForUser';
  activity: ActivityModel;
  activity_id: Scalars['Int'];
  candidatePositionRelation: CandidatePositionRelation;
  type: ActivityType | `${ActivityType}`;
};

export type ActivityModel = CandidateAssessment | ReferenceAssessment | ReferenceRequest;

export enum ActivityType {
  candidate_assessment = 'candidate_assessment',
  reference_assessment = 'reference_assessment',
  reference_survey = 'reference_survey',
  review_survey = 'review_survey',
}

export type AddHirerPositionRelationMetaInput = {
  hirerProfileId: Scalars['Int'];
  receivesReferenceNotificationEmails: Scalars['Boolean'];
};

export type AddPositionForBenchmarkInput = {
  behavioralAssessmentTargetScore?: Scalars['Int'];
  benchmarkKey: Scalars['ID'];
  candidateFormComponentGroupId?: Scalars['Int'];
  candidateInviteFromHirer?: Scalars['String'];
  candidateViewFullReport?: Scalars['Boolean'];
  details?: Scalars['JSON'];
  formComponentGroupId?: Scalars['Int'];
  hideRespondentName?: Scalars['Boolean'];
  isArchived?: Scalars['Boolean'];
  isOpen?: Scalars['Boolean'];
  notifyCandidateWithUpdates?: Scalars['Boolean'];
  numRecommendedReferences?: Scalars['Int'];
  numRequiredReferences?: Scalars['Int'];
  positionIdentifier?: Scalars['String'];
  positionReferenceRequirements?: Array<AddPositionReferenceRequirementMetaInput>;
  referenceRequirementSyntax?: RefRequirementSyntaxNodeInput;
  referencerResponseDays?: Scalars['Int'];
  sendAutomaticRemindersToSubjects?: Scalars['Boolean'];
  subjectCanInviteRespondent?: Scalars['Boolean'];
  surveysToRelationships?: Array<AddPositionSurveyToRelationshipInput>;
  validSurveySubmissionWindowDays?: Scalars['Int'];
};

export type AddPositionReferenceRequirementMetaInput = {
  numRequired: Scalars['Int'];
  order?: Scalars['Int'];
  referenceRelationTypeId: Scalars['Int'];
};

export type AddPositionRelationToCandidateInput = {
  candidateProfileId: Scalars['Int'];
  details?: Scalars['JSON'];
  positionId: Scalars['Int'];
};

export type AddPositionSurveyToRelationshipInput = {
  formComponentGroupKey: Scalars['ID'];
  referenceRelationTypeKey: Scalars['ID'];
};

export type AddPositionTemplateForCompanyInput = {
  behavioralAssessmentTargetScore?: Scalars['Int'];
  candidateFormComponentGroupId?: Scalars['Int'];
  companyId: Scalars['Int'];
  formComponentGroupId?: Scalars['Int'];
  numRequiredReferences?: Scalars['Int'];
  referenceRequirementSyntax?: RefRequirementSyntaxNodeInput;
  referencerResponseDays: Scalars['Int'];
  surveysToRelationships?: Array<AddPositionTemplateSurveyToRelationshipInput>;
  taskTypes: Array<TaskType | `${TaskType}`>;
  templateName: Scalars['String'];
};

export type AddPositionTemplateSurveyToRelationshipInput = {
  formComponentGroupKey: Scalars['ID'];
  referenceRelationTypeKey: Scalars['ID'];
};

export type AddReferencerProfileInput = {
  details?: Scalars['JSON'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  linkedinUrl?: Scalars['String'];
  password: Scalars['String'];
  referencerAddress?: Scalars['String'];
  referencerEmail: Scalars['String'];
  referencerPhoneNumber?: Scalars['String'];
  username: Scalars['String'];
};

export type AddSlackNotificationInput = {
  code: Scalars['String'];
  companyId: Scalars['Int'];
  redirectUri: Scalars['String'];
};

export type AdminKeywordSearchFcGsPagingInput = {
  fcgTypeIds?: Array<Scalars['Int']>;
  fcgTypeName?: Scalars['String'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type AggregateAnswers = AggregateAttributeAnswers | AggregateTwoSliderAnswers;

export type AggregateAttributeAnswers = {
  __typename?: 'AggregateAttributeAnswers';
  all: Array<AttributeInfo>;
  template: Array<AttributeInfo>;
  top: Array<AttributeInfo>;
};

export type AggregateTwoSliderAnswers = {
  __typename?: 'AggregateTwoSliderAnswers';
  hasNonTemplateRespondents: Scalars['Boolean'];
  isCopyFromTemplate: Scalars['Boolean'];
  optionAId: Scalars['Int'];
  optionALabel: Scalars['String'];
  optionASubtitle: Scalars['String'];
  optionAValue: Scalars['Int'];
  optionBId: Scalars['Int'];
  optionBLabel: Scalars['String'];
  optionBSubtitle: Scalars['String'];
  optionBValue: Scalars['Int'];
  questionText: Scalars['String'];
  respondentAndAnswers: Array<RespondentAndAnswer>;
  templateOptionAValue: Scalars['Int'];
  templateOptionBValue: Scalars['Int'];
};

export type AggregatedStats = {
  __typename?: 'AggregatedStats';
  averageCandidateTurnaround: Scalars['Float'];
  averageReferenceTurnaround: Scalars['Float'];
  candidateStats: CandidateStats;
  candidateTurnaroundBenchmarkValue: Scalars['String'];
  companyId: Scalars['Int'];
  endDate: Scalars['Date'];
  positionTitles: Scalars['String'];
  referenceStats: ReferenceStats;
  referenceTurnaroundBenchmarkValue: Scalars['String'];
  startDate: Scalars['Date'];
  statusStatistics: StatusStats;
};

export type AggregatedStatsSearchInput = {
  companyId: Scalars['Int'];
  endDate?: Scalars['Date'];
  forReviews?: Scalars['Boolean'];
  positionIds?: Array<Scalars['Int']>;
  startDate?: Scalars['Date'];
};

export enum AllowTimeUnit {
  MONTH = 'MONTH',
  TOTAL = 'TOTAL',
  WEEK = 'WEEK',
}

export type AnalyticsHubCpr = {
  __typename?: 'AnalyticsHubCPR';
  CPRreferenceStatus: Scalars['Boolean'];
  CPRstatusType: StatusType;
  candidateAcceptedInvite: Scalars['Boolean'];
  candidateEmail: Scalars['String'];
  candidateFirstName: Scalars['String'];
  candidateInvitedDate: Scalars['Date'];
  candidateLastName: Scalars['String'];
  candidatePositionRelationId: Scalars['Int'];
  candidateProfileId: Scalars['Int'];
  greenhouseCandidateUrl: Scalars['String'];
  greenhouseHiringManagers: Array<Scalars['String']>;
  greenhousePositionTitle: Scalars['String'];
  hiringManagers: Array<HirerProfile>;
  leverCandidateUrl: Scalars['String'];
  leverHiringManagers: Array<Scalars['String']>;
  leverPositionTitle: Scalars['String'];
  numReferencesCompleted: Scalars['Int'];
  numReferencesInvited: Scalars['Int'];
  position: Position;
  searchlightScore: Scalars['String'];
  weighted_score: Scalars['Float'];
};

export type AnalyticsHubCandidates = {
  __typename?: 'AnalyticsHubCandidates';
  analyticsHubCPRs: Array<AnalyticsHubCpr>;
  companyId: Scalars['Int'];
  endDate: Scalars['Date'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  startDate: Scalars['Date'];
  totalCount: Scalars['Int'];
};

export type AnalyticsHubCandidatesLimited = {
  __typename?: 'AnalyticsHubCandidatesLimited';
  companyId: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AnalyticsHubCandidatesSearchForCsvInput = {
  companyId: Scalars['Int'];
  emailRecipient?: Scalars['String'];
  endDate?: Scalars['Date'];
  startDate?: Scalars['Date'];
};

export type AnalyticsHubCandidatesSearchInput = {
  companyId: Scalars['Int'];
  endDate?: Scalars['Date'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  startDate?: Scalars['Date'];
};

export type Answer = {
  __typename?: 'Answer';
  answerBody: Scalars['JSON'];
  answerGroup: AnswerGroup;
  answerGroupId: Scalars['Int'];
  answerId: Scalars['Int'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  isArchived: Scalars['Boolean'];
  questionComponent: QuestionComponent;
  skillMapCompetency: SkillMapCompetency;
  skillMapCompetencyId: Scalars['Int'];
  subsectionQuestionRelation: SubsectionQuestionRelation;
  subsectionQuestionRelationId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type AnswerGroup = {
  __typename?: 'AnswerGroup';
  answerGroupId: Scalars['Int'];
  answerGroupKey: Scalars['ID'];
  answers: Array<Answer>;
  createdAt: Scalars['Date'];
  currentSectionComponentId: Scalars['Int'];
  currentSectionComponentRelationId: Scalars['Int'];
  currentSkillMapCompetencyId: Scalars['Int'];
  currentSubsectionComponentId: Scalars['Int'];
  details: Scalars['JSON'];
  draftReferenceRequest: ReferenceRequest;
  draftReferenceRequestId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  questionAnswers: Array<QuestionAnswer>;
  referenceRequestId: Scalars['Int'];
  referencerProfileId: Scalars['Int'];
  responseProgress: CurrentProgressType;
  submissionDate: Scalars['Date'];
  submittedReferenceRequest: ReferenceRequest;
  submittedReferenceRequestId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export enum AnswerInterfaceEnum {
  attributes = 'attributes',
  bar_chart = 'bar_chart',
  multiple_choice_single_selection = 'multiple_choice_single_selection',
  open_ended = 'open_ended',
  slider = 'slider',
}

export type AnswerMeta = MultipleChoiceAnswerMeta | SkillMapAnswerMeta | TwoOptionSliderAnswerMeta;

export type AnswerOption = {
  __typename?: 'AnswerOption';
  answerOptionGroup: AnswerOptionGroup;
  answerOptionId: Scalars['Int'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  isArchived: Scalars['Boolean'];
  optionName: Scalars['String'];
  subtitle: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type AnswerOptionGroup = {
  __typename?: 'AnswerOptionGroup';
  answerOptionGroupId: Scalars['Int'];
  answerOptions: Array<AnswerOption>;
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  groupName: Scalars['String'];
  isArchived: Scalars['Boolean'];
  updatedAt: Scalars['Date'];
};

export type AnswerOptionInput = {
  optionName: Scalars['String'];
  subtitle?: Scalars['String'];
};

export enum AnswerTopicEnum {
  advice_for_manager = 'advice_for_manager',
  culture = 'culture',
  excitement = 'excitement',
  growth_areas = 'growth_areas',
  peer_ranking = 'peer_ranking',
  strengths = 'strengths',
  work_motivations = 'work_motivations',
}

export type AnswersForRelationsSubmittedForCandidateInput = {
  candidateProfileId: Scalars['Int'];
  subsectionQuestionRelationIds: Array<Scalars['Int']>;
};

export type ApplicationResumeRelevantExperience = {
  __typename?: 'ApplicationResumeRelevantExperience';
  latestTitle: Scalars['String'];
  relevantYOE: Scalars['String'];
  totalYOE: Scalars['String'];
};

export type ApplicationSubscoreCompetencyCriteria = {
  __typename?: 'ApplicationSubscoreCompetencyCriteria';
  applicationSubscoreCriteriaKey: Scalars['ID'];
  bucketScore: Scalars['Int'];
  criteriaKey: Scalars['ID'];
  criteriaQuote: Scalars['String'];
  dbModel: Scalars['String'];
  description: Scalars['String'];
  explanation: Scalars['String'];
  name: Scalars['String'];
  quote: Scalars['String'];
  score: Scalars['Int'];
  type: Scalars['String'];
};

export type ApplicationSubscoreCriteria = {
  __typename?: 'ApplicationSubscoreCriteria';
  applicationSubscoreCriteriaKey: Scalars['ID'];
  bucketScore: Scalars['Int'];
  context: Scalars['String'];
  criteriaKey: Scalars['ID'];
  dbModel: Scalars['String'];
  description: Scalars['String'];
  explanation: Scalars['String'];
  maxYears: Scalars['Int'];
  minYears: Scalars['Int'];
  name: Scalars['String'];
  quote: Scalars['String'];
  relatedTitles: Scalars['String'];
  score: Scalars['Int'];
};

export type ApplicationSubscoreCriteriaLists = {
  __typename?: 'ApplicationSubscoreCriteriaLists';
  HiringCompetencyCriteria: HiringCompetencyCriteria;
  HiringJobQuestionCriteria: HiringJobQuestionCriteria;
  HiringRelevantExperienceCriteria: HiringRelevantExperienceCriteria;
  HiringSoftSkillCriteria: HiringSoftSkillCriteria;
};

export type ApplicationSubscoreJobQuestionCriteria = {
  __typename?: 'ApplicationSubscoreJobQuestionCriteria';
  answer: Scalars['String'];
  hiringCriterionKey: Scalars['ID'];
  question: Scalars['String'];
};

export type ApplicationSubscoreRelevantExperienceCriteria = {
  __typename?: 'ApplicationSubscoreRelevantExperienceCriteria';
  applicationSubscoreCriteriaKey: Scalars['ID'];
  bucketScore: Scalars['Int'];
  context: Scalars['String'];
  criteriaKey: Scalars['ID'];
  criteriaQuote: Scalars['String'];
  dbModel: Scalars['String'];
  explanation: Scalars['String'];
  maxYears: Scalars['Int'];
  minYears: Scalars['Int'];
  quote: Scalars['String'];
  relatedTitles: Scalars['String'];
  score: Scalars['Int'];
};

export type ApplicationSubscoreSoftSkillCriteria = {
  __typename?: 'ApplicationSubscoreSoftSkillCriteria';
  applicationSubscoreCriteriaKey: Scalars['ID'];
  bucketScore: Scalars['Int'];
  criteriaKey: Scalars['ID'];
  criteriaQuote: Scalars['String'];
  dbModel: Scalars['String'];
  description: Scalars['String'];
  explanation: Scalars['String'];
  name: Scalars['String'];
  quote: Scalars['String'];
  score: Scalars['Int'];
};

export enum AshbyGroupsToGrantAccess {
  HiringManager = 'HiringManager',
  Recruiter = 'Recruiter',
  RecruitingCoordinator = 'RecruitingCoordinator',
  Sourcer = 'Sourcer',
}

export enum AshbyGroupsToUpdateOnSubmission {
  HiringManager = 'HiringManager',
  Recruiter = 'Recruiter',
  RecruitingCoordinator = 'RecruitingCoordinator',
  Sourcer = 'Sourcer',
}

export type AshbyJob = {
  __typename?: 'AshbyJob';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AshbyPartner = {
  __typename?: 'AshbyPartner';
  ashbyApiKey: Scalars['String'];
  ashbyPartnerKey: Scalars['ID'];
  company: Company;
  createdAt: Scalars['Date'];
  defaultPositionTemplate: PositionTemplate;
  groupsToGrantAccess: Array<AshbyGroupsToGrantAccess | `${AshbyGroupsToGrantAccess}`>;
  groupsToUpdateOnSubmission: Array<
    AshbyGroupsToUpdateOnSubmission | `${AshbyGroupsToUpdateOnSubmission}`
  >;
  isArchived: Scalars['Boolean'];
  searchlightApiKey: Scalars['String'];
  secretToken: Scalars['String'];
  updatedAt: Scalars['Date'];
  webhookUrls: Array<AshbyWebhook>;
};

export type AshbyRequest = {
  __typename?: 'AshbyRequest';
  applicationId: Scalars['String'];
  ashbyJobs: Array<AshbyJob>;
  ashbyPartner: AshbyPartner;
  ashbyRequestKey: Scalars['ID'];
  candidatePositionRelation: CandidatePositionRelation;
  candidateUrl: Scalars['String'];
  jobName: Scalars['String'];
  primaryJobId: Scalars['String'];
};

export type AshbyWebhook = {
  __typename?: 'AshbyWebhook';
  label: Scalars['String'];
  url: Scalars['String'];
};

export type Assessment360PositionDataInput = {
  positionId: Scalars['Int'];
};

export type Assessment360PositionDataOutput = {
  __typename?: 'Assessment360PositionDataOutput';
  next_avg: Scalars['Float'];
  next_level_title: Scalars['String'];
  next_target: Scalars['Int'];
  peer_avg: Scalars['Float'];
  peer_target: Scalars['Int'];
  skill_level_target: Scalars['Int'];
  title: Scalars['String'];
};

export type AssessmentForCandidate = {
  __typename?: 'AssessmentForCandidate';
  assessment: AssessmentModel;
  assessment_id: Scalars['Int'];
  candidatePositionRelation: CandidatePositionRelation;
  type: AssessmentType | `${AssessmentType}`;
};

export type AssessmentModel = CandidateAssessment | ReferenceAssessment;

export enum AssessmentType {
  candidate_assessment = 'candidate_assessment',
  reference_assessment = 'reference_assessment',
}

export type AssignCompanyRoleToHirerInput = {
  companyRoleKey: Scalars['ID'];
  hirerProfileKey: Scalars['ID'];
};

export type AtsCandidateListDetails = {
  __typename?: 'AtsCandidateListDetails';
  ATSApplicationKey: Scalars['ID'];
  candidateName: Scalars['String'];
  previousCompany: Scalars['String'];
  previousTitle: Scalars['String'];
};

export type Attribute = {
  __typename?: 'Attribute';
  attributeId: Scalars['Int'];
  attributeKey: Scalars['String'];
  attributeName: Scalars['String'];
  isStrength: Scalars['Boolean'];
  subtitle: Scalars['String'];
};

export type AttributeInfo = {
  __typename?: 'AttributeInfo';
  attributeId: Scalars['Int'];
  attributeName: Scalars['String'];
  isTop: Scalars['Boolean'];
  respondentAndAnswers: Array<RespondentAndAnswer>;
  subtitle: Scalars['String'];
};

export type AttributeMatch = {
  __typename?: 'AttributeMatch';
  benchmarkRequestId: Scalars['Int'];
  candidatePositionRelationId: Scalars['Int'];
  hirerEmail: Scalars['String'];
  matchScore: Scalars['String'];
  percentile: Scalars['Float'];
};

export type AttributeWithCount = {
  __typename?: 'AttributeWithCount';
  attribute: Attribute;
  count: Scalars['Int'];
};

export type AttributesAnswer = BaseAnswer & {
  __typename?: 'AttributesAnswer';
  answerBody: Scalars['JSON'];
  answerGroupId: Scalars['Int'];
  answerGroupKey: Scalars['ID'];
  answerId: Scalars['Int'];
  attributeId: Scalars['Int'];
  attributeName: Scalars['String'];
  isStrength: Scalars['Boolean'];
  isTop: Scalars['Boolean'];
  questionComponentId: Scalars['Int'];
  questionIdentifier: Scalars['String'];
  questionText: Scalars['String'];
  questionType: Scalars['String'];
  respondent: RespondentInfo;
  subsectionQuestionRelationId: Scalars['Int'];
  subsectionQuestionRelationKey: Scalars['String'];
  subtitle: Scalars['String'];
};

export type AuthenticateBenchmarkRequestInvitationInput = {
  uniqueCode: Scalars['String'];
};

export type AuthenticatedBenchmarkRequestInvitation = {
  __typename?: 'AuthenticatedBenchmarkRequestInvitation';
  benchmark: Benchmark;
  benchmarkRequestKey: Scalars['ID'];
  benchmarkSurveyExperience: Scalars['Boolean'];
  loggedInUser: User;
};

export type AuthorizeWorkosInput = {
  domain: Scalars['String'];
};

export type AutomatedInviteHistory = {
  __typename?: 'AutomatedInviteHistory';
  automatedInviteHistoryKey: Scalars['ID'];
  automationSetting: AutomationSetting;
  employee: InvitationEmployee;
  invitationDate: Scalars['Date'];
  reviewCycle: ReviewCycle;
  status: Scalars['String'];
};

export type AutomatedInviteHistoryPaging = {
  __typename?: 'AutomatedInviteHistoryPaging';
  automationSettingKey: Scalars['ID'];
  data: Array<AutomatedInviteHistory>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AutomationHrisGroup = {
  __typename?: 'AutomationHrisGroup';
  automationHrisGroupKey: Scalars['ID'];
  hrisGroup: HrisGroup;
};

export type AutomationSetting = {
  __typename?: 'AutomationSetting';
  applyToAllHrisGroups: Scalars['Boolean'];
  automationHrisGroups: Array<AutomationHrisGroup>;
  automationSettingKey: Scalars['ID'];
  businessDaysFromStartDate: Scalars['Int'];
  inviteDirectManager: Scalars['Boolean'];
  inviteEmployeeReviewers: Scalars['Boolean'];
  inviteSkipManager: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  position: Position;
  reviewCycle: ReviewCycle;
  syncSchedule: Scalars['String'];
};

export type BaseAnswer = {
  answerBody: Scalars['JSON'];
  answerGroupId: Scalars['Int'];
  answerGroupKey: Scalars['ID'];
  answerId: Scalars['Int'];
  questionComponentId: Scalars['Int'];
  questionIdentifier: Scalars['String'];
  questionText: Scalars['String'];
  questionType: Scalars['String'];
  respondent: RespondentInfo;
  subsectionQuestionRelationId: Scalars['Int'];
  subsectionQuestionRelationKey: Scalars['String'];
};

export type BasicResponse = {
  __typename?: 'BasicResponse';
  success: Scalars['Boolean'];
};

export type BehaviorAnswerOption = {
  __typename?: 'BehaviorAnswerOption';
  answerOptionId: Scalars['Int'];
  optionName: Scalars['String'];
  subtitle: Scalars['String'];
};

export type BehaviorModelInput = {
  appliedFilters: Array<FilterObjectInput>;
  companyKey: Scalars['ID'];
  description: Scalars['String'];
  isPreview: Scalars['Boolean'];
  sourceType: TalentModelSource | `${TalentModelSource}`;
  talentModelKey?: Scalars['ID'];
  title: Scalars['String'];
  topGroupPercentile?: Scalars['Float'];
  winningTraits: Array<WinningTraitInput>;
  winningWorkStyles: Array<WinningWorkStyleInput>;
};

export type BehaviorProfileMatch = {
  __typename?: 'BehaviorProfileMatch';
  benchmarkRequestId: Scalars['Int'];
  candidatePositionRelationId: Scalars['Int'];
  individualMatches: Array<IndividualBehaviorMatch>;
  matchScore: Scalars['String'];
};

export type BehaviorProfilesForCompanyInput = {
  companyId: Scalars['Int'];
  endDate?: Scalars['Date'];
  forReviews?: Scalars['Boolean'];
  hirerProfileIds?: Array<Scalars['Int']>;
  positionIds?: Array<Scalars['Int']>;
  startDate?: Scalars['Date'];
};

export type BehaviorScore = {
  __typename?: 'BehaviorScore';
  compositeScore: Scalars['Int'];
  label: Scalars['String'];
  subtitle: Scalars['String'];
  values: Array<BehaviorScoreValue>;
};

export type BehaviorScoreValue = {
  __typename?: 'BehaviorScoreValue';
  referenceRequestId: Scalars['Int'];
  score: Scalars['Int'];
};

export type BehaviorValue = {
  __typename?: 'BehaviorValue';
  candidateProfileId: Scalars['Int'];
  firstAnswerOption: BehaviorAnswerOption;
  firstAnswerOptionValue: Scalars['Float'];
  secondAnswerOption: BehaviorAnswerOption;
  secondAnswerOptionValue: Scalars['Float'];
};

export type BehavioralAssessmentMedianScoresPaging = {
  __typename?: 'BehavioralAssessmentMedianScoresPaging';
  data: Array<BehavioralAssessmentMedianScoresRow>;
  totalCount: Scalars['Int'];
  totalLow: Scalars['Int'];
  totalTop: Scalars['Int'];
};

export type BehavioralAssessmentMedianScoresRow = {
  __typename?: 'BehavioralAssessmentMedianScoresRow';
  attributeDescription: Scalars['String'];
  attributeKey: Scalars['ID'];
  attributeName: Scalars['String'];
  difference: Scalars['Float'];
  groupOneMedian: Scalars['Float'];
  groupOneName: Scalars['String'];
  groupTwoMedian: Scalars['Float'];
  groupTwoName: Scalars['String'];
};

export type BehavioralAssessmentScore = {
  __typename?: 'BehavioralAssessmentScore';
  passed: Scalars['Boolean'];
  score: Scalars['Int'];
  targetScore: Scalars['Int'];
};

export type BehavioralAttributeScore = {
  __typename?: 'BehavioralAttributeScore';
  key: Scalars['ID'];
  name: Scalars['String'];
  score: Scalars['Int'];
  subtitle: Scalars['String'];
};

export type BehavioralSelfAssessmentAttribute = {
  __typename?: 'BehavioralSelfAssessmentAttribute';
  description: Scalars['String'];
  name: Scalars['String'];
};

export type Benchmark = {
  __typename?: 'Benchmark';
  benchmarkBehaviorProfile: Array<BehaviorValue>;
  benchmarkCollaborators: Array<Maybe<BenchmarkCollaborator>>;
  benchmarkId: Scalars['Int'];
  benchmarkKey: Scalars['ID'];
  benchmarkPositionRelations: Array<BenchmarkPositionRelation>;
  benchmarkRequests: Array<BenchmarkRequest>;
  benchmarkSkillMapRelations: Array<BenchmarkSkillMapRelation>;
  benchmarkTitle: Scalars['String'];
  company: Company;
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  createdByHirer: HirerProfile;
  createdByHirerId: Scalars['Int'];
  externalJobIds: Array<Scalars['String']>;
  externalJobUrls: Array<Scalars['String']>;
  formComponentGroup: FormComponentGroup;
  formComponentGroupId: Scalars['Int'];
  hasCompletedOneReferenceCheck: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isCompanyTemplate: Scalars['Boolean'];
  isStandalone: Scalars['Boolean'];
  jobInterview: JobInterview;
  jobInterviewKey: Scalars['ID'];
  jobOpeningTaxonomy: JobOpeningTaxonomy;
  numberOfCandidates: Scalars['Int'];
  position: Position;
  positions: Array<Position>;
  requisitionIds: Array<Scalars['String']>;
  talentModel: TalentModel;
  templateBenchmarkId: Scalars['Int'];
  templateBenchmarkRequest: BenchmarkRequest;
  updatedAt: Scalars['Date'];
};

export type BenchmarkCollaborator = {
  __typename?: 'BenchmarkCollaborator';
  accessLevel: IntakeAccessLevel | `${IntakeAccessLevel}`;
  benchmarkCollaboratorKey: Scalars['ID'];
  benchmarkId: Scalars['Int'];
  hirerProfile: HirerProfile;
  hirerProfileId: Scalars['Int'];
  receivesNotifications: Scalars['Boolean'];
};

export type BenchmarkCollaboratorsPaging = {
  __typename?: 'BenchmarkCollaboratorsPaging';
  benchmarkId: Scalars['Int'];
  data: Array<BenchmarkCollaborator>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type BenchmarkMatch = {
  __typename?: 'BenchmarkMatch';
  attributeMatch: AttributeMatch;
  behaviorProfileMatch: BehaviorProfileMatch;
  benchmarkRequest: BenchmarkRequest;
  benchmarkRequestId: Scalars['Int'];
  candidatePositionRelationId: Scalars['Int'];
  matchScore: Scalars['String'];
};

export type BenchmarkPositionRelation = {
  __typename?: 'BenchmarkPositionRelation';
  benchmark: Benchmark;
  benchmarkId: Scalars['Int'];
  benchmarkPositionRelationsId: Scalars['Int'];
  position: Position;
  positionId: Scalars['Int'];
};

export type BenchmarkQuestionToAnswers = {
  __typename?: 'BenchmarkQuestionToAnswers';
  benchmarkId: Scalars['Int'];
  benchmarkRequestQuestionAnswers: Array<BenchmarkRequestQuestionAnswer>;
  comments: Array<BenchmarkRequestComment>;
  questionComponent: QuestionComponent;
  questionComponentId: Scalars['Int'];
  subsectionComponentText: Scalars['String'];
};

export type BenchmarkRequest = {
  __typename?: 'BenchmarkRequest';
  benchmark: Benchmark;
  benchmarkId: Scalars['Int'];
  benchmarkRequestId: Scalars['Int'];
  benchmarkRequestKey: Scalars['ID'];
  createdAt: Scalars['Date'];
  draftAnswerGroup: AnswerGroup;
  formComponentGroup: FormComponentGroup;
  formComponentGroupId: Scalars['Int'];
  formComponentGroupKey: Scalars['ID'];
  growthAreaAttributes: Array<Attribute>;
  hirerProfile: HirerProfile;
  hirerProfileId: Scalars['Int'];
  isCopyFromTemplate: Scalars['Boolean'];
  mostRecentlySubmittedAnswerGroup: AnswerGroup;
  positions: Array<Position>;
  receivesNotifications: Scalars['Boolean'];
  sendReminders: Scalars['Boolean'];
  statusType: StatusType;
  statusTypeId: Scalars['Int'];
  strengthAttributes: Array<Attribute>;
  submissionDate: Scalars['Date'];
  submittedAnswerGroups: Array<AnswerGroup>;
  uniqueCode: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type BenchmarkRequestComment = {
  __typename?: 'BenchmarkRequestComment';
  benchmarkRequestCommentKey: Scalars['ID'];
  benchmarkRequestId: Scalars['Int'];
  createdAt: Scalars['Date'];
  creatorHirerProfileId: Scalars['Int'];
  hirerProfile: HirerProfile;
  questionComponent: QuestionComponent;
  questionComponentId: Scalars['Int'];
  text: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type BenchmarkRequestCommentForQuestion = {
  __typename?: 'BenchmarkRequestCommentForQuestion';
  comments: Array<BenchmarkRequestComment>;
  questionComponent: QuestionComponent;
  questionComponentId: Scalars['Int'];
};

export type BenchmarkRequestQuestionAnswer = {
  __typename?: 'BenchmarkRequestQuestionAnswer';
  answerGroupKey: Scalars['ID'];
  benchmarkRequestId: Scalars['Int'];
  hirerProfile: HirerProfile;
  isCopyFromTemplate: Scalars['Boolean'];
  questionAnswer: QuestionAnswer;
  questionComponentId: Scalars['Int'];
  questionComponentKey: Scalars['ID'];
  subsectionQuestionRelationId: Scalars['Int'];
  subsectionQuestionRelationKey: Scalars['ID'];
  surveyAnswer: SurveyAnswer;
};

export type BenchmarkRequestResponseInput = {
  answers?: Array<ReferenceRequestNewAnswerInput>;
  benchmarkRequestId: Scalars['Int'];
  currentSectionComponentRelationId?: Scalars['Int'];
  currentSectionComponentRelationKey?: Scalars['ID'];
};

export type BenchmarkRequestsPaging = {
  __typename?: 'BenchmarkRequestsPaging';
  data: Array<BenchmarkRequest>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type BenchmarkSkillMapRelation = {
  __typename?: 'BenchmarkSkillMapRelation';
  benchmarkSkillMapRelationId: Scalars['Int'];
  benchmarkSkillMapRelationKey: Scalars['ID'];
  skillMap: SkillMap;
  skillMapId: Scalars['Int'];
  targetSkillMapLevelIds: Array<Scalars['Int']>;
};

export type BenchmarkTalentModelUpdateInput = {
  benchmarkKey: Scalars['ID'];
  companyKey: Scalars['ID'];
  talentModelKey: Scalars['ID'];
};

export type BenchmarksForCompanyPaging = {
  __typename?: 'BenchmarksForCompanyPaging';
  companyId: Scalars['Int'];
  data: Array<Benchmark>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type BenchmarksPaging = {
  __typename?: 'BenchmarksPaging';
  companyKey: Scalars['ID'];
  data: Array<Benchmark>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection: Scalars['String'];
  sortField: BenchmarksPagingSortFieldType | `${BenchmarksPagingSortFieldType}`;
  totalCount: Scalars['Int'];
};

export enum BenchmarksPagingSortFieldType {
  created_at = 'created_at',
  title = 'title',
}

export type BestMatchScore = {
  __typename?: 'BestMatchScore';
  score: Scalars['String'];
  version: Scalars['Int'];
};

export type BulkCreateRevieweesByMergeEmployeeInput = {
  inviteDirectManager?: Scalars['Boolean'];
  inviteEmployeeReviewers?: Scalars['Boolean'];
  inviteSkipManager?: Scalars['Boolean'];
  mergeEmployeeKeys?: Array<Scalars['String']>;
  positionKey: Scalars['ID'];
  reviewCycleKey: Scalars['ID'];
};

export type BulkUpdateCandidateExperienceInput = {
  experiences: Array<UpdateCandidateExperienceInput>;
};

export type BulkUpdateExperienceSectionInput = {
  sections: Array<UpdateExperienceSectionInput>;
};

export type BulkUpdateGroupSectionRelationInput = {
  relations: Array<UpdateGroupSectionRelationInput>;
};

export type BulkUpdateRecommendationCompaniesInput = {
  recommendationCompanies?: Array<UpdateRecommendationCompanyInput>;
};

export type BulkUpdateSectionComponentRelationInput = {
  relations: Array<UpdateSectionComponentRelationInput>;
};

export type BulkUpdateSubsectionQuestionRelationInput = {
  relations: Array<UpdateSubsectionQuestionRelationInput>;
};

export type BulkUploadEmployeeCustomMetricsInput = {
  companyPerformanceMetricKey: Scalars['ID'];
  metricsToUpload: Array<UploadEmployeeCustomMetricInput>;
};

export type BulkUploadEmployeeReviewersInput = {
  companyKey: Scalars['ID'];
  employeeReviewers: Array<UploadedEmployeeReviewersInput>;
};

export type BulkUploadMergeEmployeesInput = {
  companyKey: Scalars['ID'];
  mergeEmployees: Array<UploadedMergeEmployeeInput>;
};

export type BulkUploadRevieweesInput = {
  positionKey: Scalars['ID'];
  reviewCycleKey: Scalars['ID'];
  reviewees: Array<UploadedRevieweeInput>;
};

export type CprAttribute = {
  __typename?: 'CPRAttribute';
  answerGroupId: Scalars['Int'];
  attribute: Attribute;
  attributeId: Scalars['Int'];
  candidatePositionRelationId: Scalars['Int'];
  cprAttributeId: Scalars['Int'];
  isTop: Scalars['Boolean'];
  referenceRequest: ReferenceRequest;
  referenceRequestId: Scalars['Int'];
};

export type CprForAccountCreationType = {
  __typename?: 'CPRForAccountCreationType';
  candidateEmail: Scalars['String'];
  candidatePositionRelationId: Scalars['Int'];
  candidateProfileId: Scalars['Int'];
  companyName: Scalars['String'];
  firstName: Scalars['String'];
  isArchived: Scalars['Boolean'];
  isAssessment360Position: Scalars['Boolean'];
  lastName: Scalars['String'];
  positionId: Scalars['Int'];
  positionTitle: Scalars['String'];
  showPositionTitleToCandidate: Scalars['Boolean'];
  statusName: Scalars['String'];
};

export type CancelUpcomingInvitationInput = {
  upcomingInvitationKey: Scalars['ID'];
};

export type CandidateAndReferencerEngagementInput = {
  breakDownBy?: Scalars['String'];
  companyId: Scalars['Int'];
  endDate?: Scalars['Date'];
  forReviews?: Scalars['Boolean'];
  hirerProfileIds?: Array<Scalars['Int']>;
  positionIds?: Array<Scalars['Int']>;
  startDate?: Scalars['Date'];
};

export type CandidateAndReferencerEngagementStats = {
  __typename?: 'CandidateAndReferencerEngagementStats';
  companyId: Scalars['Int'];
  data: Array<CandidateAndReferencerEngagementTimePeriod>;
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type CandidateAndReferencerEngagementTimePeriod = {
  __typename?: 'CandidateAndReferencerEngagementTimePeriod';
  companyId: Scalars['Int'];
  endDate: Scalars['Date'];
  percentCandidatesCompleted: Scalars['Float'];
  percentCandidatesEngaged: Scalars['Float'];
  percentCandidatesWith2References: Scalars['Float'];
  percentReferencesCompleted: Scalars['Float'];
  startDate: Scalars['Date'];
};

export type CandidateAssessment = {
  __typename?: 'CandidateAssessment';
  answerGroup: AnswerGroup;
  answerGroupId: Scalars['Int'];
  behaviorScores: Array<CandidateAssessmentBehaviorScore>;
  candidateAssessmentBehaviorProfile: Array<BehaviorValue>;
  candidateAssessmentId: Scalars['Int'];
  candidateAssessmentKey: Scalars['ID'];
  candidatePositionRelation: CandidatePositionRelation;
  candidatePositionRelationId: Scalars['Int'];
  candidateReviewee: Reviewee;
  checklistSubmission: CandidateChecklistSubmission;
  draftAnswerGroup: AnswerGroup;
  draftSkillMapResponses: Array<SkillMapResponse>;
  expirationDate: Scalars['Date'];
  formComponentGroup: FormComponentGroup;
  formComponentGroupId: Scalars['Int'];
  formComponentGroupKey: Scalars['ID'];
  growthAreaAttributes: Array<Attribute>;
  invitedDate: Scalars['Date'];
  isAcceptingSurveySubmissions: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  isReview: Scalars['Boolean'];
  questionsAndAnswers: Array<Maybe<CandidateAssessmentQuestionAndAnswer>>;
  skillMapRelations: Array<CprSkillMapRelation>;
  skillMaps: Array<SkillMap>;
  statusType: StatusType;
  statusTypeId: Scalars['Int'];
  strengthAttributes: Array<Attribute>;
  submissionDate: Scalars['Date'];
  submittedSkillMapResponses: Array<SkillMapResponse>;
  surveyContainsGrowthCurveQuestions: Scalars['Boolean'];
  workMotivations: Array<WorkMotivationsAnswer>;
};

export type CandidateAssessmentBehaviorScore = {
  __typename?: 'CandidateAssessmentBehaviorScore';
  candidateAssessmentId: Scalars['Int'];
  label: Scalars['String'];
  score: Scalars['Int'];
};

export type CandidateAssessmentQuestionAndAnswer = {
  __typename?: 'CandidateAssessmentQuestionAndAnswer';
  answerInterface: Scalars['String'];
  answerMeta: AnswerMeta;
  answerTopic: Scalars['String'];
  answers: Array<SurveyAnswer>;
  attribute: BehavioralSelfAssessmentAttribute;
  helpTexts: Array<QuestionHelpText>;
  qohFactorDisplayName: Scalars['String'];
  questionComponentKey: Scalars['String'];
  questionIdentifier: Scalars['String'];
  questionSurveyInterface: Scalars['String'];
  questionText: Scalars['String'];
  questionType: Scalars['String'];
  questionTypeKey: Scalars['String'];
  subsectionQuestionRelationKey: Scalars['String'];
  subtitle: Scalars['String'];
  validations: QuestionValidations;
};

export type CandidateAssessmentResponseInput = {
  answers?: Array<ReferenceRequestNewAnswerInput>;
  candidateAssessmentId: Scalars['Int'];
  currentSectionComponentRelationId?: Scalars['Int'];
  currentSectionComponentRelationKey?: Scalars['ID'];
  currentSkillMapCompetencyId?: Scalars['Int'];
  skillMapAnswers?: Array<SkillMapResponseInput>;
};

export type CandidateChecklistSubmission = {
  __typename?: 'CandidateChecklistSubmission';
  candidateAssessmentId: Scalars['Int'];
  checklistAnswers: Array<ChecklistAnswer>;
  percentageMarkedCompleted: Scalars['Float'];
};

export type CandidateExperience = {
  __typename?: 'CandidateExperience';
  candidateExperienceId: Scalars['Int'];
  candidateReferencerRelations: Array<CandidateReferencerRelation>;
  companyName: Scalars['String'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  endMonth: Scalars['Int'];
  endYear: Scalars['Int'];
  experienceDetails: Array<ExperienceDetail>;
  experienceSection: ExperienceSection;
  experienceSectionId: Scalars['Int'];
  experienceType: ExperienceType;
  isArchived: Scalars['Boolean'];
  isPresent: Scalars['Boolean'];
  locationCity: Scalars['String'];
  locationCountry: Scalars['String'];
  locationState: Scalars['String'];
  order: Scalars['Int'];
  positionTitle: Scalars['String'];
  reviewCycleId: Scalars['Int'];
  startMonth: Scalars['Int'];
  startYear: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type CandidateJobPreferences = {
  __typename?: 'CandidateJobPreferences';
  candidateProfileId: Scalars['Int'];
  ecosystemCompanies: Array<EcosystemCompany>;
  jobLocations: Array<JobLocation>;
  jobTitleResponse: Scalars['String'];
  jobTypes: Array<JobType>;
  locationResponse: Scalars['String'];
  teamSizes: Array<TeamSize>;
};

export type CandidateMatchScoreV2 = {
  __typename?: 'CandidateMatchScoreV2';
  candidateFirstName: Scalars['String'];
  culture: CultureAlignment;
  matchScore: MatchScoreV2Information;
  powerSkillsAligment: PowerSkillsAligment;
  referenceRequestCount: Scalars['Int'];
  selfAssessmentCount: Scalars['Int'];
  talentModelKey: Scalars['String'];
  user: Scalars['String'];
};

export type CandidateMatchScoreV3 = {
  __typename?: 'CandidateMatchScoreV3';
  candidateFirstName: Scalars['String'];
  matchScore: MatchScoreV3Information;
  referenceRequestCount: Scalars['Int'];
  selfAssessmentCount: Scalars['Int'];
  talentModelKey: Scalars['String'];
  talentModelName: Scalars['String'];
  winningTraits: Array<WinningTraits>;
  workingStyles: Array<WorkingStyles>;
};

export type CandidatePdfReportInput = {
  candidatePositionRelationId?: Scalars['Int'];
  candidateProfileId?: Scalars['Int'];
};

export type CandidatePositionRelation = {
  __typename?: 'CandidatePositionRelation';
  behaviorProfile: Array<BehaviorValue>;
  behaviorScores: Array<BehaviorScore>;
  behavioralAssessmentAttributeScores: Array<BehavioralAttributeScore>;
  behavioralAssessmentScore: BehavioralAssessmentScore;
  benchmarkMatches: Array<BenchmarkMatch>;
  bestMatchScore: BestMatchScore;
  candidateAssessment: CandidateAssessment;
  candidateEmail: Scalars['String'];
  candidateFirstName: Scalars['String'];
  candidateInviteFromHirer: Scalars['String'];
  candidateInvitedDate: Scalars['Date'];
  candidateLastName: Scalars['String'];
  candidatePhoneNumber: Scalars['String'];
  candidatePositionRelationId: Scalars['Int'];
  candidatePositionRelationKey: Scalars['ID'];
  candidateProfile: CandidateProfile;
  candidateProfileId: Scalars['Int'];
  candidateScore: Scalars['String'];
  competencies_score: Scalars['Float'];
  cprAttributes: Array<CprAttribute>;
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  emailReminderDays: Array<Scalars['Date']>;
  excitement_score: Scalars['Float'];
  greenhouseRequest: GreenhouseRequest;
  hirerHighlightedAnswers: Array<HirerHighlight>;
  hrisTeam: HrisTeam;
  invitedRespondentsSatisfyRequirements: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  leverRequest: LeverRequest;
  newHireFit: NewHireFit;
  numReferencesCompleted: Scalars['Int'];
  numReferencesInvited: Scalars['Int'];
  opportunityId: Scalars['String'];
  pastPerformanceStandouts: Standouts;
  pendingAndSubmittedReferenceRequests: PendingAndSubmittedReferenceRequests;
  percentile_score: Scalars['Float'];
  position: Position;
  positionId: Scalars['Int'];
  predictedPerformance: PredictedPerformanceType;
  qohScore: Scalars['Float'];
  referenceStatus: StatusType;
  referenceStatusId: Scalars['Int'];
  referencerInviteFromHirer: Scalars['String'];
  referencerResponseDays: Scalars['Int'];
  referencesCompletedDate: Scalars['Date'];
  reviewee: Reviewee;
  scoreVersion: Scalars['Int'];
  searchlightScoreSubtitle: Scalars['String'];
  searchlightScoreText: Scalars['String'];
  shouldInviteReviewers: Scalars['Boolean'];
  skillMapRelations: Array<CprSkillMapRelation>;
  skillMaps: Array<SkillMap>;
  statusType: StatusType;
  statusTypeId: Scalars['Int'];
  uniqueCode: Scalars['String'];
  weighted_score: Scalars['Float'];
};

export type CandidatePositionRelationLimited = {
  __typename?: 'CandidatePositionRelationLimited';
  candidateEmail: Scalars['String'];
  candidateFirstName: Scalars['String'];
  candidateLastName: Scalars['String'];
  candidatePositionRelationId: Scalars['Int'];
  companyId: Scalars['Int'];
  positionTitle: Scalars['String'];
};

export type CandidatePositionRelationPaging = {
  __typename?: 'CandidatePositionRelationPaging';
  data: Array<CandidatePositionRelation>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection: Scalars['String'];
  sortField: Scalars['String'];
  totalCount: Scalars['Int'];
};

export type CandidateProfile = {
  __typename?: 'CandidateProfile';
  candidateAddress: Scalars['String'];
  candidateEmail: Scalars['String'];
  candidateJobPreferences: CandidateJobPreferences;
  candidatePhoneNumber: Scalars['String'];
  candidatePhoto: Scalars['String'];
  candidatePositionRelations: Array<CandidatePositionRelation>;
  candidateProfileId: Scalars['Int'];
  candidateProfileKey: Scalars['ID'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  experienceSections: Array<ExperienceSection>;
  experienceSectionsForReviewCycles: Array<ExperienceSection>;
  isArchived: Scalars['Boolean'];
  isGamificationCandidate: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  isReferenceCandidate: Scalars['Boolean'];
  permissions: Array<Scalars['String']>;
  reviewCycles: Array<ReviewCycle>;
  updatedAt: Scalars['Date'];
  user: User;
  userId: Scalars['Int'];
  vanityLinkText: Scalars['String'];
};

export type CandidateProfileAndReferenceRequests = {
  __typename?: 'CandidateProfileAndReferenceRequests';
  candidateProfile: CandidateProfile;
  pendingReferenceRequests: Array<ReferenceRequest>;
  submittedReferenceRequests: Array<ReferenceRequest>;
};

export type CandidateProfileSearchInput = {
  candidateProfileId?: Scalars['Int'];
  isArchived?: Scalars['Boolean'];
  permissionId?: Scalars['Int'];
};

export type CandidatePublicProfile = {
  __typename?: 'CandidatePublicProfile';
  behaviorProfile: Array<BehaviorValue>;
  candidateDetails: SharedCandidateDetails;
  candidateProfileId: Scalars['Int'];
  candidateStrengths: Array<CandidateStrength>;
  referencerDetails: Array<PublicReferencer>;
};

export type CandidatePublicProfileCounters = {
  __typename?: 'CandidatePublicProfileCounters';
  candidateProfileId: Scalars['Int'];
  numQuestions: Scalars['Int'];
  numReferencesCompleted: Scalars['Int'];
  numWordsWritten: Scalars['Int'];
};

export type CandidateReferencerRelation = {
  __typename?: 'CandidateReferencerRelation';
  candidateExperience: CandidateExperience;
  candidateExperienceId: Scalars['Int'];
  candidateReferencerRelationId: Scalars['Int'];
  contextForReference: Scalars['String'];
  details: Scalars['JSON'];
  hasRecommendation: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  overlapEndDate: Scalars['Date'];
  overlapStartDate: Scalars['Date'];
  recommendation: Recommendation;
  recommendationId: Scalars['Int'];
  referenceRequests: Array<ReferenceRequest>;
  referencerEmail: Scalars['String'];
  referencerFirstName: Scalars['String'];
  referencerLastName: Scalars['String'];
  referencerPhoneNumber: Scalars['String'];
  referencerProfile: ReferencerProfile;
  referencerProfileId: Scalars['Int'];
  referencerSegment: ReferencerSegment | `${ReferencerSegment}`;
  referencerTitle: Scalars['String'];
  timeOverlap: Scalars['String'];
  workRelationship: Scalars['String'];
};

export type CandidateReferencerRelationLimited = {
  __typename?: 'CandidateReferencerRelationLimited';
  candidateExperience: CandidateExperience;
  candidateExperienceId: Scalars['Int'];
  candidateReferencerRelationId: Scalars['Int'];
  contextForReference: Scalars['String'];
  details: Scalars['JSON'];
  isArchived: Scalars['Boolean'];
  overlapEndDate: Scalars['Date'];
  overlapStartDate: Scalars['Date'];
  referencerEmail: Scalars['String'];
  referencerFirstName: Scalars['String'];
  referencerLastName: Scalars['String'];
  referencerPhoneNumber: Scalars['String'];
  referencerTitle: Scalars['String'];
  timeOverlap: Scalars['String'];
  workRelationship: Scalars['String'];
};

export type CandidateReferencerRelationPaging = {
  __typename?: 'CandidateReferencerRelationPaging';
  data: Array<CandidateReferencerRelation>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type CandidateReferencerRelationsForMyExperienceInput = {
  candidateExperienceId: Scalars['Int'];
};

export type CandidateShare = {
  __typename?: 'CandidateShare';
  candidateDetails: SharedCandidateDetails;
  candidateProfileId: Scalars['Int'];
  candidateShareId: Scalars['Int'];
  referencerDetails: Array<SharedReferenceDetails>;
};

export type CandidateShareInvite = {
  __typename?: 'CandidateShareInvite';
  candidateReferencerRelations: Array<CandidateReferencerRelationLimited>;
  candidateShare: CandidateShare;
  candidateShareId: Scalars['Int'];
  candidateShareInviteId: Scalars['Int'];
  expiredDate: Scalars['Date'];
  hirerProfileId: Scalars['Int'];
  invitationCode: Scalars['String'];
  invitedDate: Scalars['Date'];
  inviteeCompany: Scalars['String'];
  inviteeEmail: Scalars['String'];
  inviteeFirstName: Scalars['String'];
  inviteeLastName: Scalars['String'];
  revokedDate: Scalars['Date'];
  verificationCode: Scalars['String'];
  verifiedDate: Scalars['Date'];
};

export type CandidateShareInviteForMeInput = {
  inviteeCompany?: Scalars['String'];
  inviteeEmail: Scalars['String'];
  inviteeFirstName?: Scalars['String'];
  inviteeLastName?: Scalars['String'];
  sharedCandidateReferencerRelations?: Array<SharedCandidateReferencerRelationInput>;
};

export type CandidateShareRequest = {
  __typename?: 'CandidateShareRequest';
  candidateShare: CandidateShare;
  candidateShareId: Scalars['Int'];
  candidateShareRequestId: Scalars['Int'];
  invitedDate: Scalars['Date'];
  requesterCompanyName: Scalars['String'];
  requesterEmail: Scalars['String'];
  requesterLinkedin: Scalars['String'];
  requesterName: Scalars['String'];
  requesterRole: Scalars['String'];
  vanityLinkText: Scalars['String'];
  verifiedDate: Scalars['Date'];
};

export type CandidateStats = {
  __typename?: 'CandidateStats';
  acceptedInvite: Scalars['Int'];
  candidateAcceptedBenchmarkValue: Scalars['String'];
  candidateCompletedBenchmarkValue: Scalars['String'];
  candidatesWith2References: Scalars['Int'];
  completed: Scalars['Int'];
  invited: Scalars['Int'];
};

export type CandidateStrength = {
  __typename?: 'CandidateStrength';
  attribute: Attribute;
  candidateProfileId: Scalars['Int'];
  count: Scalars['Int'];
};

export type ChecklistAnswer = {
  __typename?: 'ChecklistAnswer';
  order: Scalars['Int'];
  questionComponentKey: Scalars['String'];
  status: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
};

export type CollaborativeBenchmarkQuestion = {
  __typename?: 'CollaborativeBenchmarkQuestion';
  benchmarkRequestQuestionAnswers: Array<BenchmarkRequestQuestionAnswer>;
  comments: Array<BenchmarkRequestComment>;
  question: Question;
  questionComponentKey: Scalars['ID'];
};

export type CollaborativeBenchmarkQuestionPage = {
  __typename?: 'CollaborativeBenchmarkQuestionPage';
  headingText: Scalars['String'];
  orderedQuestionsOnPage: Array<CollaborativeBenchmarkQuestion>;
};

export type CollaborativeQuestionAnswersForBenchmark = {
  __typename?: 'CollaborativeQuestionAnswersForBenchmark';
  benchmarkId: Scalars['Int'];
  orderedSubsections: Array<CollaborativeBenchmarkQuestionPage>;
};

export type CommChannel = {
  __typename?: 'CommChannel';
  commChannelKey: Scalars['ID'];
  commChannelName: Scalars['String'];
};

export type CommType = {
  __typename?: 'CommType';
  commTypeKey: Scalars['ID'];
  defaultActionText: Scalars['String'];
  defaultBodyTemplate: Scalars['String'];
  defaultSubjectTemplate: Scalars['String'];
  description: Scalars['String'];
  placeholders: Array<Scalars['String']>;
  postmarkTemplateAlias: Scalars['String'];
  supportedCommChannelIds: Array<Scalars['Int']>;
  title: Scalars['String'];
  typeName: Scalars['String'];
};

export type CommTypeForCompany = {
  __typename?: 'CommTypeForCompany';
  commType: CommType;
  commTypeKey: Scalars['ID'];
  companyComm: CompanyComm;
  companyCommKey: Scalars['ID'];
  companyId: Scalars['Int'];
};

export type Company = {
  __typename?: 'Company';
  atsNameId: Scalars['Int'];
  companyAddress: Scalars['String'];
  companyFeatureFlags: CompanyFeatureFlags;
  companyId: Scalars['Int'];
  companyKey: Scalars['ID'];
  companyLogo: Scalars['String'];
  companyName: Scalars['String'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  hasCompletedOneReferenceCheck: Scalars['Boolean'];
  hasEmployees: Scalars['Boolean'];
  hrisIntegrated: Scalars['Boolean'];
  isApiCompany: Scalars['Boolean'];
  isAssessment360Company: Scalars['Boolean'];
  notificationEvents: Array<NotificationEvent>;
  notificationTypeId: Scalars['Int'];
  positions: Array<Position>;
  remainingFreeTrialPeriod: Scalars['Int'];
  reviewCycles: Array<ReviewCycle>;
  showPositionTitleToCandidate: Scalars['Boolean'];
  slackEnabled: Scalars['Boolean'];
  srPartner: SrPartner;
  updatedAt: Scalars['Date'];
  useCompanyComms: Scalars['Boolean'];
  usesPositionTemplateForGreenhouse: Scalars['Boolean'];
  whitelistedEmailDomain: Scalars['String'];
  workOsOrganizationId: Scalars['String'];
  workdayPartner: WorkdayPartner;
};

export type CompanyApiKey = {
  __typename?: 'CompanyApiKey';
  companyId: Scalars['Int'];
  searchlightApiKey: Scalars['String'];
};

export type CompanyAtsPartners = {
  __typename?: 'CompanyAtsPartners';
  Ashby: AshbyPartner;
  Greenhouse: GreenhousePartner;
  Lever: LeverPartner;
  SmartRecruiters: SrPartner;
  Workday: WorkdayPartner;
};

export type CompanyBehaviorProfilesByStatus = {
  __typename?: 'CompanyBehaviorProfilesByStatus';
  active: Array<BehaviorValue>;
  companyId: Scalars['Int'];
  dismissed: Array<BehaviorValue>;
  endDate: Scalars['Date'];
  hired: Array<BehaviorValue>;
  offered: Array<BehaviorValue>;
  startDate: Scalars['Date'];
  top_performer: Array<BehaviorValue>;
};

export type CompanyComm = {
  __typename?: 'CompanyComm';
  actionText: Scalars['String'];
  bodyTemplates: Scalars['String'];
  commType: CommType;
  companyCommKey: Scalars['ID'];
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  language: Scalars['String'];
  subjectTemplate: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type CompanyCommonStrengths = {
  __typename?: 'CompanyCommonStrengths';
  companyId: Scalars['Int'];
  data: Array<AttributeWithCount>;
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  totalCandidates: Scalars['Int'];
};

export type CompanyCommonStrengthsInput = {
  companyId: Scalars['Int'];
  endDate?: Scalars['Date'];
  forReviews?: Scalars['Boolean'];
  hirerProfileIds?: Array<Scalars['Int']>;
  limit?: Scalars['Int'];
  positionIds?: Array<Scalars['Int']>;
  startDate?: Scalars['Date'];
};

export type CompanyFeatureFlags = {
  __typename?: 'CompanyFeatureFlags';
  analyticsDenyDefaultPreviewMode: Scalars['Boolean'];
  candidateAssessmentsEnabled: Scalars['Boolean'];
  candidateInvitations: Scalars['Boolean'];
  candidateViewFullReport: Scalars['Boolean'];
  companyCommsEnabled: Scalars['Boolean'];
  companyId: Scalars['Int'];
  customizeFormsToSkillMaps: Scalars['Boolean'];
  defaultMyTeamsAnalyticsToPreview: Scalars['Boolean'];
  defaultPositionsPageEnabled: Scalars['Boolean'];
  demoJobFlowEnabled: Scalars['Boolean'];
  disableFreeTrialExpiration: Scalars['Boolean'];
  enableAtsApplicantFiltering: Scalars['Boolean'];
  enableCustomSurveysByRelationship: Scalars['Boolean'];
  enableReviewSpecificComms: Scalars['Boolean'];
  enableSoftSkillsAnalytics: Scalars['Boolean'];
  hasStandaloneICPs: Scalars['Boolean'];
  idealCandidateProfilesEnabled: Scalars['Boolean'];
  isGreenhousePartner: Scalars['Boolean'];
  isLeverPartner: Scalars['Boolean'];
  isTrialCompany: Scalars['Boolean'];
  participantViewFullReport: Scalars['Boolean'];
  reviewCyclesEnabled: Scalars['Boolean'];
  searchlightScoreV2Enabled: Scalars['Boolean'];
  showAllReferencesTable: Scalars['Boolean'];
  showReferencerProspects: Scalars['Boolean'];
  skillMapsEnabled: Scalars['Boolean'];
  standalonePositionTemplatesEnabled: Scalars['Boolean'];
  talentModelBenchmarksDisabled: Scalars['Boolean'];
  usePostHireDataForTalentModels: Scalars['Boolean'];
  useSearchlightScoreNumber: Scalars['Boolean'];
  userProvisioning: Scalars['Boolean'];
};

export type CompanyForm = {
  __typename?: 'CompanyForm';
  companyId: Scalars['Int'];
  formComponentGroup: FormComponentGroup;
  positions: Array<Position>;
};

export type CompanyPerformanceMetric = {
  __typename?: 'CompanyPerformanceMetric';
  companyKey: Scalars['ID'];
  companyPerformanceMetricKey: Scalars['ID'];
  createdAt: Scalars['Date'];
  employeeCustomMetrics: Array<EmployeeCustomMetric>;
  isArchived: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  lastUploadedDate: Scalars['Date'];
  name: Scalars['String'];
  type: PerformanceMetricType | `${PerformanceMetricType}`;
  updatedAt: Scalars['Date'];
};

export type CompanyReferencerProspect = {
  __typename?: 'CompanyReferencerProspect';
  candidateContextForReference: Scalars['String'];
  candidateHubLink: Scalars['String'];
  candidateName: Scalars['String'];
  candidateStatusName: Scalars['String'];
  isOpenToOpportunities: Scalars['Boolean'];
  referenceLinkedIn: Scalars['String'];
  referenceRequestId: Scalars['Int'];
  referencerEmail: Scalars['String'];
  referencerExperienceText: Scalars['String'];
  referencerHasManaged: Scalars['Boolean'];
  referencerMostRecentRole: Scalars['String'];
  referencerName: Scalars['String'];
  referencerPhone: Scalars['String'];
  referencerTitle: Scalars['String'];
  searchlightPositionTitle: Scalars['String'];
  sharedEmployer: Scalars['String'];
  submissionDate: Scalars['Date'];
  workRelationship: Scalars['String'];
};

export type CompanyRole = {
  __typename?: 'CompanyRole';
  accessPermissions: Array<AccessPermission>;
  company: Company;
  companyRoleKey: Scalars['ID'];
  createdAt: Scalars['Date'];
  creatorHirerProfile: HirerProfile;
  name: Scalars['String'];
  roleDescription: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type CompanyRolesPaging = {
  __typename?: 'CompanyRolesPaging';
  companyKey: Scalars['ID'];
  data: Array<CompanyRole>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection: Scalars['String'];
  sortField: Scalars['String'];
  totalCount: Scalars['Int'];
};

export type CompanySearchInput = {
  atsName?: Scalars['String'];
  companyAddress?: Scalars['String'];
  companyId?: Scalars['Int'];
  companyName?: Scalars['String'];
  whitelistedEmailDomain?: Scalars['String'];
};

export type CompanySearchlightScoreStats = {
  __typename?: 'CompanySearchlightScoreStats';
  companyId: Scalars['Int'];
  data: Array<SearchlightScore>;
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type CompanySearchlightScoreStatsInput = {
  companyId: Scalars['Int'];
  endDate?: Scalars['Date'];
  forReviews?: Scalars['Boolean'];
  hirerProfileIds?: Array<Scalars['Int']>;
  positionIds?: Array<Scalars['Int']>;
  startDate?: Scalars['Date'];
};

export type CompanyStats = {
  __typename?: 'CompanyStats';
  candidatePositionRelations: Array<CandidatePositionRelation>;
  companyId: Scalars['Int'];
  endDate: Scalars['Date'];
  numCompletedCandidates: Scalars['Int'];
  numCompletedReferences: Scalars['Int'];
  numInvitedCandidates: Scalars['Int'];
  numInvitedReferences: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type CompetencyLevelDescriptions = {
  __typename?: 'CompetencyLevelDescriptions';
  competencyName: Scalars['String'];
  levels: Array<LevelDescription>;
};

export type ConceptExplainer = {
  __typename?: 'ConceptExplainer';
  conceptExplainerKey: Scalars['ID'];
  createdAt: Scalars['Date'];
  groupIdentifier: ConceptExplainerIdentifier | `${ConceptExplainerIdentifier}`;
  heading: Scalars['String'];
  rank: Scalars['Int'];
  richTextBody: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum ConceptExplainerIdentifier {
  cohort_analysis = 'cohort_analysis',
  employee_satisfaction = 'employee_satisfaction',
  leaderboard = 'leaderboard',
  manager_satisfaction = 'manager_satisfaction',
  qoh = 'qoh',
  retention = 'retention',
  tm_culture = 'tm_culture',
  tm_power_skills = 'tm_power_skills',
}

export type CopyBenchmarkRequestAnswersInput = {
  benchmarkRequestIdToCopyFrom?: Scalars['Int'];
  benchmarkRequestIdToCopyTo?: Scalars['Int'];
  benchmarkRequestKeyToCopyFrom?: Scalars['ID'];
  benchmarkRequestKeyToCopyTo?: Scalars['ID'];
};

export type CprSkillMapAnswer = {
  __typename?: 'CprSkillMapAnswer';
  answers: Array<Answer>;
  skillMap: SkillMap;
  submittedSkillMapResponses: Array<SkillMapResponse>;
  targetSkillMapLevelIds: Array<Scalars['Int']>;
};

export type CprSkillMapRelation = {
  __typename?: 'CprSkillMapRelation';
  cprSkillMapRelationId: Scalars['Int'];
  skillMap: SkillMap;
  skillMapId: Scalars['Int'];
  targetSkillMapLevelIds: Array<Scalars['Int']>;
};

export type CreateAndInviteCandidatePositionRelationsInput = {
  newCandidatePositionRelations: Array<SingleCandidateInvitationInput>;
  positionId: Scalars['Int'];
};

export type CreateAndInviteRevieweesAndReviewersInput = {
  newCandidatePositionRelations: Array<SingleCandidateInvitationInput>;
  positionId: Scalars['Int'];
};

export type CreateAshbyPartnerInput = {
  ashbyApiKey?: Scalars['String'];
  companyKey: Scalars['ID'];
  defaultPositionTemplateId?: Scalars['Int'];
  groupsToGrantAccess?: Array<AshbyGroupsToGrantAccess | `${AshbyGroupsToGrantAccess}`>;
  groupsToUpdateOnSubmission?: Array<
    AshbyGroupsToUpdateOnSubmission | `${AshbyGroupsToUpdateOnSubmission}`
  >;
};

export type CreateAutomationSettingInput = {
  applyToAllHrisGroups: Scalars['Boolean'];
  businessDaysFromStartDate: Scalars['Int'];
  hrisGroups?: Array<HrisGroupsAndSettingsInput>;
  inviteDirectManager: Scalars['Boolean'];
  inviteEmployeeReviewers: Scalars['Boolean'];
  inviteSkipManager: Scalars['Boolean'];
  positionKey: Scalars['ID'];
};

export type CreateBenchmarkPositionRelationInput = {
  benchmarkId: Scalars['Int'];
  positionId: Scalars['Int'];
};

export type CreateBenchmarkRequestCommentInput = {
  benchmarkRequestId: Scalars['Int'];
  questionComponentId?: Scalars['Int'];
  questionComponentKey?: Scalars['ID'];
  text: Scalars['String'];
};

export type CreateCandidateAccountAndCreateActiveCprInput = {
  candidateAddress?: Scalars['String'];
  candidateEmail: Scalars['String'];
  candidatePhoneNumber?: Scalars['String'];
  firstName?: Scalars['String'];
  lastName?: Scalars['String'];
  password: Scalars['String'];
  positionId: Scalars['Int'];
  username: Scalars['String'];
};

export type CreateCandidateAccountForExistingCprInput = {
  candidateEmail: Scalars['String'];
  candidatePhoneNumber?: Scalars['String'];
  candidatePositionRelationId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type CreateCandidateShareRequestInput = {
  requesterCompanyName: Scalars['String'];
  requesterEmail: Scalars['String'];
  requesterLinkedin?: Scalars['String'];
  requesterName: Scalars['String'];
  requesterRole: Scalars['String'];
  vanityLinkText: Scalars['String'];
};

export type CreateCompanyPerformanceMetricInput = {
  companyKey: Scalars['ID'];
  employeeCustomMetricsToUpload: Array<UploadEmployeeCustomMetricInput>;
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  type: PerformanceMetricType | `${PerformanceMetricType}`;
};

export type CreateFcgFromSurveyTemplateInput = {
  companyKey: Scalars['String'];
  componentText: Scalars['String'];
  surveyTemplateKey: Scalars['String'];
};

export type CreateHirerTeamInput = {
  companyId: Scalars['Int'];
  hirerProfileIds: Array<Scalars['Int']>;
  teamName: Scalars['String'];
};

export type CreateIntakeAndPositionInput = {
  benchmarkKeyToUseAsTemplate?: Scalars['ID'];
  benchmarkTitle: Scalars['String'];
  candidateFormComponentGroupId?: Scalars['Int'];
  candidateViewFullReport?: Scalars['Boolean'];
  companyKey: Scalars['ID'];
  details?: Scalars['JSON'];
  externalJobUrls?: Array<Scalars['String']>;
  formComponentGroupId?: Scalars['Int'];
  formComponentGroupKey?: Scalars['ID'];
  hideRespondentName?: Scalars['Boolean'];
  isAcceptingSurveySubmissions?: Scalars['Boolean'];
  isArchived?: Scalars['Boolean'];
  jobFamily?: Scalars['String'];
  jobLevel?: Scalars['String'];
  notifyCandidateWithUpdates?: Scalars['Boolean'];
  numRecommendedReferences?: Scalars['Int'];
  numRequiredReferences?: Scalars['Int'];
  positionIdentifier?: Scalars['String'];
  referenceRequirementSyntax?: RefRequirementSyntaxNodeInput;
  referencerResponseDays?: Scalars['Int'];
  requisitionIds?: Array<Scalars['String']>;
  sendAutomaticRemindersToSubjects?: Scalars['Boolean'];
  skillMaps?: Array<SkillMapWithTargetLevelsInput>;
  subjectCanInviteRespondent?: Scalars['Boolean'];
  surveysToRelationships?: Array<AddPositionSurveyToRelationshipInput>;
  talentModelKey?: Scalars['ID'];
  validSurveySubmissionWindowDays?: Scalars['Int'];
};

export type CreateIntakeInput = {
  benchmarkKeyToUseAsTemplate?: Scalars['ID'];
  benchmarkTitle: Scalars['String'];
  companyKey: Scalars['ID'];
  externalJobUrls?: Array<Scalars['String']>;
  formComponentGroupKey?: Scalars['ID'];
  jobFamily?: Scalars['String'];
  jobLevel?: Scalars['String'];
  requisitionIds?: Array<Scalars['String']>;
  skillMaps?: Array<SkillMapWithTargetLevelsInput>;
  talentModelKey?: Scalars['ID'];
};

export type CreateIntakeTemplateInput = {
  benchmarkTitle: Scalars['String'];
  companyKey: Scalars['ID'];
  formComponentGroupKey?: Scalars['ID'];
  skillMaps?: Array<SkillMapWithTargetLevelsInput>;
};

export type CreateJobQuestionCriterionForJobPostInput = {
  acceptedAnswers: Array<JobQuestionAnswerInput>;
  atsJobPostKey: Scalars['String'];
  category: JobQuestionCategory | `${JobQuestionCategory}`;
  externalQuestionId: Scalars['String'];
  externalQuestionLabel: Scalars['String'];
};

export type CreatePublicSkillMapInput = {
  componentText: Scalars['String'];
  description?: Scalars['String'];
  standardJobCareerTrackKey?: Scalars['String'];
  standardJobFamilyKey?: Scalars['String'];
  updatedSkillMapCells?: Array<UpdateCellInSkillMapInput>;
  updatedSkillMapCompetencies?: Array<UpdateCompetencyInSkillMapInput>;
  updatedSkillMapLevels?: Array<UpdateLevelInSkillMapInput>;
};

export type CreateQuestionComponentForHirerInput = {
  answerOptionGroupId?: Scalars['Int'];
  companyKey: Scalars['String'];
  componentText: Scalars['String'];
  helpTexts?: Array<HelpTextInput>;
  questionTypeKey: Scalars['String'];
  subtitleText?: Scalars['String'];
};

export type CreateRelevantExperienceCriterionForJobPostInput = {
  atsJobPostKey: Scalars['String'];
  context?: Scalars['String'];
  hiringRelevantExperienceCriterionKey?: Scalars['String'];
  maxYears?: Scalars['Int'];
  minYears: Scalars['Int'];
  quote?: Scalars['String'];
  relatedTitles?: Array<RelevantExperienceRelatedTitleInput>;
};

export type CreateReviewCycleInput = {
  companyId: Scalars['Int'];
  endDate?: Scalars['Date'];
  name: Scalars['String'];
  startDate?: Scalars['Date'];
};

export type CreateRevieweeInput = {
  companyFunction?: Scalars['String'];
  companyLevel?: Scalars['String'];
  companyTitle?: Scalars['String'];
  firstName?: Scalars['String'];
  lastName?: Scalars['String'];
  positionKey: Scalars['ID'];
  reviewCycleId: Scalars['Int'];
  reviewers?: Array<ReviewerInput>;
  workEmail?: Scalars['String'];
};

export type CreateSkillMapForCompanyInput = {
  companyId: Scalars['Int'];
  componentText: Scalars['String'];
  description?: Scalars['String'];
  updatedSkillMapCells?: Array<UpdateCellInSkillMapInput>;
  updatedSkillMapCompetencies?: Array<UpdateCompetencyInSkillMapInput>;
  updatedSkillMapLevels?: Array<UpdateLevelInSkillMapInput>;
};

export type CreateSoftSkillCriterionForJobPostInput = {
  atsJobPostKey: Scalars['String'];
  attributeKey: Scalars['String'];
  description: Scalars['String'];
  hiringSoftSkillCriterionKey?: Scalars['String'];
  isUserAdded: Scalars['Boolean'];
  name: Scalars['String'];
  quote: Scalars['String'];
};

export type CreateSrPartnerInput = {
  candidatePropertyKey?: Scalars['String'];
  candidateSubstatus?: Scalars['String'];
  companyId: Scalars['Int'];
  defaultPositionTemplateId?: Scalars['Int'];
  groupsToGrantAccess?: Array<SrGroupsToGrantAccess | `${SrGroupsToGrantAccess}`>;
  groupsToUpdateOnSubmission?: Array<
    SrGroupsToUpdateOnSubmission | `${SrGroupsToUpdateOnSubmission}`
  >;
  srApiKey?: Scalars['String'];
};

export type CreateSurveyTemplateInput = {
  displayName: Scalars['String'];
  fcgTypeKey: Scalars['String'];
  formComponentGroupKey: Scalars['String'];
  name: Scalars['String'];
};

export type CreateWorkdayPartnerInput = {
  companyId: Scalars['Int'];
  defaultPositionTemplateId?: Scalars['Int'];
  humanResourcesWsdl?: Scalars['String'];
  password?: Scalars['String'];
  recruitingWsdl?: Scalars['String'];
  username?: Scalars['String'];
};

export type CultureAlignment = {
  __typename?: 'CultureAlignment';
  closestAlignmentFactors: Array<Scalars['String']>;
  cultureProfile: Array<CultureProfile>;
  cultureProfileSelf: Array<CultureProfile>;
  description: Scalars['String'];
  farthestAlignmentFactors: Array<Scalars['String']>;
  score: Scalars['String'];
};

export type CultureDefinition = {
  __typename?: 'CultureDefinition';
  answerOptionName: Scalars['String'];
  definition: Scalars['String'];
  name: Scalars['String'];
  theme: Scalars['String'];
};

export type CultureMetric = {
  __typename?: 'CultureMetric';
  average: Scalars['Float'];
  numberOfEmployees: Scalars['Int'];
  percentOfEmployees: Scalars['Float'];
  preference: CulturePreference | `${CulturePreference}`;
  traitName: Scalars['String'];
};

export type CultureMetricInput = {
  average?: Scalars['Float'];
  numberOfEmployees: Scalars['Int'];
  percentOfEmployees?: Scalars['Float'];
  preference: CulturePreference | `${CulturePreference}`;
  traitName: Scalars['String'];
};

export enum CulturePreference {
  high = 'high',
  low = 'low',
  medium = 'medium',
}

export type CultureProfile = {
  __typename?: 'CultureProfile';
  coreDescription: Scalars['String'];
  coreLabel: Scalars['String'];
  coreValue: Scalars['Float'];
  dominantLabel: Scalars['String'];
  dominantValue: Scalars['Float'];
  inverseDescription: Scalars['String'];
  inverseLabel: Scalars['String'];
  inverseValue: Scalars['Float'];
  themeLabel: Scalars['String'];
};

export type CultureTrait = {
  __typename?: 'CultureTrait';
  description: Scalars['String'];
  inverse: Scalars['String'];
  inverseDescription: Scalars['String'];
  theme: Scalars['String'];
  traitName: Scalars['String'];
};

export type CurrentProgressType = {
  __typename?: 'CurrentProgressType';
  answerGroupId: Scalars['Int'];
  currentSectionComponentId: Scalars['Int'];
  currentSectionComponentKey: Scalars['ID'];
  currentSectionComponentRelationId: Scalars['Int'];
  currentSectionComponentRelationKey: Scalars['ID'];
  currentSkillMapCompetencyId: Scalars['Int'];
  currentSkillMapId: Scalars['Int'];
  currentSubsectionComponentId: Scalars['Int'];
  currentSubsectionComponentKey: Scalars['ID'];
};

export type CustomSlidingScaleQuestionInput = {
  componentText: Scalars['String'];
  questionComponentId: Scalars['Int'];
};

export type DeclineRecommendationForCandidateInput = {
  uniqueCode: Scalars['String'];
};

export type DeclineReferenceRequestInput = {
  answerOptionId?: Scalars['Int'];
  reason?: Scalars['String'];
  referenceRequestId: Scalars['Int'];
};

export type DeleteBenchmarkCollaboratorInput = {
  benchmarkCollaboratorKey: Scalars['String'];
};

export type DeleteBenchmarkRequestCommentInput = {
  benchmarkRequestCommentKey: Scalars['ID'];
};

export type DeleteBenchmarkRequestInput = {
  benchmarkRequestId: Scalars['Int'];
};

export type DeleteBenchmarkSkillMapForJobOpeningInput = {
  companyKey: Scalars['ID'];
  key: Scalars['ID'];
};

export type DeleteCandidatePositionRelationInput = {
  candidatePositionRelationId: Scalars['Int'];
};

export type DeleteCompanyPerformanceMetricInput = {
  companyPerformanceMetricKey: Scalars['ID'];
};

export type DeleteCompetencyCriterionForJobPostInput = {
  atsJobPostKey: Scalars['String'];
  hiringCompetencyCriterionKey: Scalars['String'];
};

export type DeleteExperienceDetailInput = {
  candidateExperienceId: Scalars['Int'];
  experienceDetailId: Scalars['Int'];
};

export type DeleteExperienceDetailOutput = {
  __typename?: 'DeleteExperienceDetailOutput';
  candidateExperienceId: Scalars['Int'];
  experienceDetailId: Scalars['Int'];
};

export type DeleteGroupSectionRelationInput = {
  groupSectionRelationId: Scalars['Int'];
};

export type DeleteHighlightInput = {
  answerId?: Scalars['Int'];
  candidatePositionRelationId?: Scalars['Int'];
  hirerHighlightId?: Scalars['Int'];
};

export type DeleteHirerCompanyRelationInput = {
  hirerCompanyRelationId: Scalars['Int'];
};

export type DeleteHirerCompanyRoleInput = {
  hirerCompanyRoleKey: Scalars['ID'];
};

export type DeleteJobQuestionCriterionForJobPostInput = {
  atsJobPostKey: Scalars['String'];
  hiringJobQuestionCriterionKey: Scalars['String'];
};

export type DeletePositionTemplateInput = {
  positionTemplateId: Scalars['Int'];
};

export type DeleteQuestionRatingInput = {
  questionRatingId: Scalars['Int'];
};

export type DeleteRelevantExperienceCriterionForJobPostInput = {
  atsJobPostKey: Scalars['String'];
  hiringRelevantExperienceCriterionKey: Scalars['String'];
};

export type DeleteRevieweeInput = {
  revieweeId: Scalars['Int'];
};

export type DeleteSectionComponentInput = {
  sectionComponentId: Scalars['Int'];
};

export type DeleteSectionComponentRelationInput = {
  sectionComponentRelationId: Scalars['Int'];
};

export type DeleteSoftSkillCriterionForJobPostInput = {
  atsJobPostKey: Scalars['String'];
  hiringSoftSkillCriterionKey: Scalars['String'];
};

export type DeleteSubsectionQuestionRelationInput = {
  subsectionQuestionRelationId: Scalars['Int'];
};

export type DuplicateBenchmarkInput = {
  benchmarkId: Scalars['Int'];
  benchmarkTitle: Scalars['String'];
};

export type DuplicateFormComponentGroupInput = {
  componentText: Scalars['String'];
  formComponentGroupId: Scalars['Int'];
  formIdentifier?: Scalars['String'];
};

export type DuplicatePositionInput = {
  newPositionTitle: Scalars['String'];
  positionIdToDuplicate: Scalars['Int'];
};

export type DuplicateSectionComponentInput = {
  componentIdentifier: Scalars['String'];
  componentText?: Scalars['String'];
  sectionComponentId: Scalars['Int'];
};

export type DuplicateSkillMapForCompanyInput = {
  companyId: Scalars['Int'];
  skillMapId: Scalars['Int'];
};

export type DuplicateSubsectionComponentInput = {
  componentIdentifier: Scalars['String'];
  componentText?: Scalars['String'];
  subsectionComponentId: Scalars['Int'];
};

export type EcosystemCompany = {
  __typename?: 'EcosystemCompany';
  companyDescription: Scalars['String'];
  companyName: Scalars['String'];
  ecosystemCompanyId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  updatedAt: Scalars['Date'];
};

export type EcosystemCompanySearchInput = {
  ecosystemCompanyIds?: Array<Scalars['Int']>;
};

export type EditBenchmarkBehavioralModelInput = {
  appliedFilters: Array<FilterObjectInput>;
  companyKey: Scalars['ID'];
  description: Scalars['String'];
  isPreview: Scalars['Boolean'];
  parentBehavioralModelKey?: Scalars['ID'];
  sourceType: TalentModelSource | `${TalentModelSource}`;
  title: Scalars['String'];
  topGroupPercentile?: Scalars['Float'];
  winningTraits: Array<WinningTraitInput>;
  winningWorkStyles: Array<WinningWorkStyleInput>;
};

export type EmployeeCpr = {
  __typename?: 'EmployeeCPR';
  candidatePositionRelation: CandidatePositionRelation;
  createdAt: Scalars['Date'];
  employeeCPRKey: Scalars['ID'];
  hrisTeam: HrisTeam;
  isArchived: Scalars['Boolean'];
  mergeEmployee: MergeEmployee;
  updatedAt: Scalars['Date'];
};

export type EmployeeCprPaging = {
  __typename?: 'EmployeeCPRPaging';
  data: Array<EmployeeCpr>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type EmployeeCprPagingInput = {
  companyKey: Scalars['ID'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
};

export type EmployeeCustomMetric = {
  __typename?: 'EmployeeCustomMetric';
  companyPerformanceMetricKey: Scalars['ID'];
  employeeCustomMetricKey: Scalars['ID'];
  employeeEmail: Scalars['String'];
  employeeFirstName: Scalars['String'];
  employeeLastName: Scalars['String'];
  maxScore: Scalars['Int'];
  mergeEmployeeKey: Scalars['ID'];
  minScore: Scalars['Int'];
  relatedTimePeriodEnd: Scalars['Date'];
  relatedTimePeriodStart: Scalars['Date'];
  score: Scalars['Int'];
};

export type EmployeeReviewer = {
  __typename?: 'EmployeeReviewer';
  createdAt: Scalars['Date'];
  employeeReviewerKey: Scalars['ID'];
  reviewerMergeEmployee: MergeEmployee;
  subjectMergeEmployee: MergeEmployee;
  updatedAt: Scalars['Date'];
  workRelationship: ReferenceRelationType;
  workRelationshipId: Scalars['Int'];
};

export type EmployeeReviewersArrayInput = {
  reviewer_email: Scalars['String'];
  reviewer_relationship: Scalars['String'];
};

export type EngagementTimePeriod = {
  __typename?: 'EngagementTimePeriod';
  endDate: Scalars['Date'];
  numPageViews: Scalars['Int'];
  startDate: Scalars['Date'];
  userCount: Scalars['Int'];
};

export type ExperienceDetail = {
  __typename?: 'ExperienceDetail';
  candidateExperienceId: Scalars['Int'];
  componentText: Scalars['String'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  experienceDetailId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  order: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type ExperienceSection = {
  __typename?: 'ExperienceSection';
  candidateExperiences: Array<CandidateExperience>;
  candidateProfile: CandidateProfile;
  candidateProfileId: Scalars['Int'];
  componentText: Scalars['String'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  experienceSectionId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  order: Scalars['Int'];
  reviewCycleId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type ExperienceType = {
  __typename?: 'ExperienceType';
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  experienceTypeId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  typeName: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type ExternalJobSource = {
  __typename?: 'ExternalJobSource';
  createdAt: Scalars['Date'];
  externalJobSourceId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  sourceName: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type ExternalJobSourcesForCompanyInput = {
  companyId: Scalars['Int'];
};

export type FcgType = {
  __typename?: 'FCGType';
  createdAt: Scalars['Date'];
  fcgTypeId: Scalars['Int'];
  fcgTypeKey: Scalars['String'];
  isArchived: Scalars['Boolean'];
  typeName: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type FetchActivitiesForMeInput = {
  excludeCandidateAssessments?: Scalars['Boolean'];
  excludeReferenceAssessments?: Scalars['Boolean'];
  excludeReferenceSurveys?: Scalars['Boolean'];
  excludeReviewSurveys?: Scalars['Boolean'];
};

export type FetchAllStandardDepartmentsInput = {
  companyKey: Scalars['String'];
};

export type FetchAllStandardJobFamiliesInput = {
  companyKey: Scalars['String'];
};

export type FetchAllStandardJobLevelsInput = {
  companyKey: Scalars['String'];
};

export type FetchAssessmentsForMeInput = {
  excludeCandidateAssessments?: Scalars['Boolean'];
  excludeReferenceAssessments?: Scalars['Boolean'];
};

export type FetchAutomatedInviteHistoryPagingInput = {
  automationSettingKey: Scalars['ID'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  status: Scalars['String'];
};

export type FetchBehavioralAssessmentMedianScoresForReflectionInput = {
  comparisonView?: SoftSkillsAnalyticsComparisonView | `${SoftSkillsAnalyticsComparisonView}`;
  reflectionKey: Scalars['String'];
};

export type FetchBehavioralAssessmentReflectionsForCompanyInput = {
  companyKey: Scalars['ID'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
};

export type FetchBenchmarkCollaboratorsPagingInput = {
  benchmarkId: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type FetchBenchmarkRequestForHirerInput = {
  benchmarkRequestId?: Scalars['Int'];
  benchmarkRequestKey?: Scalars['ID'];
};

export type FetchBenchmarkRequestsCreatedByMeInput = {
  companyId: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type FetchBenchmarkRequestsForMeInput = {
  companyId: Scalars['Int'];
  isSubmitted?: Scalars['Boolean'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type FetchBenchmarksForMeInput = {
  companyKey: Scalars['ID'];
  excludeZeroCompleteSurveys?: Scalars['Boolean'];
  isArchived?: Scalars['Boolean'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: FetchBenchmarksForMeSortField | `${FetchBenchmarksForMeSortField}`;
  standardJobFamilyKeys?: Array<Scalars['ID']>;
  type: FetchBenchmarksForMeType | `${FetchBenchmarksForMeType}`;
};

export enum FetchBenchmarksForMeSortField {
  created_at = 'created_at',
  title = 'title',
}

export enum FetchBenchmarksForMeType {
  all = 'all',
  company = 'company',
  created_by_me = 'created_by_me',
  shared_with_me = 'shared_with_me',
  template = 'template',
}

export type FetchCprWithIdForHirerInput = {
  candidatePositionRelationId: Scalars['Int'];
};

export type FetchCprWithIdsForHirerInput = {
  candidateProfileId: Scalars['Int'];
  positionId: Scalars['Int'];
};

export type FetchCandidateAssessmentByCprForHirerInput = {
  candidatePositionRelationId: Scalars['Int'];
};

export type FetchCandidateAssessmentForMeByIdInput = {
  candidateAssessmentId: Scalars['Int'];
};

export type FetchCandidateFormForPositionInput = {
  positionId: Scalars['Int'];
};

export type FetchCandidatePositionRelationByCprIdInput = {
  candidatePositionRelationId: Scalars['Int'];
};

export type FetchCandidatePositionRelationsForBenchmarkInput = {
  benchmarkKey: Scalars['ID'];
  candidateInviteSent?: Scalars['Boolean'];
  isArchived?: Scalars['Boolean'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
  statusTypeIds?: Array<Scalars['Int']>;
};

export type FetchCandidatePositionRelationsForMeInput = {
  isArchived?: Scalars['Boolean'];
};

export type FetchCandidatePublicProfileInput = {
  code: Scalars['String'];
};

export type FetchCandidateShareInviteWithInvitationCodeInput = {
  invitationCode: Scalars['String'];
};

export type FetchCommTypeForCompanyInput = {
  commTypeKey?: Scalars['String'];
  companyId: Scalars['Int'];
  positionKey?: Scalars['String'];
  typeName?: Scalars['String'];
};

export type FetchCommTypesForCompanyInput = {
  companyId: Scalars['Int'];
  positionKey?: Scalars['String'];
};

export type FetchCompanyByIdInput = {
  companyId: Scalars['Int'];
};

export type FetchCompanyFormsInput = {
  companyId: Scalars['Int'];
};

export type FetchCompanyReferencerProspectsInput = {
  companyId: Scalars['Int'];
  endDate?: Scalars['Date'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  positionIds?: Array<Scalars['Int']>;
  startDate?: Scalars['Date'];
  statusTypeIds?: Array<Scalars['Int']>;
};

export type FetchCompanyReferencerProspectsLimitedInput = {
  companyId: Scalars['Int'];
  emailRecipient?: Scalars['String'];
  endDate?: Scalars['Date'];
  keyword: Scalars['String'];
  positionIds?: Array<Scalars['Int']>;
  startDate?: Scalars['Date'];
  statusTypeIds?: Array<Scalars['Int']>;
};

export type FetchCompanyRolesForCompanyPagingInput = {
  companyKey: Scalars['ID'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
};

export type FetchCustomQuestionAnswersForBenchmarkInput = {
  benchmarkId: Scalars['Int'];
  benchmarkRequestKeysToFilter?: Array<Scalars['String']>;
};

export type FetchDefaultSkillMapsByJobTaxonomyInput = {
  companyKey: Scalars['ID'];
  jobCareerTrackKey: Scalars['ID'];
  jobFamilyKey: Scalars['ID'];
};

export type FetchFcgsByQuestionInput = {
  questionComponentId: Scalars['Int'];
};

export type FetchFormForHirerInput = {
  companyId: Scalars['Int'];
  formComponentGroupId: Scalars['Int'];
};

export type FetchFormForPositionInput = {
  positionId: Scalars['Int'];
};

export type FetchHirerProfileForCompanyInput = {
  companyId?: Scalars['Int'];
  hirerProfileId: Scalars['Int'];
};

export type FetchHrisGroupsForCompanyPagingInput = {
  companyId: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type FetchHrisTeamsForCompanyPagingInput = {
  companyId: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type FetchJobOpeningTaxonomyByBenchmarkKeyInput = {
  benchmarkKey: Scalars['String'];
};

export type FetchNumberCandidatesWithCompletedReferenceChecksInput = {
  companyKey: Scalars['ID'];
};

export type FetchOtherReviewCPRsForCprInput = {
  candidatePositionRelationId: Scalars['Int'];
};

export type FetchPositionDetailsForAccountCreationInput = {
  companyId: Scalars['Int'];
  positionIdentifier: Scalars['String'];
};

export type FetchPositionForHirerInput = {
  positionId: Scalars['Int'];
};

export type FetchPublicSkillMapsInput = {
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
  standardDepartmentKeys?: Array<Scalars['ID']>;
  standardJobCareerTrackKeys?: Array<Scalars['ID']>;
  standardJobFamilyKeys?: Array<Scalars['ID']>;
};

export type FetchQualityOfHireFiltersInput = {
  companyKey: Scalars['String'];
  previewMode?: Scalars['Boolean'];
};

export type FetchQuestionCommentsForBenchmarkRequestInput = {
  benchmarkRequestId: Scalars['Int'];
};

export type FetchQuestionRatingsByCandidatePositionRelationInput = {
  candidatePositionRelationId: Scalars['Int'];
};

export type FetchQuestionTypesForHirerInput = {
  companyKey: Scalars['ID'];
  keyword: Scalars['String'];
};

export type FetchReferenceAssessmentForMeByKeyInput = {
  referenceAssessmentKey: Scalars['ID'];
};

export type FetchReferenceRequestForReferencerInput = {
  referenceRequestId: Scalars['Int'];
};

export type FetchReferenceRequestsForCPRsInput = {
  companyId: Scalars['Int'];
};

export type FetchReferenceRequestsForMePagingInput = {
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type FetchReferenceSectionForRequestInput = {
  referenceRequestId: Scalars['Int'];
};

export type FetchReviewCycleByIdInput = {
  reviewCycleId: Scalars['Int'];
};

export type FetchReviewCycleByKeyInput = {
  reviewCycleKey: Scalars['ID'];
};

export type FetchReviewCyclesForCompanyInput = {
  companyId: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
};

export type FetchReviewCyclesForCompanyPagingInput = {
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  reviewCycleId?: Scalars['Int'];
  reviewCycleKey?: Scalars['ID'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
};

export type FetchReviewQuestionsForReportingInput = {
  companyKey: Scalars['ID'];
};

export type FetchReviewRequestsForMePagingInput = {
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type FetchRevieweeByIdInput = {
  revieweeId: Scalars['Int'];
};

export type FetchRevieweesForReviewCycleInput = {
  reviewCycleId: Scalars['Int'];
};

export type FetchRevieweesInPerformanceBucketsForReflectionInput = {
  reviewCycleKey: Scalars['ID'];
};

export type FetchSearchSectionComponentByComponentIdentifierInput = {
  componentIdentifier: Scalars['String'];
};

export type FetchSelfAssessmentQuestionsForReportingInput = {
  companyKey: Scalars['ID'];
};

export type FetchSharedBenchmarksForMeInput = {
  companyId: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type FetchSkillMapAnswersForCandidatePositionRelationInput = {
  candidatePositionRelationKey: Scalars['ID'];
};

export type FetchSkillMapByIdInput = {
  skillMapId: Scalars['Int'];
};

export type FetchSkillMapCompetenciesForCompanyInput = {
  companyId: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type FetchSkillMapsForCompanyInput = {
  companyId: Scalars['Int'];
  isArchived?: Scalars['Boolean'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
  standardDepartmentKeys?: Array<Scalars['ID']>;
  standardJobCareerTrackKeys?: Array<Scalars['ID']>;
  standardJobFamilyKeys?: Array<Scalars['ID']>;
};

export type FetchSkillMapsForCprForHirerInput = {
  candidatePositionRelationId: Scalars['Int'];
};

export type FetchStrengthsAndGapsForCprInput = {
  candidatePositionRelationKey: Scalars['ID'];
};

export type FetchTalentModelQuestionDataInput = {
  benchmarkKey: Scalars['ID'];
  benchmarkRequestKeysToFilter?: Array<Scalars['String']>;
};

export type FetchUnansweredBehavioralQuestionDataInput = {
  benchmarkId: Scalars['Int'];
};

export type FetchUnattachedBenchmarkRequestCommentsInput = {
  benchmarkId: Scalars['Int'];
};

export type FetchUpcomingInvitationsPagingInput = {
  automationSettingKey: Scalars['ID'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortField?: Scalars['String'];
  sortOrder?: Scalars['String'];
};

export type FileResponse = {
  __typename?: 'FileResponse';
  base64Data: Scalars['String'];
  fileType: Scalars['String'];
};

export type FilterData = {
  __typename?: 'FilterData';
  key: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type FilterDataInput = {
  key: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type FilterObject = {
  __typename?: 'FilterObject';
  data: Array<FilterData>;
  filterName: Scalars['String'];
  pluralFilterName: Scalars['String'];
  type: FilterType | `${FilterType}`;
};

export type FilterObjectInput = {
  data: Array<FilterDataInput>;
  type: FilterType | `${FilterType}`;
};

export enum FilterType {
  application_statuses = 'application_statuses',
  candidate_profile_keys = 'candidate_profile_keys',
  employees = 'employees',
  ethnicities = 'ethnicities',
  genders = 'genders',
  groups = 'groups',
  hiring_managers = 'hiring_managers',
  home_countries = 'home_countries',
  invitation_dates = 'invitation_dates',
  manager_rating_of_performance = 'manager_rating_of_performance',
  merge_managers = 'merge_managers',
  org_chart_ancestor_keys = 'org_chart_ancestor_keys',
  performance_metric = 'performance_metric',
  position_keys = 'position_keys',
  qoh_score_brackets = 'qoh_score_brackets',
  recruiters = 'recruiters',
  reflection_key = 'reflection_key',
  reflection_periods = 'reflection_periods',
  soft_skills_analytics_comparison_view = 'soft_skills_analytics_comparison_view',
  standard_job_career_tracks = 'standard_job_career_tracks',
  standard_job_departments = 'standard_job_departments',
  standard_job_families = 'standard_job_families',
  standard_job_levels = 'standard_job_levels',
  start_dates = 'start_dates',
  teams = 'teams',
  top_group_percentile = 'top_group_percentile',
  work_countries = 'work_countries',
}

export type FormComponentGroup = {
  __typename?: 'FormComponentGroup';
  baseFcgId: Scalars['Int'];
  componentText: Scalars['String'];
  createdAt: Scalars['Date'];
  customizedSubsectionComponentId: Scalars['Int'];
  details: Scalars['JSON'];
  fcgType: FcgType;
  fcgTypeId: Scalars['Int'];
  formComponentGroupId: Scalars['Int'];
  formComponentGroupKey: Scalars['String'];
  formIdentifier: Scalars['String'];
  groupSectionRelations: Array<GroupSectionRelation>;
  hirerCanEdit: Scalars['Boolean'];
  instructionText: Array<Maybe<Scalars['String']>>;
  isArchived: Scalars['Boolean'];
  isAutoGenerated: Scalars['Boolean'];
  isCommon: Scalars['Boolean'];
  isCommonIntake: Scalars['Boolean'];
  isCompanyFormArchived: Scalars['Boolean'];
  isPublicTemplate: Scalars['Boolean'];
  isTranslated: Scalars['Boolean'];
  numCompanyLibraries: Scalars['Int'];
  numPositions: Scalars['Int'];
  sectionComponents: Array<SectionComponent>;
  updatedAt: Scalars['Date'];
  version: Scalars['Int'];
};

export type FormComponentGroupPaging = {
  __typename?: 'FormComponentGroupPaging';
  data: Array<FormComponentGroup>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type FractionStandout = {
  __typename?: 'FractionStandout';
  body: Scalars['String'];
  denominator: Scalars['Int'];
  heading: Scalars['String'];
  numerator: Scalars['Int'];
  questionComponentId: Scalars['Int'];
};

export type GenerateCompanyMergeAccountTokenInput = {
  companyId: Scalars['Int'];
  linkToken: Scalars['String'];
};

export type GenerateCompanyMergeLinkTokenInput = {
  companyId: Scalars['Int'];
};

export type GenerateJobInterviewInput = {
  jobKey: Scalars['ID'];
};

export type GenerateSelfAssessmentFromBehavioralModelInput = {
  behavioralModelKey: Scalars['String'];
  companyKey: Scalars['String'];
};

export type GetAllPowerSkillsInput = {
  appliedFilters: Array<FilterObjectInput>;
  companyKey: Scalars['ID'];
  isPreview: Scalars['Boolean'];
};

export type GetApplicationSubscoreCriteriasInput = {
  atsApplicationKey?: Scalars['String'];
  companyKey: Scalars['ID'];
};

export type GetAtsApplicationByKey = {
  atsApplicationKey?: Scalars['String'];
  companyKey: Scalars['ID'];
};

export type GetAtsJobByKeyInput = {
  atsJobKey: Scalars['ID'];
  companyKey: Scalars['ID'];
};

export type GetAtsJobCandidateListInput = {
  applicationStatus: Array<Scalars['String']>;
  atsJobKey: Scalars['ID'];
  companyKey: Scalars['ID'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
};

export type GetAtsJobCandidatesInput = {
  allowPagination?: Scalars['Boolean'];
  applicationStatus: Array<Scalars['String']>;
  atsJobKey: Scalars['ID'];
  companyKey: Scalars['ID'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  rating?: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
};

export type GetAtsJobPostByKeyInput = {
  atsJobPostKey: Scalars['ID'];
  companyKey: Scalars['ID'];
};

export type GetAtsJobPostCandidateListInput = {
  applicationStatus: Array<Scalars['String']>;
  atsJobPostKey: Scalars['ID'];
  companyKey: Scalars['ID'];
};

export type GetAtsJobPostCandidatesInput = {
  applicationStatus: Array<Scalars['String']>;
  atsJobPostKey: Scalars['ID'];
  companyKey: Scalars['ID'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
};

export type GetAtsJobPostsInput = {
  companyKey: Scalars['ID'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
};

export type GetAtsJobsInput = {
  companyKey: Scalars['ID'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
};

export type GetCompanyPerformanceMetricByKeyInput = {
  companyPerformanceMetricKey: Scalars['ID'];
};

export type GetCompanyPerformanceMetricsByCompanyKeyInput = {
  companyKey: Scalars['ID'];
};

export type GetConceptExplainersByIdentifierInput = {
  groupIdentifier: Scalars['String'];
};

export type GetCriterionJobPostByKeyInput = {
  atsJobPostKey: Scalars['ID'];
  companyKey: Scalars['ID'];
};

export type GetDefaultTalentModelsV2forJobTaxonomyInput = {
  companyKey: Scalars['ID'];
  jobFamilyName: Scalars['String'];
  jobLevelName: Scalars['String'];
  jobTitle: Scalars['String'];
};

export type GetDefaultTalentModelsforJobTaxonomyInput = {
  companyKey: Scalars['ID'];
  jobFamilyName: Scalars['String'];
  jobLevelName: Scalars['String'];
  jobTitle: Scalars['String'];
};

export type GetEmailTemplatesForCompanyInput = {
  companyKey: Scalars['ID'];
};

export type GetEmployeeCustomMetricsByPerformanceMetricKeyInput = {
  companyPerformanceMetricKey: Scalars['ID'];
};

export type GetInterviewQuestionByKeyInput = {
  interviewQuestionKey: Scalars['ID'];
};

export type GetInterviewQuestionsByCriteriaNameInput = {
  criteriaName: Scalars['String'];
};

export type GetJobInterviewInput = {
  jobInterviewKey: Scalars['ID'];
};

export type GetJobStagesForJobApplicationByJobId = {
  atsApplicationKey?: Scalars['String'];
  companyKey: Scalars['ID'];
};

export type GetMatchScoreV2ForCandidateInput = {
  candidatePositionRelationKey: Scalars['ID'];
};

export type GetMatchScoreV3ForCandidateInput = {
  candidatePositionRelationKey: Scalars['ID'];
};

export type GetOrCreateCandidateForPositionInput = {
  candidateEmail: Scalars['String'];
  positionId: Scalars['Int'];
};

export type GetReferencesForCprInput = {
  candidatePositionRelationKey: Scalars['ID'];
};

export type GetReflectionPeriodsForCompanyInput = {
  companyKey: Scalars['ID'];
  hirerProfileKey: Scalars['ID'];
};

export type GetRejectionReasonsForCompanyInput = {
  companyKey: Scalars['ID'];
};

export type GetTalentModelsForCompanyInput = {
  companyKey: Scalars['ID'];
  createdByHirerKey?: Scalars['ID'];
  isArchived?: Scalars['Boolean'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
  sourceType?: Array<Maybe<TalentModelSource | `${TalentModelSource}`>>;
  standardDepartmentKeys?: Array<Scalars['ID']>;
  standardJobCareerTrackKeys?: Array<Scalars['ID']>;
  standardJobFamilyKeys?: Array<Scalars['ID']>;
  standardJobLevelKeys?: Array<Scalars['String']>;
  version?: Scalars['Int'];
};

export type GhJob = {
  __typename?: 'GhJob';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type GlobalStats = {
  __typename?: 'GlobalStats';
  candidatePositionRelations: Array<CandidatePositionRelation>;
  endDate: Scalars['Date'];
  numCompletedCandidates: Scalars['Int'];
  numCompletedReferences: Scalars['Int'];
  numInvitedCandidates: Scalars['Int'];
  numInvitedReferences: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type GoogleLoginUrlInput = {
  loginPageType: Scalars['String'];
};

export type GreenhouseApplicationStage = {
  __typename?: 'GreenhouseApplicationStage';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GreenhouseEmailTemplate = {
  __typename?: 'GreenhouseEmailTemplate';
  body: Scalars['String'];
  cc: Array<Maybe<Scalars['String']>>;
  created_at: Scalars['Date'];
  default: Scalars['Boolean'];
  description: Scalars['String'];
  html_body: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['Date'];
};

export enum GreenhouseGroupsToGrantAccess {
  coordinators = 'coordinators',
  hiring_managers = 'hiring_managers',
  recruiters = 'recruiters',
}

export enum GreenhouseGroupsToUpdateOnSubmission {
  coordinators = 'coordinators',
  hiring_managers = 'hiring_managers',
  recruiters = 'recruiters',
}

export type GreenhouseMoveApplication = {
  __typename?: 'GreenhouseMoveApplication';
  candidateId: Scalars['Int'];
  currentStage: GreenhouseApplicationStage;
  id: Scalars['ID'];
  status: Scalars['String'];
};

export type GreenhousePartner = {
  __typename?: 'GreenhousePartner';
  company: Company;
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  customJobFieldForIntake: Scalars['String'];
  details: Scalars['JSON'];
  ghApiKey: Scalars['String'];
  ghUserId: Scalars['String'];
  greenhousePartnerId: Scalars['Int'];
  greenhousePartnerKey: Scalars['ID'];
  groupsToGrantAccess: Array<GreenhouseGroupsToGrantAccess | `${GreenhouseGroupsToGrantAccess}`>;
  groupsToUpdateOnSubmission: Array<
    GreenhouseGroupsToUpdateOnSubmission | `${GreenhouseGroupsToUpdateOnSubmission}`
  >;
  hasApplicantScreeningPermissions: Scalars['Boolean'];
  searchlightToken: Scalars['String'];
  updatedAt: Scalars['Date'];
  usesPositionTemplate: Scalars['Boolean'];
};

export type GreenhousePartnerSearchInput = {
  companyId: Scalars['Int'];
};

export type GreenhouseRejectApplication = {
  __typename?: 'GreenhouseRejectApplication';
  candidateId: Scalars['Int'];
  id: Scalars['ID'];
  rejectionReason: RejectionReason;
  status: Scalars['String'];
};

export type GreenhouseRequest = {
  __typename?: 'GreenhouseRequest';
  candidatePositionRelationId: Scalars['Int'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  ghApplicationId: Scalars['Int'];
  ghCandidateId: Scalars['Int'];
  ghJobs: Array<GhJob>;
  greenhouseRequestId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type GreenhouseUnrejectApplication = {
  __typename?: 'GreenhouseUnrejectApplication';
  candidateId: Scalars['Int'];
  id: Scalars['ID'];
  rejectionReason: RejectionReason;
  status: Scalars['String'];
};

export type GroupSectionRelation = {
  __typename?: 'GroupSectionRelation';
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  formComponentGroup: FormComponentGroup;
  formComponentGroupId: Scalars['Int'];
  groupSectionRelationId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  order: Scalars['Int'];
  sectionComponent: SectionComponent;
  sectionComponentId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type GrowthAreasRiskInformation = {
  __typename?: 'GrowthAreasRiskInformation';
  description: Scalars['String'];
  matchedGrowthAreas: Array<Maybe<Scalars['String']>>;
  score: Scalars['String'];
};

export type HelpText = {
  __typename?: 'HelpText';
  body: Scalars['String'];
  helpTextId: Scalars['Int'];
  questionComponent: QuestionComponent;
  questionComponentId: Scalars['Int'];
  title: Scalars['String'];
};

export type HelpTextInput = {
  body?: Scalars['String'];
  title: Scalars['String'];
};

export type HirerCompanyPermission = {
  __typename?: 'HirerCompanyPermission';
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  hirerCompanyPermissionId: Scalars['Int'];
  hirerCompanyRelationId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  permissionId: Scalars['Int'];
  permissionName: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type HirerCompanyRelation = {
  __typename?: 'HirerCompanyRelation';
  company: Company;
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  hirerCompanyRelationId: Scalars['Int'];
  hirerIsManager: Scalars['Boolean'];
  hirerProfile: HirerProfile;
  hirerProfileId: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  permissions: Array<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type HirerCompanyRelationPaging = {
  __typename?: 'HirerCompanyRelationPaging';
  data: Array<HirerCompanyRelation>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type HirerCompanyRole = {
  __typename?: 'HirerCompanyRole';
  companyRole: CompanyRole;
  createdAt: Scalars['Date'];
  hirerCompanyRoleKey: Scalars['ID'];
  hirerProfile: HirerProfile;
  updatedAt: Scalars['Date'];
};

export type HirerEditQuestionComponentInput = {
  answerOptionGroupId?: Scalars['Int'];
  componentText: Scalars['String'];
  helpTexts?: Array<HelpTextInput>;
  questionComponentKey: Scalars['ID'];
  questionTypeId: Scalars['Int'];
  subtitleText?: Scalars['String'];
};

export type HirerEngagementInput = {
  breakDownBy?: Scalars['String'];
  companyId: Scalars['Int'];
  endDate?: Scalars['Date'];
  startDate?: Scalars['Date'];
};

export type HirerEngagementStats = {
  __typename?: 'HirerEngagementStats';
  byTimePeriod: Array<EngagementTimePeriod>;
  companyId: Scalars['Int'];
  endDate: Scalars['Date'];
  numPageViews: Scalars['Int'];
  startDate: Scalars['Date'];
  userCount: Scalars['Int'];
};

export type HirerExcludedApplication = {
  __typename?: 'HirerExcludedApplication';
  candidatePositionRelationId: Scalars['Int'];
  candidatePositionRelationLimited: CandidatePositionRelationLimited;
  createdAt: Scalars['Date'];
  hirerExcludedApplicationId: Scalars['Int'];
  hirerProfile: HirerProfile;
  hirerProfileId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  updatedAt: Scalars['Date'];
};

export type HirerHighlight = {
  __typename?: 'HirerHighlight';
  answerId: Scalars['Int'];
  candidatePositionRelationId: Scalars['Int'];
  hirerHighlightId: Scalars['Int'];
  hirerProfileId: Scalars['Int'];
};

export type HirerPositionRelation = {
  __typename?: 'HirerPositionRelation';
  benchmarkRequest: BenchmarkRequest;
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  hirerPositionRelationId: Scalars['Int'];
  hirerProfile: HirerProfile;
  hirerProfileId: Scalars['Int'];
  hprType: HprType;
  hprTypeId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  position: Position;
  positionId: Scalars['Int'];
  receivesReferenceNotificationEmails: Scalars['Boolean'];
  updatedAt: Scalars['Date'];
};

export type HirerPositionsForCompanyInput = {
  companyId: Scalars['Int'];
  isArchived?: Scalars['Boolean'];
  positionId?: Scalars['Int'];
  reviewCycleId?: Scalars['Int'];
};

export type HirerProfile = {
  __typename?: 'HirerProfile';
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  excludedCandidatePositionRelations: Array<HirerExcludedApplication>;
  hirerCompanyRelations: Array<HirerCompanyRelation>;
  hirerCompanyRoles: Array<HirerCompanyRole>;
  hirerDisplayName: Scalars['String'];
  hirerEmail: Scalars['String'];
  hirerInfoPending: Scalars['Boolean'];
  hirerPhoneNumber: Scalars['String'];
  hirerPositionRelations: Array<HirerPositionRelation>;
  hirerProfileId: Scalars['Int'];
  hirerProfileKey: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  updatedAt: Scalars['Date'];
  user: User;
  userId: Scalars['Int'];
};

export type HirerProfileSearchInput = {
  hirerProfileId?: Scalars['Int'];
  isArchived?: Scalars['Boolean'];
};

export type HirerReviewCyclesForCompanyInput = {
  companyKey: Scalars['ID'];
  isArchived?: Scalars['Boolean'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortField?: Scalars['String'];
  sortOrder?: Scalars['String'];
};

export type HirerReviewGroupsForCompanyInput = {
  companyKey: Scalars['ID'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  reviewCycleKey?: Scalars['ID'];
  sortField?: Scalars['String'];
  sortOrder?: Scalars['String'];
};

export type HirerSettingsForBenchmarkRequestsInput = {
  hirerProfileId: Scalars['Int'];
  sendReminders: Scalars['Boolean'];
};

export type HirerSettingsForCollaboratorsInput = {
  hirerProfileKey: Scalars['ID'];
  receivesNotifications: Scalars['Boolean'];
};

export type HirerTeam = {
  __typename?: 'HirerTeam';
  createdAt: Scalars['Date'];
  hirerProfiles: Array<HirerProfile>;
  hirerTeamId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  teamName: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type HirerTeamIdInput = {
  hirerTeamId: Scalars['Int'];
};

export type HirerTeamPaging = {
  __typename?: 'HirerTeamPaging';
  data: Array<HirerTeam>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type HirerTeamPagingInput = {
  companyId: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type HiringCompetencyCriteria = {
  __typename?: 'HiringCompetencyCriteria';
  bucket: Scalars['Int'];
  data: Array<ApplicationSubscoreCompetencyCriteria>;
};

export type HiringCompetencyCriterion = {
  __typename?: 'HiringCompetencyCriterion';
  atsJobPostKey: Scalars['ID'];
  description: Scalars['String'];
  hiringCompetencyCriterionKey: Scalars['ID'];
  isRequired: Scalars['Boolean'];
  isUserAdded: Scalars['Boolean'];
  name: Scalars['String'];
  quote: Scalars['String'];
  rawData: Scalars['JSON'];
  type: Scalars['String'];
};

export type HiringCriterion = {
  __typename?: 'HiringCriterion';
  hiringCompetencyCriterion: Array<HiringCompetencyCriterion>;
  hiringJobQuestionCriterion: Array<HiringJobQuestionCriterion>;
  hiringRelevantExperienceCriterion: Array<HiringRelevantExperienceCriterion>;
  hiringSoftSkillCriterion: Array<HiringSoftSkillCriterion>;
};

export type HiringJobQuestionCriteria = {
  __typename?: 'HiringJobQuestionCriteria';
  bucket: Scalars['Int'];
  data: Array<ApplicationSubscoreJobQuestionCriteria>;
};

export type HiringJobQuestionCriterion = {
  __typename?: 'HiringJobQuestionCriterion';
  acceptedAnswers: Array<AcceptedAnswer>;
  atsJobPostKey: Scalars['ID'];
  category: Scalars['String'];
  externalQuestionId: Scalars['String'];
  externalQuestionLabel: Scalars['String'];
  hiringJobQuestionCriterionKey: Scalars['ID'];
};

export type HiringRelevantExperienceCriteria = {
  __typename?: 'HiringRelevantExperienceCriteria';
  bucket: Scalars['Int'];
  data: ApplicationResumeRelevantExperience;
};

export type HiringRelevantExperienceCriterion = {
  __typename?: 'HiringRelevantExperienceCriterion';
  atsJobPostKey: Scalars['ID'];
  context: Scalars['String'];
  hiringRelevantExperienceCriterionKey: Scalars['ID'];
  isUserAdded: Scalars['Boolean'];
  maxYears: Scalars['Int'];
  minYears: Scalars['Int'];
  quote: Scalars['String'];
  rawData: Scalars['JSON'];
  relatedTitles: Scalars['JSON'];
};

export type HiringSoftSkillCriteria = {
  __typename?: 'HiringSoftSkillCriteria';
  bucket: Scalars['Int'];
  data: Array<ApplicationSubscoreSoftSkillCriteria>;
};

export type HiringSoftSkillCriterion = {
  __typename?: 'HiringSoftSkillCriterion';
  atsJobPostKey: Scalars['ID'];
  attributeKey: Scalars['ID'];
  description: Scalars['String'];
  hiringSoftSkillCriterionKey: Scalars['ID'];
  isUserAdded: Scalars['Boolean'];
  name: Scalars['String'];
  quote: Scalars['String'];
  rawData: Scalars['JSON'];
};

export type HprType = {
  __typename?: 'HprType';
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  hprTypeId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  typeName: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type HrisGroup = {
  __typename?: 'HrisGroup';
  companyId: Scalars['Int'];
  hrisGroupKey: Scalars['ID'];
  name: Scalars['String'];
  nameAndType: Scalars['String'];
  type: Scalars['String'];
};

export type HrisGroupsAndSettingsInput = {
  hrisGroupKey: Scalars['ID'];
};

export type HrisGroupsPaging = {
  __typename?: 'HrisGroupsPaging';
  data: Array<HrisGroup>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type HrisTeam = {
  __typename?: 'HrisTeam';
  companyId: Scalars['Int'];
  hrisTeamKey: Scalars['String'];
  name: Scalars['String'];
};

export type HrisTeamAndSettingsInput = {
  hrisTeamKey: Scalars['ID'];
};

export type HrisTeamsPaging = {
  __typename?: 'HrisTeamsPaging';
  data: Array<HrisTeam>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type IndividualBehaviorMatch = {
  __typename?: 'IndividualBehaviorMatch';
  behavior: BehaviorValue;
  benchmarkRequestId: Scalars['Int'];
  candidatePositionRelationId: Scalars['Int'];
  matchScore: Scalars['String'];
};

export enum IntakeAccessLevel {
  comment = 'comment',
  submit_survey = 'submit_survey',
}

export type IntakeInterviewPlan = {
  __typename?: 'IntakeInterviewPlan';
  benchmarkKey: Scalars['ID'];
  careerFrameworks: Array<Maybe<InterviewPlanCareerFrameworkLevels>>;
  targetCultureProfile: Array<InterviewPlanCultureItem>;
  targetPowerSkills: Array<InterviewPlanPowerSkill>;
};

export type IntakeSelectedStrength = {
  __typename?: 'IntakeSelectedStrength';
  attributeName: Scalars['String'];
  benchmarkRequest: BenchmarkRequest;
  benchmarkRequestKey: Scalars['ID'];
  isTop: Scalars['Boolean'];
  respondentEmail: Scalars['String'];
  respondentFirstName: Scalars['String'];
  respondentLastName: Scalars['String'];
  subtitle: Scalars['String'];
};

export type InterestEmail = {
  __typename?: 'InterestEmail';
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  email: Scalars['String'];
  interestEmailId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type InterestEmailInput = {
  email: Scalars['String'];
};

export type InterestEmailsFeedInput = {
  ascOrDesc?: Scalars['String'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  orderBy?: Scalars['String'];
};

export type InterviewPlanCareerFrameworkLevels = {
  __typename?: 'InterviewPlanCareerFrameworkLevels';
  competencies: Array<Maybe<CompetencyLevelDescriptions>>;
  skillMapId: Scalars['Int'];
  skillMapName: Scalars['String'];
};

export type InterviewPlanCultureItem = {
  __typename?: 'InterviewPlanCultureItem';
  definition: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
};

export type InterviewPlanPowerSkill = {
  __typename?: 'InterviewPlanPowerSkill';
  definition: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
};

export type InterviewQuestion = {
  __typename?: 'InterviewQuestion';
  badAnswerExample: Scalars['String'];
  companyKey: Scalars['ID'];
  createdAt: Scalars['Date'];
  criteriaName: Scalars['String'];
  goodAnswerExample: Scalars['String'];
  interviewQuestionKey: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  level: InterviewQuestionLevel | `${InterviewQuestionLevel}`;
  parentInterviewQuestionKey: Scalars['ID'];
  questionText: Scalars['String'];
  textIdentifier: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum InterviewQuestionLevel {
  ADVANCED = 'ADVANCED',
  BASIC = 'BASIC',
  INTERMEDIATE = 'INTERMEDIATE',
}

export type InvitationEmployee = {
  __typename?: 'InvitationEmployee';
  employeeEmail: Scalars['String'];
  employeeName: Scalars['String'];
  employeeStartDate: Scalars['Date'];
};

export type InviteBenchmarkCollaboratorInput = {
  benchmarkId: Scalars['Int'];
  hirerProfileId: Scalars['Int'];
};

export type InviteBenchmarkCollaboratorSettingsInput = {
  hirerProfileId: Scalars['Int'];
  receivesNotifications: Scalars['Boolean'];
};

export type InviteBenchmarkCollaboratorsInput = {
  benchmarkId: Scalars['Int'];
  hirersAndSettings?: Array<InviteBenchmarkCollaboratorSettingsInput>;
};

export type InviteHirerProfileToCompanyInput = {
  companyId: Scalars['Int'];
  hirerEmail: Scalars['String'];
  positionId?: Scalars['Int'];
};

export type InviteHirersToIntakeInput = {
  benchmarkKey: Scalars['ID'];
  newAccessSettings: Array<NewHirerIntakeAccessInput>;
};

export type InviteHirersToIntakeV2Input = {
  actionText?: Scalars['String'];
  benchmarkKey: Scalars['ID'];
  bodyTemplates: Scalars['String'];
  newAccessSettings: Array<NewHirerIntakeAccessInput>;
  subjectTemplate: Scalars['String'];
};

export type InviteReferencerWithCandidateRelationInput = {
  candidateReferencerRelationId: Scalars['Int'];
};

export type JobInterview = {
  __typename?: 'JobInterview';
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  job: Benchmark;
  jobInterviewKey: Scalars['ID'];
  jobInterviewQuestions: Array<JobInterviewQuestion>;
  jobKey: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type JobInterviewQuestion = {
  __typename?: 'JobInterviewQuestion';
  badAnswerExample: Scalars['String'];
  createdAt: Scalars['Date'];
  criteriaName: Scalars['String'];
  goodAnswerExample: Scalars['String'];
  interviewQuestion: InterviewQuestion;
  interviewQuestionKey: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  jobInterviewQuestionKey: Scalars['ID'];
  level: InterviewQuestionLevel | `${InterviewQuestionLevel}`;
  questionText: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type JobLocation = {
  __typename?: 'JobLocation';
  isArchived: Scalars['Boolean'];
  jobLocationId: Scalars['Int'];
  jobLocationName: Scalars['String'];
};

export type JobOpeningTaxonomy = {
  __typename?: 'JobOpeningTaxonomy';
  benchmarkKey: Scalars['ID'];
  createdAt: Scalars['Date'];
  jobOpeningTaxonomyKey: Scalars['ID'];
  standardDepartmentKey: Scalars['ID'];
  standardJobCareerTrack: StandardJobCareerTrack;
  standardJobCareerTrackKey: Scalars['ID'];
  standardJobFamily: StandardJobFamily;
  standardJobFamilyKey: Scalars['ID'];
  standardJobLevel: StandardJobLevel;
  standardJobLevelKey: Scalars['ID'];
  updatedAt: Scalars['Date'];
};

export type JobQuestion = {
  __typename?: 'JobQuestion';
  description: Scalars['String'];
  label: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  values: Array<JobQuestionValue>;
};

export type JobQuestionAnswerInput = {
  acceptedLabel: Scalars['String'];
  acceptedValue: Scalars['ID'];
};

export enum JobQuestionCategory {
  location = 'location',
  other = 'other',
  work_auth = 'work_auth',
}

export type JobQuestionValue = {
  __typename?: 'JobQuestionValue';
  label: Scalars['String'];
  value: Scalars['ID'];
};

export type JobStage = {
  __typename?: 'JobStage';
  active: Scalars['Boolean'];
  created_at: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  priority: Scalars['Int'];
};

export type JobType = {
  __typename?: 'JobType';
  isArchived: Scalars['Boolean'];
  jobTypeId: Scalars['Int'];
  jobTypeName: Scalars['String'];
};

export type KeywordSearchCPRsForPositionInput = {
  attributeIds?: Array<Scalars['Int']>;
  candidateInviteSent?: Scalars['Boolean'];
  isArchived?: Scalars['Boolean'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  positionId: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
  statusTypeIds?: Array<Scalars['Int']>;
};

export type KeywordSearchCandidatePositionRelationsInput = {
  companyId?: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type KeywordSearchCompanyFormsPagingInput = {
  companyId: Scalars['Int'];
  fcgTypeName?: Array<Scalars['String']>;
  isArchived?: Scalars['Boolean'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type KeywordSearchQuestionComponentsForHirerInput = {
  companyId: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type LevelDescription = {
  __typename?: 'LevelDescription';
  description: Scalars['String'];
  levelName: Scalars['String'];
};

export type LeverPartner = {
  __typename?: 'LeverPartner';
  company: Company;
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  defaultPosition: Position;
  defaultPositionId: Scalars['Int'];
  defaultPositionTemplate: PositionTemplate;
  defaultPositionTemplateId: Scalars['Int'];
  details: Scalars['JSON'];
  hasLeverOAuthEnabled: Scalars['Boolean'];
  leverApiKey: Scalars['String'];
  leverPartnerId: Scalars['Int'];
  leverPartnerKey: Scalars['ID'];
  leverUserId: Scalars['String'];
  signingToken: Scalars['String'];
  stageNameTrigger: Scalars['String'];
  updatedAt: Scalars['Date'];
  webhookUrls: Array<Scalars['String']>;
};

export type LeverPartnerSearchInput = {
  companyId: Scalars['Int'];
};

export type LeverPosting = {
  __typename?: 'LeverPosting';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type LeverRequest = {
  __typename?: 'LeverRequest';
  candidatePositionRelationId: Scalars['Int'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  leverCandidateId: Scalars['Int'];
  leverPostings: Array<LeverPosting>;
  leverRequestId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type MarkCandidatePositionRelationReferencesAsCompleteInput = {
  candidatePositionRelationKey: Scalars['ID'];
};

export type MatchScoreV2Information = {
  __typename?: 'MatchScoreV2Information';
  description: Scalars['String'];
  score: Scalars['String'];
};

export type MatchScoreV3Information = {
  __typename?: 'MatchScoreV3Information';
  description: Scalars['String'];
  match: Scalars['String'];
  score: Scalars['Int'];
};

export type MergeEmployee = {
  __typename?: 'MergeEmployee';
  company: Company;
  createdAt: Scalars['Date'];
  employeeEmail: Scalars['String'];
  mergeEmployeeKey: Scalars['ID'];
  rawData: Scalars['JSON'];
  updatedAt: Scalars['Date'];
};

export type MergeLinkToken = {
  __typename?: 'MergeLinkToken';
  companyId: Scalars['Int'];
  linkToken: Scalars['String'];
};

export type MergeLocation = {
  __typename?: 'MergeLocation';
  company: Company;
  createdAt: Scalars['Date'];
  externalId: Scalars['String'];
  mergeLocationKey: Scalars['ID'];
  rawData: Scalars['JSON'];
  updatedAt: Scalars['Date'];
};

export type MergePositionsInput = {
  positionIdToArchive: Scalars['Int'];
  positionIdToMergeTo: Scalars['Int'];
};

export type MergedPositionOutput = {
  __typename?: 'MergedPositionOutput';
  positionIdToArchive: Scalars['Int'];
  positionIdToMergeTo: Scalars['Int'];
};

export type MoveApplicationToStageInput = {
  atsApplicationKey: Scalars['ID'];
  companyKey: Scalars['ID'];
  toStageId: Scalars['Int'];
};

export type MoveCandidateToPositionInput = {
  candidatePositionRelations?: Array<Scalars['Int']>;
  positionId: Scalars['Int'];
};

export type MultipleChoiceAnswer = BaseAnswer & {
  __typename?: 'MultipleChoiceAnswer';
  answer: Scalars['String'];
  answerBody: Scalars['JSON'];
  answerGroupId: Scalars['Int'];
  answerGroupKey: Scalars['ID'];
  answerId: Scalars['Int'];
  favorableLabel: Scalars['String'];
  orderedAnswerOptions: Array<Scalars['String']>;
  questionComponentId: Scalars['Int'];
  questionIdentifier: Scalars['String'];
  questionText: Scalars['String'];
  questionType: Scalars['String'];
  respondent: RespondentInfo;
  score: Scalars['Float'];
  selectedIndex: Scalars['Int'];
  subsectionQuestionRelationId: Scalars['Int'];
  subsectionQuestionRelationKey: Scalars['String'];
};

export type MultipleChoiceAnswerMeta = {
  __typename?: 'MultipleChoiceAnswerMeta';
  orderedAnswerOptions: Array<QuestionAnswerOption>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty: Scalars['String'];
  acceptRecommendationForCandidate: SignInUserOutput;
  acceptRecommendationForCandidateWithGoogle: SignInUserOutput;
  activateReferencerUser: User;
  addAnswerGroup: AnswerGroup;
  addAnswerOption: AnswerOption;
  addAnswerOptionGroup: AnswerOptionGroup;
  addCandidateExperience: CandidateExperience;
  addCandidateExperienceForMe: CandidateExperience;
  addCandidateReferencerRelation: CandidateReferencerRelation;
  addCandidateReferencerRelationToNewExperience: CandidateReferencerRelation;
  addCompany: Company;
  addExperienceDetail: ExperienceDetail;
  addExperienceSection: ExperienceSection;
  addExperienceType: ExperienceType;
  addFormComponentGroup: FormComponentGroup;
  addGreenhousePartner: GreenhousePartner;
  addGroupSectionRelation: GroupSectionRelation;
  addHirerCompanyRelation: HirerCompanyRelation;
  addHirerHighlight: HirerHighlight;
  addHirerPositionRelation: HirerPositionRelation;
  addHirerProfile: HirerProfile;
  addInterestEmail: InterestEmail;
  addLeverPartner: LeverPartner;
  addPendingPositionRelationToCandidate: CandidatePositionRelation;
  addPermission: Permission;
  addPosition: Position;
  addPositionForBenchmark: Position;
  addPositionTemplateForCompany: PositionTemplate;
  addPositionWithMetadata: Position;
  addQuestionComponent: QuestionComponent;
  addQuestionType: QuestionType;
  addReferenceRelationType: ReferenceRelationType;
  addResume: Resume;
  addResumes: Array<Resume>;
  addSectionComponent: SectionComponent;
  addSectionComponentRelation: SectionComponentRelation;
  addSectionType: SectionType;
  addSlackNotification: Company;
  addStatusType: StatusType;
  addSubsectionComponent: SubsectionComponent;
  addSubsectionQuestionRelation: SubsectionQuestionRelation;
  addTalentModelToBenchmark: Benchmark;
  addTrialCompany: SignInUserOutput;
  addUser: SignInUserOutput;
  archiveBenchmark: Benchmark;
  archiveTalentModel: TalentModel;
  assignCompanyRoleToHirer: HirerCompanyRole;
  authorizeWorkos: WorkosAuthorizationUrl;
  bulkCreateRevieweesByMergeEmployee: Array<Reviewee>;
  bulkUpdateCandidateExperiences: Array<CandidateExperience>;
  bulkUpdateExperienceSections: Array<ExperienceSection>;
  bulkUpdateGroupSectionRelations: Array<GroupSectionRelation>;
  bulkUpdateSectionComponentRelations: Array<SectionComponentRelation>;
  bulkUpdateSubsectionQuestionRelations: Array<SubsectionQuestionRelation>;
  bulkUploadEmployeeCustomMetrics: Array<Maybe<EmployeeCustomMetric>>;
  bulkUploadEmployeeReviewers: Array<Maybe<EmployeeReviewer>>;
  bulkUploadMergeEmployees: Array<Maybe<MergeEmployee>>;
  bulkUploadReviewees: Array<Reviewee>;
  cancelUpcomingInvitation: AutomatedInviteHistory;
  copyBenchmarkRequestAnswers: BenchmarkRequest;
  createAndInviteCandidatePositionRelations: Array<CandidatePositionRelation>;
  createAndInviteRevieweesAndReviewers: Array<CandidatePositionRelation>;
  createAshbyPartner: AshbyPartner;
  createAutomationSetting: AutomationSetting;
  createBenchmarkAndRequests: Benchmark;
  createBenchmarkPositionRelation: BenchmarkPositionRelation;
  createBenchmarkRequestComment: BenchmarkRequestComment;
  createBenchmarkRequestsForBenchmark: Benchmark;
  createCandidateAccountAndCreateActiveCPR: SignInUserOutput;
  createCandidateAccountForExistingCPR: SignInUserOutput;
  createCandidateShareInviteForMe: CandidateShareInvite;
  createCandidateShareRequest: CandidateShareRequest;
  createCompanyPerformanceMetric: CompanyPerformanceMetric;
  createCompanyRole: CompanyRole;
  createFCGFromSurveyTemplate: FormComponentGroup;
  createGamificationCandidateFromReferencer: User;
  createHelpText: HelpText;
  createHirerTeam: HirerTeam;
  createIntake: Benchmark;
  createIntakeAndPosition: Benchmark;
  createIntakeTemplate: Benchmark;
  createJobQuestionCriterionForJobPost: HiringJobQuestionCriterion;
  createNetworkUploadReminder: ReferencerLinkedinUpload;
  createPublicSkillMap: SkillMap;
  createQuestionComponentForHirer: QuestionComponent;
  createRecommendation: Recommendation;
  createRecommenderFromCandidate: User;
  createReferenceRequests: Array<ReferenceRequest>;
  createRelevantExperienceCriterionForJobPost: HiringRelevantExperienceCriterion;
  createReviewCycleForCompany: ReviewCycle;
  createRevieweeForReviewCycle: Reviewee;
  createSkillMapForCompany: SkillMap;
  createSoftSkillCriterionForJobPost: HiringSoftSkillCriterion;
  createSrPartner: SrPartner;
  createSurveyTemplate: SurveyTemplate;
  createWorkdayPartner: WorkdayPartner;
  declineRecommendationForCandidate: Recommendation;
  declineReferenceRequest: ReferenceRequest;
  deepCopyFormComponentGroup: FormComponentGroup;
  deleteBenchmarkCollaborator: BenchmarkCollaborator;
  deleteBenchmarkRequest: Scalars['Int'];
  deleteBenchmarkRequestComment: BenchmarkRequestComment;
  deleteCandidatePositionRelation: CandidatePositionRelation;
  deleteCompanyApiKey: CompanyApiKey;
  deleteCompanyPerformanceMetric: CompanyPerformanceMetric;
  deleteCompetencyCriterionForJobPost: HiringCompetencyCriterion;
  deleteExperienceDetail: DeleteExperienceDetailOutput;
  deleteGroupSectionRelation: GroupSectionRelation;
  deleteHirerCompanyRelation: HirerCompanyRelation;
  deleteHirerCompanyRelationForAdminHirer: HirerCompanyRelation;
  deleteHirerCompanyRole: HirerCompanyRole;
  deleteHirerHighlight: HirerHighlight;
  deleteHirerPositionRelation: HirerPositionRelation;
  deleteJobQuestionCriterionForJobPost: HiringJobQuestionCriterion;
  deletePositionTemplate: PositionTemplate;
  deleteQuestionRating: QuestionRating;
  deleteRelevantExperienceCriterionForJobPost: HiringRelevantExperienceCriterion;
  deleteReviewee: Reviewee;
  deleteSectionComponent: SectionComponent;
  deleteSectionComponentRelation: SectionComponentRelation;
  deleteSkillMapForJobOpening: Scalars['Boolean'];
  deleteSoftSkillCriterionForJobPost: HiringSoftSkillCriterion;
  deleteSubsectionQuestionRelation: SubsectionQuestionRelation;
  deleteTalentModel: Scalars['ID'];
  duplicateBenchmark: Benchmark;
  duplicatePosition: Position;
  duplicateSectionComponent: SectionComponent;
  duplicateSkillMapForCompany: SkillMap;
  duplicateSubsectionComponent: SubsectionComponent;
  duplicateTalentModel: TalentModel;
  editBenchmarkBehavioralModel: TalentModel;
  generateCompanyApiKey: CompanyApiKey;
  generateCompanyMergeAccountToken: Company;
  generateCompanyMergeLinkToken: MergeLinkToken;
  generateJobInterview: JobInterview;
  generateSelfAssessmentFromBehavioralModel: FormComponentGroup;
  getOrCreateBenchmarkAndCreateBenchmarkRequests: Array<BenchmarkRequest>;
  getOrCreateBenchmarkAndRequestsForPosition: Array<Benchmark>;
  getOrCreateCandidateForPosition: CandidatePositionRelation;
  getOrCreateQuestionRating: QuestionRating;
  hirerEditQuestionComponent: QuestionComponent;
  inviteBenchmarkCollaborator: BenchmarkCollaborator;
  inviteBenchmarkCollaborators: Array<BenchmarkCollaborator>;
  inviteHirerProfileToCompany: HirerProfile;
  inviteHirersToIntake: Benchmark;
  inviteHirersToIntakeV2: Benchmark;
  inviteReferencerWithCandidateRelation: CandidateReferencerRelation;
  logoutUser: BasicResponse;
  markCandidatePositionRelationReferencesAsComplete: CandidatePositionRelation;
  mergePositions: MergedPositionOutput;
  moveApplicationToStage: GreenhouseMoveApplication;
  moveCandidateToPosition: Array<CandidatePositionRelation>;
  notifyCandidateForRecommendation: Recommendation;
  overrideUpcomingInvitationDate: UpcomingInvitation;
  refreshUserToken: RefreshTokenData;
  regenerateJobInterview: JobInterview;
  rejectApplication: GreenhouseRejectApplication;
  removeIntakeAccessForHirer: Benchmark;
  removeSlackForCompany: Company;
  removeTalentModelFromBenchmark: Benchmark;
  requestApplicationsSyncForJob: AtsJobInformation;
  requestApplicationsSyncForJobPost: AtsJobPostInformation;
  requestCandidateShareInviteVerification: CandidateShareInvite;
  requestUserReset: Scalars['JSON'];
  rescheduleCanceledInvitation: UpcomingInvitation;
  resendBenchmarkRequest: BenchmarkRequest;
  resetReferenceSubmission: ReferenceRequest;
  retryInvitationForCandidatePositionRelation: CandidatePositionRelation;
  saveBenchmarkRequest: BenchmarkRequest;
  saveCandidateAssessment: CandidateAssessment;
  saveReferenceRequestV2: ReferenceRequest;
  sendCompanyCommPreview: BasicResponse;
  sendInvitationForCandidatePositionRelation: CandidatePositionRelation;
  sendReminderForCandidatePositionRelation: BasicResponse;
  sendTestCandidateInvite: Scalars['Boolean'];
  signInAdminUser: SignInUserOutput;
  signInHirerUser: SignInUserOutput;
  signInHirerWithBenchmark: SignInHirerOutput;
  signInHirerWithGoogle: SignInUserOutput;
  signInReferencer: SignInReferencerOutput;
  signInUser: SignInUserOutput;
  signInWithGoogle: SignInUserOutput;
  signInWithWorkos: SignInUserOutput;
  signUpCandidateAndCreateCPRWithGoogle: SignInUserOutput;
  signUpCandidateForCPRWithGoogle: SignInUserOutput;
  signUpReferencerUser: SignInUserOutput;
  snapshotBehaviorModel: TalentModel;
  snapshotBehavioralAssessmentBehavioralModel: TalentModel;
  snapshotTalentModel: TalentModel;
  submitBenchmarkRequest: BenchmarkRequest;
  submitCandidateAssessment: CandidateAssessment;
  submitReferenceRequestV2: ReferenceRequest;
  submitReferencerNPS: AnswerGroup;
  submitSlidingScaleCustomizationRequest: BasicResponse;
  swapTalentModelFromJobOpening: Benchmark;
  syncApplicationAndResume: AtsApplicationDetails;
  takeoverCompany: SignInUserOutput;
  takeoverUser: SignInUserOutput;
  testCanAccessGreenhouseJobPosts: Scalars['Boolean'];
  toggleArchiveCompanyFormByFormComponentGroup: Scalars['Boolean'];
  toggleShouldScreenApplicants: AtsJobInformation;
  toggleShouldScreenApplicantsForJobPost: AtsJobPostInformation;
  unrejectApplication: GreenhouseUnrejectApplication;
  updateAshbyPartner: AshbyPartner;
  updateAutomationSetting: AutomationSetting;
  updateAutomationSettingStatus: AutomationSetting;
  updateBenchmark: Benchmark;
  updateBenchmarkCollaboratorNotifications: BenchmarkCollaborator;
  updateBenchmarkRequestComment: BenchmarkRequestComment;
  updateBenchmarkRequestNotifications: BenchmarkRequest;
  updateBulkHirerRelationsForPosition: Array<HirerPositionRelation>;
  updateBulkRecommendationCompaniesForCandidate: Array<RecommendationCompany>;
  updateCandidateExperience: CandidateExperience;
  updateCandidateExperienceForMe: CandidateExperience;
  updateCandidateIsPublic: CandidateProfile;
  updateCandidateJobPreferences: CandidateJobPreferences;
  updateCandidatePicture: CandidateProfile;
  updateCandidatePositionRelation: CandidatePositionRelation;
  updateCandidateProfile: CandidateProfile;
  updateCandidateReferencerRelation: CandidateReferencerRelation;
  updateCompany: Company;
  updateCompanyLogo: Company;
  updateCompanyNotificationEvents: Company;
  updateCompanyNotificationType: Company;
  updateCompanyPerformanceMetric: CompanyPerformanceMetric;
  updateCompanyRole: CompanyRole;
  updateCompanyShowPositionTitleToCandidate: Company;
  updateDomainForCompany: Company;
  updateExperienceSection: ExperienceSection;
  updateFormComponentGroup: FormComponentGroup;
  updateFormComponentGroupByKey: FormComponentGroup;
  updateFormComponentGroupsBySection: FormComponentGroup;
  updateGreenhousePartner: GreenhousePartner;
  updateHirerCompanyPermissionsFromAdminHirer: HirerCompanyPermission;
  updateHirerCompanyRelation: HirerCompanyRelation;
  updateHirerExcludedApplications: Array<HirerExcludedApplication>;
  updateHirerPositionRelationReceiveEmailSetting: HirerPositionRelation;
  updateHirerTeam: HirerTeam;
  updateHiringCriteriaForJobPost: AtsJobPostInformation;
  updateIntakeAccessForHirer: Benchmark;
  updateInterestEmail: InterestEmail;
  updateInvitationEmailForCandidatePositionRelation: CandidatePositionRelation;
  updateJobInterviewQuestion: JobInterviewQuestion;
  updateJobQuestionCriterionForJobPost: HiringJobQuestionCriterion;
  updateLeverPartner: LeverPartner;
  updateLockedSkillMap: SkillMap;
  updateMyUserSettings: User;
  updateNotificationsForIntakeHirer: Benchmark;
  updateOptionalReferenceRequestFields: ReferenceRequest;
  updatePendingHirerProfile: HirerProfile;
  updatePerformanceBucketsForReflectionReviewees: Array<Reviewee>;
  updatePosition: Position;
  updatePositionStatus: Position;
  updatePositionTemplate: PositionTemplate;
  updatePublicSkillMap: SkillMap;
  updateQuestionComponent: QuestionComponent;
  updateRecommendation: Recommendation;
  updateRecommendationCompanyForCandidate: RecommendationCompany;
  updateReferenceIsPublic: CandidateReferencerRelation;
  updateReferencerNetworkUploaded: ReferencerLinkedinUpload;
  updateReflectionPerformanceBucketForSingleReviewee: Reviewee;
  updateReviewCycle: ReviewCycle;
  updateReviewee: Reviewee;
  updateSectionComponent: SectionComponent;
  updateSkillMap: SkillMap;
  updateSkillMapForJobOpening: SkillMap;
  updateSkillMapsForBenchmark: Benchmark;
  updateSkillMapsForPosition: Position;
  updateSrPartner: SrPartner;
  updateSubsectionComponent: SubsectionComponent;
  updateSurveyTemplate: SurveyTemplate;
  updateTalentModelMetaData: TalentModel;
  updateTemplateOnIntake: Benchmark;
  updateUser: User;
  updateUserPassword: User;
  updateWorkdayPartner: WorkdayPartner;
  upsertAnswer: Answer;
  upsertAnswerForBenchmarkRequest: Answer;
  upsertBehavioralModel: TalentModel;
  upsertCommTypeForCompany: CompanyComm;
  upsertCompetencyCriterionForJobPost: HiringCompetencyCriterion;
  upsertTalentModel: TalentModel;
  usertEmployeeCpr: EmployeeCpr;
  verifyHirerProfileForCandidateShareInvite: SignInUserOutput;
};

export type MutationAcceptRecommendationForCandidateArgs = {
  input: AcceptRecommendationForCandidateInput;
};

export type MutationAcceptRecommendationForCandidateWithGoogleArgs = {
  input: AcceptRecommendationForCandidateWithGoogleInput;
};

export type MutationActivateReferencerUserArgs = {
  input: ActivateReferencerUserInput;
};

export type MutationAddAnswerGroupArgs = {
  input: NewAnswerGroupInput;
};

export type MutationAddAnswerOptionArgs = {
  input: NewAnswerOptionInput;
};

export type MutationAddAnswerOptionGroupArgs = {
  input: NewAnswerOptionGroupInput;
};

export type MutationAddCandidateExperienceArgs = {
  input: NewCandidateExperienceInput;
};

export type MutationAddCandidateExperienceForMeArgs = {
  input: NewCandidateExperienceInput;
};

export type MutationAddCandidateReferencerRelationArgs = {
  input: NewCandidateReferencerRelationInput;
};

export type MutationAddCandidateReferencerRelationToNewExperienceArgs = {
  input: NewCandidateExperienceAndReferencerRelationInput;
};

export type MutationAddCompanyArgs = {
  input: NewCompanyInput;
};

export type MutationAddExperienceDetailArgs = {
  input: NewExperienceDetailInput;
};

export type MutationAddExperienceSectionArgs = {
  input: NewExperienceSectionInput;
};

export type MutationAddExperienceTypeArgs = {
  input: NewExperienceTypeInput;
};

export type MutationAddFormComponentGroupArgs = {
  input: NewFormComponentGroupInput;
};

export type MutationAddGreenhousePartnerArgs = {
  input: NewGreenhousePartnerInput;
};

export type MutationAddGroupSectionRelationArgs = {
  input: NewGroupSectionRelationInput;
};

export type MutationAddHirerCompanyRelationArgs = {
  input: NewHirerCompanyRelationInput;
};

export type MutationAddHirerHighlightArgs = {
  input: NewHighlightInput;
};

export type MutationAddHirerPositionRelationArgs = {
  input: NewHirerPositionRelationInput;
};

export type MutationAddHirerProfileArgs = {
  input: NewHirerProfileInput;
};

export type MutationAddInterestEmailArgs = {
  input: InterestEmailInput;
};

export type MutationAddLeverPartnerArgs = {
  input: NewLeverPartnerInput;
};

export type MutationAddPendingPositionRelationToCandidateArgs = {
  input: AddPositionRelationToCandidateInput;
};

export type MutationAddPermissionArgs = {
  input: NewPermissionInput;
};

export type MutationAddPositionArgs = {
  input: NewPositionInput;
};

export type MutationAddPositionForBenchmarkArgs = {
  input: AddPositionForBenchmarkInput;
};

export type MutationAddPositionTemplateForCompanyArgs = {
  input: AddPositionTemplateForCompanyInput;
};

export type MutationAddPositionWithMetadataArgs = {
  input: NewPositionMetaInput;
};

export type MutationAddQuestionComponentArgs = {
  input: NewQuestionComponentInput;
};

export type MutationAddQuestionTypeArgs = {
  input: NewQuestionTypeInput;
};

export type MutationAddReferenceRelationTypeArgs = {
  input: NewReferenceRelationTypeInput;
};

export type MutationAddResumeArgs = {
  input: NewResumeInput;
};

export type MutationAddResumesArgs = {
  input: NewResumesInput;
};

export type MutationAddSectionComponentArgs = {
  input: NewSectionComponentInput;
};

export type MutationAddSectionComponentRelationArgs = {
  input: NewSectionComponentRelationInput;
};

export type MutationAddSectionTypeArgs = {
  input: NewSectionTypeInput;
};

export type MutationAddSlackNotificationArgs = {
  input: AddSlackNotificationInput;
};

export type MutationAddStatusTypeArgs = {
  input: NewStatusTypeInput;
};

export type MutationAddSubsectionComponentArgs = {
  input: NewSubsectionComponentInput;
};

export type MutationAddSubsectionQuestionRelationArgs = {
  input: NewSubsectionQuestionRelationInput;
};

export type MutationAddTalentModelToBenchmarkArgs = {
  input: BenchmarkTalentModelUpdateInput;
};

export type MutationAddTrialCompanyArgs = {
  input: NewTrialCompanyInput;
};

export type MutationAddUserArgs = {
  input: NewUserInput;
};

export type MutationArchiveBenchmarkArgs = {
  input: ArchiveBenchmarkInput;
};

export type MutationArchiveTalentModelArgs = {
  input: TalentModelIdInput;
};

export type MutationAssignCompanyRoleToHirerArgs = {
  input: AssignCompanyRoleToHirerInput;
};

export type MutationAuthorizeWorkosArgs = {
  input: AuthorizeWorkosInput;
};

export type MutationBulkCreateRevieweesByMergeEmployeeArgs = {
  input: BulkCreateRevieweesByMergeEmployeeInput;
};

export type MutationBulkUpdateCandidateExperiencesArgs = {
  input: BulkUpdateCandidateExperienceInput;
};

export type MutationBulkUpdateExperienceSectionsArgs = {
  input: BulkUpdateExperienceSectionInput;
};

export type MutationBulkUpdateGroupSectionRelationsArgs = {
  input: BulkUpdateGroupSectionRelationInput;
};

export type MutationBulkUpdateSectionComponentRelationsArgs = {
  input: BulkUpdateSectionComponentRelationInput;
};

export type MutationBulkUpdateSubsectionQuestionRelationsArgs = {
  input: BulkUpdateSubsectionQuestionRelationInput;
};

export type MutationBulkUploadEmployeeCustomMetricsArgs = {
  input: BulkUploadEmployeeCustomMetricsInput;
};

export type MutationBulkUploadEmployeeReviewersArgs = {
  input: BulkUploadEmployeeReviewersInput;
};

export type MutationBulkUploadMergeEmployeesArgs = {
  input: BulkUploadMergeEmployeesInput;
};

export type MutationBulkUploadRevieweesArgs = {
  input: BulkUploadRevieweesInput;
};

export type MutationCancelUpcomingInvitationArgs = {
  input: CancelUpcomingInvitationInput;
};

export type MutationCopyBenchmarkRequestAnswersArgs = {
  input: CopyBenchmarkRequestAnswersInput;
};

export type MutationCreateAndInviteCandidatePositionRelationsArgs = {
  input: CreateAndInviteCandidatePositionRelationsInput;
};

export type MutationCreateAndInviteRevieweesAndReviewersArgs = {
  input: CreateAndInviteRevieweesAndReviewersInput;
};

export type MutationCreateAshbyPartnerArgs = {
  input: CreateAshbyPartnerInput;
};

export type MutationCreateAutomationSettingArgs = {
  input: CreateAutomationSettingInput;
};

export type MutationCreateBenchmarkAndRequestsArgs = {
  input: CreateBenchmarkAndRequestsInput;
};

export type MutationCreateBenchmarkPositionRelationArgs = {
  input: CreateBenchmarkPositionRelationInput;
};

export type MutationCreateBenchmarkRequestCommentArgs = {
  input: CreateBenchmarkRequestCommentInput;
};

export type MutationCreateBenchmarkRequestsForBenchmarkArgs = {
  input: CreateBenchmarkRequestsForBenchmarkInput;
};

export type MutationCreateCandidateAccountAndCreateActiveCprArgs = {
  input: CreateCandidateAccountAndCreateActiveCprInput;
};

export type MutationCreateCandidateAccountForExistingCprArgs = {
  input: CreateCandidateAccountForExistingCprInput;
};

export type MutationCreateCandidateShareInviteForMeArgs = {
  input: CandidateShareInviteForMeInput;
};

export type MutationCreateCandidateShareRequestArgs = {
  input: CreateCandidateShareRequestInput;
};

export type MutationCreateCompanyPerformanceMetricArgs = {
  input: CreateCompanyPerformanceMetricInput;
};

export type MutationCreateCompanyRoleArgs = {
  input: NewCompanyRoleInput;
};

export type MutationCreateFcgFromSurveyTemplateArgs = {
  input: CreateFcgFromSurveyTemplateInput;
};

export type MutationCreateHelpTextArgs = {
  input: NewHelpTextInput;
};

export type MutationCreateHirerTeamArgs = {
  input: CreateHirerTeamInput;
};

export type MutationCreateIntakeArgs = {
  input: CreateIntakeInput;
};

export type MutationCreateIntakeAndPositionArgs = {
  input: CreateIntakeAndPositionInput;
};

export type MutationCreateIntakeTemplateArgs = {
  input: CreateIntakeTemplateInput;
};

export type MutationCreateJobQuestionCriterionForJobPostArgs = {
  input: CreateJobQuestionCriterionForJobPostInput;
};

export type MutationCreatePublicSkillMapArgs = {
  input: CreatePublicSkillMapInput;
};

export type MutationCreateQuestionComponentForHirerArgs = {
  input: CreateQuestionComponentForHirerInput;
};

export type MutationCreateRecommendationArgs = {
  input: NewRecommendationInput;
};

export type MutationCreateReferenceRequestsArgs = {
  input: NewReferenceRequestsInput;
};

export type MutationCreateRelevantExperienceCriterionForJobPostArgs = {
  input: CreateRelevantExperienceCriterionForJobPostInput;
};

export type MutationCreateReviewCycleForCompanyArgs = {
  input: CreateReviewCycleInput;
};

export type MutationCreateRevieweeForReviewCycleArgs = {
  input: CreateRevieweeInput;
};

export type MutationCreateSkillMapForCompanyArgs = {
  input: CreateSkillMapForCompanyInput;
};

export type MutationCreateSoftSkillCriterionForJobPostArgs = {
  input: CreateSoftSkillCriterionForJobPostInput;
};

export type MutationCreateSrPartnerArgs = {
  input: CreateSrPartnerInput;
};

export type MutationCreateSurveyTemplateArgs = {
  input: CreateSurveyTemplateInput;
};

export type MutationCreateWorkdayPartnerArgs = {
  input: CreateWorkdayPartnerInput;
};

export type MutationDeclineRecommendationForCandidateArgs = {
  input: DeclineRecommendationForCandidateInput;
};

export type MutationDeclineReferenceRequestArgs = {
  input: DeclineReferenceRequestInput;
};

export type MutationDeepCopyFormComponentGroupArgs = {
  input: DuplicateFormComponentGroupInput;
};

export type MutationDeleteBenchmarkCollaboratorArgs = {
  input: DeleteBenchmarkCollaboratorInput;
};

export type MutationDeleteBenchmarkRequestArgs = {
  input: DeleteBenchmarkRequestInput;
};

export type MutationDeleteBenchmarkRequestCommentArgs = {
  input: DeleteBenchmarkRequestCommentInput;
};

export type MutationDeleteCandidatePositionRelationArgs = {
  input: DeleteCandidatePositionRelationInput;
};

export type MutationDeleteCompanyApiKeyArgs = {
  input: FetchCompanyByIdInput;
};

export type MutationDeleteCompanyPerformanceMetricArgs = {
  input: DeleteCompanyPerformanceMetricInput;
};

export type MutationDeleteCompetencyCriterionForJobPostArgs = {
  input: DeleteCompetencyCriterionForJobPostInput;
};

export type MutationDeleteExperienceDetailArgs = {
  input: DeleteExperienceDetailInput;
};

export type MutationDeleteGroupSectionRelationArgs = {
  input: DeleteGroupSectionRelationInput;
};

export type MutationDeleteHirerCompanyRelationArgs = {
  input: UpdateHirerCompanyRelationInput;
};

export type MutationDeleteHirerCompanyRelationForAdminHirerArgs = {
  input: DeleteHirerCompanyRelationInput;
};

export type MutationDeleteHirerCompanyRoleArgs = {
  input: DeleteHirerCompanyRoleInput;
};

export type MutationDeleteHirerHighlightArgs = {
  input: DeleteHighlightInput;
};

export type MutationDeleteHirerPositionRelationArgs = {
  input: SearchHirerPositionRelationInput;
};

export type MutationDeleteJobQuestionCriterionForJobPostArgs = {
  input: DeleteJobQuestionCriterionForJobPostInput;
};

export type MutationDeletePositionTemplateArgs = {
  input: DeletePositionTemplateInput;
};

export type MutationDeleteQuestionRatingArgs = {
  input: DeleteQuestionRatingInput;
};

export type MutationDeleteRelevantExperienceCriterionForJobPostArgs = {
  input: DeleteRelevantExperienceCriterionForJobPostInput;
};

export type MutationDeleteRevieweeArgs = {
  input: DeleteRevieweeInput;
};

export type MutationDeleteSectionComponentArgs = {
  input: DeleteSectionComponentInput;
};

export type MutationDeleteSectionComponentRelationArgs = {
  input: DeleteSectionComponentRelationInput;
};

export type MutationDeleteSkillMapForJobOpeningArgs = {
  input: DeleteBenchmarkSkillMapForJobOpeningInput;
};

export type MutationDeleteSoftSkillCriterionForJobPostArgs = {
  input: DeleteSoftSkillCriterionForJobPostInput;
};

export type MutationDeleteSubsectionQuestionRelationArgs = {
  input: DeleteSubsectionQuestionRelationInput;
};

export type MutationDeleteTalentModelArgs = {
  input: TalentModelIdInput;
};

export type MutationDuplicateBenchmarkArgs = {
  input: DuplicateBenchmarkInput;
};

export type MutationDuplicatePositionArgs = {
  input: DuplicatePositionInput;
};

export type MutationDuplicateSectionComponentArgs = {
  input: DuplicateSectionComponentInput;
};

export type MutationDuplicateSkillMapForCompanyArgs = {
  input: DuplicateSkillMapForCompanyInput;
};

export type MutationDuplicateSubsectionComponentArgs = {
  input: DuplicateSubsectionComponentInput;
};

export type MutationDuplicateTalentModelArgs = {
  input: TalentModelIdInput;
};

export type MutationEditBenchmarkBehavioralModelArgs = {
  input: EditBenchmarkBehavioralModelInput;
};

export type MutationGenerateCompanyApiKeyArgs = {
  input: FetchCompanyByIdInput;
};

export type MutationGenerateCompanyMergeAccountTokenArgs = {
  input: GenerateCompanyMergeAccountTokenInput;
};

export type MutationGenerateCompanyMergeLinkTokenArgs = {
  input: GenerateCompanyMergeLinkTokenInput;
};

export type MutationGenerateJobInterviewArgs = {
  input: GenerateJobInterviewInput;
};

export type MutationGenerateSelfAssessmentFromBehavioralModelArgs = {
  input: GenerateSelfAssessmentFromBehavioralModelInput;
};

export type MutationGetOrCreateBenchmarkAndCreateBenchmarkRequestsArgs = {
  input: GetOrCreateBenchmarkAndCreateBenchmarkRequestsInput;
};

export type MutationGetOrCreateBenchmarkAndRequestsForPositionArgs = {
  input: GetOrCreateBenchmarkAndCreateBenchmarkRequestsInput;
};

export type MutationGetOrCreateCandidateForPositionArgs = {
  input: GetOrCreateCandidateForPositionInput;
};

export type MutationGetOrCreateQuestionRatingArgs = {
  input: NewQuestionRatingInput;
};

export type MutationHirerEditQuestionComponentArgs = {
  input: HirerEditQuestionComponentInput;
};

export type MutationInviteBenchmarkCollaboratorArgs = {
  input: InviteBenchmarkCollaboratorInput;
};

export type MutationInviteBenchmarkCollaboratorsArgs = {
  input: InviteBenchmarkCollaboratorsInput;
};

export type MutationInviteHirerProfileToCompanyArgs = {
  input: InviteHirerProfileToCompanyInput;
};

export type MutationInviteHirersToIntakeArgs = {
  input: InviteHirersToIntakeInput;
};

export type MutationInviteHirersToIntakeV2Args = {
  input: InviteHirersToIntakeV2Input;
};

export type MutationInviteReferencerWithCandidateRelationArgs = {
  input: InviteReferencerWithCandidateRelationInput;
};

export type MutationMarkCandidatePositionRelationReferencesAsCompleteArgs = {
  input: MarkCandidatePositionRelationReferencesAsCompleteInput;
};

export type MutationMergePositionsArgs = {
  input: MergePositionsInput;
};

export type MutationMoveApplicationToStageArgs = {
  input: MoveApplicationToStageInput;
};

export type MutationMoveCandidateToPositionArgs = {
  input: MoveCandidateToPositionInput;
};

export type MutationNotifyCandidateForRecommendationArgs = {
  input: NotifyCandidateForRecommendationInput;
};

export type MutationOverrideUpcomingInvitationDateArgs = {
  input: OverrideUpcomingInvitationDateInput;
};

export type MutationRefreshUserTokenArgs = {
  input: RefreshTokenInput;
};

export type MutationRegenerateJobInterviewArgs = {
  input: RegenerateJobInterviewInput;
};

export type MutationRejectApplicationArgs = {
  input: RejectApplicationInput;
};

export type MutationRemoveIntakeAccessForHirerArgs = {
  input: RemoveIntakeAccessForHirerInput;
};

export type MutationRemoveSlackForCompanyArgs = {
  input: Scalars['Int'];
};

export type MutationRemoveTalentModelFromBenchmarkArgs = {
  input: BenchmarkTalentModelUpdateInput;
};

export type MutationRequestApplicationsSyncForJobArgs = {
  input: RequestApplicationsSyncForJobInput;
};

export type MutationRequestApplicationsSyncForJobPostArgs = {
  input: RequestApplicationsSyncForJobPostInput;
};

export type MutationRequestCandidateShareInviteVerificationArgs = {
  input: RequestCandidateShareInviteVerificationInput;
};

export type MutationRequestUserResetArgs = {
  input: RequestUserResetInput;
};

export type MutationRescheduleCanceledInvitationArgs = {
  input: RescheduleCanceledInvitationInput;
};

export type MutationResendBenchmarkRequestArgs = {
  input: ResendBenchmarkRequestInput;
};

export type MutationResetReferenceSubmissionArgs = {
  input: ResetReferenceSubmissionInput;
};

export type MutationRetryInvitationForCandidatePositionRelationArgs = {
  input: RetryInvitationForCandidatePositionRelationInput;
};

export type MutationSaveBenchmarkRequestArgs = {
  input: BenchmarkRequestResponseInput;
};

export type MutationSaveCandidateAssessmentArgs = {
  input: CandidateAssessmentResponseInput;
};

export type MutationSaveReferenceRequestV2Args = {
  input: ReferenceRequestResponseInputV2;
};

export type MutationSendCompanyCommPreviewArgs = {
  input: SendCompanyCommPreviewInput;
};

export type MutationSendInvitationForCandidatePositionRelationArgs = {
  input: SendCandidateInvitationInput;
};

export type MutationSendReminderForCandidatePositionRelationArgs = {
  input: SendReminderForCandidatePositionRelationInput;
};

export type MutationSendTestCandidateInviteArgs = {
  input: CreateAndInviteCandidatePositionRelationsInput;
};

export type MutationSignInAdminUserArgs = {
  input: SignInAdminUserInput;
};

export type MutationSignInHirerUserArgs = {
  input: SignInHirerUserInput;
};

export type MutationSignInHirerWithBenchmarkArgs = {
  input: SignInHirerWithBenchmarkInput;
};

export type MutationSignInHirerWithGoogleArgs = {
  input: SignInHirerWithGoogleInput;
};

export type MutationSignInReferencerArgs = {
  input: SignInReferencerInput;
};

export type MutationSignInUserArgs = {
  input: SignInUserInput;
};

export type MutationSignInWithGoogleArgs = {
  input: SignInWithGoogleInput;
};

export type MutationSignInWithWorkosArgs = {
  input: SignInWithWorkosInput;
};

export type MutationSignUpCandidateAndCreateCprWithGoogleArgs = {
  input: SignUpCandidateAndCreateCprWithGoogleInput;
};

export type MutationSignUpCandidateForCprWithGoogleArgs = {
  input: SignUpCandidateForCprWithGoogleInput;
};

export type MutationSignUpReferencerUserArgs = {
  input: AddReferencerProfileInput;
};

export type MutationSnapshotBehaviorModelArgs = {
  input: SnapshotBehaviorModelInput;
};

export type MutationSnapshotBehavioralAssessmentBehavioralModelArgs = {
  input: SnapshotBehavioralAssessmentBehavioralModelInput;
};

export type MutationSnapshotTalentModelArgs = {
  input: SnapshotTalentModelInput;
};

export type MutationSubmitBenchmarkRequestArgs = {
  input: BenchmarkRequestResponseInput;
};

export type MutationSubmitCandidateAssessmentArgs = {
  input: CandidateAssessmentResponseInput;
};

export type MutationSubmitReferenceRequestV2Args = {
  input: ReferenceRequestResponseInputV2;
};

export type MutationSubmitReferencerNpsArgs = {
  input: SubmitReferencerNpsInput;
};

export type MutationSubmitSlidingScaleCustomizationRequestArgs = {
  input: SubmitSlidingScaleCustomizationRequestInput;
};

export type MutationSwapTalentModelFromJobOpeningArgs = {
  input: BenchmarkTalentModelUpdateInput;
};

export type MutationSyncApplicationAndResumeArgs = {
  input: GetAtsApplicationByKey;
};

export type MutationTakeoverCompanyArgs = {
  input: TakeoverCompanyInput;
};

export type MutationTakeoverUserArgs = {
  input: TakeoverUserInput;
};

export type MutationTestCanAccessGreenhouseJobPostsArgs = {
  input: TestCanAccessGreenhouseJobPostsInput;
};

export type MutationToggleArchiveCompanyFormByFormComponentGroupArgs = {
  input: ToggleArchiveCompanyByFormComponentGroupInput;
};

export type MutationToggleShouldScreenApplicantsArgs = {
  input: ToggleShouldScreenApplicantsInput;
};

export type MutationToggleShouldScreenApplicantsForJobPostArgs = {
  input: ToggleShouldScreenApplicantsInput;
};

export type MutationUnrejectApplicationArgs = {
  input: UnrejectApplicationInput;
};

export type MutationUpdateAshbyPartnerArgs = {
  input: UpdateAshbyPartnerInput;
};

export type MutationUpdateAutomationSettingArgs = {
  input: UpdateAutomationSettingInput;
};

export type MutationUpdateAutomationSettingStatusArgs = {
  input: UpdateAutomationSettingStatusInput;
};

export type MutationUpdateBenchmarkArgs = {
  input: UpdateBenchmarkInput;
};

export type MutationUpdateBenchmarkCollaboratorNotificationsArgs = {
  input: UpdateBenchmarkCollaboratorNotificationsInput;
};

export type MutationUpdateBenchmarkRequestCommentArgs = {
  input: UpdateBenchmarkRequestCommentInput;
};

export type MutationUpdateBenchmarkRequestNotificationsArgs = {
  input: UpdateBenchmarkRequestNotificationsInput;
};

export type MutationUpdateBulkHirerRelationsForPositionArgs = {
  input: UpdateBulkHirerRelationsForPositionInput;
};

export type MutationUpdateBulkRecommendationCompaniesForCandidateArgs = {
  input: BulkUpdateRecommendationCompaniesInput;
};

export type MutationUpdateCandidateExperienceArgs = {
  input: UpdateCandidateExperienceInput;
};

export type MutationUpdateCandidateExperienceForMeArgs = {
  input: UpdateCandidateExperienceInput;
};

export type MutationUpdateCandidateIsPublicArgs = {
  input: UpdateCandidateIsPublicInput;
};

export type MutationUpdateCandidateJobPreferencesArgs = {
  input: UpdateCandidateJobPreferencesInput;
};

export type MutationUpdateCandidatePictureArgs = {
  input: UpdateCandidateAvatarInput;
};

export type MutationUpdateCandidatePositionRelationArgs = {
  input: UpdateCandidatePositionRelationInput;
};

export type MutationUpdateCandidateProfileArgs = {
  input: UpdateCandidateProfileInput;
};

export type MutationUpdateCandidateReferencerRelationArgs = {
  input: UpdateCandidateReferencerRelationInput;
};

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};

export type MutationUpdateCompanyLogoArgs = {
  input: UpdateCompanyLogoInput;
};

export type MutationUpdateCompanyNotificationEventsArgs = {
  input: UpdateCompanyNotificationEventsInput;
};

export type MutationUpdateCompanyNotificationTypeArgs = {
  input: UpdateCompanyNotificationTypeInput;
};

export type MutationUpdateCompanyPerformanceMetricArgs = {
  input: UpdateCompanyPerformanceMetricInput;
};

export type MutationUpdateCompanyRoleArgs = {
  input: UpdateCompanyRoleInput;
};

export type MutationUpdateCompanyShowPositionTitleToCandidateArgs = {
  input: UpdateCompanyShowPositionTitleToCandidateInput;
};

export type MutationUpdateDomainForCompanyArgs = {
  input: UpdateDomainForCompanyInput;
};

export type MutationUpdateExperienceSectionArgs = {
  input: UpdateExperienceSectionInput;
};

export type MutationUpdateFormComponentGroupArgs = {
  input: UpdateFormComponentGroupInput;
};

export type MutationUpdateFormComponentGroupByKeyArgs = {
  input: UpdateFormComponentGroupByKeyInput;
};

export type MutationUpdateFormComponentGroupsBySectionArgs = {
  input: UpdateFormComponentGroupBySectionInput;
};

export type MutationUpdateGreenhousePartnerArgs = {
  input: UpdateGreenhousePartnerInput;
};

export type MutationUpdateHirerCompanyPermissionsFromAdminHirerArgs = {
  input: UpdateHirerCompanyPermissionsFromAdminHirerInput;
};

export type MutationUpdateHirerCompanyRelationArgs = {
  input: UpdateHirerCompanyRelationInput;
};

export type MutationUpdateHirerExcludedApplicationsArgs = {
  input: NewHirerExcludedApplicationsInput;
};

export type MutationUpdateHirerPositionRelationReceiveEmailSettingArgs = {
  input: UpdateHirerPositionRelationInput;
};

export type MutationUpdateHirerTeamArgs = {
  input: UpdateHirerTeamInput;
};

export type MutationUpdateHiringCriteriaForJobPostArgs = {
  input: UpdateHiringCriteriaForJobPostInput;
};

export type MutationUpdateIntakeAccessForHirerArgs = {
  input: UpdateIntakeAccessForHirerInput;
};

export type MutationUpdateInterestEmailArgs = {
  input: UpdateInterestEmailInput;
};

export type MutationUpdateInvitationEmailForCandidatePositionRelationArgs = {
  input: UpdateInvitationEmailForCandidatePositionRelationInput;
};

export type MutationUpdateJobInterviewQuestionArgs = {
  input: UpdateJobInterviewQuestionInput;
};

export type MutationUpdateJobQuestionCriterionForJobPostArgs = {
  input: UpdateJobQuestionCriterionForJobPostInput;
};

export type MutationUpdateLeverPartnerArgs = {
  input: UpdateLeverPartnerInput;
};

export type MutationUpdateLockedSkillMapArgs = {
  input: UpdateLockedSkillMapInput;
};

export type MutationUpdateMyUserSettingsArgs = {
  input: UpdateMyUserSettingsInput;
};

export type MutationUpdateNotificationsForIntakeHirerArgs = {
  input: UpdateNotificationsForIntakeHirerInput;
};

export type MutationUpdateOptionalReferenceRequestFieldsArgs = {
  input: UpdateOptionalReferenceRequestFieldsInput;
};

export type MutationUpdatePendingHirerProfileArgs = {
  input: UpdateHirerProfileInput;
};

export type MutationUpdatePerformanceBucketsForReflectionRevieweesArgs = {
  input: UpdatePerformanceBucketsForReflectionRevieweesInput;
};

export type MutationUpdatePositionArgs = {
  input: UpdatePositionInput;
};

export type MutationUpdatePositionStatusArgs = {
  input: UpdatePositionStatusInput;
};

export type MutationUpdatePositionTemplateArgs = {
  input: UpdatePositionTemplate;
};

export type MutationUpdatePublicSkillMapArgs = {
  input: UpdateSkillMapInput;
};

export type MutationUpdateQuestionComponentArgs = {
  input: UpdateQuestionComponentInput;
};

export type MutationUpdateRecommendationArgs = {
  input: UpdateRecommendationInput;
};

export type MutationUpdateRecommendationCompanyForCandidateArgs = {
  input: UpdateRecommendationCompanyInput;
};

export type MutationUpdateReferenceIsPublicArgs = {
  input: UpdateReferenceIsPublicInput;
};

export type MutationUpdateReflectionPerformanceBucketForSingleRevieweeArgs = {
  input: UpdateReflectionPerformanceBucketForSingleRevieweeInput;
};

export type MutationUpdateReviewCycleArgs = {
  input: UpdateReviewCycleInput;
};

export type MutationUpdateRevieweeArgs = {
  input: UpdateRevieweeInput;
};

export type MutationUpdateSectionComponentArgs = {
  input: UpdateSectionComponentInput;
};

export type MutationUpdateSkillMapArgs = {
  input: UpdateSkillMapInput;
};

export type MutationUpdateSkillMapForJobOpeningArgs = {
  input: UpdateSkillMapForJobOpeningInput;
};

export type MutationUpdateSkillMapsForBenchmarkArgs = {
  input: UpdateSkillMapsForBenchmarkInput;
};

export type MutationUpdateSkillMapsForPositionArgs = {
  input: UpdateSkillMapsForPositionInput;
};

export type MutationUpdateSrPartnerArgs = {
  input: UpdateSrPartnerInput;
};

export type MutationUpdateSubsectionComponentArgs = {
  input: UpdateSubsectionComponentInput;
};

export type MutationUpdateSurveyTemplateArgs = {
  input: UpdateSurveyTemplateInput;
};

export type MutationUpdateTalentModelMetaDataArgs = {
  input: UpdateTalentModelMetaDataInput;
};

export type MutationUpdateTemplateOnIntakeArgs = {
  input: UpdateTemplateOnIntakeInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};

export type MutationUpdateWorkdayPartnerArgs = {
  input: UpdateWorkdayPartnerInput;
};

export type MutationUpsertAnswerArgs = {
  input: UpsertAnswerInput;
};

export type MutationUpsertAnswerForBenchmarkRequestArgs = {
  input: UpsertAnswerForBenchmarkRequestInput;
};

export type MutationUpsertBehavioralModelArgs = {
  input: BehaviorModelInput;
};

export type MutationUpsertCommTypeForCompanyArgs = {
  input: UpsertCommTypeForCompanyInput;
};

export type MutationUpsertCompetencyCriterionForJobPostArgs = {
  input: UpsertCompetencyCriterionForJobPostInput;
};

export type MutationUpsertTalentModelArgs = {
  input: TalentModelInput;
};

export type MutationUsertEmployeeCprArgs = {
  input: UsertEmployeeCprInput;
};

export type MutationVerifyHirerProfileForCandidateShareInviteArgs = {
  input: VerifyHirerProfileForCandidateShareInviteInput;
};

export type NewAnswerGroupInput = {
  draftReferenceRequestId?: Scalars['Int'];
  submissionDate?: Scalars['Date'];
  submittedReferenceRequestId?: Scalars['Int'];
};

export type NewAnswerInput = {
  answerBody: Scalars['JSON'];
  answerGroupId: Scalars['Int'];
  details?: Scalars['JSON'];
  subsectionQuestionRelationId: Scalars['Int'];
};

export type NewAnswerOptionGroupInput = {
  answerOptions: Array<AnswerOptionInput>;
  details?: Scalars['JSON'];
  groupName: Scalars['String'];
};

export type NewAnswerOptionInput = {
  answerOptionGroupId: Scalars['Int'];
  details?: Scalars['JSON'];
  optionName: Scalars['String'];
  subtitle?: Scalars['String'];
};

export type NewCandidateExperienceAndReferencerRelationInput = {
  candidateReferencerRelation: NewCandidateReferencerRelationInput;
  newCandidateExperience: NewCandidateExperienceInput;
};

export type NewCandidateExperienceInput = {
  companyName: Scalars['String'];
  details?: Scalars['JSON'];
  endMonth?: Scalars['Int'];
  endYear?: Scalars['Int'];
  experienceSectionId: Scalars['Int'];
  experienceTypeId: Scalars['Int'];
  isPresent?: Scalars['Boolean'];
  locationCity?: Scalars['String'];
  locationCountry?: Scalars['String'];
  locationState?: Scalars['String'];
  order: Scalars['Int'];
  positionTitle?: Scalars['String'];
  startMonth?: Scalars['Int'];
  startYear?: Scalars['Int'];
};

export type NewCandidateReferencerRelationInput = {
  candidateExperienceId: Scalars['Int'];
  contextForReference?: Scalars['String'];
  details?: Scalars['JSON'];
  overlapEndDate?: Scalars['Date'];
  overlapStartDate?: Scalars['Date'];
  referencerEmail: Scalars['String'];
  referencerFirstName: Scalars['String'];
  referencerLastName: Scalars['String'];
  referencerTitle: Scalars['String'];
  timeOverlap?: Scalars['String'];
  workRelationship: Scalars['String'];
};

export type NewCompanyInput = {
  atsName?: Scalars['String'];
  companyAddress?: Scalars['String'];
  companyName: Scalars['String'];
  details?: Scalars['JSON'];
  whitelistedEmailDomain?: Scalars['String'];
};

export type NewCompanyRoleInput = {
  accessPermissions: Array<Scalars['ID']>;
  companyKey: Scalars['ID'];
  name: Scalars['String'];
  roleDescription?: Scalars['String'];
};

export type NewExperienceDetailInput = {
  candidateExperienceId: Scalars['Int'];
  componentText: Scalars['String'];
  details?: Scalars['JSON'];
  order: Scalars['Int'];
};

export type NewExperienceSectionInput = {
  candidateProfileId: Scalars['Int'];
  componentText: Scalars['String'];
  details?: Scalars['JSON'];
  order: Scalars['Int'];
};

export type NewExperienceTypeInput = {
  details?: Scalars['JSON'];
  typeName: Scalars['String'];
};

export type NewFormComponentGroupInput = {
  componentText: Scalars['String'];
  details?: Scalars['JSON'];
  fcgTypeId?: Scalars['Int'];
  formIdentifier: Scalars['String'];
  formTypeId?: Scalars['Int'];
  instructionText?: Array<Scalars['String']>;
  isCommon?: Scalars['Boolean'];
  isCommonIntake?: Scalars['Boolean'];
  isPublicTemplate?: Scalars['Boolean'];
  version?: Scalars['Int'];
};

export type NewGreenhousePartnerInput = {
  companyId: Scalars['Int'];
  customJobFieldForIntake?: Scalars['String'];
  ghApiKey?: Scalars['String'];
  ghUserId?: Scalars['String'];
  groupsToGrantAccess?: Array<GreenhouseGroupsToGrantAccess | `${GreenhouseGroupsToGrantAccess}`>;
  groupsToUpdateOnSubmission?: Array<
    GreenhouseGroupsToUpdateOnSubmission | `${GreenhouseGroupsToUpdateOnSubmission}`
  >;
};

export type NewGroupSectionRelationInput = {
  formComponentGroupId: Scalars['Int'];
  isRequired?: Scalars['Boolean'];
  order: Scalars['Int'];
  sectionComponentId: Scalars['Int'];
};

export type NewHelpTextInput = {
  body: Scalars['String'];
  questionComponentId: Scalars['Int'];
  title: Scalars['String'];
};

export type NewHighlightInput = {
  answerId: Scalars['Int'];
  candidatePositionRelationId: Scalars['Int'];
};

export type NewHireFit = {
  __typename?: 'NewHireFit';
  candidatePositionRelationId: Scalars['Int'];
  score: Scalars['Float'];
  scoreText: Scalars['String'];
};

export type NewHirerCompanyRelationInput = {
  companyId: Scalars['Int'];
  hirerProfileId: Scalars['Int'];
  permissionId?: Scalars['Int'];
};

export type NewHirerExcludedApplicationsInput = {
  candidatePositionRelationIds: Array<Scalars['Int']>;
  companyId: Scalars['Int'];
  hirerProfileId: Scalars['Int'];
};

export type NewHirerIntakeAccessInput = {
  accessLevel: IntakeAccessLevel | `${IntakeAccessLevel}`;
  emailToInvite?: Scalars['String'];
  hirerProfileKey?: Scalars['ID'];
};

export type NewHirerPositionRelationInput = {
  hirerProfileId: Scalars['Int'];
  positionId: Scalars['Int'];
  receivesReferenceNotificationEmails?: Scalars['Boolean'];
};

export type NewHirerProfileInput = {
  companyId?: Scalars['Int'];
  creatorUserId?: Scalars['Int'];
  hirerEmail: Scalars['String'];
  hirerFirstName: Scalars['String'];
  hirerLastName: Scalars['String'];
  isArchived: Scalars['Boolean'];
  positionId?: Scalars['Int'];
  sendInvitation?: Scalars['Boolean'];
  tempPassword?: Scalars['String'];
  username: Scalars['String'];
};

export type NewLeverPartnerInput = {
  companyId: Scalars['Int'];
  defaultPositionId?: Scalars['Int'];
  defaultPositionTemplateId?: Scalars['Int'];
  leverApiKey?: Scalars['String'];
  leverUserId?: Scalars['String'];
  signingToken?: Scalars['String'];
  stageNameTrigger?: Scalars['String'];
};

export type NewPermissionInput = {
  details?: Scalars['JSON'];
  permissionName: Scalars['String'];
};

export type NewPositionInput = {
  benchmarkKey?: Scalars['String'];
  candidateInviteFromHirer?: Scalars['String'];
  companyId: Scalars['Int'];
  details?: Scalars['JSON'];
  formComponentGroupId?: Scalars['Int'];
  hirerProfileId?: Scalars['Int'];
  isArchived?: Scalars['Boolean'];
  isOpen?: Scalars['Boolean'];
  numRecommendedReferences?: Scalars['Int'];
  numRequiredReferences?: Scalars['Int'];
  positionIdentifier?: Scalars['String'];
  positionTitle: Scalars['String'];
  referencerResponseDays?: Scalars['Int'];
  validSurveySubmissionWindowDays?: Scalars['Int'];
};

export type NewPositionMetaInput = {
  behavioralAssessmentTargetScore?: Scalars['Int'];
  candidateFormComponentGroupId?: Scalars['Int'];
  candidateInviteFromHirer?: Scalars['String'];
  candidateViewFullReport?: Scalars['Boolean'];
  companyId: Scalars['Int'];
  details?: Scalars['JSON'];
  externalJobId?: Scalars['String'];
  externalJobSourceId?: Scalars['Int'];
  formComponentGroupId?: Scalars['Int'];
  hideRespondentName?: Scalars['Boolean'];
  hirerPositionRelations?: Array<AddHirerPositionRelationMetaInput>;
  hirerProfileIdsForBenchmarkRequests?: Array<Scalars['Int']>;
  hirerSettingsForBenchmarkRequests?: Array<HirerSettingsForBenchmarkRequestsInput>;
  isArchived?: Scalars['Boolean'];
  isOpen?: Scalars['Boolean'];
  notifyCandidateWithUpdates?: Scalars['Boolean'];
  numRecommendedReferences?: Scalars['Int'];
  numRequiredReferences?: Scalars['Int'];
  positionIdentifier?: Scalars['String'];
  positionReferenceRequirements?: Array<AddPositionReferenceRequirementMetaInput>;
  positionTitle: Scalars['String'];
  referenceRequirementSyntax?: RefRequirementSyntaxNodeInput;
  referencerResponseDays?: Scalars['Int'];
  reviewCycleId?: Scalars['Int'];
  sendAutomaticRemindersToSubjects?: Scalars['Boolean'];
  skillMapsWithLevels?: Array<SkillMapWithTargetLevelsInput>;
  subjectCanInviteRespondent?: Scalars['Boolean'];
  surveysToRelationships?: Array<AddPositionSurveyToRelationshipInput>;
  validSurveySubmissionWindowDays?: Scalars['Int'];
  withReviewCycle?: Scalars['Boolean'];
};

export type NewPositionSurveyToRelationshipInput = {
  formComponentGroupKey: Scalars['ID'];
  positionKey: Scalars['ID'];
  referenceRelationType: Scalars['ID'];
};

export type NewPositionTemplateSurveyToRelationshipInput = {
  formComponentGroupKey: Scalars['ID'];
  positionTemplateKey: Scalars['ID'];
  referenceRelationType: Scalars['ID'];
};

export type NewQuestionComponentInput = {
  answerOptionGroupId?: Scalars['Int'];
  answerTopic?: AnswerTopicEnum | `${AnswerTopicEnum}`;
  componentIdentifier: Scalars['String'];
  componentText?: Scalars['String'];
  details?: Scalars['JSON'];
  helpTexts?: Array<HelpTextInput>;
  questionTypeId: Scalars['Int'];
  subtitleText?: Scalars['String'];
};

export type NewQuestionRatingInput = {
  candidatePositionRelationId: Scalars['Int'];
  questionComponentId: Scalars['Int'];
  questionRatingId?: Scalars['Int'];
  rating: Scalars['Int'];
};

export type NewQuestionTypeInput = {
  details?: Scalars['JSON'];
  typeName: Scalars['String'];
};

export type NewRecommendationInput = {
  candidateEmail?: Scalars['String'];
  candidateFirstName: Scalars['String'];
  candidateLastName: Scalars['String'];
  candidateLinkedin?: Scalars['String'];
  candidatePhoneNumber?: Scalars['String'];
  companyName?: Scalars['String'];
  overlapEndDate: Scalars['Date'];
  overlapStartDate: Scalars['Date'];
  referencerTitle: Scalars['String'];
  referencerWorkRelationship: Scalars['String'];
};

export type NewReferenceRelationTypeInput = {
  details?: Scalars['JSON'];
  typeName: Scalars['String'];
};

export type NewReferenceRequestsInput = {
  allowFollowUp?: Scalars['Boolean'];
  candidatePositionRelationId?: Scalars['Int'];
  candidateReferencerRelationId: Scalars['Int'];
  contextForReference?: Scalars['String'];
  inviteFromCandidate?: Scalars['String'];
  isPublic?: Scalars['Boolean'];
  referencerInvitedDate?: Scalars['Date'];
  referencerResponseDays?: Scalars['Int'];
};

export type NewResumeInput = {
  candidateProfileId?: Scalars['Int'];
  componentText: Scalars['String'];
  positionId?: Scalars['Int'];
  url: Scalars['String'];
};

export type NewResumesInput = {
  resumes: Array<NewResumeInput>;
};

export type NewSectionComponentInput = {
  componentIdentifier: Scalars['String'];
  componentText?: Scalars['String'];
  details?: Scalars['JSON'];
  sectionTypeId?: Scalars['Int'];
};

export type NewSectionComponentRelationInput = {
  isRequired?: Scalars['Boolean'];
  order: Scalars['Int'];
  sectionComponentId: Scalars['Int'];
  subsectionComponentId: Scalars['Int'];
};

export type NewSectionTypeInput = {
  details?: Scalars['JSON'];
  typeName: Scalars['String'];
};

export type NewStatusTypeInput = {
  details?: Scalars['JSON'];
  typeName: Scalars['String'];
};

export type NewSubsectionComponentInput = {
  componentIdentifier: Scalars['String'];
  componentText?: Scalars['String'];
  conditionalAnswers?: Array<Scalars['String']>;
  details?: Scalars['JSON'];
  headingText?: Scalars['String'];
  isConditional?: Scalars['Boolean'];
  parentSubsectionId?: Scalars['Int'];
};

export type NewSubsectionQuestionRelationInput = {
  isRequired?: Scalars['Boolean'];
  order: Scalars['Int'];
  questionComponentId: Scalars['Int'];
  subsectionComponentId: Scalars['Int'];
};

export type NewTrialCompanyInput = {
  companyName: Scalars['String'];
  email: Scalars['String'];
  googleId?: Scalars['String'];
  password?: Scalars['String'];
  userFirstName: Scalars['String'];
  userLastName: Scalars['String'];
};

export type NewUserInput = {
  candidateAddress?: Scalars['String'];
  candidateEmail?: Scalars['String'];
  candidatePhoneNumber?: Scalars['String'];
  candidateProfileId?: Scalars['Int'];
  details?: Scalars['JSON'];
  firstName: Scalars['String'];
  hirerEmail?: Scalars['String'];
  hirerPhoneNumber?: Scalars['String'];
  hirerProfileId?: Scalars['Int'];
  isArchived?: Scalars['Boolean'];
  isCandidate?: Scalars['Boolean'];
  isHirer?: Scalars['Boolean'];
  isReferencer?: Scalars['Boolean'];
  lastName: Scalars['String'];
  password?: Scalars['String'];
  permissionId?: Scalars['Int'];
  primaryEmail: Scalars['String'];
  referencerAddress?: Scalars['String'];
  referencerEmail?: Scalars['String'];
  referencerPhoneNumber?: Scalars['String'];
  referencerProfileId?: Scalars['Int'];
  username: Scalars['String'];
};

export type NotificationEvent = {
  __typename?: 'NotificationEvent';
  createdAt: Scalars['Date'];
  isArchived: Scalars['Boolean'];
  notificationEventId: Scalars['Int'];
  notificationEventName: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type NotificationType = {
  __typename?: 'NotificationType';
  notificationTypeId: Scalars['Int'];
  notificationTypeName: Scalars['String'];
};

export type NotifyCandidateForRecommendationInput = {
  recommendationId: Scalars['Int'];
};

export type OpenEndedAnswer = BaseAnswer & {
  __typename?: 'OpenEndedAnswer';
  answer: Scalars['String'];
  answerBody: Scalars['JSON'];
  answerGroupId: Scalars['Int'];
  answerGroupKey: Scalars['ID'];
  answerId: Scalars['Int'];
  questionComponentId: Scalars['Int'];
  questionIdentifier: Scalars['String'];
  questionText: Scalars['String'];
  questionType: Scalars['String'];
  respondent: RespondentInfo;
  subsectionQuestionRelationId: Scalars['Int'];
  subsectionQuestionRelationKey: Scalars['String'];
};

export enum Operator {
  AND = 'AND',
  EQ = 'EQ',
  OR = 'OR',
}

export type OpportunityIdInput = {
  opportunityId: Scalars['String'];
};

export type OpportunityPdfReportInput = {
  opportunityId: Scalars['String'];
};

export type OverrideUpcomingInvitationDateInput = {
  invitationDate: Scalars['Date'];
  upcomingInvitationKey: Scalars['ID'];
};

export type PagingReferenceRequests = {
  __typename?: 'PagingReferenceRequests';
  data: Array<ReferenceRequest>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagingSkillMapCompetencies = {
  __typename?: 'PagingSkillMapCompetencies';
  data: Array<SkillMapCompetency>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagingSkillMaps = {
  __typename?: 'PagingSkillMaps';
  data: Array<SkillMap>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagingUsers = {
  __typename?: 'PagingUsers';
  data: Array<User>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PendingAndSubmittedReferenceRequests = {
  __typename?: 'PendingAndSubmittedReferenceRequests';
  pendingReferenceRequests: Array<ReferenceRequest>;
  submittedReferenceRequests: Array<ReferenceRequest>;
};

export enum PerformanceMetricType {
  custom = 'custom',
  engagement_score = 'engagement_score',
  manager_performance = 'manager_performance',
  performance_review = 'performance_review',
  quota_attainment = 'quota_attainment',
  searchlight_qoh = 'searchlight_qoh',
  tenure = 'tenure',
}

export type Permission = {
  __typename?: 'Permission';
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  isArchived: Scalars['Boolean'];
  permissionId: Scalars['Int'];
  permissionName: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type PermissionsArray = {
  __typename?: 'PermissionsArray';
  permissions: Array<Scalars['String']>;
};

export type Position = {
  __typename?: 'Position';
  automationSetting: AutomationSetting;
  behavioralAssessmentTargetScore: Scalars['Int'];
  benchmarkPositionRelations: Array<BenchmarkPositionRelation>;
  benchmarkRequests: Array<BenchmarkRequest>;
  candidateFormComponentGroup: FormComponentGroup;
  candidateFormComponentGroupId: Scalars['Int'];
  candidateInviteFromHirer: Scalars['String'];
  candidatePositionRelations: Array<CandidatePositionRelation>;
  candidateViewFullReport: Scalars['Boolean'];
  candidates: Array<CandidateProfile>;
  company: Company;
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  externalJobId: Scalars['String'];
  externalJobSource: ExternalJobSource;
  externalJobSourceId: Scalars['Int'];
  formComponentGroup: FormComponentGroup;
  formComponentGroupId: Scalars['Int'];
  hideRespondentName: Scalars['Boolean'];
  hirerPositionRelations: Array<HirerPositionRelation>;
  isAcceptingSurveySubmissions: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isAssessment360Position: Scalars['Boolean'];
  isCandidateInitiatedPosition: Scalars['Boolean'];
  isOpen: Scalars['Boolean'];
  notifyCandidateWithUpdates: Scalars['Boolean'];
  numCandidatePositionRelations: Scalars['Int'];
  numRecommendedReferences: Scalars['Int'];
  numRequiredReferences: Scalars['Int'];
  positionId: Scalars['Int'];
  positionIdentifier: Scalars['String'];
  positionKey: Scalars['ID'];
  positionReferenceRequirements: Array<PositionReferenceRequirement>;
  positionSkillMapRelations: Array<PositionSkillMapRelation>;
  positionTemplate: PositionTemplate;
  positionTitle: Scalars['String'];
  referenceRequirementSyntax: RefRequirementSyntaxNode;
  referencerInviteFromHirer: Scalars['String'];
  referencerResponseDays: Scalars['Int'];
  reviewCycle: ReviewCycle;
  reviewCycleId: Scalars['Int'];
  sendAutomaticRemindersToSubjects: Scalars['Boolean'];
  skillMaps: Array<SkillMap>;
  subjectCanInviteRespondent: Scalars['Boolean'];
  surveysToRelationships: Array<PositionSurveyToRelationship>;
  updatedAt: Scalars['Date'];
  usesChecklistQuestion: Scalars['Boolean'];
  usesCompetencyQuestions: Scalars['Boolean'];
  usesExcitementQuestion: Scalars['Boolean'];
  usesNewHireFit: Scalars['Boolean'];
  validSurveySubmissionWindowDays: Scalars['Int'];
};

export type PositionDetailsForAccountCreationType = {
  __typename?: 'PositionDetailsForAccountCreationType';
  companyId: Scalars['Int'];
  companyName: Scalars['String'];
  isAssessment360Position: Scalars['Boolean'];
  positionId: Scalars['Int'];
  positionIdentifier: Scalars['String'];
  positionTitle: Scalars['String'];
  showPositionTitleToCandidate: Scalars['Boolean'];
};

export type PositionKeywordPagingSearchForHirerInput = {
  companyId: Scalars['Int'];
  isArchived?: Scalars['Boolean'];
  isOpen?: Scalars['Boolean'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  reviewCycleId?: Scalars['Int'];
  sortField?: Scalars['String'];
  sortOrder?: Scalars['String'];
};

export type PositionKeywordPagingSearchInput = {
  companyId?: Scalars['Int'];
  isArchived?: Scalars['Boolean'];
  isOpen?: Scalars['Boolean'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  positionId?: Scalars['Int'];
  positionIdentifier?: Scalars['String'];
  positionTitle?: Scalars['String'];
};

export type PositionPaging = {
  __typename?: 'PositionPaging';
  data: Array<Position>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PositionReferenceRequirement = {
  __typename?: 'PositionReferenceRequirement';
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  isArchived: Scalars['Boolean'];
  numRequired: Scalars['Int'];
  order: Scalars['Int'];
  position: Position;
  positionId: Scalars['Int'];
  positionReferenceRequirementId: Scalars['Int'];
  referenceRelationType: ReferenceRelationType;
  referenceRelationTypeId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type PositionSearchInput = {
  companyId?: Scalars['Int'];
  companyName?: Scalars['String'];
  isArchived?: Scalars['Boolean'];
  isOpen?: Scalars['Boolean'];
  positionId?: Scalars['Int'];
  positionIdentifier?: Scalars['String'];
  positionTitle?: Scalars['String'];
};

export type PositionSkillMapRelation = {
  __typename?: 'PositionSkillMapRelation';
  positionSkillMapRelationId: Scalars['Int'];
  skillMap: SkillMap;
  skillMapId: Scalars['Int'];
  targetSkillMapLevelIds: Array<Scalars['Int']>;
};

export type PositionStats = {
  __typename?: 'PositionStats';
  candidatePositionRelations: Array<CandidatePositionRelation>;
  endDate: Scalars['Date'];
  numCompletedCandidates: Scalars['Int'];
  numCompletedReferences: Scalars['Int'];
  numInvitedCandidates: Scalars['Int'];
  numInvitedReferences: Scalars['Int'];
  positionId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type PositionSurveyToRelationship = {
  __typename?: 'PositionSurveyToRelationship';
  formComponentGroup: FormComponentGroup;
  formComponentGroupKey: Scalars['ID'];
  position: Position;
  positionKey: Scalars['ID'];
  positionSurveyToRelationshipKey: Scalars['ID'];
  referenceRelationType: ReferenceRelationType;
  referenceRelationTypeKey: Scalars['ID'];
};

export type PositionTemplate = {
  __typename?: 'PositionTemplate';
  behavioralAssessmentTargetScore: Scalars['Int'];
  candidateFormComponentGroupId: Scalars['Int'];
  company: Company;
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  formComponentGroup: FormComponentGroup;
  formComponentGroupId: Scalars['Int'];
  inUse: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  numRequiredReferences: Scalars['Int'];
  positionTemplateId: Scalars['Int'];
  referenceRequirementSyntax: RefRequirementSyntaxNode;
  referenceRequirements: Array<PositionTemplateRequirement>;
  referencerResponseDays: Scalars['Int'];
  surveysToRelationships: Array<PositionTemplateSurveyToRelationship>;
  taskTypes: Array<TaskType | `${TaskType}`>;
  templateName: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type PositionTemplateByIdInput = {
  positionTemplateId: Scalars['Int'];
};

export type PositionTemplateRequirement = {
  __typename?: 'PositionTemplateRequirement';
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  isArchived: Scalars['Boolean'];
  numRequired: Scalars['Int'];
  positionTemplateId: Scalars['Int'];
  positionTemplateRequirementId: Scalars['Int'];
  referenceRelationType: ReferenceRelationType;
  referenceRelationTypeId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type PositionTemplateSurveyToRelationship = {
  __typename?: 'PositionTemplateSurveyToRelationship';
  formComponentGroup: FormComponentGroup;
  formComponentGroupKey: Scalars['ID'];
  positionTemplate: PositionTemplate;
  positionTemplateSurveyToRelationshipKey: Scalars['ID'];
  referenceRelationType: ReferenceRelationType;
  referenceRelationTypeKey: Scalars['ID'];
};

export type PositionTemplatesForCompanyPaging = {
  __typename?: 'PositionTemplatesForCompanyPaging';
  data: Array<PositionTemplate>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PositionTemplatesForCompanyPagingInput = {
  companyId: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type PowerSkill = {
  __typename?: 'PowerSkill';
  avgQohWithTrait: Scalars['Float'];
  avgQohWithoutTrait: Scalars['Float'];
  conditionalExpectation: Scalars['Float'];
  conditionalExpectationBin: Scalars['Int'];
  conditionalExpectationPercentChange: Scalars['Float'];
  definition: Scalars['String'];
  name: Scalars['String'];
  numberOfEmployees: Scalars['Int'];
  percentOfEmployees: Scalars['Float'];
};

export type PowerSkillInput = {
  avgQohWithTrait?: Scalars['Float'];
  avgQohWithoutTrait?: Scalars['Float'];
  conditionalExpectation?: Scalars['Float'];
  conditionalExpectationBin?: Scalars['Int'];
  conditionalExpectationPercentChange?: Scalars['Float'];
  definition: Scalars['String'];
  name: Scalars['String'];
  numberOfEmployees: Scalars['Int'];
  percentOfEmployees?: Scalars['Float'];
};

export type PowerSkillsAligment = {
  __typename?: 'PowerSkillsAligment';
  growthAreas: GrowthAreasRiskInformation;
  strengths: StrengthsFitInformation;
};

export type PredictedPerformanceExplainerType = {
  __typename?: 'PredictedPerformanceExplainerType';
  description: Scalars['String'];
  title: Scalars['String'];
};

export type PredictedPerformanceType = {
  __typename?: 'PredictedPerformanceType';
  candidatePositionRelationKey: Scalars['ID'];
  explainer: PredictedPerformanceExplainerType;
  label: Scalars['String'];
  qualitativeFactors: Scalars['String'];
  quantitativeFactors: Scalars['String'];
  subtitle: Scalars['String'];
};

export type ProspectsHubForCompany = {
  __typename?: 'ProspectsHubForCompany';
  companyId: Scalars['Int'];
  companyReferencerProspects: Array<CompanyReferencerProspect>;
  endDate: Scalars['Date'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  positionIds: Array<Scalars['Int']>;
  startDate: Scalars['Date'];
  statusTypeIds: Array<Scalars['Int']>;
  totalCount: Scalars['Int'];
};

export type ProspectsHubForCompanyLimited = {
  __typename?: 'ProspectsHubForCompanyLimited';
  companyId: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PublicReferencer = {
  __typename?: 'PublicReferencer';
  candidateReferencerRelationId: Scalars['Int'];
  candidateRole: Scalars['String'];
  companyName: Scalars['String'];
  overlapEndDate: Scalars['Date'];
  overlapStartDate: Scalars['Date'];
  publicSummary: Scalars['String'];
  referencerTitle: Scalars['String'];
  workRelationship: Scalars['String'];
};

export type QoHFiltersForCompany = {
  __typename?: 'QoHFiltersForCompany';
  applicationStatuses: FilterObject;
  candidateInvitationDates: FilterObject;
  candidateNames: FilterObject;
  companyKey: Scalars['String'];
  ethnicities: FilterObject;
  genders: FilterObject;
  groups: FilterObject;
  hiringManagers: FilterObject;
  homeCountries: FilterObject;
  mergeManagers: FilterObject;
  orgLeaders: FilterObject;
  performanceRatingByManager: FilterObject;
  positions: FilterObject;
  qohScorePercentile: FilterObject;
  recruiters: FilterObject;
  reflectionPeriods: FilterObject;
  standardJobCareerTracks: FilterObject;
  standardJobDepartments: FilterObject;
  standardJobFamilies: FilterObject;
  standardJobLevels: FilterObject;
  startDates: FilterObject;
  workCountries: FilterObject;
};

export type QohFactor = {
  __typename?: 'QohFactor';
  createdAt: Scalars['Date'];
  displayName: Scalars['String'];
  internalIdentifier: Scalars['String'];
  isArchived: Scalars['Boolean'];
  qohFactorKey: Scalars['ID'];
  qohFormulaId: Scalars['Int'];
  respondent: Scalars['String'];
  updatedAt: Scalars['Date'];
  weight: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  adminKeywordSearchFCGsPaging: FormComponentGroupPaging;
  aggregatedHiringStats: AggregatedStats;
  analyticsHubCandidates: AnalyticsHubCandidates;
  answerGroups: Array<AnswerGroup>;
  answerOptionGroups: Array<AnswerOptionGroup>;
  answerOptions: Array<AnswerOption>;
  attributes: Array<Attribute>;
  authenticateBenchmarkRequestInvitation: AuthenticatedBenchmarkRequestInvitation;
  bulkSearchReferenceRequests: Array<ReferenceRequest>;
  candidateExperiences: Array<CandidateExperience>;
  candidatePositionRelations: Array<CandidatePositionRelation>;
  candidatePositionRelationsForHirer: CandidatePositionRelationPaging;
  candidateProfiles: Array<CandidateProfile>;
  candidateReferencerRelationById: CandidateReferencerRelation;
  candidateReferencerRelations: Array<CandidateReferencerRelation>;
  candidateReferencerRelationsForMe: Array<CandidateReferencerRelation>;
  candidateReferencerRelationsForMyExperience: Array<CandidateReferencerRelation>;
  candidateReferencerRelationsForReferencer: Array<CandidateReferencerRelation>;
  candidatesAndReferenceRequests: Array<CandidateProfileAndReferenceRequests>;
  companies: Array<Company>;
  experienceDetails: Array<ExperienceDetail>;
  experienceSections: Array<ExperienceSection>;
  experienceSectionsForCandidate: Array<ExperienceSection>;
  experienceSectionsForMe: Array<ExperienceSection>;
  experienceTypes: Array<ExperienceType>;
  exportAnalyticsHubCandidatesCsv: AnalyticsHubCandidatesLimited;
  exportProspectsHubForCompanyCsv: ProspectsHubForCompanyLimited;
  externalJobSourcesForCompany: Array<ExternalJobSource>;
  fetchAccessPermissions: AccessPermissionsPaging;
  fetchActivitiesForMe: Array<ActivityForUser>;
  fetchAllStandardJobCareerTracks: Array<StandardJobCareerTrack>;
  fetchAllStandardJobDepartments: Array<StandardDepartment>;
  fetchAllStandardJobFamilies: Array<StandardJobFamily>;
  fetchAllStandardJobLevels: Array<StandardJobLevel>;
  fetchAssessmentsForMe: Array<AssessmentForCandidate>;
  fetchAutomatedInviteHistoryPaging: AutomatedInviteHistoryPaging;
  fetchBehaviorProfilesForCompany: CompanyBehaviorProfilesByStatus;
  fetchBehavioralAssessmentMedianScoresForReflection: BehavioralAssessmentMedianScoresPaging;
  fetchBehavioralAssessmentReflectionsForCompany: Array<ReviewCycle>;
  fetchBenchmarkById: Benchmark;
  fetchBenchmarkCollaboratorsPaging: BenchmarkCollaboratorsPaging;
  fetchBenchmarkRequestForHirer: BenchmarkRequest;
  fetchBenchmarkRequestsCreatedByMe: BenchmarkRequestsPaging;
  fetchBenchmarkRequestsForMe: BenchmarkRequestsPaging;
  fetchBenchmarksForMe: BenchmarksPaging;
  fetchCPRDetailsForAccountCreation: CprForAccountCreationType;
  fetchCPRWithIdForHirer: CandidatePositionRelation;
  fetchCPRWithIdsForHirer: CandidatePositionRelation;
  fetchCPRsForPosition: CandidatePositionRelationPaging;
  fetchCandidateAndReferencerEngagementStats: CandidateAndReferencerEngagementStats;
  fetchCandidateAssessmentByCPRForHirer: CandidateAssessment;
  fetchCandidateAssessmentForMeById: CandidateAssessment;
  fetchCandidateAssessmentsForMe: Array<CandidateAssessment>;
  fetchCandidateFormForPosition: FormComponentGroup;
  fetchCandidatePositionRelationByCprId: CandidatePositionRelation;
  fetchCandidatePositionRelationsForMe: Array<CandidatePositionRelation>;
  fetchCandidateProfileForMe: CandidateProfile;
  fetchCandidatePublicProfile: CandidatePublicProfile;
  fetchCandidatePublicProfileCounters: CandidatePublicProfileCounters;
  fetchCandidateShareInviteWithInvitationCode: CandidateShareInvite;
  fetchCandidateShareInvitesForMe: Array<CandidateShareInvite>;
  fetchCandidateShareRequestsForMe: Array<CandidateShareRequest>;
  fetchCandidatesPositionRelationsForBenchmark: CandidatePositionRelationPaging;
  fetchCollaborativeQuestionAnswersForBenchmark: CollaborativeQuestionAnswersForBenchmark;
  fetchCommTypeForCompany: CommTypeForCompany;
  fetchCommTypesForCompany: Array<CommTypeForCompany>;
  fetchCommonStrengths: CompanyCommonStrengths;
  fetchCompanyApiKey: CompanyApiKey;
  fetchCompanyById: Company;
  fetchCompanyForms: Array<FormComponentGroup>;
  fetchCompanyFormsForHirer: Array<CompanyForm>;
  fetchCompanyReferencerProspects: ProspectsHubForCompany;
  fetchCompanyRolesForCompany: CompanyRolesPaging;
  fetchCustomQuestionAnswersForBenchmark: Array<BenchmarkQuestionToAnswers>;
  fetchDefaultSkillMapsByJobTaxonomy: Array<SkillMap>;
  fetchEcosystemCompanies: Array<EcosystemCompany>;
  fetchEmployeeCPRsPaging: EmployeeCprPaging;
  fetchFCGTypes: Array<FcgType>;
  fetchFcgsByQuestion: Array<FormComponentGroup>;
  fetchFormComponentGroupsForAdmin: Array<FormComponentGroup>;
  fetchFormForHirer: FormComponentGroup;
  fetchFormForPosition: FormComponentGroup;
  fetchHirerEngagementStats: HirerEngagementStats;
  fetchHirerProfileForCompany: HirerProfile;
  fetchHirerProfileForMe: HirerProfile;
  fetchHirerTeamById: HirerTeam;
  fetchHirerTeamsPaging: HirerTeamPaging;
  fetchHrisGroupsForCompanyPaging: HrisGroupsPaging;
  fetchHrisTeamsForCompanyPaging: HrisTeamsPaging;
  fetchJobLocations: Array<JobLocation>;
  fetchJobOpeningTaxonomyByBenchmarkKey: Array<JobOpeningTaxonomy>;
  fetchJobPreferencesForCandidate: CandidateJobPreferences;
  fetchJobTypes: Array<JobType>;
  fetchNetworkUploadReminderForReferencer: ReferencerLinkedinUpload;
  fetchNumberCandidatesWithCompletedReferenceChecks: Scalars['Int'];
  fetchOtherReviewCPRsForCPR: Array<CandidatePositionRelation>;
  fetchPositionDetailsForAccountCreation: PositionDetailsForAccountCreationType;
  fetchPositionForHirer: Position;
  fetchPositionsForReviewCyclePaging: PositionPaging;
  fetchPublicSkillMaps: PagingSkillMaps;
  fetchQualityOfHireFilters: QoHFiltersForCompany;
  fetchQuestionCommentsForBenchmarkRequest: Array<BenchmarkRequestCommentForQuestion>;
  fetchQuestionTypesForHirer: Array<Maybe<QuestionType>>;
  fetchRecommendationsForCandidate: Array<Recommendation>;
  fetchRecommendationsForReferencer: Array<Recommendation>;
  fetchReferenceAssessmentForMeByKey: ReferenceAssessment;
  fetchReferenceCPRsForMe: Array<CandidatePositionRelation>;
  fetchReferenceRequestForReferencer: ReferenceRequest;
  fetchReferenceRequestsForCPRs: Array<CandidateProfileAndReferenceRequests>;
  fetchReferenceRequestsForMe: PagingReferenceRequests;
  fetchReferenceSectionForRequest: ReferenceSectionForRequest;
  fetchReviewCycleById: ReviewCycle;
  fetchReviewCycleByKey: ReviewCycle;
  fetchReviewCycleCPRsForMe: Array<CandidatePositionRelation>;
  fetchReviewCycleRequestsForMe: PagingReferenceRequests;
  fetchReviewCyclesForCompany: Array<ReviewCycle>;
  fetchReviewCyclesForMe: Array<ReviewCycle>;
  fetchReviewQuestionsForReporting: Array<QuestionComponent>;
  fetchRevieweeById: Reviewee;
  fetchRevieweesForReviewCycle: Array<Reviewee>;
  fetchRevieweesForReviewCyclePaging: RevieweesPaging;
  fetchRevieweesPerformanceBucketForReflection: RevieweesPerformanceBucketForReflection;
  fetchSearchlightScoreStats: CompanySearchlightScoreStats;
  fetchSelfAssessmentQuestionsForReporting: Array<QuestionComponent>;
  fetchShareableCRRsForMe: Array<CandidateReferencerRelation>;
  fetchSharedBenchmarksForMe: BenchmarksForCompanyPaging;
  fetchSkillMapAnswersForCandidatePositionRelation: Array<CprSkillMapAnswer>;
  fetchSkillMapById: SkillMap;
  fetchSkillMapCompetenciesForCompany: PagingSkillMapCompetencies;
  fetchSkillMapsForCompany: PagingSkillMaps;
  fetchSkillMapsForCprForHirer: Array<SkillMap>;
  fetchSlidingScaleQuestions: Array<SlidingScaleQuestion>;
  fetchStandaloneBenchmarksForCompany: BenchmarksPaging;
  fetchStrengthsAndGapsForCpr: StrengthsAndGapsForCpr;
  fetchTalentModelQuestionData: TalentModelQuestionData;
  fetchTeamSizes: Array<TeamSize>;
  fetchUnansweredBehavioralQuestionData: Array<UnansweredBehavioralQuestionData>;
  fetchUnattachedBenchmarkRequestComments: Array<BenchmarkRequestComment>;
  fetchUpcomingInvitationsPaging: UpcomingInvitationsPaging;
  findSectionComponentByComponentIdentifier: SectionComponent;
  formComponentGroups: Array<FormComponentGroup>;
  generateAssessmentReportForHirer: FileResponse;
  generateFullReportForCandidate: FileResponse;
  generateOnboardingReportForHirer: FileResponse;
  generateOnePagerForCandidate: FileResponse;
  generateQuestionSummaryReportForHirer: FileResponse;
  generateReferenceCentricReportForHirer: FileResponse;
  generateShareReportForAdmin: FileResponse;
  getAllCultureDefinitions: Array<CultureTrait>;
  getAllGrowthAreas: Array<PowerSkill>;
  getAllStrengths: Array<PowerSkill>;
  getApplicationSubscoreCriterias: ApplicationSubscoreCriteriaLists;
  getAtsApplicationByKey: AtsApplicationDetails;
  getAtsJobByKey: AtsJobInformation;
  getAtsJobCandidateList: Array<AtsJobCandidateListItem>;
  getAtsJobCandidates: AtsJobCandidatesPaging;
  getAtsJobPostByKey: AtsJobPostInformation;
  getAtsJobPostCandidateList: Array<AtsApplicationDetails>;
  getAtsJobPostCandidates: AtsJobPostCandidatesPaging;
  getAtsJobPosts: AtsJobPostInformationPaging;
  getAtsJobs: AtsJobInformationPaging;
  getCompanyPerformanceMetricByKey: CompanyPerformanceMetric;
  getCompanyPerformanceMetricsByCompanyKey: Array<CompanyPerformanceMetric>;
  getConceptExplainersByIdentifier: Array<ConceptExplainer>;
  getDefaultTalentModelsV2forJobTaxonomyInput: Array<TalentModel>;
  getDefaultTalentModelsforJobTaxonomyInput: Array<TalentModel>;
  getEmployeeCustomMetricsByPerformanceMetricKey: EmployeeCustomMetric;
  getGoldStandardAttributes: Array<Attribute>;
  getInterviewQuestionByKey: InterviewQuestion;
  getInterviewQuestionsByCriteriaName: Array<InterviewQuestion>;
  getJobInterview: JobInterview;
  getJobStagesForJobApplicationByJobId: Array<JobStage>;
  getMatchScoreV2ForCandidate: Array<CandidateMatchScoreV2>;
  getMatchScoreV3ForCandidate: CandidateMatchScoreV3;
  getOpportunity: CandidatePositionRelation;
  getOpportunityForCandidate: CandidatePositionRelation;
  getRatingsForHirerByCandidatePositionRelation: Array<QuestionRating>;
  getReferencesForCpr: Array<ReferenceRequestForCpr>;
  getReflectionPeriodsForCompany: ReflectionPeriodsForCompany;
  getRejectionEmailTemplatesForCompany: Array<GreenhouseEmailTemplate>;
  getRejectionReasonsForCompany: Array<RejectionReason>;
  getStatsForPeerAssessment: Assessment360PositionDataOutput;
  getTalentModel: TalentModel;
  getTalentModelsForCompany: TalentModelPaging;
  globalStats: Array<GlobalStats>;
  googleLoginUrl: Scalars['String'];
  googleLoginUrlForUserType: Scalars['String'];
  greenhousePartners: Array<GreenhousePartner>;
  groupSectionRelations: Array<GroupSectionRelation>;
  helpTexts: Array<HelpText>;
  hirerCompanyRelations: Array<HirerCompanyRelation>;
  hirerCompanyRelationsForCompany: HirerCompanyRelationPaging;
  hirerPositionRelations: Array<HirerPositionRelation>;
  hirerPositionsForCompany: Array<Position>;
  hirerProfiles: Array<HirerProfile>;
  hirerReviewCyclesForCompanyPaging: ReviewCyclePaging;
  hirerReviewGroupsForCompanyPaging: PositionPaging;
  interestEmails: Array<InterestEmail>;
  interestEmailsFeed: Array<InterestEmail>;
  keywordSearchCandidatePositionRelations: CandidatePositionRelationPaging;
  keywordSearchCompanyFormsPaging: FormComponentGroupPaging;
  keywordSearchQuestionComponentsForHirer: QuestionComponentsPaging;
  leverPartners: Array<LeverPartner>;
  notificationTypes: Array<NotificationType>;
  permissions: Array<Permission>;
  positionStats: Array<PositionStats>;
  positionTemplateById: PositionTemplate;
  positionTemplatesForCompanyPaging: PositionTemplatesForCompanyPaging;
  positions: Array<Position>;
  queryAtsPartnersForCompany: CompanyAtsPartners;
  queryCandidateReferencerRelationsForAdmin: CandidateReferencerRelationPaging;
  queryCandidateReferencerRelationsForAdminHirer: CandidateReferencerRelationPaging;
  queryGreenhousePartnerForCompany: GreenhousePartner;
  queryUsersPaging: PagingUsers;
  questionComponents: Array<QuestionComponent>;
  questionComponentsPaging: QuestionComponentsPaging;
  questionTypes: Array<QuestionType>;
  referenceRelationTypes: Array<ReferenceRelationType>;
  referenceRequestById: ReferenceRequest;
  referenceRequestFeed: PagingReferenceRequests;
  referenceRequests: Array<ReferenceRequest>;
  referencerProfiles: Array<ReferencerProfile>;
  resumes: Array<Resume>;
  returnHirerUserFromToken: User;
  returnInterviewPlanForIntake: IntakeInterviewPlan;
  returnUserFromToken: User;
  searchKeywordPagingPositions: PositionPaging;
  searchKeywordPagingPositionsForHirer: PositionPaging;
  searchSubsectionComponentByComponentIdentifier: SubsectionComponent;
  sectionComponentRelations: Array<SectionComponentRelation>;
  sectionComponents: Array<SectionComponent>;
  sectionTypes: Array<SectionType>;
  status: Scalars['String'];
  statusTypes: Array<StatusType>;
  subsectionComponents: Array<SubsectionComponent>;
  subsectionQuestionRelations: Array<SubsectionQuestionRelation>;
  users: Array<User>;
  verifyEmailToCompleteSignup: Scalars['Boolean'];
};

export type QueryAdminKeywordSearchFcGsPagingArgs = {
  input: AdminKeywordSearchFcGsPagingInput;
};

export type QueryAggregatedHiringStatsArgs = {
  input: AggregatedStatsSearchInput;
};

export type QueryAnalyticsHubCandidatesArgs = {
  input: AnalyticsHubCandidatesSearchInput;
};

export type QueryAnswerGroupsArgs = {
  input: SearchAnswerGroupsInput;
};

export type QueryAnswerOptionGroupsArgs = {
  input: SearchAnswerOptionGroupInput;
};

export type QueryAttributesArgs = {
  input: SearchAttributesInput;
};

export type QueryAuthenticateBenchmarkRequestInvitationArgs = {
  input: AuthenticateBenchmarkRequestInvitationInput;
};

export type QueryBulkSearchReferenceRequestsArgs = {
  input: SearchBulkReferenceRequestInput;
};

export type QueryCandidateExperiencesArgs = {
  input: QueryCandidateExperienceInput;
};

export type QueryCandidatePositionRelationsArgs = {
  input: SearchCandidatePositionRelationsInput;
};

export type QueryCandidatePositionRelationsForHirerArgs = {
  input: SearchCandidatePositionRelationsInput;
};

export type QueryCandidateProfilesArgs = {
  input: CandidateProfileSearchInput;
};

export type QueryCandidateReferencerRelationByIdArgs = {
  input: QueryCandidateReferencerRelationByIdInput;
};

export type QueryCandidateReferencerRelationsArgs = {
  input: QueryCandidateReferencerRelationsInput;
};

export type QueryCandidateReferencerRelationsForMyExperienceArgs = {
  input: CandidateReferencerRelationsForMyExperienceInput;
};

export type QueryCandidatesAndReferenceRequestsArgs = {
  input: SearchCandidateProfilesInput;
};

export type QueryCompaniesArgs = {
  input: CompanySearchInput;
};

export type QueryExperienceDetailsArgs = {
  input: SearchExperienceDetailsInput;
};

export type QueryExperienceSectionsArgs = {
  input: QueryExperienceSectionsInput;
};

export type QueryExperienceSectionsForCandidateArgs = {
  input: QueryCandidateExperienceSectionInput;
};

export type QueryExportAnalyticsHubCandidatesCsvArgs = {
  input: AnalyticsHubCandidatesSearchForCsvInput;
};

export type QueryExportProspectsHubForCompanyCsvArgs = {
  input: FetchCompanyReferencerProspectsLimitedInput;
};

export type QueryExternalJobSourcesForCompanyArgs = {
  input: ExternalJobSourcesForCompanyInput;
};

export type QueryFetchAccessPermissionsArgs = {
  input: FetchAccessPermissionsPagingInput;
};

export type QueryFetchActivitiesForMeArgs = {
  input: FetchActivitiesForMeInput;
};

export type QueryFetchAssessmentsForMeArgs = {
  input: FetchAssessmentsForMeInput;
};

export type QueryFetchAutomatedInviteHistoryPagingArgs = {
  input: FetchAutomatedInviteHistoryPagingInput;
};

export type QueryFetchBehaviorProfilesForCompanyArgs = {
  input: BehaviorProfilesForCompanyInput;
};

export type QueryFetchBehavioralAssessmentMedianScoresForReflectionArgs = {
  input: FetchBehavioralAssessmentMedianScoresForReflectionInput;
};

export type QueryFetchBehavioralAssessmentReflectionsForCompanyArgs = {
  input: FetchBehavioralAssessmentReflectionsForCompanyInput;
};

export type QueryFetchBenchmarkByIdArgs = {
  input: FetchBenchmarkByIdInput;
};

export type QueryFetchBenchmarkCollaboratorsPagingArgs = {
  input: FetchBenchmarkCollaboratorsPagingInput;
};

export type QueryFetchBenchmarkRequestForHirerArgs = {
  input: FetchBenchmarkRequestForHirerInput;
};

export type QueryFetchBenchmarkRequestsCreatedByMeArgs = {
  input: FetchBenchmarkRequestsCreatedByMeInput;
};

export type QueryFetchBenchmarkRequestsForMeArgs = {
  input: FetchBenchmarkRequestsForMeInput;
};

export type QueryFetchBenchmarksForMeArgs = {
  input: FetchBenchmarksForMeInput;
};

export type QueryFetchCprDetailsForAccountCreationArgs = {
  input: ValidateCandidateInvitationInput;
};

export type QueryFetchCprWithIdForHirerArgs = {
  input: FetchCprWithIdForHirerInput;
};

export type QueryFetchCprWithIdsForHirerArgs = {
  input: FetchCprWithIdsForHirerInput;
};

export type QueryFetchCPRsForPositionArgs = {
  input: KeywordSearchCPRsForPositionInput;
};

export type QueryFetchCandidateAndReferencerEngagementStatsArgs = {
  input: CandidateAndReferencerEngagementInput;
};

export type QueryFetchCandidateAssessmentByCprForHirerArgs = {
  input: FetchCandidateAssessmentByCprForHirerInput;
};

export type QueryFetchCandidateAssessmentForMeByIdArgs = {
  input: FetchCandidateAssessmentForMeByIdInput;
};

export type QueryFetchCandidateFormForPositionArgs = {
  input: FetchCandidateFormForPositionInput;
};

export type QueryFetchCandidatePositionRelationByCprIdArgs = {
  input: FetchCandidatePositionRelationByCprIdInput;
};

export type QueryFetchCandidatePositionRelationsForMeArgs = {
  input: FetchCandidatePositionRelationsForMeInput;
};

export type QueryFetchCandidatePublicProfileArgs = {
  input: FetchCandidatePublicProfileInput;
};

export type QueryFetchCandidatePublicProfileCountersArgs = {
  input: FetchCandidatePublicProfileInput;
};

export type QueryFetchCandidateShareInviteWithInvitationCodeArgs = {
  input: FetchCandidateShareInviteWithInvitationCodeInput;
};

export type QueryFetchCandidatesPositionRelationsForBenchmarkArgs = {
  input: FetchCandidatePositionRelationsForBenchmarkInput;
};

export type QueryFetchCollaborativeQuestionAnswersForBenchmarkArgs = {
  input: FetchCustomQuestionAnswersForBenchmarkInput;
};

export type QueryFetchCommTypeForCompanyArgs = {
  input: FetchCommTypeForCompanyInput;
};

export type QueryFetchCommTypesForCompanyArgs = {
  input: FetchCommTypesForCompanyInput;
};

export type QueryFetchCommonStrengthsArgs = {
  input: CompanyCommonStrengthsInput;
};

export type QueryFetchCompanyApiKeyArgs = {
  input: FetchCompanyByIdInput;
};

export type QueryFetchCompanyByIdArgs = {
  input: FetchCompanyByIdInput;
};

export type QueryFetchCompanyFormsArgs = {
  input: FetchCompanyFormsInput;
};

export type QueryFetchCompanyFormsForHirerArgs = {
  input: FetchCompanyFormsInput;
};

export type QueryFetchCompanyReferencerProspectsArgs = {
  input: FetchCompanyReferencerProspectsInput;
};

export type QueryFetchCompanyRolesForCompanyArgs = {
  input: FetchCompanyRolesForCompanyPagingInput;
};

export type QueryFetchCustomQuestionAnswersForBenchmarkArgs = {
  input: FetchCustomQuestionAnswersForBenchmarkInput;
};

export type QueryFetchDefaultSkillMapsByJobTaxonomyArgs = {
  input: FetchDefaultSkillMapsByJobTaxonomyInput;
};

export type QueryFetchEcosystemCompaniesArgs = {
  input: EcosystemCompanySearchInput;
};

export type QueryFetchEmployeeCPRsPagingArgs = {
  input: EmployeeCprPagingInput;
};

export type QueryFetchFcgsByQuestionArgs = {
  input: FetchFcgsByQuestionInput;
};

export type QueryFetchFormComponentGroupsForAdminArgs = {
  input: SearchFormComponentGroupInput;
};

export type QueryFetchFormForHirerArgs = {
  input: FetchFormForHirerInput;
};

export type QueryFetchFormForPositionArgs = {
  input: FetchFormForPositionInput;
};

export type QueryFetchHirerEngagementStatsArgs = {
  input: HirerEngagementInput;
};

export type QueryFetchHirerProfileForCompanyArgs = {
  input: FetchHirerProfileForCompanyInput;
};

export type QueryFetchHirerTeamByIdArgs = {
  input: HirerTeamIdInput;
};

export type QueryFetchHirerTeamsPagingArgs = {
  input: HirerTeamPagingInput;
};

export type QueryFetchHrisGroupsForCompanyPagingArgs = {
  input: FetchHrisGroupsForCompanyPagingInput;
};

export type QueryFetchHrisTeamsForCompanyPagingArgs = {
  input: FetchHrisTeamsForCompanyPagingInput;
};

export type QueryFetchNumberCandidatesWithCompletedReferenceChecksArgs = {
  input: FetchNumberCandidatesWithCompletedReferenceChecksInput;
};

export type QueryFetchOtherReviewCPRsForCprArgs = {
  input: FetchOtherReviewCPRsForCprInput;
};

export type QueryFetchPositionDetailsForAccountCreationArgs = {
  input: FetchPositionDetailsForAccountCreationInput;
};

export type QueryFetchPositionForHirerArgs = {
  input: FetchPositionForHirerInput;
};

export type QueryFetchPositionsForReviewCyclePagingArgs = {
  input: FetchReviewCyclesForCompanyPagingInput;
};

export type QueryFetchPublicSkillMapsArgs = {
  input: FetchPublicSkillMapsInput;
};

export type QueryFetchQualityOfHireFiltersArgs = {
  input: FetchQualityOfHireFiltersInput;
};

export type QueryFetchQuestionCommentsForBenchmarkRequestArgs = {
  input: FetchQuestionCommentsForBenchmarkRequestInput;
};

export type QueryFetchQuestionTypesForHirerArgs = {
  input: FetchQuestionTypesForHirerInput;
};

export type QueryFetchReferenceAssessmentForMeByKeyArgs = {
  input: FetchReferenceAssessmentForMeByKeyInput;
};

export type QueryFetchReferenceRequestForReferencerArgs = {
  input: FetchReferenceRequestForReferencerInput;
};

export type QueryFetchReferenceRequestsForCPRsArgs = {
  input: FetchReferenceRequestsForCPRsInput;
};

export type QueryFetchReferenceRequestsForMeArgs = {
  input: FetchReferenceRequestsForMePagingInput;
};

export type QueryFetchReferenceSectionForRequestArgs = {
  input: FetchReferenceSectionForRequestInput;
};

export type QueryFetchReviewCycleByIdArgs = {
  input: FetchReviewCycleByIdInput;
};

export type QueryFetchReviewCycleByKeyArgs = {
  input: FetchReviewCycleByKeyInput;
};

export type QueryFetchReviewCycleRequestsForMeArgs = {
  input: FetchReviewRequestsForMePagingInput;
};

export type QueryFetchReviewCyclesForCompanyArgs = {
  input: FetchReviewCyclesForCompanyInput;
};

export type QueryFetchReviewQuestionsForReportingArgs = {
  input: FetchReviewQuestionsForReportingInput;
};

export type QueryFetchRevieweeByIdArgs = {
  input: FetchRevieweeByIdInput;
};

export type QueryFetchRevieweesForReviewCycleArgs = {
  input: FetchRevieweesForReviewCycleInput;
};

export type QueryFetchRevieweesForReviewCyclePagingArgs = {
  input: FetchReviewCyclesForCompanyPagingInput;
};

export type QueryFetchRevieweesPerformanceBucketForReflectionArgs = {
  input: FetchRevieweesInPerformanceBucketsForReflectionInput;
};

export type QueryFetchSearchlightScoreStatsArgs = {
  input: CompanySearchlightScoreStatsInput;
};

export type QueryFetchSelfAssessmentQuestionsForReportingArgs = {
  input: FetchSelfAssessmentQuestionsForReportingInput;
};

export type QueryFetchSharedBenchmarksForMeArgs = {
  input: FetchSharedBenchmarksForMeInput;
};

export type QueryFetchSkillMapAnswersForCandidatePositionRelationArgs = {
  input: FetchSkillMapAnswersForCandidatePositionRelationInput;
};

export type QueryFetchSkillMapByIdArgs = {
  input: FetchSkillMapByIdInput;
};

export type QueryFetchSkillMapCompetenciesForCompanyArgs = {
  input: FetchSkillMapCompetenciesForCompanyInput;
};

export type QueryFetchSkillMapsForCompanyArgs = {
  input: FetchSkillMapsForCompanyInput;
};

export type QueryFetchSkillMapsForCprForHirerArgs = {
  input: FetchSkillMapsForCprForHirerInput;
};

export type QueryFetchStandaloneBenchmarksForCompanyArgs = {
  input: FetchStandaloneBenchmarksForCompanyInput;
};

export type QueryFetchStrengthsAndGapsForCprArgs = {
  input: FetchStrengthsAndGapsForCprInput;
};

export type QueryFetchTalentModelQuestionDataArgs = {
  input: FetchTalentModelQuestionDataInput;
};

export type QueryFetchUnansweredBehavioralQuestionDataArgs = {
  input: FetchUnansweredBehavioralQuestionDataInput;
};

export type QueryFetchUnattachedBenchmarkRequestCommentsArgs = {
  input: FetchUnattachedBenchmarkRequestCommentsInput;
};

export type QueryFetchUpcomingInvitationsPagingArgs = {
  input: FetchUpcomingInvitationsPagingInput;
};

export type QueryFindSectionComponentByComponentIdentifierArgs = {
  input: FetchSearchSectionComponentByComponentIdentifierInput;
};

export type QueryFormComponentGroupsArgs = {
  input: SearchFormComponentGroupInput;
};

export type QueryGenerateAssessmentReportForHirerArgs = {
  input: OpportunityPdfReportInput;
};

export type QueryGenerateOnboardingReportForHirerArgs = {
  input: OpportunityPdfReportInput;
};

export type QueryGenerateOnePagerForCandidateArgs = {
  input: CandidatePdfReportInput;
};

export type QueryGenerateQuestionSummaryReportForHirerArgs = {
  input: OpportunityPdfReportInput;
};

export type QueryGenerateReferenceCentricReportForHirerArgs = {
  input: OpportunityPdfReportInput;
};

export type QueryGenerateShareReportForAdminArgs = {
  input: OpportunityPdfReportInput;
};

export type QueryGetAllGrowthAreasArgs = {
  input: GetAllPowerSkillsInput;
};

export type QueryGetAllStrengthsArgs = {
  input: GetAllPowerSkillsInput;
};

export type QueryGetApplicationSubscoreCriteriasArgs = {
  input: GetApplicationSubscoreCriteriasInput;
};

export type QueryGetAtsApplicationByKeyArgs = {
  input: GetAtsApplicationByKey;
};

export type QueryGetAtsJobByKeyArgs = {
  input: GetAtsJobByKeyInput;
};

export type QueryGetAtsJobCandidateListArgs = {
  input: GetAtsJobCandidateListInput;
};

export type QueryGetAtsJobCandidatesArgs = {
  input: GetAtsJobCandidatesInput;
};

export type QueryGetAtsJobPostByKeyArgs = {
  input: GetAtsJobPostByKeyInput;
};

export type QueryGetAtsJobPostCandidateListArgs = {
  input: GetAtsJobPostCandidateListInput;
};

export type QueryGetAtsJobPostCandidatesArgs = {
  input: GetAtsJobPostCandidatesInput;
};

export type QueryGetAtsJobPostsArgs = {
  input: GetAtsJobPostsInput;
};

export type QueryGetAtsJobsArgs = {
  input: GetAtsJobsInput;
};

export type QueryGetCompanyPerformanceMetricByKeyArgs = {
  input: GetCompanyPerformanceMetricByKeyInput;
};

export type QueryGetCompanyPerformanceMetricsByCompanyKeyArgs = {
  input: GetCompanyPerformanceMetricsByCompanyKeyInput;
};

export type QueryGetConceptExplainersByIdentifierArgs = {
  input: GetConceptExplainersByIdentifierInput;
};

export type QueryGetDefaultTalentModelsV2forJobTaxonomyInputArgs = {
  input: GetDefaultTalentModelsV2forJobTaxonomyInput;
};

export type QueryGetDefaultTalentModelsforJobTaxonomyInputArgs = {
  input: GetDefaultTalentModelsforJobTaxonomyInput;
};

export type QueryGetEmployeeCustomMetricsByPerformanceMetricKeyArgs = {
  input: GetEmployeeCustomMetricsByPerformanceMetricKeyInput;
};

export type QueryGetGoldStandardAttributesArgs = {
  input: SearchGoldStandardAttributesInput;
};

export type QueryGetInterviewQuestionByKeyArgs = {
  input: GetInterviewQuestionByKeyInput;
};

export type QueryGetInterviewQuestionsByCriteriaNameArgs = {
  input: GetInterviewQuestionsByCriteriaNameInput;
};

export type QueryGetJobInterviewArgs = {
  input: GetJobInterviewInput;
};

export type QueryGetJobStagesForJobApplicationByJobIdArgs = {
  input: GetJobStagesForJobApplicationByJobId;
};

export type QueryGetMatchScoreV2ForCandidateArgs = {
  input: GetMatchScoreV2ForCandidateInput;
};

export type QueryGetMatchScoreV3ForCandidateArgs = {
  input: GetMatchScoreV3ForCandidateInput;
};

export type QueryGetOpportunityArgs = {
  input: OpportunityIdInput;
};

export type QueryGetRatingsForHirerByCandidatePositionRelationArgs = {
  input: FetchQuestionRatingsByCandidatePositionRelationInput;
};

export type QueryGetReferencesForCprArgs = {
  input: GetReferencesForCprInput;
};

export type QueryGetReflectionPeriodsForCompanyArgs = {
  input: GetReflectionPeriodsForCompanyInput;
};

export type QueryGetRejectionEmailTemplatesForCompanyArgs = {
  input: GetEmailTemplatesForCompanyInput;
};

export type QueryGetRejectionReasonsForCompanyArgs = {
  input: GetRejectionReasonsForCompanyInput;
};

export type QueryGetStatsForPeerAssessmentArgs = {
  input: Assessment360PositionDataInput;
};

export type QueryGetTalentModelArgs = {
  input: TalentModelIdInput;
};

export type QueryGetTalentModelsForCompanyArgs = {
  input: GetTalentModelsForCompanyInput;
};

export type QueryGlobalStatsArgs = {
  input: QueryGlobalStatsInput;
};

export type QueryGoogleLoginUrlForUserTypeArgs = {
  input: GoogleLoginUrlInput;
};

export type QueryGreenhousePartnersArgs = {
  input: GreenhousePartnerSearchInput;
};

export type QueryGroupSectionRelationsArgs = {
  input: SearchGroupSectionRelationsInput;
};

export type QueryHelpTextsArgs = {
  input: SearchHelpTextInput;
};

export type QueryHirerCompanyRelationsArgs = {
  input: SearchHirerCompanyRelationInput;
};

export type QueryHirerCompanyRelationsForCompanyArgs = {
  input: QueryHirerCompanyRelationPagingForCompanyInput;
};

export type QueryHirerPositionRelationsArgs = {
  input: SearchHirerPositionRelationInput;
};

export type QueryHirerPositionsForCompanyArgs = {
  input: HirerPositionsForCompanyInput;
};

export type QueryHirerProfilesArgs = {
  input: HirerProfileSearchInput;
};

export type QueryHirerReviewCyclesForCompanyPagingArgs = {
  input: HirerReviewCyclesForCompanyInput;
};

export type QueryHirerReviewGroupsForCompanyPagingArgs = {
  input: HirerReviewGroupsForCompanyInput;
};

export type QueryInterestEmailsFeedArgs = {
  input: InterestEmailsFeedInput;
};

export type QueryKeywordSearchCandidatePositionRelationsArgs = {
  input: KeywordSearchCandidatePositionRelationsInput;
};

export type QueryKeywordSearchCompanyFormsPagingArgs = {
  input: KeywordSearchCompanyFormsPagingInput;
};

export type QueryKeywordSearchQuestionComponentsForHirerArgs = {
  input: KeywordSearchQuestionComponentsForHirerInput;
};

export type QueryLeverPartnersArgs = {
  input: LeverPartnerSearchInput;
};

export type QueryPositionStatsArgs = {
  input: QueryPositionStatsInput;
};

export type QueryPositionTemplateByIdArgs = {
  input: PositionTemplateByIdInput;
};

export type QueryPositionTemplatesForCompanyPagingArgs = {
  input: PositionTemplatesForCompanyPagingInput;
};

export type QueryPositionsArgs = {
  input: PositionSearchInput;
};

export type QueryQueryAtsPartnersForCompanyArgs = {
  input: QueryAtsPartnersForCompanyInput;
};

export type QueryQueryCandidateReferencerRelationsForAdminArgs = {
  input: QueryCandidateReferencerRelationsForAdminInput;
};

export type QueryQueryCandidateReferencerRelationsForAdminHirerArgs = {
  input: QueryCandidateReferencerRelationsForHirerInput;
};

export type QueryQueryGreenhousePartnerForCompanyArgs = {
  input: GreenhousePartnerSearchInput;
};

export type QueryQueryUsersPagingArgs = {
  input: QueryUsersPagingInput;
};

export type QueryQuestionComponentsArgs = {
  input: SearchQuestionComponentInput;
};

export type QueryQuestionComponentsPagingArgs = {
  input: QuestionComponentsPagingInput;
};

export type QueryReferenceRelationTypesArgs = {
  input: SearchReferenceRelationTypeInput;
};

export type QueryReferenceRequestByIdArgs = {
  input: SearchReferenceRequestByIdInput;
};

export type QueryReferenceRequestFeedArgs = {
  input: ReferenceRequestFeedInput;
};

export type QueryReferenceRequestsArgs = {
  input: SearchReferenceRequestInput;
};

export type QueryResumesArgs = {
  input: SearchResumeInput;
};

export type QueryReturnInterviewPlanForIntakeArgs = {
  input: ReturnInterviewPlanForIntakeInput;
};

export type QuerySearchKeywordPagingPositionsArgs = {
  input: PositionKeywordPagingSearchInput;
};

export type QuerySearchKeywordPagingPositionsForHirerArgs = {
  input: PositionKeywordPagingSearchForHirerInput;
};

export type QuerySearchSubsectionComponentByComponentIdentifierArgs = {
  input: SearchSubsectionComponentByComponentIdentifierInput;
};

export type QuerySectionComponentRelationsArgs = {
  input: SearchSectionComponentRelationsInput;
};

export type QuerySectionComponentsArgs = {
  input: SearchSectionComponentsInput;
};

export type QuerySubsectionComponentsArgs = {
  input: SearchSubsectionComponentsInput;
};

export type QuerySubsectionQuestionRelationsArgs = {
  input: SearchSubsectionQuestionRelationsInput;
};

export type QueryUsersArgs = {
  input: UserSearchInput;
};

export type QueryVerifyEmailToCompleteSignupArgs = {
  input: UserEmailInput;
};

export type QueryAtsPartnersForCompanyInput = {
  companyKey: Scalars['ID'];
};

export type QueryCandidateExperienceInput = {
  candidateExperienceId?: Scalars['Int'];
  experienceSectionId?: Scalars['Int'];
};

export type QueryCandidateExperienceSectionInput = {
  candidateProfileId: Scalars['Int'];
};

export type QueryCandidateReferencerRelationByIdInput = {
  candidateReferencerRelationId: Scalars['Int'];
};

export type QueryCandidateReferencerRelationsForAdminInput = {
  companyId?: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QueryCandidateReferencerRelationsForHirerInput = {
  companyId: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QueryCandidateReferencerRelationsInput = {
  candidateExperienceId?: Scalars['Int'];
  candidateReferencerRelationId?: Scalars['Int'];
};

export type QueryExperienceSectionsInput = {
  experienceSectionId: Scalars['Int'];
};

export type QueryGlobalStatsInput = {
  period: Scalars['Int'];
  timeUnit: AllowTimeUnit | `${AllowTimeUnit}`;
};

export type QueryHirerCompanyRelationPagingForCompanyInput = {
  companyId: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  permissions?: Array<Scalars['String']>;
};

export type QueryPositionStatsInput = {
  period: Scalars['Int'];
  positionId: Scalars['Int'];
  timeUnit: AllowTimeUnit | `${AllowTimeUnit}`;
};

export type QueryUsersPagingInput = {
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  primaryEmail?: Scalars['String'];
  userId?: Scalars['Int'];
  username?: Scalars['String'];
};

export type Question = {
  __typename?: 'Question';
  answerInterface: Scalars['String'];
  answerMeta: AnswerMeta;
  answerTopic: Scalars['String'];
  attribute: BehavioralSelfAssessmentAttribute;
  helpTexts: Array<QuestionHelpText>;
  qohFactorDisplayName: Scalars['String'];
  questionComponentKey: Scalars['String'];
  questionIdentifier: Scalars['String'];
  questionSurveyInterface: Scalars['String'];
  questionText: Scalars['String'];
  questionType: Scalars['String'];
  questionTypeKey: Scalars['String'];
  subsectionQuestionRelationKey: Scalars['String'];
  subtitle: Scalars['String'];
  validations: QuestionValidations;
};

export type QuestionAnswer = {
  __typename?: 'QuestionAnswer';
  answerBody: Scalars['JSON'];
  answerGroupId: Scalars['ID'];
  answerGroupKey: Scalars['ID'];
  answerId: Scalars['Int'];
  answerText: Scalars['String'];
  questionComponentId: Scalars['Int'];
  questionComponentKey: Scalars['ID'];
  questionIdentifier: Scalars['String'];
  questionText: Scalars['String'];
  questionType: Scalars['String'];
  subsectionQuestionRelationId: Scalars['Int'];
};

export type QuestionAnswerOption = {
  __typename?: 'QuestionAnswerOption';
  answerOptionId: Scalars['Int'];
  answerOptionKey: Scalars['String'];
  cultureDefinition: Scalars['String'];
  cultureName: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
};

export type QuestionComponent = {
  __typename?: 'QuestionComponent';
  answerOptionGroup: AnswerOptionGroup;
  answerOptionGroupId: Scalars['Int'];
  answerTopic: AnswerTopicEnum | `${AnswerTopicEnum}`;
  attribute: BehavioralSelfAssessmentAttribute;
  componentIdentifier: Scalars['String'];
  componentText: Scalars['String'];
  createdAt: Scalars['Date'];
  creatorCompanyId: Scalars['Int'];
  details: Scalars['JSON'];
  hasAnswerOptions: Scalars['Boolean'];
  helpTexts: Array<HelpText>;
  hirerCanAccess: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  orderedAnswerOptions: Array<AnswerOption>;
  qohFactor: QohFactor;
  questionComponentId: Scalars['Int'];
  questionComponentKey: Scalars['ID'];
  questionType: QuestionType;
  questionTypeId: Scalars['Int'];
  subsectionQuestionRelations: Array<SubsectionQuestionRelation>;
  subtitleText: Scalars['String'];
  surveyDisplayMeta: SurveyDisplayMeta;
  updatedAt: Scalars['Date'];
};

export type QuestionComponentsPaging = {
  __typename?: 'QuestionComponentsPaging';
  data: Array<QuestionComponent>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type QuestionComponentsPagingInput = {
  answerTopicFilters?: Array<Scalars['String']>;
  excludedQuestionComponentIds?: Array<Scalars['Int']>;
  isArchived?: Scalars['Boolean'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QuestionHelpText = {
  __typename?: 'QuestionHelpText';
  description: Scalars['String'];
  title: Scalars['String'];
};

export type QuestionRating = {
  __typename?: 'QuestionRating';
  candidatePositionRelationId: Scalars['Int'];
  hirerProfileId: Scalars['Int'];
  questionComponentId: Scalars['Int'];
  questionRatingId: Scalars['Int'];
  rating: Scalars['Int'];
};

export enum QuestionSurveyInterfaceEnum {
  checkbox = 'checkbox',
  filtered_multi_select = 'filtered_multi_select',
  likert_scale = 'likert_scale',
  multi_select = 'multi_select',
  no_input = 'no_input',
  number_input = 'number_input',
  plain_text = 'plain_text',
  rating_slider = 'rating_slider',
  rich_text = 'rich_text',
  segmented_control = 'segmented_control',
  single_select = 'single_select',
  skill_map = 'skill_map',
}

export type QuestionType = {
  __typename?: 'QuestionType';
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  details: Scalars['JSON'];
  displayName: Scalars['String'];
  hasAnswerOptions: Scalars['Boolean'];
  hirerCanAccess: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  questionTypeId: Scalars['Int'];
  questionTypeKey: Scalars['String'];
  typeName: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type QuestionValidations = {
  __typename?: 'QuestionValidations';
  isConditional: Scalars['Boolean'];
  maxSelections: Scalars['Int'];
  required: Scalars['Boolean'];
};

export type QuestionsPage = {
  __typename?: 'QuestionsPage';
  headingText: Scalars['String'];
  instructionText: Scalars['String'];
  orderedQuestionsOnPage: Array<Question>;
  sectionComponentRelationKey: Scalars['String'];
  subsectionComponentKey: Scalars['String'];
};

export type Recommendation = {
  __typename?: 'Recommendation';
  candidateDeclined: Scalars['Boolean'];
  candidateEmail: Scalars['String'];
  candidateFirstName: Scalars['String'];
  candidateLastName: Scalars['String'];
  candidateLinkedin: Scalars['String'];
  candidateNotifiedDate: Scalars['Date'];
  candidatePhoneNumber: Scalars['String'];
  candidateReferencerRelation: CandidateReferencerRelation;
  candidateReferencerRelationId: Scalars['Int'];
  companyName: Scalars['String'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  formComponentGroupId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  overlapEndDate: Scalars['Date'];
  overlapStartDate: Scalars['Date'];
  recommendationCompanies: Array<RecommendationCompany>;
  recommendationId: Scalars['Int'];
  referenceRequests: Array<ReferenceRequest>;
  referencerProfile: ReferencerProfile;
  referencerProfileId: Scalars['Int'];
  referencerTitle: Scalars['String'];
  referencerWorkRelationship: Scalars['String'];
  status: Scalars['String'];
  uniqueCode: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type RecommendationCompany = {
  __typename?: 'RecommendationCompany';
  candidateApproved: Scalars['Boolean'];
  companyName: Scalars['String'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  ecosystemCompanyId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  recommendationCompanyId: Scalars['Int'];
  referenceRequest: ReferenceRequest;
  referenceRequestId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type RefRequirementArgumentUnion =
  | RefRequirementConditionArgument
  | RefRequirementSyntaxNode;

export type RefRequirementCondition = {
  __typename?: 'RefRequirementCondition';
  numRequired: Scalars['Int'];
  referenceRelationType: ReferenceRelationType;
  referenceRelationTypeKey: Scalars['ID'];
};

export type RefRequirementConditionArgument = {
  __typename?: 'RefRequirementConditionArgument';
  condition: RefRequirementCondition;
  operator: Operator | `${Operator}`;
};

export type RefRequirementConditionInput = {
  numRequired: Scalars['Int'];
  referenceRelationTypeKey: Scalars['ID'];
};

export type RefRequirementSyntaxArguments = {
  __typename?: 'RefRequirementSyntaxArguments';
  arguments: Array<RefRequirementArgumentUnion>;
  operator: Operator | `${Operator}`;
};

export type RefRequirementSyntaxArgumentsInput = {
  arguments?: Array<RefRequirementSyntaxArgumentsInput>;
  condition?: RefRequirementConditionInput;
  operator: Operator | `${Operator}`;
};

export type RefRequirementSyntaxNode = {
  __typename?: 'RefRequirementSyntaxNode';
  arguments: Array<RefRequirementArgumentUnion>;
  operator: Operator | `${Operator}`;
};

export type RefRequirementSyntaxNodeInput = {
  arguments: Array<RefRequirementSyntaxArgumentsInput>;
  operator: Operator | `${Operator}`;
};

export type ReferenceAssessment = {
  __typename?: 'ReferenceAssessment';
  candidatePositionRelation: CandidatePositionRelation;
  completedDate: Scalars['Date'];
  invitedDate: Scalars['Date'];
  referenceAssessmentKey: Scalars['ID'];
};

export type ReferenceRelationType = {
  __typename?: 'ReferenceRelationType';
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  isArchived: Scalars['Boolean'];
  referenceRelationTypeId: Scalars['Int'];
  referenceRelationTypeKey: Scalars['ID'];
  typeName: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type ReferenceRelationshipDetails = {
  __typename?: 'ReferenceRelationshipDetails';
  candidateRole: Scalars['String'];
  collaborationFrequency: Scalars['String'];
  companyName: Scalars['String'];
  contextForReference: Scalars['String'];
  phoneNumber: Scalars['String'];
  referencerTitle: Scalars['String'];
  relationshipStrength: Scalars['String'];
  timeOverlap: Scalars['String'];
  workRelationship: Scalars['String'];
};

export type ReferenceRequest = {
  __typename?: 'ReferenceRequest';
  adviceForManager: Scalars['String'];
  allowFollowUp: Scalars['Boolean'];
  candidateCanViewFullReport: Scalars['Boolean'];
  candidatePositionRelation: CandidatePositionRelation;
  candidatePositionRelationId: Scalars['Int'];
  candidateReferencerRelation: CandidateReferencerRelation;
  candidateReferencerRelationId: Scalars['Int'];
  candidateReviewee: Reviewee;
  checklistSubmission: ReferencerChecklistSubmission;
  company: Company;
  contextForReference: Scalars['String'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  draftAnswerGroup: AnswerGroup;
  draftSkillMapResponses: Array<SkillMapResponse>;
  expirationDate: Scalars['Date'];
  formComponentGroup: FormComponentGroup;
  formComponentGroupId: Scalars['Int'];
  formComponentGroupKey: Scalars['ID'];
  invitationIp: Scalars['String'];
  inviteFromCandidate: Scalars['String'];
  isArchived: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  isReview: Scalars['Boolean'];
  isSalesLead: Scalars['Boolean'];
  mostRecentlySubmittedAnswerGroup: AnswerGroup;
  numSubmissions: Scalars['Int'];
  openToOpportunities: Scalars['Boolean'];
  overlapMonths: Scalars['Int'];
  peerRankAnswer: Scalars['String'];
  publicSummary: Answer;
  questionsAndAnswers: Array<ReferenceRequestQuestionAndAnswers>;
  recencyMonths: Scalars['Int'];
  recommendation: Recommendation;
  recommendationCompanies: Array<RecommendationCompany>;
  referenceDueDate: Scalars['Date'];
  referenceRequestId: Scalars['Int'];
  referenceRequestKey: Scalars['ID'];
  referencerExperienceYears: Scalars['Float'];
  referencerHasManagedBefore: Scalars['Boolean'];
  referencerInvitedDate: Scalars['Date'];
  referencerLinkedin: Scalars['String'];
  referencerMostRecentRole: Scalars['String'];
  referencerPhone: Scalars['String'];
  referencerResponseDays: Scalars['Int'];
  resetDates: Array<Scalars['Date']>;
  reviewCycleId: Scalars['Int'];
  reviewCycleName: Scalars['String'];
  sharedEmployer: Scalars['String'];
  skillMapRelations: Array<ReferenceRequestSkillMapRelation>;
  skillMaps: Array<SkillMap>;
  statusType: StatusType;
  statusTypeId: Scalars['Int'];
  submissionDate: Scalars['Date'];
  submissionIp: Scalars['String'];
  submissionIpMatchesInvitationIp: Scalars['Boolean'];
  submittedAnswerGroups: Array<AnswerGroup>;
  submittedSkillMapResponses: Array<SkillMapResponse>;
  surveyContainsGrowthCurveQuestions: Scalars['Boolean'];
  uniqueCode: Scalars['String'];
  updatedAt: Scalars['Date'];
  workRelationship: Scalars['String'];
  workWithCandidateAgain: Scalars['Boolean'];
};

export type ReferenceRequestFeedInput = {
  ascOrDesc?: Scalars['String'];
  isArchived?: Scalars['Boolean'];
  keyword: Scalars['String'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  orderBy?: Scalars['String'];
};

export type ReferenceRequestForCpr = {
  __typename?: 'ReferenceRequestForCpr';
  candidateReferencerRelation: CandidateReferencerRelation;
  candidateReferencerRelationKey: Scalars['ID'];
  referenceRequest: ReferenceRequest;
  referenceRequestKey: Scalars['ID'];
  referencerEmail: Scalars['String'];
  referencerFirstName: Scalars['String'];
  referencerLastName: Scalars['String'];
  referencerLinkedIn: Scalars['String'];
  referencerPhoneNumber: Scalars['String'];
  referencerRecentRole: Scalars['String'];
  relationshipDetails: ReferenceRelationshipDetails;
};

export type ReferenceRequestNewAnswerInput = {
  answerBody: Scalars['JSON'];
  subsectionQuestionRelationId?: Scalars['Int'];
  subsectionQuestionRelationKey?: Scalars['ID'];
};

export type ReferenceRequestQuestionAndAnswers = {
  __typename?: 'ReferenceRequestQuestionAndAnswers';
  answerInterface: Scalars['String'];
  answerMeta: AnswerMeta;
  answerTopic: Scalars['String'];
  answers: Array<SurveyAnswer>;
  attribute: BehavioralSelfAssessmentAttribute;
  helpTexts: Array<QuestionHelpText>;
  qohFactorDisplayName: Scalars['String'];
  questionComponentKey: Scalars['String'];
  questionIdentifier: Scalars['String'];
  questionSurveyInterface: Scalars['String'];
  questionText: Scalars['String'];
  questionType: Scalars['String'];
  questionTypeKey: Scalars['String'];
  subsectionQuestionRelationKey: Scalars['String'];
  subtitle: Scalars['String'];
  validations: QuestionValidations;
};

export type ReferenceRequestResponseInputV2 = {
  answers?: Array<ReferenceRequestNewAnswerInput>;
  currentSectionComponentRelationId?: Scalars['Int'];
  currentSectionComponentRelationKey?: Scalars['ID'];
  currentSkillMapCompetencyId?: Scalars['Int'];
  isSalesLead?: Scalars['Boolean'];
  openToOpportunities?: Scalars['Boolean'];
  referenceRequestId: Scalars['Int'];
  skillMapAnswers?: Array<SkillMapResponseInput>;
  workWithCandidateAgain?: Scalars['Boolean'];
};

export type ReferenceRequestSkillMapRelation = {
  __typename?: 'ReferenceRequestSkillMapRelation';
  referenceRequestSkillMapRelationId: Scalars['Int'];
  skillMap: SkillMap;
  skillMapId: Scalars['Int'];
  targetSkillMapLevelIds: Array<Scalars['Int']>;
};

export type ReferenceSectionForRequest = {
  __typename?: 'ReferenceSectionForRequest';
  candidateFirstName: Scalars['String'];
  referenceRequestId: Scalars['Int'];
  referenceSection: SectionComponent;
};

export type ReferenceStats = {
  __typename?: 'ReferenceStats';
  completed: Scalars['Int'];
  invited: Scalars['Int'];
  referenceCompletedBenchmarkValue: Scalars['String'];
};

export type ReferencerChecklistSubmission = {
  __typename?: 'ReferencerChecklistSubmission';
  checklistAnswers: Array<ChecklistAnswer>;
  percentageMarkedCompleted: Scalars['Float'];
  referenceRequestKey: Scalars['String'];
};

export type ReferencerLinkedinUpload = {
  __typename?: 'ReferencerLinkedinUpload';
  referencerLinkedinUploadId: Scalars['Int'];
  referencerProfileId: Scalars['Int'];
  referencerReminded: Scalars['Boolean'];
  referencerReminderTime: Scalars['Date'];
  referencerUploaded: Scalars['Boolean'];
};

export type ReferencerProfile = {
  __typename?: 'ReferencerProfile';
  canAddRecommendation: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  currentFunction: Scalars['String'];
  currentTitle: Scalars['String'];
  details: Scalars['JSON'];
  isArchived: Scalars['Boolean'];
  linkedinUrl: Scalars['String'];
  referencerAddress: Scalars['String'];
  referencerEmail: Scalars['String'];
  referencerPhoneNumber: Scalars['String'];
  referencerProfileId: Scalars['Int'];
  updatedAt: Scalars['Date'];
  user: User;
  userId: Scalars['Int'];
};

export type ReferencerProfileSearchInput = {
  referencerProfileId: Scalars['Int'];
};

export enum ReferencerSegment {
  HumanResources = 'HUMAN_RESOURCES',
  IndividualContributor = 'INDIVIDUAL_CONTRIBUTOR',
  Manager = 'MANAGER',
}

export enum ReflectionPerformanceBucket {
  LOW = 'LOW',
  TOP = 'TOP',
}

export type ReflectionPeriodsForCompany = {
  __typename?: 'ReflectionPeriodsForCompany';
  companyKey: Scalars['ID'];
  reflectionPeriods: Array<Scalars['String']>;
};

export type RefreshBenchmarkEvent = {
  __typename?: 'RefreshBenchmarkEvent';
  benchmarkId: Scalars['Int'];
  creatorHirerProfileId: Scalars['Int'];
  event: Scalars['String'];
};

export type RefreshBenchmarkInput = {
  benchmarkId: Scalars['Int'];
};

export type RefreshTokenData = {
  __typename?: 'RefreshTokenData';
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type RegenerateJobInterviewInput = {
  jobInterviewKey: Scalars['ID'];
  jobKey: Scalars['ID'];
};

export type RejectApplicationInput = {
  atsApplicationKey: Scalars['ID'];
  companyKey: Scalars['ID'];
  notes?: Scalars['String'];
  rejectionEmailSendAt?: Scalars['Date'];
  rejectionEmailTemplateId?: Scalars['ID'];
  rejectionReasonId: Scalars['Int'];
};

export type RejectionReason = {
  __typename?: 'RejectionReason';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: RejectionReasonType;
};

export type RejectionReasonType = {
  __typename?: 'RejectionReasonType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RelevantExperienceRelatedTitleInput = {
  isUserAdded: Scalars['Boolean'];
  title: Scalars['String'];
};

export type RemoveIntakeAccessForHirerInput = {
  benchmarkKey: Scalars['ID'];
  hirerProfileKey: Scalars['ID'];
};

export type RequestApplicationsSyncForJobInput = {
  atsJobKey: Scalars['String'];
};

export type RequestApplicationsSyncForJobPostInput = {
  atsJobPostKey: Scalars['String'];
};

export type RequestCandidateShareInviteVerificationInput = {
  candidateShareInviteId: Scalars['Int'];
  password: Scalars['String'];
};

export type RequestUserResetInput = {
  primaryEmail?: Scalars['String'];
  username?: Scalars['String'];
};

export type RescheduleCanceledInvitationInput = {
  automatedInviteHistoryKey: Scalars['ID'];
  invitationDate: Scalars['Date'];
};

export type ResendBenchmarkRequestInput = {
  benchmarkRequestId: Scalars['Int'];
};

export type ResetReferenceSubmissionInput = {
  referenceRequestId: Scalars['Int'];
};

export type RespondentAndAnswer = {
  __typename?: 'RespondentAndAnswer';
  answer: Scalars['JSON'];
  respondent: RespondentInfo;
};

export type RespondentInfo = {
  __typename?: 'RespondentInfo';
  answerGroupId: Scalars['Int'];
  answerGroupKey: Scalars['ID'];
  metadata: Scalars['JSON'];
  respondentFirstName: Scalars['String'];
  respondentId: Scalars['Int'];
  respondentLastName: Scalars['String'];
  respondentName: Scalars['String'];
  surveyId: Scalars['Int'];
};

export type Resume = {
  __typename?: 'Resume';
  candidateProfile: CandidateProfile;
  candidateProfileId: Scalars['Int'];
  componentText: Scalars['String'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  isArchived: Scalars['Boolean'];
  position: Position;
  positionId: Scalars['Int'];
  resumeId: Scalars['Int'];
  updatedAt: Scalars['Date'];
  url: Scalars['String'];
};

export type RetryInvitationForCandidatePositionRelationInput = {
  candidatePositionRelationKey: Scalars['ID'];
};

export type ReturnInterviewPlanForIntakeInput = {
  benchmarkKey: Scalars['ID'];
};

export type ReviewCycle = {
  __typename?: 'ReviewCycle';
  company: Company;
  companyId: Scalars['Int'];
  endDate: Scalars['Date'];
  hasLowPerformers: Scalars['Boolean'];
  hasMinTopPerformers: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  numLowPerformers: Scalars['Int'];
  numReviewees: Scalars['Int'];
  numTopPerformers: Scalars['Int'];
  reviewCycleId: Scalars['Int'];
  reviewCycleKey: Scalars['ID'];
  reviewGroups: Array<Position>;
  startDate: Scalars['Date'];
};

export type ReviewCyclePaging = {
  __typename?: 'ReviewCyclePaging';
  data: Array<ReviewCycle>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Reviewee = {
  __typename?: 'Reviewee';
  candidatePositionRelation: CandidatePositionRelation;
  companyFunction: Scalars['String'];
  companyLevel: Scalars['String'];
  companyTitle: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  managerHirerProfile: HirerProfile;
  performanceBucket: ReflectionPerformanceBucket | `${ReflectionPerformanceBucket}`;
  positionId: Scalars['Int'];
  reviewCycle: ReviewCycle;
  reviewCycleId: Scalars['Int'];
  revieweeId: Scalars['Int'];
  revieweeKey: Scalars['ID'];
  reviewers: Array<Reviewer>;
  workEmail: Scalars['String'];
};

export type RevieweesPaging = {
  __typename?: 'RevieweesPaging';
  data: Array<Reviewee>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type RevieweesPerformanceBucketForReflection = {
  __typename?: 'RevieweesPerformanceBucketForReflection';
  lowPerformers: Array<Reviewee>;
  minTopPerformers: Scalars['Int'];
  topPerformers: Array<Reviewee>;
};

export type Reviewer = {
  __typename?: 'Reviewer';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  reviewerId: Scalars['String'];
  workRelationship: ReferenceRelationType;
};

export type ReviewerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  workRelationshipKey?: Scalars['ID'];
};

export type ReviewerSelectedAttribute = {
  __typename?: 'ReviewerSelectedAttribute';
  attributeName: Scalars['String'];
  isTop: Scalars['Boolean'];
  referenceRequest: ReferenceRequest;
  referenceRequestKey: Scalars['ID'];
  respondentEmail: Scalars['String'];
  respondentFirstName: Scalars['String'];
  respondentLastName: Scalars['String'];
  subtitle: Scalars['String'];
};

export type RolePermission = {
  __typename?: 'RolePermission';
  accessPermission: AccessPermission;
  companyRole: CompanyRole;
  createdAt: Scalars['Date'];
  rolePermissionKey: Scalars['ID'];
  updatedAt: Scalars['Date'];
};

export type SearchAnswerGroupsInput = {
  answerGroupId?: Scalars['Int'];
  draftReferenceRequestId?: Scalars['Int'];
  submissionDate?: Scalars['Date'];
  submittedReferenceRequestId?: Scalars['Int'];
};

export type SearchAnswerOptionGroupInput = {
  answerOptionGroupId?: Scalars['Int'];
  groupName?: Scalars['String'];
  keyword: Scalars['String'];
};

export type SearchAnswersInput = {
  answerBody?: Scalars['JSON'];
  answerGroupId?: Scalars['Int'];
  answerId?: Scalars['Int'];
  subsectionQuestionRelationId?: Scalars['Int'];
};

export type SearchAttributesInput = {
  attributeId?: Scalars['Int'];
  isArchived?: Scalars['Boolean'];
  isStrength?: Scalars['Boolean'];
};

export type SearchBulkReferenceRequestInput = {
  referenceRequestIds: Array<Scalars['Int']>;
};

export type SearchCandidatePositionRelationsInput = {
  attributeIds?: Array<Scalars['Int']>;
  candidateEmail?: Scalars['String'];
  candidateInviteSent?: Scalars['Boolean'];
  candidatePositionRelationId?: Scalars['Int'];
  candidateProfileId?: Scalars['Int'];
  companyId?: Scalars['Int'];
  details?: Scalars['JSON'];
  forReviews?: Scalars['Boolean'];
  isArchived?: Scalars['Boolean'];
  isArchivedReviewCycle?: Scalars['Boolean'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  positionId?: Scalars['Int'];
  positionIds?: Array<Scalars['Int']>;
  sendInviteOnCreate?: Scalars['Boolean'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
  statusTypeIds?: Array<Scalars['Int']>;
  uniqueCode?: Scalars['String'];
};

export type SearchCandidateProfilesInput = {
  candidateProfileIds: Array<Scalars['Int']>;
};

export type SearchExperienceDetailsInput = {
  candidateExperienceId?: Scalars['Int'];
};

export type SearchFormComponentGroupInput = {
  componentText?: Scalars['String'];
  formComponentGroupId?: Scalars['Int'];
  formIdentifier?: Scalars['String'];
  formTypeId?: Scalars['Int'];
  isArchived?: Scalars['Boolean'];
  isCommon?: Scalars['Boolean'];
  isPublicTemplate?: Scalars['Boolean'];
};

export type SearchGoldStandardAttributesInput = {
  attributeKey?: Scalars['String'];
  attributeNameSearch?: Scalars['String'];
};

export type SearchGroupSectionRelationsInput = {
  formComponentGroupId?: Scalars['Int'];
  sectionComponentId?: Scalars['Int'];
};

export type SearchHelpTextInput = {
  questionComponentId?: Scalars['Int'];
};

export type SearchHirerCompanyRelationInput = {
  companyId?: Scalars['Int'];
  hirerCompanyRelationId?: Scalars['Int'];
  hirerProfileId?: Scalars['Int'];
};

export type SearchHirerPositionRelationInput = {
  hirerPositionRelationId?: Scalars['Int'];
  hirerProfileId?: Scalars['Int'];
  positionId?: Scalars['Int'];
};

export type SearchQuestionComponentInput = {
  questionComponentId?: Scalars['Int'];
};

export type SearchReferenceRelationTypeInput = {
  referenceRelationTypeId?: Scalars['Int'];
};

export type SearchReferenceRequestByIdInput = {
  referenceRequestId: Scalars['Int'];
};

export type SearchReferenceRequestInput = {
  allowFollowUp?: Scalars['Boolean'];
  candidatePositionRelationId?: Scalars['Int'];
  candidateReferencerRelationId?: Scalars['Int'];
  contextForReference?: Scalars['String'];
  isPublic?: Scalars['Boolean'];
  referenceRequestId?: Scalars['Int'];
  referencerInvitedDate?: Scalars['Date'];
  referencerResponseDays?: Scalars['Int'];
  statusTypeId?: Scalars['Int'];
  submissionDate?: Scalars['Date'];
  uniqueCode?: Scalars['String'];
};

export type SearchResumeInput = {
  candidateProfileId?: Scalars['Int'];
  componentText?: Scalars['String'];
  positionId?: Scalars['Int'];
  resumeId?: Scalars['Int'];
};

export type SearchSectionComponentRelationsInput = {
  sectionComponentId?: Scalars['Int'];
  subsectionComponentId?: Scalars['Int'];
};

export type SearchSectionComponentsInput = {
  sectionComponentId?: Scalars['Int'];
};

export type SearchSubsectionComponentByComponentIdentifierInput = {
  componentIdentifier: Scalars['String'];
};

export type SearchSubsectionComponentsInput = {
  subsectionComponentId?: Scalars['Int'];
};

export type SearchSubsectionQuestionRelationsInput = {
  questionComponentId?: Scalars['Int'];
  subsectionComponentId?: Scalars['Int'];
};

export type SearchlightScore = {
  __typename?: 'SearchlightScore';
  candidatePositionRelationId: Scalars['Int'];
  date: Scalars['Date'];
  isTopPerformer: Scalars['Boolean'];
  type: Scalars['String'];
  weightedScore: Scalars['Float'];
};

export type Section = {
  __typename?: 'Section';
  isMainResponseSection: Scalars['Boolean'];
  isSkillMapSection: Scalars['Boolean'];
  orderedSubsections: Array<QuestionsPage>;
  sectionComponentKey: Scalars['String'];
  sectionText: Scalars['String'];
};

export type SectionComponent = {
  __typename?: 'SectionComponent';
  componentIdentifier: Scalars['String'];
  componentText: Scalars['String'];
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  groupSectionRelations: Array<GroupSectionRelation>;
  isArchived: Scalars['Boolean'];
  sectionComponentId: Scalars['Int'];
  sectionComponentRelations: Array<SectionComponentRelation>;
  sectionType: SectionType;
  subsectionComponents: Array<SubsectionComponent>;
  updatedAt: Scalars['Date'];
};

export type SectionComponentRelation = {
  __typename?: 'SectionComponentRelation';
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  isArchived: Scalars['Boolean'];
  order: Scalars['Int'];
  sectionComponent: SectionComponent;
  sectionComponentId: Scalars['Int'];
  sectionComponentRelationId: Scalars['Int'];
  subsectionComponent: SubsectionComponent;
  subsectionComponentId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type SectionType = {
  __typename?: 'SectionType';
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  isArchived: Scalars['Boolean'];
  sectionTypeId: Scalars['Int'];
  typeName: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type SelfSelectedAttribute = {
  __typename?: 'SelfSelectedAttribute';
  attributeName: Scalars['String'];
  candidateAssessment: CandidateAssessment;
  candidateAssessmentKey: Scalars['ID'];
  isTop: Scalars['Boolean'];
  subjectEmail: Scalars['String'];
  subjectFirstName: Scalars['String'];
  subjectLastName: Scalars['String'];
  subtitle: Scalars['String'];
};

export type SendCandidateInvitationInput = {
  candidateEmail: Scalars['String'];
  candidateFirstName: Scalars['String'];
  candidateLastName: Scalars['String'];
  candidatePositionRelationId: Scalars['Int'];
  companyName: Scalars['String'];
  positionTitle: Scalars['String'];
};

export type SendCompanyCommPreviewInput = {
  actionText?: Scalars['String'];
  bodyTemplates: Scalars['String'];
  commTypeKey: Scalars['String'];
  companyId: Scalars['Int'];
  positionKey?: Scalars['String'];
  subjectTemplate: Scalars['String'];
};

export type SendReminderForCandidatePositionRelationInput = {
  actionText?: Scalars['String'];
  bodyTemplates: Scalars['String'];
  candidatePositionRelationId: Scalars['Int'];
  subjectTemplate: Scalars['String'];
};

export type SharedCandidateDetails = {
  __typename?: 'SharedCandidateDetails';
  candidateEmail: Scalars['String'];
  candidateFirstName: Scalars['String'];
  candidateLastName: Scalars['String'];
  candidatePhoto: Scalars['String'];
  candidateProfileId: Scalars['Int'];
  vanityLinkText: Scalars['String'];
};

export type SharedCandidateReferencerRelationInput = {
  candidateReferencerRelationId: Scalars['Int'];
};

export type SharedReferenceDetails = {
  __typename?: 'SharedReferenceDetails';
  candidateReferencerRelationId: Scalars['Int'];
  candidateRole: Scalars['String'];
  companyName: Scalars['String'];
  contextForReference: Scalars['String'];
  referenceResponse: Array<Answer>;
  referencerFirstName: Scalars['String'];
  referencerLastName: Scalars['String'];
  referencerTitle: Scalars['String'];
  timeOverlap: Scalars['String'];
};

export type SignInAdminUserInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type SignInHirerOutput = {
  __typename?: 'SignInHirerOutput';
  benchmarkRequestId: Scalars['Int'];
  hirerProfile: HirerProfile;
  token: Scalars['String'];
};

export type SignInHirerUserInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type SignInHirerWithBenchmarkInput = {
  uniqueCode: Scalars['String'];
};

export type SignInHirerWithBenchmarkOutput = {
  __typename?: 'SignInHirerWithBenchmarkOutput';
  benchmarkRequestId: Scalars['Int'];
  hirerProfile: HirerProfile;
  token: Scalars['String'];
};

export type SignInHirerWithGoogleInput = {
  code: Scalars['String'];
};

export type SignInReferencerInput = {
  referencerEmail: Scalars['String'];
  uniqueCode: Scalars['String'];
};

export type SignInReferencerOutput = {
  __typename?: 'SignInReferencerOutput';
  referenceRequest: ReferenceRequest;
  referencerProfile: ReferencerProfile;
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type SignInUserInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type SignInUserOutput = {
  __typename?: 'SignInUserOutput';
  adminUserEmail: Scalars['String'];
  adminUserId: Scalars['Int'];
  permissions: Array<Scalars['String']>;
  refreshToken: Scalars['String'];
  tempLogin: Scalars['Boolean'];
  token: Scalars['String'];
  user: User;
};

export type SignInWithGoogleInput = {
  code: Scalars['String'];
  googleUserType?: Scalars['String'];
};

export type SignInWithWorkosInput = {
  code: Scalars['String'];
};

export type SignUpCandidateAndCreateCprWithGoogleInput = {
  code: Scalars['String'];
  positionId?: Scalars['Int'];
};

export type SignUpCandidateForCprWithGoogleInput = {
  candidatePositionRelationId: Scalars['Int'];
  code: Scalars['String'];
};

export type SingleCandidateInvitationInput = {
  candidateEmail: Scalars['String'];
  candidateFirstName?: Scalars['String'];
  candidateLastName?: Scalars['String'];
  candidatePhoneNumber?: Scalars['String'];
  candidatePositionRelationId?: Scalars['Int'];
};

export type SkillMap = {
  __typename?: 'SkillMap';
  benchmarks: Array<Benchmark>;
  companyId: Scalars['Int'];
  componentText: Scalars['String'];
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  inUse: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  positions: Array<Position>;
  scoreUnits: Scalars['Int'];
  skillMapCells: Array<SkillMapCell>;
  skillMapCompetencies: Array<SkillMapCompetency>;
  skillMapId: Scalars['Int'];
  skillMapKey: Scalars['ID'];
  skillMapLevels: Array<SkillMapLevel>;
  standardJobCareerTrack: StandardJobCareerTrack;
  standardJobCareerTrackKey: Scalars['String'];
  standardJobFamily: StandardJobFamily;
  standardJobFamilyKey: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type SkillMapAnswerMeta = {
  __typename?: 'SkillMapAnswerMeta';
  skillMapCells: Array<SkillMapCell>;
};

export type SkillMapCell = {
  __typename?: 'SkillMapCell';
  componentText: Scalars['String'];
  rank: Scalars['Int'];
  skillMapCellId: Scalars['Int'];
  skillMapCompetencyId: Scalars['Int'];
  skillMapCompetencyKey: Scalars['ID'];
  skillMapLevel: SkillMapLevel;
  skillMapLevelId: Scalars['Int'];
};

export type SkillMapCompetency = {
  __typename?: 'SkillMapCompetency';
  componentText: Scalars['String'];
  description: Scalars['String'];
  rank: Scalars['Int'];
  skillMapCells: Array<SkillMapCell>;
  skillMapCompetencyId: Scalars['Int'];
  skillMapCompetencyKey: Scalars['ID'];
  skillMapId: Scalars['Int'];
};

export type SkillMapLevel = {
  __typename?: 'SkillMapLevel';
  componentText: Scalars['String'];
  description: Scalars['String'];
  rank: Scalars['Int'];
  skillMapLevelId: Scalars['Int'];
};

export type SkillMapResponse = {
  __typename?: 'SkillMapResponse';
  competencyText: Scalars['String'];
  exampleText: Scalars['String'];
  score: Scalars['Int'];
  selectedSkillMapCellId: Scalars['Int'];
  skillMapCellScore: Scalars['Int'];
  skillMapCells: Array<SkillMapCell>;
  skillMapCompetencyId: Scalars['Int'];
  skillMapCompetencyKey: Scalars['ID'];
};

export type SkillMapResponseInput = {
  answerBody: Scalars['JSON'];
  skillMapCompetencyKey: Scalars['ID'];
};

export type SkillMapWithTargetLevelsInput = {
  skillMapId: Scalars['Int'];
  skillMapLevelIds?: Array<Scalars['Int']>;
};

export type SlidingScaleQuestion = {
  __typename?: 'SlidingScaleQuestion';
  componentText: Scalars['String'];
  isGoldStandard: Scalars['Boolean'];
  questionComponentId: Scalars['Int'];
};

export type SnapshotBehaviorModelInput = {
  appliedFilters: Array<FilterObjectInput>;
  companyKey: Scalars['ID'];
  companyPerformanceMetricKey?: Scalars['ID'];
  description: Scalars['String'];
  isPreview: Scalars['Boolean'];
  title: Scalars['String'];
  topGroupPercentile: Scalars['Float'];
};

export type SnapshotBehavioralAssessmentBehavioralModelInput = {
  attributeKeys: Array<Scalars['ID']>;
  companyKey: Scalars['ID'];
  comparisonView?: SoftSkillsAnalyticsComparisonView | `${SoftSkillsAnalyticsComparisonView}`;
  reflectionKey: Scalars['ID'];
};

export type SnapshotTalentModelInput = {
  appliedFilters: Array<FilterObjectInput>;
  companyKey: Scalars['ID'];
  description: Scalars['String'];
  isPreHire: Scalars['Boolean'];
  isPreview: Scalars['Boolean'];
  title: Scalars['String'];
};

export enum SoftSkillsAnalyticsComparisonView {
  TopVsLow = 'TOP_VS_LOW',
  TopVsRest = 'TOP_VS_REST',
  WholeTeam = 'WHOLE_TEAM',
}

export enum SrGroupsToGrantAccess {
  coordinator = 'coordinator',
  executive = 'executive',
  hiring_manager = 'hiring_manager',
  interview_team = 'interview_team',
  recruiter = 'recruiter',
}

export enum SrGroupsToUpdateOnSubmission {
  coordinator = 'coordinator',
  executive = 'executive',
  hiring_manager = 'hiring_manager',
  interview_team = 'interview_team',
  recruiter = 'recruiter',
}

export type SrJob = {
  __typename?: 'SrJob';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SrPartner = {
  __typename?: 'SrPartner';
  candidatePropertyKey: Scalars['String'];
  candidateSubstatus: Scalars['String'];
  company: Company;
  createdAt: Scalars['Date'];
  defaultPositionTemplate: PositionTemplate;
  groupsToGrantAccess: Array<SrGroupsToGrantAccess | `${SrGroupsToGrantAccess}`>;
  groupsToUpdateOnSubmission: Array<
    SrGroupsToUpdateOnSubmission | `${SrGroupsToUpdateOnSubmission}`
  >;
  isArchived: Scalars['Boolean'];
  lastCandidatesFetchDate: Scalars['Date'];
  srApiKey: Scalars['String'];
  srPartnerKey: Scalars['ID'];
  updatedAt: Scalars['Date'];
};

export type SrRequest = {
  __typename?: 'SrRequest';
  applicationId: Scalars['String'];
  candidateId: Scalars['String'];
  candidatePositionRelation: CandidatePositionRelation;
  candidateUrl: Scalars['String'];
  primaryJobId: Scalars['String'];
  srJobs: Array<SrJob>;
  srPartner: SrPartner;
  srRequestKey: Scalars['ID'];
};

export type StandardDepartment = {
  __typename?: 'StandardDepartment';
  name: Scalars['String'];
  standardDepartmentKey: Scalars['ID'];
};

export type StandardJobCareerTrack = {
  __typename?: 'StandardJobCareerTrack';
  name: Scalars['String'];
  standardJobCareerTrackKey: Scalars['ID'];
};

export type StandardJobFamily = {
  __typename?: 'StandardJobFamily';
  name: Scalars['String'];
  parentDepartmentId: Scalars['Date'];
  standardDepartment: StandardDepartment;
  standardJobFamilyKey: Scalars['ID'];
};

export type StandardJobLevel = {
  __typename?: 'StandardJobLevel';
  name: Scalars['String'];
  standardJobLevelKey: Scalars['ID'];
};

export type Standout = FractionStandout;

export type Standouts = {
  __typename?: 'Standouts';
  caution: Array<Standout>;
  positive: Array<Standout>;
};

export type StatusStats = {
  __typename?: 'StatusStats';
  active: Scalars['Int'];
  dismissed: Scalars['Int'];
  hired: Scalars['Int'];
  hiredOfferedBenchmarkValue: Scalars['String'];
  offered: Scalars['Int'];
};

export type StatusType = {
  __typename?: 'StatusType';
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  isArchived: Scalars['Boolean'];
  statusTypeId: Scalars['Int'];
  typeName: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type StrengthsAndGapsForCpr = {
  __typename?: 'StrengthsAndGapsForCpr';
  candidatePositionRelationKey: Scalars['ID'];
  intakeSelectedStrengths: Array<IntakeSelectedStrength>;
  reviewerSelectedGaps: Array<ReviewerSelectedAttribute>;
  reviewerSelectedStrengths: Array<ReviewerSelectedAttribute>;
  selfSelectedGaps: Array<SelfSelectedAttribute>;
  selfSelectedStrengths: Array<SelfSelectedAttribute>;
};

export type StrengthsFitInformation = {
  __typename?: 'StrengthsFitInformation';
  description: Scalars['String'];
  matchedStrengths: Array<Maybe<Scalars['String']>>;
  score: Scalars['String'];
};

export type SubmitReferencerNpsInput = {
  answers: Array<ReferenceRequestNewAnswerInput>;
  formComponentGroupId: Scalars['Int'];
  referencerProfileId: Scalars['Int'];
};

export type SubmitSlidingScaleCustomizationRequestInput = {
  companyId: Scalars['Int'];
  formName: Scalars['String'];
  slidingScaleQuestions?: Array<CustomSlidingScaleQuestionInput>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _empty: Scalars['String'];
};

export type SubsectionComponent = {
  __typename?: 'SubsectionComponent';
  childSubsections: Array<SubsectionComponent>;
  componentIdentifier: Scalars['String'];
  componentText: Scalars['String'];
  conditionalAnswers: Array<Scalars['String']>;
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  headingText: Scalars['String'];
  hirerCanEdit: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isConditional: Scalars['Boolean'];
  parentSubsection: SubsectionComponent;
  questionComponents: Array<QuestionComponent>;
  sectionComponentRelations: Array<SectionComponentRelation>;
  subsectionComponentId: Scalars['Int'];
  subsectionQuestionRelations: Array<SubsectionQuestionRelation>;
  updatedAt: Scalars['Date'];
};

export type SubsectionQuestionInput = {
  conditionalAnswers?: Array<Scalars['String']>;
  hirerCanEdit?: Scalars['Boolean'];
  isConditional?: Scalars['Boolean'];
  isRequired?: Scalars['Boolean'];
  maxSelections?: Scalars['Int'];
  parentQuestionComponentId?: Scalars['Int'];
  questionComponentId: Scalars['Int'];
};

export type SubsectionQuestionRelation = {
  __typename?: 'SubsectionQuestionRelation';
  conditionalAnswers: Array<Scalars['String']>;
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  hirerCanEdit: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isConditional: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  maxSelections: Scalars['Int'];
  order: Scalars['Int'];
  parentSubsectionQuestionRelationId: Scalars['Int'];
  questionComponent: QuestionComponent;
  questionComponentId: Scalars['Int'];
  subsectionComponent: SubsectionComponent;
  subsectionComponentId: Scalars['Int'];
  subsectionQuestionRelationId: Scalars['Int'];
  subsectionQuestionRelationKey: Scalars['ID'];
  updatedAt: Scalars['Date'];
};

export type SubsectionQuestionRelationAnswers = {
  __typename?: 'SubsectionQuestionRelationAnswers';
  answers: Array<Answer>;
  candidateProfileId: Scalars['Int'];
  subsectionQuestionRelationId: Scalars['Int'];
};

export type SubsectionWithQuestionsInput = {
  componentText?: Scalars['String'];
  headingText?: Scalars['String'];
  subsectionQuestions: Array<SubsectionQuestionInput>;
};

export type SurveyAnswer =
  | AttributesAnswer
  | MultipleChoiceAnswer
  | OpenEndedAnswer
  | TwoOptionSliderAnswer
  | WorkMotivationsAnswer;

export type SurveyDisplayMeta = {
  __typename?: 'SurveyDisplayMeta';
  answerInterface: Scalars['String'];
  answerMeta: AnswerMeta;
  questionSurveyInterface: Scalars['String'];
};

export type SurveyTemplate = {
  __typename?: 'SurveyTemplate';
  displayName: Scalars['String'];
  fcgType: FcgType;
  formComponentGroup: FormComponentGroup;
  name: Scalars['String'];
  surveyTemplateKey: Scalars['ID'];
};

export type TakeoverCompanyInput = {
  companyKey: Scalars['ID'];
};

export type TakeoverUserInput = {
  userId: Scalars['Int'];
};

export type TalentModel = {
  __typename?: 'TalentModel';
  appliedFilters: Array<FilterObject>;
  authorName: Scalars['String'];
  benchmarks: Array<Benchmark>;
  clonedTalentIsBenchmark: Scalars['Boolean'];
  clonedTalentModelKey: Scalars['ID'];
  clonedTalentModelTitle: Scalars['String'];
  createdAt: Scalars['Date'];
  cultureMetrics: Array<CultureMetric>;
  cultureTraits: Array<CultureTrait>;
  description: Scalars['String'];
  growthAreas: Array<PowerSkill>;
  isPreview: Scalars['Boolean'];
  sourceType: TalentModelSource | `${TalentModelSource}`;
  strengths: Array<PowerSkill>;
  talentModelKey: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
  version: Scalars['Int'];
  winningTraits: Array<WinningTrait>;
  winningWorkStyles: Array<WinningWorkStyle>;
};

export type TalentModelIdInput = {
  companyKey?: Scalars['ID'];
  talentModelKey: Scalars['ID'];
};

export type TalentModelInput = {
  appliedFilters: Array<FilterObjectInput>;
  companyKey: Scalars['ID'];
  cultureMetrics: Array<CultureMetricInput>;
  description: Scalars['String'];
  growthAreas: Array<PowerSkillInput>;
  isPreview: Scalars['Boolean'];
  sourceType: TalentModelSource | `${TalentModelSource}`;
  strengths: Array<PowerSkillInput>;
  talentModelKey?: Scalars['ID'];
  title: Scalars['String'];
};

export type TalentModelPaging = {
  __typename?: 'TalentModelPaging';
  companyKey: Scalars['ID'];
  data: Array<TalentModel>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type TalentModelQuestion = {
  __typename?: 'TalentModelQuestion';
  aggregateAnswers: AggregateAnswers;
  askedRespondentIds: Array<Scalars['Int']>;
  askedRespondents: Array<HirerProfile>;
  question: Question;
};

export type TalentModelQuestionData = {
  __typename?: 'TalentModelQuestionData';
  benchmarkKey: Scalars['ID'];
  questions: Array<TalentModelQuestion>;
};

export enum TalentModelSource {
  BehavioralAssessment = 'BEHAVIORAL_ASSESSMENT',
  Benchmark = 'BENCHMARK',
  Snapshot = 'SNAPSHOT',
  UserCreated = 'USER_CREATED',
}

export enum TaskType {
  candidate_assessment = 'candidate_assessment',
  reference_and_candidate_assessment = 'reference_and_candidate_assessment',
  reference_assessment = 'reference_assessment',
}

export type TeamSize = {
  __typename?: 'TeamSize';
  isArchived: Scalars['Boolean'];
  teamSizeId: Scalars['Int'];
  teamSizeName: Scalars['String'];
};

export type TestCanAccessGreenhouseJobPostsInput = {
  greenhousePartnerKey: Scalars['ID'];
};

export type ToggleArchiveCompanyByFormComponentGroupInput = {
  archive: Scalars['Boolean'];
  companyKey: Scalars['String'];
  formComponentGroupKey: Scalars['String'];
};

export type ToggleShouldScreenApplicantsInput = {
  atsJobKey: Scalars['ID'];
  companyKey: Scalars['ID'];
  shouldScreenApplicants?: Scalars['Boolean'];
};

export type TwoOptionSliderAnswer = BaseAnswer & {
  __typename?: 'TwoOptionSliderAnswer';
  answerBody: Scalars['JSON'];
  answerGroupId: Scalars['Int'];
  answerGroupKey: Scalars['ID'];
  answerId: Scalars['Int'];
  optionAId: Scalars['Int'];
  optionALabel: Scalars['String'];
  optionASubtitle: Scalars['String'];
  optionAValue: Scalars['Int'];
  optionBId: Scalars['Int'];
  optionBLabel: Scalars['String'];
  optionBSubtitle: Scalars['String'];
  optionBValue: Scalars['Int'];
  questionComponentId: Scalars['Int'];
  questionIdentifier: Scalars['String'];
  questionText: Scalars['String'];
  questionType: Scalars['String'];
  respondent: RespondentInfo;
  subsectionQuestionRelationId: Scalars['Int'];
  subsectionQuestionRelationKey: Scalars['String'];
};

export type TwoOptionSliderAnswerMeta = {
  __typename?: 'TwoOptionSliderAnswerMeta';
  labelOneCultureDefinition: InterviewPlanCultureItem;
  labelOneDescription: Scalars['String'];
  labelOneId: Scalars['Int'];
  labelOneKey: Scalars['String'];
  labelOneTitle: Scalars['String'];
  labelTwoCultureDefinition: InterviewPlanCultureItem;
  labelTwoDescription: Scalars['String'];
  labelTwoId: Scalars['Int'];
  labelTwoKey: Scalars['String'];
  labelTwoTitle: Scalars['String'];
};

export type UnansweredBehavioralQuestionData = {
  __typename?: 'UnansweredBehavioralQuestionData';
  questionComponent: QuestionComponent;
  subsectionComponentText: Scalars['String'];
  subsectionQuestionRelationId: Scalars['Int'];
};

export type UnrejectApplicationInput = {
  atsApplicationKey: Scalars['ID'];
  companyKey: Scalars['ID'];
};

export type UpcomingInvitation = {
  __typename?: 'UpcomingInvitation';
  automationSetting: AutomationSetting;
  candidatePositionRelation: CandidatePositionRelation;
  employee: InvitationEmployee;
  invitationDate: Scalars['Date'];
  invitationDateOverride: Scalars['Boolean'];
  reviewCycle: ReviewCycle;
  upcomingInvitationKey: Scalars['ID'];
};

export type UpcomingInvitationsPaging = {
  __typename?: 'UpcomingInvitationsPaging';
  automationSettingKey: Scalars['String'];
  data: Array<UpcomingInvitation>;
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type UpdateAshbyPartnerInput = {
  ashbyApiKey?: Scalars['String'];
  ashbyPartnerKey: Scalars['ID'];
  defaultPositionTemplateId?: Scalars['Int'];
  groupsToGrantAccess?: Array<AshbyGroupsToGrantAccess | `${AshbyGroupsToGrantAccess}`>;
  groupsToUpdateOnSubmission?: Array<
    AshbyGroupsToUpdateOnSubmission | `${AshbyGroupsToUpdateOnSubmission}`
  >;
};

export type UpdateAutomationSettingInput = {
  applyToAllHrisGroups: Scalars['Boolean'];
  automationSettingKey: Scalars['ID'];
  businessDaysFromStartDate: Scalars['Int'];
  hrisGroups?: Array<HrisGroupsAndSettingsInput>;
  inviteDirectManager: Scalars['Boolean'];
  inviteEmployeeReviewers: Scalars['Boolean'];
  inviteSkipManager: Scalars['Boolean'];
};

export type UpdateAutomationSettingStatusInput = {
  automationSettingKey: Scalars['ID'];
  isArchived: Scalars['Boolean'];
};

export type UpdateBenchmarkCollaboratorNotificationsInput = {
  benchmarkCollaboratorKey: Scalars['String'];
  receivesNotifications: Scalars['Boolean'];
};

export type UpdateBenchmarkRequestCommentInput = {
  benchmarkRequestCommentKey: Scalars['ID'];
  text: Scalars['String'];
};

export type UpdateBenchmarkRequestNotificationsInput = {
  benchmarkRequestId: Scalars['Int'];
  receivesNotifications: Scalars['Boolean'];
};

export type UpdateBulkHirerRelationsForPositionInput = {
  positionId: Scalars['Int'];
  relationsToCreate: Array<NewHirerPositionRelationInput>;
  relationsToDelete: Array<Scalars['Int']>;
  relationsToUpdate?: Array<UpdateHirerPositionRelationInput>;
};

export type UpdateCandidateAvatarInput = {
  candidatePhoto: Scalars['String'];
};

export type UpdateCandidateExperienceInput = {
  candidateExperienceId: Scalars['Int'];
  companyName?: Scalars['String'];
  details?: Scalars['JSON'];
  endMonth?: Scalars['Int'];
  endYear?: Scalars['Int'];
  experienceSectionId?: Scalars['Int'];
  experienceTypeId?: Scalars['Int'];
  isPresent?: Scalars['Boolean'];
  locationCity?: Scalars['String'];
  locationCountry?: Scalars['String'];
  locationState?: Scalars['String'];
  order?: Scalars['Int'];
  positionTitle?: Scalars['String'];
  startMonth?: Scalars['Int'];
  startYear?: Scalars['Int'];
};

export type UpdateCandidateIsPublicInput = {
  isPublic: Scalars['Boolean'];
};

export type UpdateCandidateJobPreferencesInput = {
  ecosystemCompanies?: Array<Scalars['Int']>;
  jobLocations?: Array<Scalars['Int']>;
  jobTitleResponse?: Scalars['String'];
  jobTypes?: Array<Scalars['Int']>;
  locationResponse?: Scalars['String'];
  teamSizes?: Array<Scalars['Int']>;
};

export type UpdateCandidatePositionRelationInput = {
  candidatePositionRelationId: Scalars['Int'];
  candidateProfileId?: Scalars['Int'];
  isArchived?: Scalars['Boolean'];
  positionId?: Scalars['Int'];
  statusTypeId?: Scalars['Int'];
};

export type UpdateCandidateProfileInput = {
  candidateEmail?: Scalars['String'];
  candidatePhoneNumber?: Scalars['String'];
  candidateProfileId: Scalars['Int'];
  firstName?: Scalars['String'];
  isArchived?: Scalars['Boolean'];
  lastName?: Scalars['String'];
  permissionId?: Scalars['Int'];
};

export type UpdateCandidateReferencerRelationInput = {
  candidateReferencerRelationId: Scalars['Int'];
  contextForReference?: Scalars['String'];
  details?: Scalars['JSON'];
  isArchived?: Scalars['Boolean'];
  overlapEndDate?: Scalars['Date'];
  overlapStartDate?: Scalars['Date'];
  referencerEmail?: Scalars['String'];
  referencerFirstName?: Scalars['String'];
  referencerLastName?: Scalars['String'];
  referencerTitle?: Scalars['String'];
  timeOverlap?: Scalars['String'];
  workRelationship?: Scalars['String'];
};

export type UpdateCellInSkillMapInput = {
  componentText: Scalars['String'];
  deleted?: Scalars['Boolean'];
  skillMapCellId: Scalars['Int'];
  skillMapCompetencyId: Scalars['Int'];
  skillMapLevelId: Scalars['Int'];
};

export type UpdateCompanyInput = {
  atsName?: Scalars['String'];
  companyAddress?: Scalars['String'];
  companyKey: Scalars['ID'];
  companyName: Scalars['String'];
  details?: Scalars['JSON'];
  notificationTypeId?: Scalars['Int'];
  participantViewFullReport?: Scalars['Boolean'];
  whitelistedEmailDomain?: Scalars['String'];
};

export type UpdateCompanyLogoInput = {
  companyId: Scalars['Int'];
  companyLogo: Scalars['String'];
};

export type UpdateCompanyNotificationEventsInput = {
  companyId: Scalars['Int'];
  notificationEvents: Array<Scalars['String']>;
};

export type UpdateCompanyNotificationTypeInput = {
  companyId: Scalars['Int'];
  notificationTypeId: Scalars['Int'];
};

export type UpdateCompanyPerformanceMetricInput = {
  companyPerformanceMetricKey: Scalars['ID'];
  employeeCustomMetricsToUpload?: Array<UploadEmployeeCustomMetricInput>;
  isArchived?: Scalars['Boolean'];
  name?: Scalars['String'];
  type?: PerformanceMetricType | `${PerformanceMetricType}`;
};

export type UpdateCompanyRoleInput = {
  accessPermissions: Array<Scalars['ID']>;
  companyRoleKey: Scalars['ID'];
  name: Scalars['String'];
  roleDescription?: Scalars['String'];
};

export type UpdateCompanyShowPositionTitleToCandidateInput = {
  companyId: Scalars['Int'];
  showPositionTitleToCandidate: Scalars['Boolean'];
};

export type UpdateCompetencyInSkillMapInput = {
  componentText: Scalars['String'];
  deleted?: Scalars['Boolean'];
  description?: Scalars['String'];
  rank: Scalars['Int'];
  skillMapCompetencyId: Scalars['Int'];
};

export type UpdateDomainForCompanyInput = {
  companyId: Scalars['Int'];
  whitelistedEmailDomain: Scalars['String'];
};

export type UpdateExperienceSectionInput = {
  componentText?: Scalars['String'];
  details?: Scalars['JSON'];
  experienceSectionId: Scalars['Int'];
  order?: Scalars['Int'];
};

export type UpdateFormComponentGroupByKeyInput = {
  componentText?: Scalars['String'];
  details?: Scalars['JSON'];
  formComponentGroupKey: Scalars['ID'];
  instructionText?: Array<Scalars['String']>;
  isCommon?: Scalars['Boolean'];
};

export type UpdateFormComponentGroupBySectionInput = {
  componentText?: Scalars['String'];
  existingFormComponentGroupId?: Scalars['Int'];
  sectionComponentsToUpsert: Array<UpsertSectionComponentInput>;
  version?: Scalars['Int'];
};

export type UpdateFormComponentGroupInput = {
  componentText: Scalars['String'];
  details?: Scalars['JSON'];
  fcgTypeId?: Scalars['Int'];
  formComponentGroupId: Scalars['Int'];
  formIdentifier: Scalars['String'];
  formTypeId?: Scalars['Int'];
  instructionText?: Array<Scalars['String']>;
  isCommon?: Scalars['Boolean'];
  isCommonIntake?: Scalars['Boolean'];
  isPublicTemplate?: Scalars['Boolean'];
  isTranslated?: Scalars['Boolean'];
  version?: Scalars['Int'];
};

export type UpdateGreenhousePartnerInput = {
  customJobFieldForIntake?: Scalars['String'];
  ghApiKey?: Scalars['String'];
  ghUserId?: Scalars['String'];
  greenhousePartnerId: Scalars['Int'];
  groupsToGrantAccess?: Array<GreenhouseGroupsToGrantAccess | `${GreenhouseGroupsToGrantAccess}`>;
  groupsToUpdateOnSubmission?: Array<
    GreenhouseGroupsToUpdateOnSubmission | `${GreenhouseGroupsToUpdateOnSubmission}`
  >;
};

export type UpdateGroupSectionRelationInput = {
  groupSectionRelationId: Scalars['Int'];
  order?: Scalars['Int'];
};

export type UpdateHirerCompanyPermissionsFromAdminHirerInput = {
  hirerCompanyRelationId: Scalars['Int'];
  permissionId: Scalars['Int'];
};

export type UpdateHirerCompanyRelationInput = {
  companyId?: Scalars['Int'];
  hirerCompanyRelationId: Scalars['Int'];
  hirerProfileId?: Scalars['Int'];
  permissionId?: Scalars['Int'];
};

export type UpdateHirerPositionRelationInput = {
  hirerPositionRelationId: Scalars['Int'];
  receivesReferenceNotificationEmails: Scalars['Boolean'];
};

export type UpdateHirerProfileInput = {
  hirerFirstName: Scalars['String'];
  hirerLastName: Scalars['String'];
  password: Scalars['String'];
  username?: Scalars['String'];
};

export type UpdateHirerTeamInput = {
  hirerProfileIds: Array<Scalars['Int']>;
  hirerTeamId: Scalars['Int'];
  teamName: Scalars['String'];
};

export type UpdateHiringCriteriaForJobPostInput = {
  atsJobPostKey: Scalars['ID'];
  competencyCriteria?: Array<UpsertCompetencyCriterionForJobPostInput>;
  locationQuestion?: CreateJobQuestionCriterionForJobPostInput;
  otherQuestion?: CreateJobQuestionCriterionForJobPostInput;
  relevantExperienceCriteria?: Array<CreateRelevantExperienceCriterionForJobPostInput>;
  softSkillCriteria?: Array<CreateSoftSkillCriterionForJobPostInput>;
  workAuthorizationQuestion?: CreateJobQuestionCriterionForJobPostInput;
};

export type UpdateIntakeAccessForHirerInput = {
  accessLevel: IntakeAccessLevel | `${IntakeAccessLevel}`;
  actionText?: Scalars['String'];
  benchmarkKey: Scalars['ID'];
  bodyTemplates?: Scalars['String'];
  hirerProfileKey: Scalars['ID'];
  subjectTemplate?: Scalars['String'];
};

export type UpdateInterestEmailInput = {
  details?: Scalars['JSON'];
  email?: Scalars['String'];
  interestEmailId: Scalars['Int'];
};

export type UpdateInvitationEmailForCandidatePositionRelationInput = {
  opportunityId: Scalars['String'];
  updatedEmail: Scalars['String'];
};

export type UpdateJobInterviewQuestionInput = {
  badAnswerExample?: Scalars['String'];
  goodAnswerExample?: Scalars['String'];
  interviewQuestionKey?: Scalars['ID'];
  jobInterviewQuestionKey: Scalars['ID'];
};

export type UpdateJobQuestionCriterionForJobPostInput = {
  acceptedAnswers?: Array<JobQuestionAnswerInput>;
  atsJobPostKey: Scalars['String'];
  externalQuestionId?: Scalars['ID'];
  externalQuestionLabel?: Scalars['String'];
  hiringJobQuestionCriterionKey: Scalars['String'];
};

export type UpdateLevelInSkillMapInput = {
  componentText: Scalars['String'];
  deleted?: Scalars['Boolean'];
  description?: Scalars['String'];
  rank: Scalars['Int'];
  skillMapLevelId: Scalars['Int'];
};

export type UpdateLeverPartnerInput = {
  defaultPositionId?: Scalars['Int'];
  defaultPositionTemplateId?: Scalars['Int'];
  leverApiKey?: Scalars['String'];
  leverPartnerId: Scalars['Int'];
  leverUserId?: Scalars['String'];
  signingToken?: Scalars['String'];
  stageNameTrigger?: Scalars['String'];
};

export type UpdateLockedSkillMapInput = {
  componentText: Scalars['String'];
  description?: Scalars['String'];
  isArchived?: Scalars['Boolean'];
  skillMapId: Scalars['Int'];
};

export type UpdateMyUserSettingsInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UpdateNotificationsForIntakeHirerInput = {
  benchmarkKey: Scalars['ID'];
  hirerProfileKey: Scalars['ID'];
  sendSurveyReminders?: Scalars['Boolean'];
  subscribeToActivity?: Scalars['Boolean'];
};

export type UpdateOptionalReferenceRequestFieldsInput = {
  isSalesLead?: Scalars['Boolean'];
  openToOpportunities?: Scalars['Boolean'];
  referenceRequestKey: Scalars['ID'];
};

export type UpdatePerformanceBucketsForReflectionRevieweesInput = {
  reflectionKey: Scalars['ID'];
  reviewees: Array<UpdateReflectionPerformanceBucketForSingleRevieweeInput>;
};

export type UpdatePositionInput = {
  behavioralAssessmentTargetScore?: Scalars['Int'];
  candidateFormComponentGroupId?: Scalars['Int'];
  candidateInviteFromHirer?: Scalars['String'];
  candidateViewFullReport?: Scalars['Boolean'];
  companyId?: Scalars['Int'];
  details?: Scalars['JSON'];
  externalJobId?: Scalars['String'];
  externalJobSourceId?: Scalars['Int'];
  formComponentGroupId?: Scalars['Int'];
  hideRespondentName?: Scalars['Boolean'];
  isAcceptingSurveySubmissions?: Scalars['Boolean'];
  isOpen?: Scalars['Boolean'];
  notifyCandidateWithUpdates?: Scalars['Boolean'];
  numRecommendedReferences?: Scalars['Int'];
  numRequiredReferences?: Scalars['Int'];
  positionId: Scalars['Int'];
  positionIdentifier?: Scalars['String'];
  positionTitle?: Scalars['String'];
  referenceRequirementSyntax?: RefRequirementSyntaxNodeInput;
  referencerResponseDays?: Scalars['Int'];
  sendAutomaticRemindersToSubjects?: Scalars['Boolean'];
  skillMapsWithLevels?: Array<SkillMapWithTargetLevelsInput>;
  subjectCanInviteRespondent?: Scalars['Boolean'];
  surveysToRelationships?: Array<AddPositionSurveyToRelationshipInput>;
  validSurveySubmissionWindowDays?: Scalars['Int'];
};

export type UpdatePositionStatusInput = {
  isArchived: Scalars['Boolean'];
  positionId: Scalars['Int'];
};

export type UpdatePositionTemplate = {
  behavioralAssessmentTargetScore?: Scalars['Int'];
  candidateFormComponentGroupId?: Scalars['Int'];
  formComponentGroupId?: Scalars['Int'];
  numRequiredReferences?: Scalars['Int'];
  positionTemplateId: Scalars['Int'];
  referenceRequirementSyntax?: RefRequirementSyntaxNodeInput;
  referencerResponseDays: Scalars['Int'];
  surveysToRelationships?: Array<AddPositionTemplateSurveyToRelationshipInput>;
  taskTypes: Array<TaskType | `${TaskType}`>;
  templateName: Scalars['String'];
};

export type UpdateQuestionComponentInput = {
  answerOptionGroupId?: Scalars['Int'];
  answerTopic?: AnswerTopicEnum | `${AnswerTopicEnum}`;
  componentIdentifier: Scalars['String'];
  componentText?: Scalars['String'];
  details?: Scalars['JSON'];
  helpTexts?: Array<HelpTextInput>;
  hirerCanAccess?: Scalars['Boolean'];
  isArchived?: Scalars['Boolean'];
  questionComponentId: Scalars['Int'];
  questionTypeId: Scalars['Int'];
  subtitleText?: Scalars['String'];
};

export type UpdateRecommendationCompanyInput = {
  candidateApproved: Scalars['Boolean'];
  recommendationCompanyId: Scalars['Int'];
};

export type UpdateRecommendationInput = {
  candidateDeclined?: Scalars['Boolean'];
  candidateEmail?: Scalars['String'];
  candidateFirstName?: Scalars['String'];
  candidateLastName?: Scalars['String'];
  candidateLinkedin?: Scalars['String'];
  candidatePhoneNumber?: Scalars['String'];
  companyName?: Scalars['String'];
  overlapEndDate?: Scalars['Date'];
  overlapStartDate?: Scalars['Date'];
  recommendationId: Scalars['Int'];
  referencerTitle?: Scalars['String'];
  referencerWorkRelationship?: Scalars['String'];
};

export type UpdateReferenceIsPublicInput = {
  candidateReferencerRelationId: Scalars['Int'];
  isPublic: Scalars['Boolean'];
};

export type UpdateReflectionPerformanceBucketForSingleRevieweeInput = {
  performanceBucket?: ReflectionPerformanceBucket | `${ReflectionPerformanceBucket}`;
  revieweeKey: Scalars['ID'];
};

export type UpdateReviewCycleInput = {
  endDate?: Scalars['Date'];
  isArchived?: Scalars['Boolean'];
  name: Scalars['String'];
  reviewCycleId: Scalars['Int'];
  startDate?: Scalars['Date'];
};

export type UpdateRevieweeInput = {
  companyFunction?: Scalars['String'];
  companyLevel?: Scalars['String'];
  companyTitle?: Scalars['String'];
  firstName?: Scalars['String'];
  lastName?: Scalars['String'];
  revieweeId: Scalars['Int'];
  reviewers?: Array<ReviewerInput>;
  workEmail?: Scalars['String'];
};

export type UpdateSectionComponentInput = {
  componentIdentifier: Scalars['String'];
  componentText?: Scalars['String'];
  sectionComponentId: Scalars['Int'];
};

export type UpdateSectionComponentRelationInput = {
  order?: Scalars['Int'];
  sectionComponentRelationId: Scalars['Int'];
};

export type UpdateSkillMapForJobOpeningInput = {
  jobOpeningKey: Scalars['ID'];
  skillMapId: Scalars['Int'];
  targetLevelIds?: Array<Scalars['Int']>;
};

export type UpdateSkillMapInput = {
  componentText: Scalars['String'];
  description?: Scalars['String'];
  isArchived?: Scalars['Boolean'];
  skillMapId: Scalars['Int'];
  standardJobCareerTrackKey?: Scalars['String'];
  standardJobFamilyKey?: Scalars['String'];
  updatedSkillMapCells?: Array<UpdateCellInSkillMapInput>;
  updatedSkillMapCompetencies?: Array<UpdateCompetencyInSkillMapInput>;
  updatedSkillMapLevels?: Array<UpdateLevelInSkillMapInput>;
};

export type UpdateSkillMapsForBenchmarkInput = {
  benchmarkId: Scalars['Int'];
  skillMaps?: Array<SkillMapWithTargetLevelsInput>;
};

export type UpdateSkillMapsForPositionInput = {
  positionId: Scalars['Int'];
  skillMaps: Array<SkillMapWithTargetLevelsInput>;
};

export type UpdateSrPartnerInput = {
  candidatePropertyKey?: Scalars['String'];
  candidateSubstatus?: Scalars['String'];
  defaultPositionTemplateId?: Scalars['Int'];
  groupsToGrantAccess?: Array<SrGroupsToGrantAccess | `${SrGroupsToGrantAccess}`>;
  groupsToUpdateOnSubmission?: Array<
    SrGroupsToUpdateOnSubmission | `${SrGroupsToUpdateOnSubmission}`
  >;
  srApiKey?: Scalars['String'];
  srPartnerKey: Scalars['ID'];
};

export type UpdateSubsectionComponentInput = {
  componentIdentifier: Scalars['String'];
  componentText?: Scalars['String'];
  headingText?: Scalars['String'];
  subsectionComponentId: Scalars['Int'];
};

export type UpdateSubsectionQuestionRelationInput = {
  order?: Scalars['Int'];
  subsectionQuestionRelationId: Scalars['Int'];
};

export type UpdateSurveyTemplateInput = {
  displayName: Scalars['String'];
  fcgTypeKey: Scalars['String'];
  formComponentGroupKey: Scalars['String'];
  name: Scalars['String'];
  surveyTemplateKey: Scalars['ID'];
};

export type UpdateTalentModelMetaDataInput = {
  description?: Scalars['String'];
  talentModelKey: Scalars['ID'];
  title?: Scalars['String'];
};

export type UpdateTemplateOnIntakeInput = {
  benchmarkKey: Scalars['ID'];
  templateBenchmarkKey: Scalars['ID'];
};

export type UpdateUserInput = {
  firstName?: Scalars['String'];
  isCandidate?: Scalars['Boolean'];
  isHirer?: Scalars['Boolean'];
  isReferencer?: Scalars['Boolean'];
  lastName?: Scalars['String'];
  permissionId?: Scalars['Int'];
  primaryEmail?: Scalars['String'];
  tempPassword?: Scalars['String'];
  userId: Scalars['Int'];
  username?: Scalars['String'];
};

export type UpdateUserPasswordInput = {
  password: Scalars['String'];
  userId?: Scalars['Int'];
};

export type UpdateWorkdayPartnerInput = {
  defaultPositionTemplateId?: Scalars['Int'];
  humanResourcesWsdl?: Scalars['String'];
  password?: Scalars['String'];
  recruitingWsdl?: Scalars['String'];
  username?: Scalars['String'];
  workdayPartnerKey: Scalars['String'];
};

export type UploadEmployeeCustomMetricInput = {
  employeeEmail: Scalars['String'];
  employeeFirstName: Scalars['String'];
  employeeLastName: Scalars['String'];
  maxScore: Scalars['Int'];
  minScore: Scalars['Int'];
  relatedTimePeriodEnd: Scalars['Date'];
  relatedTimePeriodStart: Scalars['Date'];
  score: Scalars['Int'];
};

export type UploadedEmployeeReviewersInput = {
  email: Scalars['String'];
  reviewers: Array<EmployeeReviewersArrayInput>;
};

export type UploadedMergeEmployeeInput = {
  email?: Scalars['String'];
  employment_status?: Scalars['String'];
  ethnicity?: Scalars['String'];
  first_name?: Scalars['String'];
  gender?: Scalars['String'];
  group?: Scalars['String'];
  jobFunction?: Scalars['String'];
  jobLevel?: Scalars['String'];
  jobTitle?: Scalars['String'];
  last_name?: Scalars['String'];
  manager_email?: Scalars['String'];
  manager_first_name?: Scalars['String'];
  manager_last_name?: Scalars['String'];
  start_date?: Scalars['String'];
};

export type UploadedRevieweeInput = {
  email: Scalars['String'];
  ethnicity?: Scalars['String'];
  first_name: Scalars['String'];
  gender?: Scalars['String'];
  group: Scalars['String'];
  last_name: Scalars['String'];
  reviewer_email_1?: Scalars['String'];
  reviewer_email_2?: Scalars['String'];
  reviewer_email_3?: Scalars['String'];
  reviewer_email_4?: Scalars['String'];
  reviewer_email_5?: Scalars['String'];
  reviewer_email_6?: Scalars['String'];
  reviewer_email_7?: Scalars['String'];
  reviewer_first_name_1?: Scalars['String'];
  reviewer_first_name_2?: Scalars['String'];
  reviewer_first_name_3?: Scalars['String'];
  reviewer_first_name_4?: Scalars['String'];
  reviewer_first_name_5?: Scalars['String'];
  reviewer_first_name_6?: Scalars['String'];
  reviewer_first_name_7?: Scalars['String'];
  reviewer_last_name_1?: Scalars['String'];
  reviewer_last_name_2?: Scalars['String'];
  reviewer_last_name_3?: Scalars['String'];
  reviewer_last_name_4?: Scalars['String'];
  reviewer_last_name_5?: Scalars['String'];
  reviewer_last_name_6?: Scalars['String'];
  reviewer_last_name_7?: Scalars['String'];
  reviewer_relationship_1?: Scalars['String'];
  reviewer_relationship_2?: Scalars['String'];
  reviewer_relationship_3?: Scalars['String'];
  reviewer_relationship_4?: Scalars['String'];
  reviewer_relationship_5?: Scalars['String'];
  reviewer_relationship_6?: Scalars['String'];
  reviewer_relationship_7?: Scalars['String'];
  start_date?: Scalars['Date'];
};

export type UpsertAnswerForBenchmarkRequestInput = {
  answerBody: Scalars['JSON'];
  answerGroupKey: Scalars['ID'];
  companyKey: Scalars['ID'];
  subsectionQuestionRelationKey: Scalars['ID'];
};

export type UpsertAnswerInput = {
  answerBody?: Scalars['JSON'];
  answerGroupKey: Scalars['ID'];
  skillMapCompetencyId?: Scalars['Int'];
  subsectionQuestionRelationId?: Scalars['Int'];
  subsectionQuestionRelationKey?: Scalars['ID'];
};

export type UpsertCommTypeForCompanyInput = {
  actionText?: Scalars['String'];
  bodyTemplates: Scalars['String'];
  commTypeKey: Scalars['String'];
  companyId: Scalars['Int'];
  positionKey?: Scalars['String'];
  subjectTemplate: Scalars['String'];
};

export type UpsertCompetencyCriterionForJobPostInput = {
  atsJobPostKey: Scalars['String'];
  description: Scalars['String'];
  hiringCompetencyCriterionKey?: Scalars['String'];
  isRequired: Scalars['Boolean'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type UpsertFormComponentGroupInput = {
  componentText: Scalars['String'];
  existingFormComponentGroupId?: Scalars['Int'];
  referenceSectionRelations: Array<SubsectionWithQuestionsInput>;
};

export type UpsertSectionComponentInput = {
  componentText: Scalars['String'];
  hirerCanEdit?: Scalars['Boolean'];
  sectionRelations: Array<SubsectionWithQuestionsInput>;
};

export type User = {
  __typename?: 'User';
  candidateProfile: CandidateProfile;
  createdAt: Scalars['Date'];
  details: Scalars['JSON'];
  firstName: Scalars['String'];
  hirerProfile: HirerProfile;
  isArchived: Scalars['Boolean'];
  lastName: Scalars['String'];
  permissions: Array<Scalars['String']>;
  primaryEmail: Scalars['String'];
  referencerProfile: ReferencerProfile;
  updatedAt: Scalars['Date'];
  userId: Scalars['Int'];
  userKey: Scalars['ID'];
  username: Scalars['String'];
};

export type UserEmailInput = {
  email: Scalars['String'];
};

export type UserSearchInput = {
  email?: Scalars['String'];
  userId?: Scalars['Int'];
  username?: Scalars['String'];
};

export type UsertEmployeeCprInput = {
  candidatePositionRelationKey: Scalars['ID'];
  mergeEmployeeKey: Scalars['ID'];
};

export type ValidateCandidateInvitationInput = {
  candidateEmail?: Scalars['String'];
  uniqueCode?: Scalars['String'];
};

export type VerifyHirerProfileForCandidateShareInviteInput = {
  candidateShareInviteId: Scalars['Int'];
  verificationCode: Scalars['String'];
};

export type WinningTrait = {
  __typename?: 'WinningTrait';
  active: Scalars['Boolean'];
  densityRatio: Scalars['Float'];
  description: Scalars['String'];
  importance: Scalars['String'];
  name: Scalars['String'];
  remainingGroupDensity: Scalars['Float'];
  topGroupDensity: Scalars['Float'];
  totalDensity: Scalars['Float'];
};

export enum WinningTraitImportance {
  baseline = 'baseline',
  differentiator = 'differentiator',
  superpower = 'superpower',
  undetermined = 'undetermined',
}

export type WinningTraitInput = {
  active: Scalars['Boolean'];
  densityRatio: Scalars['Float'];
  description: Scalars['String'];
  importance: WinningTraitImportance | `${WinningTraitImportance}`;
  name: Scalars['String'];
  remainingGroupDensity: Scalars['Float'];
  topGroupDensity: Scalars['Float'];
  totalDensity: Scalars['Float'];
};

export type WinningTraits = {
  __typename?: 'WinningTraits';
  bucketedScore: Scalars['Int'];
  candidateScore: Scalars['Int'];
  importance: Scalars['String'];
  traitDescription: Scalars['String'];
  traitName: Scalars['String'];
};

export type WinningWorkStyle = {
  __typename?: 'WinningWorkStyle';
  description: Scalars['String'];
  name: Scalars['String'];
  remainingGroupScore: Scalars['Float'];
  selectedScore: Scalars['Float'];
  topGroupScore: Scalars['Float'];
};

export type WinningWorkStyleInput = {
  inverseTraitDescription: Scalars['String'];
  inverseTraitName: Scalars['String'];
  remainingGroupScore: Scalars['Float'];
  selectedScore: Scalars['Float'];
  topGroupScore: Scalars['Float'];
  traitDescription: Scalars['String'];
  traitName: Scalars['String'];
};

export type WorkMotivationsAnswer = BaseAnswer & {
  __typename?: 'WorkMotivationsAnswer';
  answerBody: Scalars['JSON'];
  answerGroupId: Scalars['Int'];
  answerGroupKey: Scalars['ID'];
  answerId: Scalars['Int'];
  isTop: Scalars['Boolean'];
  key: Scalars['String'];
  label: Scalars['String'];
  questionComponentId: Scalars['Int'];
  questionIdentifier: Scalars['String'];
  questionText: Scalars['String'];
  questionType: Scalars['String'];
  respondent: RespondentInfo;
  subsectionQuestionRelationId: Scalars['Int'];
  subsectionQuestionRelationKey: Scalars['String'];
  subtitle: Scalars['String'];
};

export type WorkdayPartner = {
  __typename?: 'WorkdayPartner';
  company: Company;
  defaultPositionTemplate: PositionTemplate;
  humanResourcesWsdl: Scalars['String'];
  lastCandidatesFetchDate: Scalars['Date'];
  password: Scalars['String'];
  recruitingWsdl: Scalars['String'];
  username: Scalars['String'];
  workdayPartnerKey: Scalars['ID'];
};

export type WorkdayRequest = {
  __typename?: 'WorkdayRequest';
  candidatePositionRelation: CandidatePositionRelation;
  requisitionName: Scalars['String'];
  workdayPartner: WorkdayPartner;
  workdayPartnerKey: Scalars['ID'];
};

export type WorkingStyles = {
  __typename?: 'WorkingStyles';
  candidateScore: Scalars['Int'];
  inverseTraitDescription: Scalars['String'];
  inverseTraitName: Scalars['String'];
  targetScore: Scalars['Int'];
  traitDescription: Scalars['String'];
  traitName: Scalars['String'];
};

export type WorkosAuthorizationUrl = {
  __typename?: 'WorkosAuthorizationURL';
  authorizationURL: Scalars['String'];
};

export type ArchiveBenchmarkInput = {
  benchmarkId: Scalars['Int'];
};

export type CreateBenchmarkAndRequestsInput = {
  benchmarkKeyToUseAsTemplate?: Scalars['ID'];
  benchmarkTitle: Scalars['String'];
  companyId: Scalars['Int'];
  externalJobIds?: Array<Scalars['String']>;
  externalJobUrls?: Array<Scalars['String']>;
  hirerProfileIdsForBenchmarkRequests?: Array<Scalars['Int']>;
  hirerSettingsForBenchmarkRequests?: Array<HirerSettingsForBenchmarkRequestsInput>;
  hirerSettingsForCollaborators?: Array<HirerSettingsForCollaboratorsInput>;
  isCompanyTemplate?: Scalars['Boolean'];
  requisitionIds?: Array<Scalars['String']>;
  skillMaps?: Array<SkillMapWithTargetLevelsInput>;
};

export type CreateBenchmarkRequestsForBenchmarkInput = {
  benchmarkId: Scalars['Int'];
  hirerProfileIdsForBenchmarkRequests?: Array<Scalars['Int']>;
  hirerSettingsForBenchmarkRequests?: Array<HirerSettingsForBenchmarkRequestsInput>;
};

export type FetchAccessPermissionsPagingInput = {
  companyKey: Scalars['ID'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortDirection?: Scalars['String'];
  sortField?: Scalars['String'];
};

export type FetchBenchmarkByIdInput = {
  benchmarkId: Scalars['Int'];
};

export type FetchStandaloneBenchmarksForCompanyInput = {
  companyId: Scalars['Int'];
  createdByHirerId?: Scalars['Int'];
  keyword: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type GetOrCreateBenchmarkAndCreateBenchmarkRequestsInput = {
  hirerProfileIdsForBenchmarkRequests?: Array<Scalars['Int']>;
  positionId: Scalars['Int'];
};

export type UpdateBenchmarkInput = {
  benchmarkId: Scalars['Int'];
  benchmarkTitle?: Scalars['String'];
  externalJobIds?: Array<Scalars['String']>;
  externalJobUrls?: Array<Scalars['String']>;
  formComponentGroupKey?: Scalars['ID'];
  jobFamily?: Scalars['String'];
  jobLevel?: Scalars['String'];
  requisitionIds?: Array<Scalars['String']>;
  skillMaps?: Array<SkillMapWithTargetLevelsInput>;
};
