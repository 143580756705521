import { gql } from '@apollo/client';
import { hirerProfileDetails } from './sharedApiConstants';

const hirerProfiles = `
hirerProfiles {
  ${hirerProfileDetails}
}
`;

export const HIRER_TEAM_FRAGMENT = gql`
  fragment hirerTeamDetails on HirerTeam {
    hirerTeamId
    teamName
    ${hirerProfiles}
  }
`;

export const CREATE_HIRER_TEAM = gql`
  mutation createHirerTeam($input: CreateHirerTeamInput!) {
    createHirerTeam(input: $input) {
      hirerTeamId
    }
  }
`;

export const UPDATE_HIRER_TEAM = gql`
  mutation updateHirerTeam($input: UpdateHirerTeamInput!) {
    updateHirerTeam(input: $input) {
      ...hirerTeamDetails
    }
  }
  ${HIRER_TEAM_FRAGMENT}
`;

export const FETCH_HIRER_TEAM_BY_ID = gql`
  query fetchHirerTeamById($input: HirerTeamIdInput!) {
    fetchHirerTeamById(input: $input) {
      hirerTeamId
      teamName
      ${hirerProfiles}
    }
  }
`;

export const FETCH_HIRER_TEAMS_PAGING = gql`
  query fetchHirerTeamsPaging($input: HirerTeamPagingInput!) {
    fetchHirerTeamsPaging(input: $input) {
      page
      pageSize
      totalCount
      data {
        hirerTeamId
        teamName
        ${hirerProfiles}
      }
    }
  }
`;
