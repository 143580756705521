import { ApolloCache, makeVar } from '@apollo/client';

import {
  FETCH_HIRER_TEAMS_PAGING,
  FETCH_HIRER_TEAM_BY_ID,
  HIRER_TEAM_FRAGMENT,
} from 'api/hirerTeam_api';
import { HirerTeam, HirerTeamPaging, HirerTeamPagingInput, Query } from 'types';
import { EmptyObject, KeyFieldsContext } from 'types/interfaces';
import { getPagingQueryField } from './helpers';

export const fetchHirerTeamsPagingVars = makeVar<
  Pick<HirerTeamPagingInput, 'companyId' | 'keyword'> | EmptyObject
>({});

export const updateHirerTeamInCache = (cache: ApolloCache<unknown>, hirerTeam: HirerTeam): void => {
  let { hirerTeamId } = hirerTeam;
  hirerTeamId = +hirerTeamId;
  const data = {
    ...hirerTeam,
    hirerTeamId,
  };

  cache.writeFragment({
    id: `HirerTeam_${hirerTeamId}`,
    fragment: HIRER_TEAM_FRAGMENT,
    data,
  });

  try {
    cache.writeQuery({
      query: FETCH_HIRER_TEAM_BY_ID,
      variables: {
        input: {
          hirerTeamId,
        },
      },
      data: {
        fetchHirerTeamById: data,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export const addHirerTeamToCache = (cache: ApolloCache<unknown>, hirerTeam: HirerTeam): void => {
  try {
    const input = fetchHirerTeamsPagingVars();
    const data = cache.readQuery({
      query: FETCH_HIRER_TEAMS_PAGING,
      variables: {
        input,
      },
    }) as Query;
    const { fetchHirerTeamsPaging } = data || {};
    cache.writeQuery({
      query: FETCH_HIRER_TEAMS_PAGING,
      variables: {
        input,
      },
      data: {
        ...fetchHirerTeamsPaging,
        data: [hirerTeam, fetchHirerTeamsPaging.data],
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export const fetchHirerTeamsPaging = getPagingQueryField<
  HirerTeam,
  HirerTeamPaging,
  HirerTeamPagingInput
>(['companyId', 'keyword'], 'hirerTeamId');

export default {
  keyFields: ({ hirerTeamId: id }: HirerTeam, { typename }: KeyFieldsContext): string =>
    `${typename}_${id}`,
  fields: {
    hirerTeamId: {
      read(hirerTeamId: string): number {
        return +hirerTeamId;
      },
    },
  },
};
