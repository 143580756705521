import get from 'lodash/get';
import { ApolloCache, makeVar } from '@apollo/client';

import {
  BENCHMARK_REQUEST_FRAGMENT,
  BENCHMARK_REQUEST_ON_BENCHMARK,
  FULL_POSITION_FRAGMENT,
  HIRER_POSITION_RELATION_DETAILS_FRAGMENT,
} from 'api/fragments';
import { FETCH_BENCHMARK_REQUESTS_CREATED_BY_ME } from 'api/benchmarks_api';
import {
  Query,
  Position,
  BenchmarkRequest,
  Benchmark,
  FetchBenchmarkRequestsCreatedByMeInput,
  FetchBenchmarkRequestsForMeInput,
  BenchmarkRequestsPaging,
} from 'types';
import { EmptyObject, KeyFieldsContext } from 'types/interfaces';
import {
  getPagingQueryField,
  makeVarWithWindowUpdate,
  readAndWritePagindatedDataToCache,
} from './helpers';
import { benchmarkCacheRef } from './benchmark';

const _fetchBenchmarkRequestsCreatedByMeVar = makeVar<
  FetchBenchmarkRequestsCreatedByMeInput | EmptyObject
>({});

export const fetchBenchmarkRequestsCreatedByMeVar = makeVarWithWindowUpdate(
  _fetchBenchmarkRequestsCreatedByMeVar
);

export const _fetchBenchmarkRequestsForMeVar = makeVar<
  FetchBenchmarkRequestsForMeInput | EmptyObject
>({});

export const fetchBenchmarkRequestsForMeVar = makeVarWithWindowUpdate(
  _fetchBenchmarkRequestsForMeVar,
  ['isSubmitted']
);

export default {
  keyFields: (
    { benchmarkRequestId: id }: BenchmarkRequest,
    { typename }: KeyFieldsContext
  ): string => `${typename}_${id}`,
  fields: {
    benchmarkRequestId: {
      read(benchmarkRequestId: string): number {
        return +benchmarkRequestId;
      },
    },
  },
};

export const benchmarkRequestCacheRef = (
  benchmarkRequestId: string | number | void
): string | null => (benchmarkRequestId ? `BenchmarkRequest_${benchmarkRequestId}` : null);

export const updateBenchmarkRequest = (
  cache: ApolloCache<unknown>,
  data?: BenchmarkRequest
): void => {
  if (!data) {
    return;
  }
  const id = benchmarkRequestCacheRef(get(data, 'benchmarkRequestId'));
  id &&
    cache.writeFragment({
      id,
      fragment: BENCHMARK_REQUEST_FRAGMENT,
      data,
    });
};

// BenchmarkRequest
export const addOrDeleteBenchmarkRequest = (
  cache: ApolloCache<Query>,
  benchmarkRequest: BenchmarkRequest,
  positionId: number,
  toDelete?: boolean
): void => {
  const {
    benchmarkRequestId,
    benchmarkId,
    positions: benchmarkRequestPositions,
  } = benchmarkRequest;
  const positions: Pick<Position, 'positionId'>[] =
    benchmarkRequestPositions || (positionId ? [{ positionId }] : []);
  const id = benchmarkRequestCacheRef(benchmarkRequestId);
  const benchmarkCacheId = benchmarkCacheRef(benchmarkId);
  if (!id) {
    return;
  }

  if (toDelete) {
    cache.evict({
      id,
    });
  } else {
    cache.writeFragment({
      id,
      fragment: BENCHMARK_REQUEST_FRAGMENT,
      data: benchmarkRequest,
    });
  }

  // Update on benchmark
  if (benchmarkCacheId) {
    try {
      const benchmarkFragment = cache.readFragment({
        id: benchmarkCacheId,
        fragment: BENCHMARK_REQUEST_ON_BENCHMARK,
        fragmentName: 'benchmarkBenchmarkRequests',
      });
      const { benchmarkRequests = [] } = benchmarkFragment as Benchmark;
      cache.writeFragment({
        id: benchmarkCacheId,
        fragment: BENCHMARK_REQUEST_ON_BENCHMARK,
        fragmentName: 'benchmarkBenchmarkRequests',
        data: {
          benchmarkRequests: toDelete
            ? benchmarkRequests.filter(r => r.benchmarkRequestId !== benchmarkRequestId)
            : [benchmarkRequest, ...benchmarkRequests],
        },
      });
    } catch (e) {}
  }
  // Update on positions
  positions.forEach(({ positionId }) => {
    try {
      const positionFragment = cache.readFragment({
        id: `Position_${positionId}`,
        fragment: FULL_POSITION_FRAGMENT,
        fragmentName: 'positionDetails',
      });
      const { benchmarkRequests = [] } = positionFragment as Position;
      cache.writeFragment({
        id: `Position_${positionId}`,
        fragment: FULL_POSITION_FRAGMENT,
        fragmentName: 'positionDetails',
        data: {
          benchmarkRequests: toDelete
            ? benchmarkRequests.filter(r => r.benchmarkRequestId !== benchmarkRequestId)
            : [benchmarkRequest, ...benchmarkRequests],
        },
      });
    } catch (e) {}
    const hprFragment = cache.readFragment({
      id: `Position_${positionId}`,
      fragment: FULL_POSITION_FRAGMENT,
      fragmentName: 'positionDetails',
    });
    const { hirerPositionRelations = [] } = (hprFragment as Position) || {};

    hirerPositionRelations.forEach(i => {
      try {
        if (i.benchmarkRequest && +i.benchmarkRequest.benchmarkRequestId === +benchmarkRequestId) {
          cache.writeFragment({
            id: `HirerPositionRelation_${i.hirerPositionRelationId}`,
            fragment: HIRER_POSITION_RELATION_DETAILS_FRAGMENT,
            data: {
              benchmarkRequest: toDelete ? null : benchmarkRequest,
            },
          });
        }
      } catch (e) {}
    });
  });
};

export const fetchBenchmarkRequestsForMe = getPagingQueryField<
  BenchmarkRequest,
  BenchmarkRequestsPaging,
  FetchBenchmarkRequestsForMeInput
>(['isSubmitted', 'companyId', 'keyword'], 'benchmarkRequestId');

export const fetchBenchmarkRequestsCreatedByMe = getPagingQueryField<
  BenchmarkRequest,
  BenchmarkRequestsPaging,
  FetchBenchmarkRequestsCreatedByMeInput
>(['companyId', 'keyword'], 'benchmarkRequestId');

export function updateFetchBenchmarkRequestsCreatedByMe(
  cache: ApolloCache<unknown>,
  benchmarkOrBenchmarkRequestIdToDelete: BenchmarkRequest | string | number
): void {
  const { keyword, companyId } = fetchBenchmarkRequestsCreatedByMeVar();
  const input = {
    keyword,
    companyId,
  };
  const shouldDelete =
    typeof benchmarkOrBenchmarkRequestIdToDelete === 'string' ||
    typeof benchmarkOrBenchmarkRequestIdToDelete === 'number';
  readAndWritePagindatedDataToCache(
    input,
    shouldDelete
      ? benchmarkOrBenchmarkRequestIdToDelete.toString()
      : [benchmarkOrBenchmarkRequestIdToDelete as BenchmarkRequest],
    FETCH_BENCHMARK_REQUESTS_CREATED_BY_ME,
    cache,
    'benchmarkRequestId'
  );
}
