import get from 'lodash/get';
import { ApolloCache, FieldFunctionOptions, makeVar } from '@apollo/client';

import { mergePaginatedData, readAndWritePagindatedDataToCache } from './helpers';
import { BENCHMARK_COLLABORATORS } from 'api/fragments';
import {
  BenchmarkCollaborator,
  FetchBenchmarkCollaboratorsPagingInput,
  BenchmarkCollaboratorsPaging,
} from 'types';
import { EmptyObject } from 'types/interfaces';
import { FETCH_BENCHMARK_COLLABORATORS_PAGING } from 'api/collaborators_api';

export const fetchBenchmarkCollaboratorsPagingInputVar = makeVar<
  FetchBenchmarkCollaboratorsPagingInput | EmptyObject
>({});

export default {
  keyFields: (
    { benchmarkCollaboratorKey: id }: BenchmarkCollaborator,
    {
      typename,
    }: {
      typename: string;
    }
  ): string => `${typename}_${id}`,
};

export const benchmarkCollaboratorKeyCacheRef = (benchmarkCollaboratorKey: string): string =>
  `BenchmarkCollaborator_${benchmarkCollaboratorKey}`;

export const updateBenchmarkCollaborator = (
  cache: ApolloCache<unknown>,
  data?: Partial<BenchmarkCollaborator>
): void => {
  if (!data) {
    return;
  }
  const id = benchmarkCollaboratorKeyCacheRef(get(data, 'benchmarkRequestCommentKey'));

  cache.writeFragment<Partial<BenchmarkCollaborator>>({
    id,
    fragment: BENCHMARK_COLLABORATORS,
    data,
  });
};

export const fetchBenchmarkCollaboratorsPaging = {
  keyArgs: ['input', ['benchmarkId', 'keyword']],
  merge(
    existing: BenchmarkCollaboratorsPaging,
    incoming: BenchmarkCollaboratorsPaging,
    options: FieldFunctionOptions
  ): BenchmarkCollaboratorsPaging {
    if (existing) {
      return {
        ...incoming,
        data: mergePaginatedData<BenchmarkCollaborator>(
          'benchmarkCollaboratorKey',
          existing.data,
          incoming.data,
          options
        ),
      };
    }
    return incoming;
  },
};

export const addBenchmarkCollaboratorToCache = (
  cache: ApolloCache<unknown>,
  data: Partial<BenchmarkCollaborator>[]
): void => {
  const { benchmarkId, keyword } = fetchBenchmarkCollaboratorsPagingInputVar();
  readAndWritePagindatedDataToCache(
    {
      benchmarkId,
      keyword,
    },
    data,
    FETCH_BENCHMARK_COLLABORATORS_PAGING,
    cache
  );
};

export const deleteBenchmarkCollaboratorFromCache = (
  cache: ApolloCache<unknown>,
  benchmarkCollaboratorKey: string
): void => {
  const { benchmarkId, keyword } = fetchBenchmarkCollaboratorsPagingInputVar();
  cache.evict({
    id: benchmarkCollaboratorKeyCacheRef(benchmarkCollaboratorKey),
  });
  readAndWritePagindatedDataToCache(
    {
      benchmarkId,
      keyword,
    },
    benchmarkCollaboratorKeyCacheRef(benchmarkCollaboratorKey),
    FETCH_BENCHMARK_COLLABORATORS_PAGING,
    cache,
    'benchmarkCollaboratorKey'
  );
};
