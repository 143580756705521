import { ApolloCache, makeVar } from '@apollo/client';

import {
  AutomationSetting,
  FetchHrisGroupsForCompanyPagingInput,
  HrisGroup,
  HrisGroupsPaging,
  MakeRequired,
} from 'types';
import { EmptyObject } from 'types/interfaces';
import { FULL_POSITION_FRAGMENT as AUTOMATION_SETTING_FRAGMENT } from 'api/fragments';
import { getPagingQueryField } from './helpers';

export default {
  keyFields: (
    { automationSettingKey: id }: AutomationSetting,
    {
      typename,
    }: {
      typename: string;
    }
  ): string => `${typename}_${id}`,
};

export const fetchHrisGroupsForCompanyPagingInputVar = makeVar<
  FetchHrisGroupsForCompanyPagingInput | EmptyObject
>({});

export const automationSettingKeyCacheRef = (automationSettingKey: string): string =>
  `AutomationSetting_${automationSettingKey}`;

export const updateAutomationSetting = (
  cache: ApolloCache<unknown>,
  data: MakeRequired<AutomationSetting, 'automationSettingKey'> | void
): void => {
  if (!data) {
    return;
  }
  try {
    cache.writeFragment({
      id: automationSettingKeyCacheRef(data.automationSettingKey),
      fragment: AUTOMATION_SETTING_FRAGMENT,
      data,
    });
  } catch (e) {}
};

export const fetchHrisGroupsForCompanyPaging = getPagingQueryField<
  HrisGroup,
  HrisGroupsPaging,
  FetchHrisGroupsForCompanyPagingInput
>(['companyId', 'keyword'], 'hrisGroupKey');
