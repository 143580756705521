import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { OrderedSubsectionsEntity, TransformedAnswerBody } from 'components/SurveyExperience/types';

interface SurveyState {
  localAnswers: Record<string, TransformedAnswerBody>;
  shouldRedirectToCandidateExperience: boolean;
  setLocalAnswer: (subsectionQuestionRelationKey: string, answer: TransformedAnswerBody) => void;
  setShouldRedirectToCandidateExperience: (shouldRedirectToCandidateExperience: boolean) => void;
  getLocalAnswersForPage: (
    subsection: OrderedSubsectionsEntity
  ) => Record<string, TransformedAnswerBody>;
  clearLocalAnswers: (keys?: string[]) => void;
}

const useSurveyStore = create<SurveyState>()(
  devtools(
    persist(
      (set, get) => ({
        localAnswers: {},
        shouldRedirectToCandidateExperience: false,
        setShouldRedirectToCandidateExperience: (shouldRedirectToCandidateExperience: boolean) =>
          set({ shouldRedirectToCandidateExperience }),
        setLocalAnswer: (subsectionQuestionRelationKey: string, answer: TransformedAnswerBody) =>
          set(state => ({
            localAnswers: {
              ...state.localAnswers,
              [subsectionQuestionRelationKey]: answer,
            },
          })),
        getLocalAnswersForPage: subsection => {
          const localAnswers = get().localAnswers;
          const sqrKeys = subsection.orderedQuestionsOnPage.map(
            ({ subsectionQuestionRelationKey }) => subsectionQuestionRelationKey
          );
          const localAnswersForSubsection = Object.keys(localAnswers)
            .filter(key => sqrKeys.includes(key))
            .reduce((obj, key) => {
              obj[key] = localAnswers[key];
              return obj;
            }, {} as Record<string, TransformedAnswerBody>);
          return localAnswersForSubsection;
        },
        clearLocalAnswers: (keys?: string[]) =>
          set(state => {
            if (!keys) {
              return { localAnswers: {} };
            }

            const cleanedLocalAnswers = Object.keys(state.localAnswers)
              .filter(key => !keys.includes(key))
              .reduce((obj, key) => {
                obj[key] = state.localAnswers[key];
                return obj;
              }, {} as Record<string, TransformedAnswerBody>);
            return {
              localAnswers: cleanedLocalAnswers,
            };
          }),
      }),
      { name: 'survey-storage' }
    )
  )
);

export { useSurveyStore };
