import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { FilterData } from 'types';

interface TalentModelFiltersState {
  filters: Record<string, FilterData[] | FilterData>;
  employeeCount: number;
  setEmployeeCount: (count: number) => void;
  addToFilters: (type: string, selectedFilters: FilterData[] | FilterData) => void;
  setFilters: (filters: Record<string, FilterData[] | FilterData>) => void;
  deleteFilter: (type: string) => void;
  deleteEverything: () => void;
}

// Generic Store Creator Function
const createTalentModelFilterStore = (storeName: string) =>
  create<TalentModelFiltersState>()(
    devtools(
      persist(
        set => ({
          filters: {},
          employeeCount: 0,
          deleteEverything: () => set({ filters: {}, employeeCount: 0 }),
          setEmployeeCount: (count: number) => set(() => ({ employeeCount: count })),
          addToFilters: (type: string, selectedFilters: FilterData[] | FilterData) =>
            set(state => ({
              filters: {
                ...state.filters,
                [`${type}`]: selectedFilters,
              },
            })),
          setFilters: (filters: Record<string, FilterData[] | FilterData>) =>
            set({
              filters,
            }),
          deleteFilter: (type: string) =>
            set(state => {
              const newFilters = { ...state.filters };
              delete newFilters[type];
              return {
                filters: newFilters,
              };
            }),
        }),
        { name: storeName }
      )
    )
  );

// Creating Specific Stores
const usePreHireTalentModelFilterStore = createTalentModelFilterStore(
  'pre-hire-talent-model-filters'
);

const usePostHireTalentModelFilterStore = createTalentModelFilterStore(
  'post-hire-talent-model-filters'
);

export { usePostHireTalentModelFilterStore, usePreHireTalentModelFilterStore };
