import { gql } from '@apollo/client';

export const FETCH_COMPANY_ROLES_FOR_COMPANY = gql`
  query fetchCompanyRolesForCompany($input: FetchCompanyRolesForCompanyPagingInput!) {
    fetchCompanyRolesForCompany(input: $input) {
      data {
        name
        companyRoleKey
        roleDescription
        accessPermissions {
          title
          accessPermissionKey
        }
      }
      totalCount
    }
  }
`;

export const CREATE_COMPANY_ROLE = gql`
  mutation createCompanyRole($input: NewCompanyRoleInput!) {
    createCompanyRole(input: $input) {
      companyRoleKey
      name
      roleDescription
      accessPermissions {
        accessPermissionKey
        title
        userType
        description
      }
    }
  }
`;

export const UPDATE_COMPANY_ROLE = gql`
  mutation updateCompanyRole($input: UpdateCompanyRoleInput!) {
    updateCompanyRole(input: $input) {
      companyRoleKey
      name
      roleDescription
      accessPermissions {
        accessPermissionKey
        title
        userType
        description
        createdAt
        updatedAt
      }
    }
  }
`;
