import get from 'lodash/get';

import { COMM_TYPE_FOR_COMPANY, COMPANY_COMM } from 'api/fragments';

export const commTypeForCompanyCacheRef = commTypeKey =>
  commTypeKey ? `CommTypeForCompany_${commTypeKey}` : null;

export const commTypeCacheRef = commTypeKey => (commTypeKey ? `CommType_${commTypeKey}` : null);

export const companyCommCacheRef = companyCommKey =>
  companyCommKey ? `CompanyComm_${companyCommKey}` : null;

export const updateCommTypeForCompany = (cache, data) => {
  if (!data) {
    return;
  }
  const { commType, ...companyComm } = data;
  const { companyCommKey } = companyComm;
  const commTypeKey = get(commType, 'commTypeKey');

  const commTypeForCompanyId = commTypeForCompanyCacheRef(commTypeKey);
  const companyCommId = companyCommCacheRef(companyCommKey);
  if (commTypeKey) {
    cache.writeFragment({
      id: commTypeForCompanyId,
      fragment: COMM_TYPE_FOR_COMPANY,
      data: {
        commTypeKey,
        companyCommKey,
        companyComm,
      },
    });
    cache.writeFragment({
      id: companyCommId,
      fragment: COMPANY_COMM,
      data: companyComm,
    });
  }
};

export default {
  keyFields: ({ commTypeKey: id }, { typename }) => `${typename}_${id}`,
};
