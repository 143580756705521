import { ApolloCache, makeVar } from '@apollo/client';

import {
  UpcomingInvitation,
  FetchUpcomingInvitationsPagingInput,
  UpcomingInvitationsPaging,
} from 'types';
import { EmptyObject } from 'types/interfaces';
import { UPCOMING_INVITATION_FRAGMENT } from 'api/fragments';
import { getPagingQueryField } from './helpers';

export const fetchUpcomingInvitationsPagingInputVar = makeVar<
  FetchUpcomingInvitationsPagingInput | EmptyObject
>({});

export default {
  keyFields: (
    { upcomingInvitationKey: id }: UpcomingInvitation,
    {
      typename,
    }: {
      typename: string;
    }
  ): string => `${typename}_${id}`,
};

export const upcomingInvitationKeyCacheRef = (upcomingInvitationKey: string): string =>
  `UpcomingInvitation_${upcomingInvitationKey}`;

export const updateUpcomingInvitation = (
  cache: ApolloCache<unknown>,
  data?: Partial<UpcomingInvitation>
): void => {
  if (!data) {
    return;
  }
  try {
    cache.writeFragment({
      id: upcomingInvitationKeyCacheRef(data.upcomingInvitationKey!),
      fragment: UPCOMING_INVITATION_FRAGMENT,
      fragmentName: 'upcomingInvitationDetails',
      data,
    });
  } catch (e) {}
};

export const fetchUpcomingInvitationsPaging = getPagingQueryField<
  UpcomingInvitation,
  UpcomingInvitationsPaging,
  FetchUpcomingInvitationsPagingInput
>(['automationSettingKey', 'keyword'], 'upcomingInvitationKey');
