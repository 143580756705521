import { InMemoryCache } from '@apollo/client';

import Answer, { QuestionAnswer } from './answer';
import AutomationSetting, { fetchHrisGroupsForCompanyPaging } from './automationSetting';
import UpcomingInvitation, { fetchUpcomingInvitationsPaging } from './upcomingInvitation';
import AutomatedInviteHistory, {
  fetchAutomatedInviteHistoryPaging,
} from './automatedInviteHistory';
import Benchmark, {
  fetchStandaloneBenchmarksForCompany,
  fetchSharedBenchmarksForMe,
} from './benchmark';
import BenchmarkCollaborator, { fetchBenchmarkCollaboratorsPaging } from './benchmarkCollaborator';
import BenchmarkRequest, {
  fetchBenchmarkRequestsForMe,
  fetchBenchmarkRequestsCreatedByMe,
} from './benchmarkRequest';
import BenchmarkRequestComment from './benchmarkRequestComment';
import CandidatePositionRelation, {
  fetchCPRsForPosition,
  candidatePositionRelationsForHirer,
} from './candidatePositionRelations';
import CompanyFeatureFlags from './companyFeatureFlags';
import Company from './company';
import CompanyRole from './companyRole';
import CommTypeForCompany from './commTypeForCompany';
import FormComponentGroup, { keywordSearchCompanyFormsPaging } from './formComponentGroup';
import HirerCompanyRelation, { hirerCompanyRelationsForCompany } from './hirerCompanyRelations';
import HirerProfile from './hirerProfile';
import HirerPositionRelation from './hirerPositionRelation';
import HirerTeam, { fetchHirerTeamsPaging } from './hirerTeam';
import QuestionComponent, { questionComponentsPaging } from './questionComponent';
import Permission from './permission';
import Position, {
  hirerReviewGroupsForCompanyPaging,
  searchKeywordPagingPositionsForHirer,
} from './position';
import { fetchReviewCycleRequestsForMe, hirerReviewCyclesForCompanyPaging } from './reviewCycles';
import PositionTemplate, { positionTemplatesForCompanyPaging } from './positionTemplate';
import SkillMap, { fetchPublicSkillMaps, fetchSkillMapsForCompany } from './skillMap';
import GreenhousePartner from './greenhousePartner';
import { WorkdayPartner, SrPartner } from './atsPartner';

export default new InMemoryCache({
  typePolicies: {
    AccessPermission: {
      keyFields: ({ accessPermissionKey: id }, { typename }) => `${typename}_${id}`,
    },
    Answer,
    AnswerOption: {
      keyFields: ({ answerOptionId: id }, { typename }) => `${typename}_${id}`,
      fields: {
        answerOptionId: {
          read(answerOptionId) {
            return +answerOptionId;
          },
        },
      },
    },
    AnswerOptionGroup: {
      keyFields: ({ answerOptionGroupId: id }, { typename }) => `${typename}_${id}`,
      fields: {
        answerOptionGroupId: {
          read(answerOptionGroupId) {
            return +answerOptionGroupId;
          },
        },
      },
    },
    AutomatedInviteHistory,
    AutomationSetting,
    Benchmark,
    BenchmarkCollaborator,
    BenchmarkRequest,
    BenchmarkRequestComment,
    BenchmarkSkillMapRelation: {
      fields: {
        benchmarkSkillMapRelationId: {
          read(benchmarkSkillMapRelationId) {
            return +benchmarkSkillMapRelationId;
          },
        },
      },
    },
    CandidateAssessment: {
      keyFields: ({ candidateAssessmentId: id }, { typename }) => `${typename}_${id}`,
      fields: {
        candidateAssessmentId: {
          read(candidateAssessmentId) {
            return +candidateAssessmentId;
          },
        },
      },
    },
    CandidateExperience: {
      keyFields: ({ candidateExperienceId: id }, { typename }) => `${typename}_${id}`,
      fields: {
        candidateExperienceId: {
          read(candidateExperienceId) {
            return +candidateExperienceId;
          },
        },
        candidateReferencerRelations: {
          merge(_exisiting, incoming) {
            return incoming;
          },
        },
      },
    },
    CandidateProfile: {
      keyFields: ({ candidateProfileId: id }, { typename }) => `${typename}_${id}`,
      fields: {
        candidateProfileId: {
          read(candidateProfileId) {
            return +candidateProfileId;
          },
        },
      },
    },
    CandidatePositionRelation,
    CandidateReferencerRelation: {
      keyFields: ({ candidateReferencerRelationId: id }, { typename }) => `${typename}_${id}`,
      fields: {
        candidateReferencerRelationId: {
          read(candidateReferencerRelationId) {
            return +candidateReferencerRelationId;
          },
          merge(_existing, incoming) {
            return incoming;
          },
        },
      },
    },
    CommTypeForCompany,
    Company,
    CompanyFeatureFlags,
    CompanyRole,
    ExperienceSection: {
      keyFields: ({ experienceSectionId: id }, { typename }) => `${typename}_${id}`,
      fields: {
        experienceSectionId: {
          read(experienceSectionId) {
            return +experienceSectionId;
          },
        },
      },
    },
    EmployeeReviewer: {
      keyFields: ({ employeeReviewerKey: id }, { typename }) => `${typename}_${id}`,
    },
    FormComponentGroup,
    GreenhousePartner,
    HirerCompanyRelation,
    HirerCompanyRole: {
      keyFields: ({ hirerCompanyRoleKey: id }, { typename }) => `${typename}_${id}`,
    },
    HirerPositionRelation,
    HirerProfile,
    HirerTeam,
    NotificationType: {
      keyFields: ['notificationTypeId'],
      fields: {
        notificationTypeId: {
          read(notificationTypeId) {
            return +notificationTypeId;
          },
        },
      },
    },
    Permission,
    Position,
    PositionTemplate,
    QohFactor: {
      keyFields: ['qohFactorKey'],
    },
    Query: {
      fields: {
        candidatePositionRelationsForHirer,
        fetchAutomatedInviteHistoryPaging,
        fetchBenchmarkCollaboratorsPaging,
        fetchBenchmarkRequestsCreatedByMe,
        fetchBenchmarkRequestsForMe,
        fetchCPRsForPosition,
        fetchHirerTeamsPaging,
        fetchHrisGroupsForCompanyPaging,
        fetchPublicSkillMaps,
        positionTemplatesForCompanyPaging,
        fetchReviewCycleRequestsForMe,
        fetchSharedBenchmarksForMe,
        fetchSkillMapsForCompany,
        fetchStandaloneBenchmarksForCompany,
        fetchUpcomingInvitationsPaging,
        hirerCompanyRelationsForCompany,
        hirerPositionRelations: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
        questionComponentsPaging,
        searchKeywordPagingPositionsForHirer,
        hirerReviewGroupsForCompanyPaging,
        keywordSearchCompanyFormsPaging,
        hirerReviewCyclesForCompanyPaging,
      },
    },
    QuestionAnswer,
    QuestionComponent,
    QuestionRating: {
      keyFields: ({ questionRatingId: id }, { typename }) => `${typename}_${id}`,
      fields: {
        questionRatingId: {
          read(questionRatingId) {
            return +questionRatingId;
          },
        },
      },
    },
    QuestionType: {
      keyFields: ({ questionTypeId: id }, { typename }) => `${typename}_${id}`,
      fields: {
        questionTypeId: {
          read(questionTypeId) {
            return +questionTypeId;
          },
        },
      },
    },
    ReferenceRequest: {
      keyFields: ({ referenceRequestId: id }, { typename }) => `${typename}_${id}`,
      fields: {
        referenceRequestId: {
          read(referenceRequestId) {
            return +referenceRequestId;
          },
        },
      },
    },
    ReviewCycle: {
      keyFields: ({ reviewCycleId: id }, { typename }) => `${typename}_${id}`,
      fields: {
        reviewCycleId: {
          read(reviewCycleId) {
            return +reviewCycleId;
          },
        },
      },
    },
    Reviewee: {
      keyFields: ({ revieweeId: id }, { typename }) => `${typename}_${id}`,
      fields: {
        revieweeId: {
          read(revieweeId) {
            return +revieweeId;
          },
        },
      },
    },
    ReferenceRelationType: {
      keyFields: ['referenceRelationTypeKey'],
      fields: {
        referenceRelationTypeId: {
          read(referenceRelationTypeId) {
            return +referenceRelationTypeId;
          },
        },
      },
    },
    SectionComponent: {
      keyFields: ['sectionComponentId'],
      fields: {
        sectionComponentId: {
          read(sectionComponentId) {
            return +sectionComponentId;
          },
        },
      },
    },
    SectionComponentRelation: {
      keyFields: ['sectionComponentRelationId'],
      fields: {
        sectionComponentRelationId: {
          read(sectionComponentRelationId) {
            return +sectionComponentRelationId;
          },
        },
      },
    },
    SkillMap,
    SkillMapCell: {
      keyFields: ['skillMapCellId'],
      fields: {
        skillMapCellId: {
          read(skillMapCellId) {
            return +skillMapCellId;
          },
        },
      },
    },
    SkillMapCompetency: {
      keyFields: ['skillMapCompetencyId'],
      fields: {
        skillMapCompetencyId: {
          read(skillMapCompetencyId) {
            return +skillMapCompetencyId;
          },
        },
      },
    },
    SkillMapLevel: {
      keyFields: ['skillMapLevelId'],
      fields: {
        skillMapLevelId: {
          read(skillMapLevelId) {
            return +skillMapLevelId;
          },
        },
      },
    },
    SrPartner,
    StatusType: {
      fields: {
        statusTypeId: {
          read(statusTypeId) {
            return +statusTypeId;
          },
        },
      },
    },
    SubsectionComponent: {
      keyFields: ['subsectionComponentId'],
      fields: {
        subsectionComponentId: {
          read(subsectionComponentId) {
            return +subsectionComponentId;
          },
        },
      },
    },
    SubsectionQuestionRelation: {
      keyFields: ['subsectionQuestionRelationId'],
      fields: {
        subsectionQuestionRelationId: {
          read(subsectionQuestionRelationId) {
            return +subsectionQuestionRelationId;
          },
        },
      },
    },
    UpcomingInvitation,
    User: {
      keyFields: ({ userId: id }, { typename }) => `${typename}_${id}`,
      fields: {
        userId: {
          read(userId) {
            return +userId;
          },
        },
      },
    },
    WorkdayPartner,
  },
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'LeverPartner':
        return `${object.__typename}_${object.leverPartnerId}`;
      case 'CandidateProfileAndScores':
        return `${object.__typename}_${object.candidateProfile.candidateProfileId}`;
      case 'CandidateProfileAndReferenceRequests':
        return `${object.__typename}_${object.candidateProfile.candidateProfileId}`;
      case 'ReferenceRelationType':
        return `${object.__typename}_${object.referenceRelationTypeId}`;
      case 'PositionReferenceRequirement':
        return `${object.__typename}_${object.positionReferenceRequirementId}`;
      case 'CompanyFeatureFlags':
        return `${object.__typename}_${object.companyId}`;
      case 'CPRAttributes':
        return `${object.__typename}_${object.cprAttributeId}`;
      case 'Attributes':
        return `${object.__typename}_${object.attributeId}`;
      case 'Recommendations':
        return `${object.__typename}_${object.recommendationId}`;
      case 'RecommendationCompanies':
        return `${object.__typename}_${object.recommendationCompanyId}`;
      case 'RecommendationCompany':
        return `${object.__typename}_${object.recommendationCompanyId}`;
      case 'ReferencerLinkedinUploads':
        return `${object.__typename}_${object.referencerLinkedinUploadId}`;
      case 'AggregatedStats':
        return `${object.__typename}_${object.companyId}`;
      case 'AnalyticsHubCandidates':
        return `${object.__typename}_${object.companyId}`;
      case 'QuestionRating':
        return `${object.__typename}_${object.questionComponentId}`;
      case 'CPRForAccountCreationType':
        return `${object.__typename}_${object.candidatePositionRelationId}`;
      default:
        return object.id || object._id; // fall back to `id` and `_id` for all other types
    }
  },
});
