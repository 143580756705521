import { ApolloCache, makeVar } from '@apollo/client';

import {
  ReferenceRequest,
  PagingReferenceRequests,
  FetchReviewRequestsForMePagingInput,
  ReviewCycle,
  ReviewCyclePaging,
  HirerReviewCyclesForCompanyInput,
} from 'types';
import { EmptyObject } from 'types/interfaces';
import { REVIEW_CYCLES_FOR_COMPANY_PAGING } from 'api/reviewCycles_api';
import {
  getPagingQueryField,
  makeVarWithWindowUpdate,
  readAndWritePagindatedDataToCache,
} from './helpers';

const inputVar = makeVar<EmptyObject | HirerReviewCyclesForCompanyInput>({});

export const hirerReviewCyclesForCompanyPagingInputVar = makeVarWithWindowUpdate(inputVar, [
  'companyKey',
]);

export const fetchReviewCycleRequestsForMe = getPagingQueryField<
  ReferenceRequest,
  PagingReferenceRequests,
  FetchReviewRequestsForMePagingInput
>(['keyword'], 'referenceRequestId');

export const hirerReviewCyclesForCompanyPaging = getPagingQueryField<
  ReviewCycle,
  ReviewCyclePaging,
  HirerReviewCyclesForCompanyInput
>(['companyKey', 'keyword', 'sortField', 'sortOrder'], 'reviewCycleId');

export const addReviewCycleForCompanyToQuery = (
  cache: ApolloCache<unknown>,
  data?: ReviewCycle
): void => {
  if (!data) {
    return;
  }

  const { companyKey, keyword } = hirerReviewCyclesForCompanyPagingInputVar();

  readAndWritePagindatedDataToCache(
    {
      companyKey,
      keyword,
    },
    [data],
    REVIEW_CYCLES_FOR_COMPANY_PAGING,
    cache,
    'reviewCycleId'
  );
};
