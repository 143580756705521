import { ApolloClient, ApolloLink, HttpLink, concat, split, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import localRateLimiting from 'util/localRateLimiting';

const hasuraApiUrl =
  process.env.REACT_APP_API === 'local'
    ? 'http://localhost:3003/v1/graphql/'
    : `${process.env.REACT_APP_HASURA_API as string}/v1/graphql`;

const hasuraWsLink = new WebSocketLink({
  uri:
    process.env.REACT_APP_API === 'local'
      ? 'ws://localhost:3003/v1/graphql'
      : `${(process.env.REACT_APP_HASURA_API as string)?.replace('https', 'wss')}`,
  options: {
    reconnect: true,
    lazy: true,
    inactivityTimeout: 5 * 1000 * 60,
    connectionParams: () => ({
      authorization: `Bearer ${localStorage.getItem('token') || null}`,
    }),
  },
});

// For IE, we need to specify fetch (from js-polyfill)
const hasuraHttpLink = new HttpLink({
  uri: hasuraApiUrl,
  fetch: global.fetch,
});

const hasuraSplitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    localRateLimiting.increment();
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  hasuraWsLink,
  hasuraHttpLink
);

const hasuraAuthMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${localStorage.getItem('token') || null}`,
    },
  });

  return forward(operation);
});

export const hasuraClient = new ApolloClient({
  link: concat(hasuraAuthMiddleware, hasuraSplitLink),
  cache: new InMemoryCache({
    typePolicies: {
      CultureDefinitions: {
        keyFields: ({ cultureDefinitionKey: id }, { typename }) => `${typename}_${id}`,
      },
      Benchmarks: {
        keyFields: ({ benchmarkKey: id }, { typename }) => `${typename}_${id}`,
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first',
    },
  },
});
