import { ApolloCache } from '@apollo/client';
import get from 'lodash/get';

import {
  Company,
  CompanyAtsPartners,
  NotificationEvent,
  Query,
  QueryAtsPartnersForCompanyInput,
  QueryQueryAtsPartnersForCompanyArgs,
} from 'types';
import { KeyFieldsContext } from 'types/interfaces';
import { QUERY_ATS_PARTNERS_FOR_COMPANY } from 'api/ats_api';
import { UPDATED_COMPANY_FRAGMENT } from 'api/fragments';

export const companyCacheRef = (companyId: string | number): string =>
  companyId ? `Company_${companyId}` : '';

export function updateCompanyCache(cache: ApolloCache<unknown>, data?: Company): void {
  const id = companyCacheRef(get(data, 'companyId') as number);
  id &&
    cache.writeFragment({
      id,
      fragment: UPDATED_COMPANY_FRAGMENT,
      data,
    });
}

export default {
  keyFields: ({ companyId: id }: Company, { typename }: KeyFieldsContext): string =>
    `${typename}_${id}`,
  fields: {
    companyId: {
      read(companyId: string): number {
        return +companyId;
      },
    },
    notificationEvents: {
      merge(_existing: NotificationEvent[], incoming: NotificationEvent[]): NotificationEvent[] {
        return incoming;
      },
    },
  },
};

export function updateQueryAtsPartnersForCompany(
  cache: ApolloCache<unknown>,
  input: QueryAtsPartnersForCompanyInput,
  data: Partial<CompanyAtsPartners>
): void {
  try {
    const cachedQueryAtsPartnersForCompany = cache.readQuery<
      Pick<Query, 'queryAtsPartnersForCompany'>,
      QueryQueryAtsPartnersForCompanyArgs
    >({
      query: QUERY_ATS_PARTNERS_FOR_COMPANY,
      variables: {
        input,
      },
    });

    if (cachedQueryAtsPartnersForCompany) {
      cache.writeQuery({
        query: QUERY_ATS_PARTNERS_FOR_COMPANY,
        variables: { input },
        data: {
          queryAtsPartnersForCompany: {
            ...cachedQueryAtsPartnersForCompany.queryAtsPartnersForCompany,
            ...data,
          },
        },
      });
    }
  } catch (e) {}
}
