class LocalRateLimiting {
  private queryCount: number;
  constructor() {
    this.queryCount = 0;

    process.env.NODE_ENV === 'development' &&
      setInterval(() => {
        if (this.queryCount > 20) {
          throw new Error(`Called the api ${this.queryCount} times in less than 1 second`);
        }
        this.setQueryCount(0);
      }, 1000);
  }

  increment = (): void => {
    this.queryCount++;
  };

  setQueryCount = (count: number): void => {
    this.queryCount = count;
  };
}

export default new LocalRateLimiting();
