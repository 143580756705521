export const baseHasuraApiUrl =
  process.env.REACT_APP_API === 'local'
    ? 'http://localhost:5000/hasura/v1'
    : `${process.env.REACT_APP_API as string}/hasura/v1`;

const condition = `
  condition {
    numRequired
    referenceRelationTypeKey
    referenceRelationType {
      referenceRelationTypeKey
      typeName
    }
  }
`;

export function getReferenceRequirementSyntax(depth = 1): string {
  if (depth === 0) {
    return '';
  }

  return `
    arguments {
      __typename
      ... on RefRequirementConditionArgument {
        operator
        ${condition}
      }

      ... on RefRequirementSyntaxNode {
        operator

        ${getReferenceRequirementSyntax(depth - 1)}
      }
    }
  `;
}
