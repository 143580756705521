import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { FilterData } from 'types/apollo';

interface TalentModelBuilderStore {
  filters: Record<string, FilterData[] | FilterData>;
  employeeCount: number;
  setEmployeeCount: (count: number) => void;
  // 👋 this can probably be done with a set, but for testing purposes I'm doing it with []
  // we should just plug another logic here if needed, and the component will behave the same
  addToFilters: (type: string, selectedFilters: FilterData[] | FilterData) => void;
  setFilters: (filters: Record<string, FilterData[] | FilterData>) => void;
  deleteFilter: (type: string) => void;
  deleteEverything: () => void;
}

const useTalentModelBuilderStore = create<TalentModelBuilderStore>()(
  devtools(
    persist(
      set => ({
        filters: {},
        employeeCount: 0,
        setEmployeeCount: (count: number) => set(() => ({ employeeCount: count })),
        addToFilters: (type: string, selectedFilters: FilterData[] | FilterData) =>
          set(state => ({
            filters: {
              ...state.filters,
              [`${type}`]: selectedFilters,
            },
          })),
        setFilters: (filters: Record<string, FilterData[] | FilterData>) =>
          set({
            filters,
          }),
        deleteFilter: (type: string) =>
          set(state => {
            const newFilters = state.filters;
            delete newFilters[type];
            return {
              filters: newFilters,
            };
          }),
        deleteEverything: () => set({ filters: {}, employeeCount: 0 }),
      }),
      { name: 'talent-model-builder-storage' }
    )
  )
);

export { useTalentModelBuilderStore };
