import {
  ApolloCache,
  DocumentNode,
  FieldFunctionOptions,
  FieldMergeFunction,
  FieldReadFunction,
  ReactiveVar,
  StoreObject,
} from '@apollo/client';
import { getOperationName, Reference } from '@apollo/client/utilities';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { Maybe, PagedResults, PagingData, Query } from 'types';
import { PagedData } from 'types/interfaces';
import { setWindowState } from 'components/SharedFunctions';

export function mergePaginatedData<T extends StoreObject>(
  id: keyof T,
  existing: Array<T>,
  incoming: Array<T>,
  { readField, mergeObjects }: FieldFunctionOptions
): Array<T> {
  const merged: T[] = existing ? existing.slice(0) : [];
  const objectIdToIndex: Record<string, number> = {};
  if (existing) {
    existing.forEach((item, index) => {
      objectIdToIndex[readField<string>(id as string, item) as string] = index;
    });
  }
  incoming.forEach(item => {
    const name = readField<string>(id as string, item) as string;
    const index = objectIdToIndex[name];
    if (typeof index === 'number') {
      // Merge the new author data with the existing author data.
      merged[index] = mergeObjects(merged[index], item);
    } else {
      // First time we've seen this author in this array.
      objectIdToIndex[name] = merged.length;
      merged.push(item);
    }
  });

  return merged;
}

export function makeVarWithWindowUpdate<T>(inputVar: ReactiveVar<T>, keysToIgnore?: string[]) {
  return (args?: T): T => {
    if (args) {
      setWindowState(args, keysToIgnore);
      return inputVar(args);
    }
    return inputVar();
  };
}

export function readAndWritePagindatedDataToCache(
  input: unknown,
  data: Array<Reference | StoreObject> | string,
  query: DocumentNode,
  cache: ApolloCache<unknown>,
  idKey = ''
): void {
  const queryName = getOperationName(query) as keyof Query;
  const cachedData = cache.readQuery<Query>({
    query,
    variables: {
      input,
    },
  });
  if (cachedData) {
    const { [queryName]: pagedData } = cachedData as unknown as {
      [key: string]: PagedData<unknown>;
    };
    const toDelete = typeof data === 'string';
    const refs = Array.isArray(data)
      ? data.map(d => cache.identify(d)).map((__ref, idx) => (__ref ? { __ref } : data[idx]))
      : data;

    cache.writeQuery({
      query,
      variables: { input },
      data: {
        [queryName]: {
          ...pagedData,
          totalCount: (pagedData?.totalCount || 0) + (toDelete ? -1 : data.length),
          data: toDelete
            ? pagedData.data.filter((item: unknown) => get(item, idKey, '').toString() !== data)
            : (refs as unknown[]).concat(pagedData.data),
        },
      },
    });
  }
}

export function unwrapObject(
  refOrItem: Readonly<StoreObject> | Reference,
  fieldFunctionOptions: FieldFunctionOptions,
  depth = 3
): StoreObject {
  const { cache, readField, isReference } = fieldFunctionOptions;
  if (isReference(refOrItem) && depth) {
    return unwrapObject(
      readField((refOrItem as Reference).__ref, cache.extract()) as Readonly<StoreObject>,
      fieldFunctionOptions,
      depth - 1
    );
  }
  return Object.keys(refOrItem).reduce((acc, key) => {
    const value = (refOrItem as Readonly<StoreObject>)[key];

    if (isReference(value) && depth) {
      acc[key] = unwrapObject(value as Reference, fieldFunctionOptions, depth - 1);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {} as StoreObject);
}

export function getPagingQueryField<
  K extends StoreObject,
  T extends PagedResults<K>,
  Q extends PagingData
>(
  keyArgs: (keyof Q)[] | keyof Q,
  id: keyof K
): {
  keyArgs: (string[] | string)[];
  merge: FieldMergeFunction;
  read: FieldReadFunction;
} {
  return {
    keyArgs: ['input', keyArgs as string[]],
    merge(incoming: T, existing: T, options: FieldFunctionOptions): T {
      if (incoming) {
        return {
          ...existing,
          data: mergePaginatedData<K>(id, incoming.data, existing.data, options),
        };
      }
      return existing;
    },
    read(existing: T, options: FieldFunctionOptions): Maybe<T> {
      if (existing) {
        const { args } = options;
        const { data, totalCount } = existing;
        const { page, pageSize, sortDirection, sortField, sortOrder } = args?.input || {};
        let tableData = [...data];
        let pageStart = (page - 1) * pageSize;

        if (sortField) {
          tableData.sort((a, b) => {
            const aFieldValue = get(a, sortField) || 0;
            const bFieldValue = get(b, sortField) || 0;
            const aValue =
              typeof aFieldValue === 'number' || isNaN(Date.parse(aFieldValue as string))
                ? aFieldValue
                : new Date(aFieldValue as string);
            const bValue =
              typeof bFieldValue === 'number' || isNaN(Date.parse(bFieldValue as string))
                ? bFieldValue
                : new Date(bFieldValue as string);
            const desc = (sortDirection || sortOrder) !== 'asc';
            if (typeof aValue === 'string' && typeof bValue === 'string') {
              if (aValue.toLowerCase() < bValue.toLowerCase()) {
                return desc ? -1 : 1;
              }
              return desc ? 1 : -1;
            }
            return desc ? +bValue - +aValue : +aValue - +bValue;
          });
        }
        // If we start loading data from the middle of the page (ie, page 2, but page 1 hasn't been loaded yet),
        // Then the starting index to slice the data will be different
        const total = Math.max(tableData.length, totalCount);
        const totalPages = Math.ceil(total / pageSize);
        if (page > 1 && page === totalPages && total % pageSize > 0) {
          pageStart = Math.max(0, tableData.length - (total % pageSize));
        } else if (isNil(data[pageStart])) {
          const numPages = Math.ceil(tableData.length / pageSize);
          pageStart = (numPages - 1) * pageSize;
        }
        tableData = tableData.slice(pageStart, pageStart + pageSize);

        return {
          ...existing,
          totalCount: total,
          data: tableData,
        };
      }
    },
  };
}
