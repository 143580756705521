import { gql } from '@apollo/client';

import { revieweeDetails, statusType } from './sharedApiConstants';

export const REVIEW_CYCLE_FRAGMENT = gql`
  fragment reviewCycleDetails on ReviewCycle {
    reviewCycleId
    reviewCycleKey
    isArchived
    name
    startDate
    endDate
    reviewGroups {
      positionId
    }
  }
`;

export const REVIEW_CYCLES_FOR_COMPANY = gql`
  query FetchReviewCyclesForCompany($input: FetchReviewCyclesForCompanyInput!) {
    fetchReviewCyclesForCompany(input: $input) {
      ...reviewCycleDetails
    }
  }
  ${REVIEW_CYCLE_FRAGMENT}
`;

export const REVIEW_CYCLES_FOR_COMPANY_PAGING = gql`
  query hirerReviewCyclesForCompanyPaging($input: HirerReviewCyclesForCompanyInput!) {
    hirerReviewCyclesForCompanyPaging(input: $input) {
      page
      pageSize
      totalCount
      data {
        ...reviewCycleDetails
      }
    }
  }
  ${REVIEW_CYCLE_FRAGMENT}
`;

export const CREATE_REVIEW_CYCLE_FOR_COMPANY = gql`
  mutation createReviewCycleForCompany($input: CreateReviewCycleInput!) {
    createReviewCycleForCompany(input: $input) {
      reviewCycleId
      reviewCycleKey
      name
      startDate
      endDate
    }
  }
`;

export const DELETE_REVIEWEE = gql`
  mutation deleteReviewee($input: DeleteRevieweeInput!) {
    deleteReviewee(input: $input) {
      revieweeId
    }
  }
`;

export const UPDATE_REVIEWEE = gql`
  mutation updateReviewee($input: UpdateRevieweeInput!) {
    updateReviewee(input: $input) {
      ${revieweeDetails}
    }
  }
`;

export const REVIEW_CYCLE_ON_COMPANY = gql`
  fragment reviewCycles on Company {
    companyId
    reviewCycles {
      ...reviewCycleDetails
    }
  }
  ${REVIEW_CYCLE_FRAGMENT}
`;

export const CREATE_REVIEWEE_FOR_REVIEW_CYCLE = gql`
  mutation createRevieweeForReviewCycle($input: CreateRevieweeInput!) {
    createRevieweeForReviewCycle(input: $input) {
      reviewCycleId
    }
  }
`;

export const FETCH_POSITIONS_FOR_REVIEW_CYCLE_PAGING = gql`
  query fetchPositionsForReviewCyclePaging($input: FetchReviewCyclesForCompanyPagingInput!) {
    fetchPositionsForReviewCyclePaging(input: $input) {
      page
      pageSize
      totalCount
      data {
        positionId
        positionTitle
        candidatePositionRelations {
          candidatePositionRelationId
        }
      }
    }
  }
`;

export const FETCH_REVIEW_CYCLES_FOR_ME = gql`
  query fetchReviewCyclesForMe {
    fetchReviewCyclesForMe {
      reviewCycleId
      reviewCycleKey
      name
      company {
        companyId
        companyName
        companyLogo
      }
    }
  }
`;

export const UPDATE_REVIEW_CYCLE_FOR_COMPANY = gql`
  mutation updateReviewCycle($input: UpdateReviewCycleInput!) {
    updateReviewCycle(input: $input) {
      ...reviewCycleDetails
    }
  }
  ${REVIEW_CYCLE_FRAGMENT}
`;

export const FETCH_REVIEWEES_FOR_REVIEW_CYCLE_PAGING = gql`
  query fetchRevieweesForReviewCyclePaging($input: FetchReviewCyclesForCompanyPagingInput!) {
    fetchRevieweesForReviewCyclePaging(input: $input) {
      page
      pageSize
      totalCount
      data {
        revieweeId
        revieweeKey
        firstName
        lastName
        workEmail
        performanceBucket
        reviewers {
          reviewerId
          email
          firstName
          lastName
          workRelationship {
            typeName
            referenceRelationTypeKey
          }
        }
        candidatePositionRelation {
          candidatePositionRelationId
          candidatePositionRelationKey
          candidateInvitedDate
          statusType {
            statusTypeId
            typeName
          }
          candidateAssessment {
            candidateAssessmentId
            candidateAssessmentKey
            invitedDate
            submissionDate
            statusType {
              statusTypeId
              typeName
            }
          }
          candidateProfile {
            candidateProfileId
          }
        }
        reviewCycle {
          reviewCycleId
          reviewGroups {
            positionId
            positionIdentifier
          }
        }
      }
    }
  }
`;

/* Candidate dashboard */
export const FETCH_REVIEW_CYCLE_REQUESTS_FOR_ME = gql`
  query fetchReviewCycleRequestsForMe($input: FetchReviewRequestsForMePagingInput!) {
    fetchReviewCycleRequestsForMe(input: $input) {
      offset
      limit
      totalCount
      data {
        createdAt
        submissionDate
        referenceRequestId
        uniqueCode
        ${statusType}
        reviewCycleId
        reviewCycleName
        candidateReviewee {
          revieweeId
          firstName
          lastName
        }
        draftAnswerGroup {
          answerGroupId
          answerGroupKey
        }
        referencerInvitedDate
        candidateReferencerRelation {
          candidateReferencerRelationId
          candidateExperience {
            candidateExperienceId
            experienceSection {
              experienceSectionId
              candidateProfile {
                candidateProfileId
                candidateEmail
                user {
                  userId
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_REFERENCER_REQUESTS_FOR_ME_PAGING = gql`
  query fetchReferenceRequestsForMe($input: FetchReferenceRequestsForMePagingInput!) {
    fetchReferenceRequestsForMe(input: $input) {
      offset
      limit
      totalCount
      data {
        createdAt
        submissionDate
        referenceRequestId
        uniqueCode
        statusType {
          typeName
        }
        referencerInvitedDate
        draftAnswerGroup {
          answerGroupId
          answerGroupKey
        }
        candidateReviewee {
          revieweeId
          firstName
          lastName
        }
        candidateReferencerRelation {
          candidateExperience {
            experienceSection {
              componentText
            }
          }
        }
        candidateReferencerRelation {
          candidateReferencerRelationId
          candidateExperience {
            candidateExperienceId
            experienceSection {
              experienceSectionId
              candidateProfile {
                candidateProfileId
                candidateEmail
                user {
                  userId
                  firstName
                  lastName
                }
              }
            }
          }
        }
        reviewCycleId
        reviewCycleName
      }
    }
  }
`;

export const HIRER_REVIEW_GROUPS_FOR_COMPANY_PAGING = gql`
  query hirerReviewGroupsForCompanyPaging($input: HirerReviewGroupsForCompanyInput!) {
    hirerReviewGroupsForCompanyPaging(input: $input) {
      totalCount
      data {
        positionId
        positionKey
        positionTitle

        reviewCycle {
          reviewCycleId
          reviewCycleKey
          name
        }
      }
    }
  }
`;
