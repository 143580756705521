import { makeVar } from '@apollo/client';
import {
  FormComponentGroup,
  KeywordSearchCompanyFormsPagingInput,
  FormComponentGroupPaging,
  AdminKeywordSearchFcGsPagingInput,
} from 'types';
import { EmptyObject, KeyFieldsContext } from 'types/interfaces';
import { getPagingQueryField, makeVarWithWindowUpdate } from './helpers';

const _keywordSearchCompanyFormsPagingInputVar = makeVar<
  KeywordSearchCompanyFormsPagingInput | EmptyObject
>({});

export const keywordSearchCompanyFormsPagingInputVar = makeVarWithWindowUpdate(
  _keywordSearchCompanyFormsPagingInputVar,
  ['fcgTypeName']
);

export const keywordSearchAdminCompanyFormsPagingInputVar = makeVarWithWindowUpdate(
  makeVar<AdminKeywordSearchFcGsPagingInput | EmptyObject>({})
);

export default {
  keyFields: (
    { formComponentGroupId: id }: FormComponentGroup,
    { typename }: KeyFieldsContext
  ): string => `${typename}_${id}`,
  fields: {
    formComponentGroupId: {
      read(formComponentGroupId: string): number {
        return +formComponentGroupId;
      },
    },
  },
};

export const keywordSearchCompanyFormsPaging = getPagingQueryField<
  FormComponentGroup,
  FormComponentGroupPaging,
  KeywordSearchCompanyFormsPagingInput
>(['fcgTypeName', 'companyId', 'keyword', 'isArchived'], 'formComponentGroupId');
