import { ApolloCache } from '@apollo/client';

import {
  AutomatedInviteHistory,
  AutomatedInviteHistoryPaging,
  MakeRequired,
  FetchAutomatedInviteHistoryPagingInput,
} from 'types';
import { AUTOMATED_INVITE_HISTORY } from 'api/fragments';
import { getPagingQueryField } from './helpers';

export default {
  keyFields: (
    { automatedInviteHistoryKey: id }: AutomatedInviteHistory,
    {
      typename,
    }: {
      typename: string;
    }
  ): string => `${typename}_${id}`,
};

export const automatedInviteHistoryKeyCacheRef = (automatedInviteHistoryKey: string): string =>
  `AutomatedInviteHistory_${automatedInviteHistoryKey}`;

export const fetchAutomatedInviteHistoryPaging = getPagingQueryField<
  AutomatedInviteHistory,
  AutomatedInviteHistoryPaging,
  FetchAutomatedInviteHistoryPagingInput
>(['automationSettingKey', 'status', 'keyword'], 'automatedInviteHistoryKey');

export const updateAutomatedInviteHistory = (
  cache: ApolloCache<unknown>,
  data?: MakeRequired<AutomatedInviteHistory, 'automatedInviteHistoryKey'>
): void => {
  if (!data) {
    return;
  }
  try {
    cache.writeFragment({
      id: automatedInviteHistoryKeyCacheRef(data.automatedInviteHistoryKey!),
      fragment: AUTOMATED_INVITE_HISTORY,
      data,
    });
  } catch (e) {}
};
