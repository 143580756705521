import { getReferenceRequirementSyntax } from './utils';

export const SKILL_MAP_LEVEL_DETAILS = `
  skillMapLevelId
  componentText
  description
  rank
`;

export const SKILL_MAP_CELL_DETAILS = `
  skillMapCellId
  skillMapCompetencyId
  componentText
  skillMapLevelId
`;

export const SKILL_MAP_RESPONSES = `
  skillMapCompetencyId
  skillMapCompetencyKey
  competencyText
  skillMapCellScore
  selectedSkillMapCellId
  exampleText
`;

const SKILL_MAP_COMPETENCY_DETAILS = `
  skillMapCompetencyId
  skillMapCompetencyKey
  componentText
  description
  skillMapId
  rank
`;

export const SKILL_MAP_DETAILS = `
  skillMapId
  componentText
  isPublic
  companyId
  scoreUnits
  description
  inUse
  isArchived
  skillMapLevels {
    ${SKILL_MAP_LEVEL_DETAILS}
  }

  skillMapCompetencies {
    ${SKILL_MAP_COMPETENCY_DETAILS}

    skillMapCells {
      ${SKILL_MAP_CELL_DETAILS}
    }
  }
  standardJobFamilyKey
  standardJobCareerTrackKey
`;

export const userDetails = `
  userId
  username
  firstName
  lastName
  primaryEmail
  isArchived
  candidateProfile {
    isReferenceCandidate
  }
`;

export const candidateProfileDetails = `
  candidateProfileId
  candidateEmail
  candidatePhoneNumber
  candidatePhoto
  isArchived
  isPublic
  vanityLinkText
  permissions
  user {
    ${userDetails}
  }
`;

export const hirerProfileDetails = `
  hirerProfileId
  hirerProfileKey
  hirerEmail
  hirerPhoneNumber
  hirerInfoPending
  isArchived
  userId

  user {
    ${userDetails}
  }

  hirerDisplayName @client
`;

export const behaviorScores = `
  behaviorScores {
    label
    subtitle
    compositeScore
    values {
      referenceRequestId
      score
    }
  }
`;

export const statusType = `
  statusType {
    statusTypeId
    typeName
  }
`;

const answerOptionDetails = `
  answerOptionId
  optionName
  subtitle
`;

export const behaviorProfileDetails = `
  firstAnswerOption {
    ${answerOptionDetails}
  }
  secondAnswerOption {
    ${answerOptionDetails}
  }
  firstAnswerOptionValue
  secondAnswerOptionValue
`;

export const questionType = `
  questionType {
    questionTypeId
    typeName
  }
`;

export const answerOptionGroupDetails = `
  answerOptionGroupId
  groupName
  answerOptions {
    answerOptionId
    optionName
    subtitle
  }
`;

export const answerOptionGroupType = `
  answerOptionGroup {
    ${answerOptionGroupDetails}
  }
`;

export const skillMapRelation = `
  targetSkillMapLevelIds
  skillMap {
    ${SKILL_MAP_DETAILS}
  }
`;

export const positionSkillMapRelations = `
  positionSkillMapRelations {
    positionSkillMapRelationId
    skillMapId
    ${skillMapRelation}
  }
`;

export const benchmarkSkillMapRelations = `
  benchmarkSkillMapRelations {
    benchmarkSkillMapRelationKey
    benchmarkSkillMapRelationId
    targetSkillMapLevelIds
    ${skillMapRelation}
  }
`;

export const cprSkillMapRelations = `
  skillMapRelations {
    cprSkillMapRelationId
    skillMapId
    ${skillMapRelation}
  }
`;

export const referenceRequestSkillMapRelations = `
  skillMapRelations {
    referenceRequestSkillMapRelationId
    skillMapId
    skillMap {
      ${SKILL_MAP_DETAILS}
    }
    targetSkillMapLevelIds
  }
`;

export const referenceRelationTypeDetails = `
	referenceRelationTypeId
	referenceRelationTypeKey
	typeName
`;

export const positionReferenceRequirementDetails = `
	positionReferenceRequirementId
	order
	numRequired
	referenceRelationType {
		${referenceRelationTypeDetails}
	}
`;

export const positionReferenceRequirements = `
  positionReferenceRequirements {
    ${positionReferenceRequirementDetails}
  }
`;

export const referenceRequirementSyntax = `
  operator

  ${getReferenceRequirementSyntax(3)}
`;

export const positionDetails = `
  positionId
  positionKey
  notifyCandidateWithUpdates
  subjectCanInviteRespondent
  sendAutomaticRemindersToSubjects
  hideRespondentName
  positionTitle
  numCandidatePositionRelations
  candidateViewFullReport
  positionIdentifier
  details
  isOpen
  isArchived
  companyId
  candidateInviteFromHirer
  referencerResponseDays
  validSurveySubmissionWindowDays
  numRequiredReferences
  formComponentGroupId
  externalJobId
  externalJobSourceId
  reviewCycleId
  candidateFormComponentGroup {
    formComponentGroupId
    componentText
  }
  externalJobSource {
    externalJobSourceId
    sourceName
  }
  formComponentGroup {
    formComponentGroupId
    componentText
  }
  referenceRequirementSyntax {
    ${referenceRequirementSyntax}
  }
  ${positionReferenceRequirements}
  ${positionSkillMapRelations}
`;

export const companyFeatureFlags = `
  companyId
  isGreenhousePartner
  isLeverPartner
  defaultPositionsPageEnabled
  showReferencerProspects
  reviewCyclesEnabled
  hasStandaloneICPs
  skillMapsEnabled
  candidateAssessmentsEnabled
  standalonePositionTemplatesEnabled
  companyCommsEnabled
  customizeFormsToSkillMaps
  isTrialCompany
  enableCustomSurveysByRelationship
  enableReviewSpecificComms
  analyticsDenyDefaultPreviewMode
  usePostHireDataForTalentModels
  useSearchlightScoreNumber
  talentModelBenchmarksDisabled
  demoJobFlowEnabled
  defaultMyTeamsAnalyticsToPreview
  searchlightScoreV2Enabled
  disableFreeTrialExpiration
  enableAtsApplicantFiltering
  enableSoftSkillsAnalytics
`;

export const fullCompanyDetails = `
  companyId
  companyKey
  companyName
  companyLogo
  companyAddress
  details
  slackEnabled
  whitelistedEmailDomain
  isAssessment360Company
  notificationTypeId
  isApiCompany
  notificationEvents {
    notificationEventId
    notificationEventName
  }
`;

export const companyDetails = `
  companyId
  companyKey
  companyName
  companyLogo
  slackEnabled
  notificationTypeId
  whitelistedEmailDomain
  showPositionTitleToCandidate
  companyAddress
  details
  workOsOrganizationId
`;

export const recommendationCompanyDetails = `
  recommendationCompanyId
  updatedAt
  candidateApproved
  companyName
  ecosystemCompanyId
`;

export const referenceRequestDetails = `
  referenceRequestId
  referencerInvitedDate
  submissionDate
  publicSummary {
    answerId
    answerBody
  }
  ${statusType}
  formComponentGroup {
    formComponentGroupId
    sectionComponents {
      sectionComponentId
      componentText
      subsectionComponents {
        subsectionComponentId
        questionComponents {
          questionComponentId
        }
      }
    }
  }
`;

export const submittedAnswerGroups = `
  answerGroupId
  submittedReferenceRequestId
  submissionDate
  answers {
    answerId
    answerBody
    subsectionQuestionRelation {
      subsectionQuestionRelationId
      subsectionQuestionRelationKey
      order
      isRequired
      questionComponent {
        questionComponentId
        componentText
        componentIdentifier
        ${questionType}
      }
    }
  }
`;

export const candidateReferencerRelationDetails = `
  candidateReferencerRelationId
  candidateExperienceId
  referencerTitle
  timeOverlap
  workRelationship
  referencerEmail
  referencerFirstName
  referencerLastName
  isArchived
  isPublic
  overlapStartDate
  overlapEndDate
  contextForReference
  hasRecommendation
  details
  referenceRequests {
    ${referenceRequestDetails}
    mostRecentlySubmittedAnswerGroup {
      ${submittedAnswerGroups}
    }
  }
  referencerProfile {
    referencerProfileId
    referencerEmail
    user {
      userId
      firstName
      lastName
    }
  }
  recommendation {
    recommendationId
    referencerProfile {
      referencerProfileId
      user {
        userId
        firstName
        lastName
      }
    }
    recommendationCompanies {
      ${recommendationCompanyDetails}
    }
  }
`;

export const candidateExperienceDetails = `
  candidateExperienceId
  order
  positionTitle
  companyName
  locationCity
  locationState
  locationCountry
  startMonth
  startYear
  endMonth
  endYear
  isPresent
  details
  reviewCycleId
  experienceType {
    experienceTypeId
    typeName
  }
  experienceSection {
    experienceSectionId
  }
  experienceDetails {
    experienceDetailId
    componentText
    order
  }
  candidateReferencerRelations {
    ${candidateReferencerRelationDetails}
    hasRecommendation
    recommendation {
      recommendationId
    }
  }
`;

export const candidateReferencerRelationWithExperienceDetails = `
  ${candidateReferencerRelationDetails}
  candidateExperience {
    ${candidateExperienceDetails}
  }
`;

export const experienceSectionDetails = `
  experienceSectionId
  componentText
  order
  candidateExperiences {
    ${candidateExperienceDetails}
  }
`;

export const CPR_ATTRIBUTE = `
  attributeId
  candidatePositionRelationId
  isTop
  referenceRequest {
    referenceRequestId
    submissionDate
    candidateReferencerRelation {
      candidateReferencerRelationId
      referencerFirstName
      referencerLastName
      workRelationship
    }
  }
  attribute {
    attributeId
    attributeName
    isStrength
    subtitle
  }
`;

export const predictivePerformance = `
  predictedPerformance {
    candidatePositionRelationKey
    label
    subtitle
  }
`;

export const candidatePositionRelationDetails = `
  candidatePositionRelationId
  candidatePositionRelationKey
  opportunityId
  candidateProfileId
  candidateInvitedDate
  candidateFirstName
  candidateLastName
  candidateEmail
  isArchived
  statusTypeId
  createdAt
  excitement_score
  percentile_score
  emailReminderDays
  competencies_score
  numReferencesCompleted
  numReferencesInvited
  positionId
  weighted_score
  searchlightScoreText
  searchlightScoreSubtitle
  ${predictivePerformance}
  ${statusType}
  candidateAssessment {
    candidateAssessmentId
    ${statusType}
  }
  referenceStatus {
    statusTypeId
    typeName
  }
  greenhouseRequest {
    greenhouseRequestId
    ghJobs {
      id
      name
    }
  }
  leverRequest {
    leverRequestId
    leverPostings {
      id
      name
    }
  }
  behavioralAssessmentScore {
    score
    targetScore
  }
`;

export const candidateShareInviteDetails = `
  candidateShareInviteId
  candidateShare {
    candidateShareId
    candidateProfileId
    referencerDetails {
      candidateReferencerRelationId
      referencerFirstName
      referencerLastName
      timeOverlap
      referencerTitle
      contextForReference
      companyName
      candidateRole
    }
  }
  inviteeCompany
  inviteeEmail
  inviteeFirstName
  inviteeLastName
  expiredDate
  invitedDate
  invitationCode
  verificationCode
  verifiedDate
`;

export const relationDetails = `
  candidateReferencerRelationId
  referencerTitle
  referencerFirstName
  referencerLastName
  referencerEmail
  referencerPhoneNumber
  timeOverlap
  workRelationship
  isArchived
  referenceRequests {
    referenceRequestId
    referencerInvitedDate
    statusType{
      typeName
    }
  }
  referencerProfile {
    referencerEmail
    referencerProfileId
    user {
      userId
      firstName
      lastName
    }
  }
`;

export const strengthAttributes = `
  strengthAttributes {
    attributeId
    attributeName
    subtitle
  }
`;

export const growthAreaAttributes = `
  growthAreaAttributes {
    attributeId
    attributeName
    subtitle
  }
`;

export const question = `
  subsectionQuestionRelationKey
  questionComponentKey
  questionText
  questionIdentifier
  subtitle
  questionType
  questionTypeKey
  questionSurveyInterface
  helpTexts {
    title
    description
  }
  answerMeta {
    __typename
    ... on TwoOptionSliderAnswerMeta {
      labelOneId
      labelOneTitle
      labelOneDescription
      labelOneCultureDefinition {
        name
        definition
      }

      labelTwoId
      labelTwoTitle
      labelTwoDescription
      labelTwoCultureDefinition {
        name
        definition
      }
    }
    ... on MultipleChoiceAnswerMeta {
      orderedAnswerOptions {
        answerOptionKey
        answerOptionId
        title
        subtitle
      }
    }
  }
  validations {
    required  
  }
`;

export const benchmarkRequest = `
  benchmarkRequestId
  benchmarkRequestKey
  formComponentGroupId
  formComponentGroupKey
  isCopyFromTemplate
  uniqueCode
  benchmarkId
  submissionDate
  sendReminders
  receivesNotifications
  hirerProfile {
    ${hirerProfileDetails}
    hirerCompanyRelations {
      hirerCompanyRelationId
      companyId
      permissions
    }
    hirerPositionRelations {
      hirerPositionRelationId
      positionId
    }
  }
  positions {
    positionId
    positionTitle
    company {
      companyId
      companyName
      companyLogo
    }
  }
  ${statusType}
  benchmark {
    benchmarkId
    benchmarkKey
    benchmarkTitle
    isStandalone
    benchmarkBehaviorProfile {
      ${behaviorProfileDetails}
    }
    company {
      companyId
      companyName
      companyLogo
    }
  }
  ${strengthAttributes}
  submittedAnswerGroups {
    answerGroupId
    submissionDate
  }
  draftAnswerGroup {
    answerGroupId
    answerGroupKey
  }
  mostRecentlySubmittedAnswerGroup {
    ${submittedAnswerGroups}
  }
`;

export const benchmark = `
  benchmarkId
  benchmarkKey
  formComponentGroupId
  createdByHirerId
  createdByHirer {
    ${hirerProfileDetails}
  }
  createdAt
  updatedAt
`;

export const hirerCompanyRelationDetails = `
	hirerCompanyRelationId
	permissions
	company {
		companyId
		companyName
    companyLogo
    companyKey
	}
	hirerProfile {
		${hirerProfileDetails}
	}
`;

export const hirerPositionRelations = `
  hirerPositionRelationId
  createdAt
  updatedAt
  isArchived
  details
  positionId
  hirerProfileId
  receivesReferenceNotificationEmails
`;

export const excludedCandidatePositionRelations = `
  candidatePositionRelationId
  hirerExcludedApplicationId
  isArchived
  hirerProfileId
`;

export const sectionComponentDetails = `
  sectionComponentId
  componentText
  componentIdentifier
  sectionComponentRelations {
    sectionComponentRelationId
    order
    subsectionComponent {
      subsectionComponentId
      componentText
      componentIdentifier
      subsectionQuestionRelations {
        order
        isRequired
        subsectionQuestionRelationId
        parentSubsectionQuestionRelationId
        isConditional
        conditionalAnswers
        questionComponent {
          questionComponentId
          componentText
          componentIdentifier
          subtitleText
          helpTexts {
            helpTextId
            title
            body
          }
          ${questionType}
          ${answerOptionGroupType}
        }
      }
    }
  }
`;

export const formComponentGroup = `
  formComponentGroupId
  formIdentifier
  componentText
  sectionComponents {
    ${sectionComponentDetails}
  }
`;

export const reviewerDetails = `
  reviewerId
  firstName
  lastName
  email
  workRelationship {
    ${referenceRelationTypeDetails}
  }
`;

export const revieweeDetails = `
  revieweeId
  firstName
  lastName
  workEmail
  companyLevel
  companyFunction
  companyTitle
  reviewCycleId
  reviewers {
    ${reviewerDetails}
  }
`;

export const hirerPositionRelationDetails = `
  hirerPositionRelationId
  receivesReferenceNotificationEmails
  positionId
  hirerProfileId
  hirerProfile {
    ${hirerProfileDetails}
  }
  position {
    positionId
    positionTitle
    positionIdentifier
    companyId
    company {
      companyId
      companyName
      companyLogo
    }
  }
  benchmarkRequest {
    ${benchmarkRequest}
  }
`;

export const submittedReferenceRequestDetails = `
  referenceRequestId
  referenceRequestKey
  adviceForManager
  details
  formComponentGroupId
  overlapMonths
  recencyMonths
  peerRankAnswer
  workRelationship
  referencerHasManagedBefore
  referencerExperienceYears
  referencerLinkedin
  submissionDate
  expirationDate
  referencerInvitedDate
  submissionIpMatchesInvitationIp
  invitationIp
  submissionIp
  submittedSkillMapResponses {
    ${SKILL_MAP_RESPONSES}
    score
  }
  publicSummary {
    answerId
    answerBody
  }
  candidateReferencerRelation {
    candidateReferencerRelationId
    overlapStartDate
    overlapEndDate
    timeOverlap
    referencerTitle
    workRelationship
    contextForReference
    details
    referencerFirstName
    referencerLastName
    referencerProfile {
      referencerProfileId
      referencerAddress
      referencerEmail
      user {
      userId
      firstName
      lastName
      }
    }
    candidateExperience {
      candidateExperienceId
      positionTitle
      companyName
    }
  }
  submittedAnswerGroups {
    answerGroupId
    submissionDate
  }
  checklistSubmission {
    checklistAnswers {
      title
      subtitle
      status
      order
      questionComponentKey
    }
    percentageMarkedCompleted
  }
  resetDates
  mostRecentlySubmittedAnswerGroup {
    ${submittedAnswerGroups}
  }
`;

export const pendingReferenceRequestDetails = `
  referenceRequestId
  referenceRequestKey
  referencerInvitedDate
  expirationDate
  submissionIpMatchesInvitationIp
  candidateReferencerRelation {
    candidateReferencerRelationId
    overlapStartDate
    overlapEndDate
    timeOverlap
    referencerTitle
    workRelationship
    referencerProfile {
      referencerProfileId
      referencerAddress
      referencerEmail
      user {
        userId
        firstName
        lastName
      }
    }
    candidateExperience {
      candidateExperienceId
      positionTitle
      companyName
    }
  }
`;

export const questionComponentDetails = `
  questionComponentId
  questionComponentKey
  componentText
  subtitleText
  isArchived
  componentIdentifier
  hasAnswerOptions
  questionTypeId
  answerOptionGroupId
  ${questionType}
  helpTexts {
    helpTextId
    title
    body
  }
  ${answerOptionGroupType}
  answerTopic
  surveyDisplayMeta {
    questionSurveyInterface
    answerInterface
    answerMeta {
      ... on MultipleChoiceAnswerMeta {
        orderedAnswerOptions {
          answerOptionKey
          answerOptionId
          title
          subtitle
          cultureName
          cultureDefinition
        }
      }
      ... on TwoOptionSliderAnswerMeta {
        labelOneId
        labelOneKey
        labelOneTitle
        labelOneDescription
        labelTwoId
        labelTwoKey
        labelTwoTitle
        labelTwoDescription
      }
    }
  }
  qohFactor {
    qohFactorKey
    displayName
    respondent
  }
`;

export const subsectionComponentDetails = `
  subsectionComponentId
  componentText
  headingText
  componentIdentifier
  conditionalAnswers
  details
`;

export const fcgType = `
  fcgType {
    typeName
    fcgTypeId
  }
`;

export const formComponentGroupDetails = `
  formComponentGroupId
  formComponentGroupKey
  formIdentifier
  fcgTypeId
  fcgType {
    fcgTypeId
    typeName
  }
  componentText
  instructionText
  customizedSubsectionComponentId
  isTranslated
  isPublicTemplate
  details
`;

export const positionTemplateDetails = `
  positionTemplateId
  templateName
  companyId
  referencerResponseDays
  numRequiredReferences
  candidateFormComponentGroupId
  behavioralAssessmentTargetScore
  formComponentGroup {
    formComponentGroupId
    componentText
  }
  referenceRequirementSyntax {
    ${referenceRequirementSyntax}
  }
  referenceRequirements {
    positionTemplateRequirementId
    numRequired
    referenceRelationType {
      ${referenceRelationTypeDetails}
    }
  }
  surveysToRelationships {
    positionTemplateSurveyToRelationshipKey
    formComponentGroupKey
    formComponentGroup {
      componentText
      formComponentGroupKey
    }
    referenceRelationTypeKey
    referenceRelationType {
      typeName
      referenceRelationTypeKey
    }
  }
  taskTypes
  inUse
`;

export const draftAnswerGroup = `
  answerGroupId
  answerGroupKey
  currentSectionComponentRelationId
  answers {
    answerId
    answerBody
    subsectionQuestionRelation {
      subsectionQuestionRelationId
      subsectionQuestionRelationKey
    }
  }
  responseProgress {
    answerGroupId
    currentSkillMapId
    currentSkillMapCompetencyId
    currentSectionComponentRelationId
    currentSectionComponentRelationKey
    currentSectionComponentId
    currentSectionComponentKey
    currentSubsectionComponentId
    currentSubsectionComponentKey
    currentSkillMapId
    currentSkillMapCompetencyId
  }
`;

export const submittedAnswerGroup = `
  answerGroupId
  submissionDate
  answers {
    answerId
    answerBody
    subsectionQuestionRelation {
      subsectionQuestionRelationId
      subsectionQuestionRelationKey
    }
  }
`;

export const mostRecentlySubmittedAnswerGroup = `
  answerGroupId
  submissionDate
  answers {
    answerId
    answerBody
    subsectionQuestionRelation {
      subsectionQuestionRelationId
      subsectionQuestionRelationKey
    }
  }
`;

export const benchmarkMatchDetails = `
  candidatePositionRelationId
  benchmarkRequestId
  matchScore
  behaviorProfileMatch {
    candidatePositionRelationId
    benchmarkRequestId
    individualMatches {
      benchmarkRequestId
      matchScore
      behavior {
        candidateProfileId
        ${behaviorProfileDetails}
      }
    }
  }
`;

export const greenhousePartnerDetails = `
  greenhousePartnerId
  greenhousePartnerKey
  ghUserId
  ghApiKey
  searchlightToken
  groupsToUpdateOnSubmission
  customJobFieldForIntake
  groupsToGrantAccess
  hasApplicantScreeningPermissions
`;

export const commType = `
  commTypeKey
  description
  defaultSubjectTemplate
  defaultBodyTemplate
  placeholders
  title
`;

export const companyComm = `
  companyCommKey
  subjectTemplate
  bodyTemplates
`;

export const benchmarkRequestComment = `
  benchmarkRequestCommentKey
  text
  benchmarkRequestId
  creatorHirerProfileId
  questionComponentId
  updatedAt
  hirerProfile {
    ${hirerProfileDetails}
  }
`;

export const benchmarkCollaborator = `
  benchmarkCollaboratorKey
  receivesNotifications
  hirerProfile {
    ${hirerProfileDetails}
  }
  hirerProfileId
  accessLevel
`;

export const upcomingInvitation = `
  upcomingInvitationKey
  invitationDate
  invitationDateOverride
`;

export const invitationEmployee = `
  employeeName
  employeeEmail
  employeeStartDate
`;

export const automatedInviteHistory = `
  automatedInviteHistoryKey
  status
  invitationDate
  employee {
    ${invitationEmployee}
  }
`;

export const automationSetting = `
  automationSettingKey
  businessDaysFromStartDate
  isArchived
  syncSchedule
  inviteDirectManager
  inviteSkipManager
  inviteEmployeeReviewers
  applyToAllHrisGroups
  automationHrisGroups {
    hrisGroup {
      hrisGroupKey
      name
      nameAndType
    }
  }
`;

export const workdayPartnerDetails = `
  workdayPartnerKey
  recruitingWsdl
  humanResourcesWsdl
  username
  password
  defaultPositionTemplate {
    positionTemplateId
    templateName
  }
`;

export const srPartnerDetails = `
  srPartnerKey
  srApiKey
  groupsToGrantAccess
  groupsToUpdateOnSubmission
  candidateSubstatus
  candidatePropertyKey
  defaultPositionTemplate {
    positionTemplateId
    templateName
  }
`;

export const hirerCompanyRoles = `
  hirerCompanyRoles {
    hirerCompanyRoleKey
    companyRole {
      companyRoleKey
      name
      company {
        companyId
      }
      accessPermissions {
        accessPermissionKey
        title
        internalIdentifier
      }
    }
  }
`;

export const ashbyPartnerDetails = `
  ashbyPartnerKey
  ashbyApiKey
  searchlightApiKey
  secretToken
  groupsToGrantAccess
  groupsToUpdateOnSubmission
  webhookUrls {
    label
    url
  }
`;

export const candidatePositionRelationsDetails = `
  bestMatchScore {
    score
    version
  }
  candidatePositionRelationId
  candidatePositionRelationKey
  weighted_score
  excitement_score
  competencies_score
  scoreVersion
  predictedPerformance {
    label
    subtitle
    qualitativeFactors
    quantitativeFactors
    explainer {
      description
      title
    }
  }
  opportunityId
  statusTypeId
  emailReminderDays
  candidateInvitedDate
  referencesCompletedDate
  referenceStatus {
    statusTypeId
    typeName
  }
  opportunityId
  searchlightScoreText
  searchlightScoreSubtitle
  hirerHighlightedAnswers {
    hirerHighlightId
    hirerProfileId
    candidatePositionRelationId
    answerId
  }
  ${behaviorScores}
  behaviorProfile {
    ${behaviorProfileDetails}
  }
  candidateProfile {
    candidateProfileId
    candidateEmail
    candidatePhoneNumber
    user {
      userId
      firstName
      lastName
    }
  }
  cprAttributes {
    ${CPR_ATTRIBUTE}
  }
  ${cprSkillMapRelations}
  positionId
  position {
    positionId
    positionTitle
    usesExcitementQuestion
    companyId
    reviewCycleId
    formComponentGroup {
      ${formComponentGroupDetails}
      sectionComponents {
        sectionComponentId
        componentText
        componentIdentifier
        sectionComponentRelations {
          sectionComponentRelationId
          order
          subsectionComponent {
            ${subsectionComponentDetails}
            subsectionQuestionRelations {
              subsectionQuestionRelationId
              order
              isRequired
              isConditional	
              conditionalAnswers
              maxSelections	
              parentSubsectionQuestionRelationId
              questionComponent {
                questionComponentId
                answerTopic
                qohFactor {
                  qohFactorKey
                  displayName
                  respondent
                }
                componentText
                componentIdentifier
                subtitleText
                helpTexts {
                  helpTextId
                  title
                  body
                }
                ${questionType}
                answerOptionGroup {
                  answerOptionGroupId
                  groupName
                  answerOptions {
                    answerOptionId
                    optionName
                    subtitle
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  greenhouseRequest {
    greenhouseRequestId
    ghJobs {
      name
    }
  }
  leverRequest {
    leverRequestId
    leverPostings {
      name
    }
  }
`;
