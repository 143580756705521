import { FieldFunctionOptions } from '@apollo/client';

import { HirerCompanyRole, HirerPositionRelation, HirerProfile, User } from 'types';
import { KeyFieldsContext } from 'types/interfaces';

export default {
  keyFields: ({ hirerProfileId }: HirerProfile, { typename }: KeyFieldsContext): string =>
    `${typename}_${hirerProfileId}`,
  fields: {
    hirerProfileId: {
      read(hirerProfileId: string): number {
        return +hirerProfileId;
      },
    },
    hirerDisplayName: {
      read(_exisiting: string, options: FieldFunctionOptions): string {
        const user = options.readField(
          `User_${options.readField('userId')}`,
          options.cache.extract()
        ) as User;
        if (options.readField('hirerInfoPending') || !user) {
          return options.readField('hirerEmail') as string;
        }
        const { firstName, lastName } = user;

        return `${firstName} ${lastName}`;
      },
    },
    hirerPositionRelations: {
      merge(
        _existing: HirerPositionRelation[],
        incoming: HirerPositionRelation[]
      ): HirerPositionRelation[] {
        return incoming;
      },
    },
    hirerCompanyRoles: {
      merge(_existing: HirerCompanyRole[], incoming: HirerCompanyRole[]): HirerCompanyRole[] {
        return incoming;
      },
    },
  },
};
