module.exports = {
  background: '#f8f9fb',
  gray: {
    100: '#f6f6fd',
    200: '#ededfb',
    300: '#cccce1',
    400: '#a5a5b9',
    DEFAULT: '#848496',
    600: '#636373',
    700: '#424250',
    800: '#21212d',
    900: '#00000a',
  },
  primary: {
    100: '#f2effb',
    200: '#cbbeef',
    300: '#a38de2',
    400: '#7c5cd6',
    DEFAULT: '#5b33cc',
    600: '#45279b',
    700: '#301b6b',
    800: '#211249',
    900: '#120a29',
  },
  secondary: {
    100: '#eff8fb',
    200: '#bfe2ed',
    300: '#8fcde0',
    400: '#5fb7d3',
    DEFAULT: '#359ec0',
    600: '#287690',
    700: '#1d5668',
    800: '#123540',
    900: '#071b22',
  },
  info: {
    100: '#e5f3ff',
    200: '#b8deff',
    300: '#8bc8ff',
    400: '#5eb2ff',
    DEFAULT: '#319cff',
    600: '#2479c5',
    700: '#18558c',
    800: '#0c3153',
    900: '#000d1a',
  },
  success: {
    100: '#e5faf5',
    200: '#afe9db',
    300: '#70d7bf',
    400: '#38c7a6',
    DEFAULT: '#25a789',
    600: '#178269',
    700: '#0f5746',
    800: '#0c4538',
    900: '#051a15',
  },
  warning: {
    100: '#fff7eb',
    200: '#ffe3b6',
    300: '#ffcf86',
    400: '#ffbb56',
    DEFAULT: '#ffa726',
    600: '#c5811e',
    700: '#8c5b14',
    800: '#53350a',
    900: '#1a0f00',
  },
  error: {
    100: '#fee8e7',
    200: '#fdbeba',
    300: '#fa958e',
    400: '#f76c62',
    DEFAULT: '#f44336',
    600: '#bd3328',
    700: '#86231b',
    800: '#4f130e',
    900: '#180301',
  },
  scoreGradient: {
    DEFAULT: '#72D1F5',
    600: '#38A1C3',
    700: '#047192',
    800: '#004361',
    900: '#001A33',
  },
};
