import { FieldFunctionOptions, makeVar } from '@apollo/client';
import isNil from 'lodash/isNil';

import {
  CandidatePositionRelation,
  CandidatePositionRelationPaging,
  HirerHighlight,
  KeywordSearchCPRsForPositionInput,
  SearchCandidatePositionRelationsInput,
} from 'types';
import { EmptyObject, KeyFieldsContext } from 'types/interfaces';
import { getPagingQueryField, makeVarWithWindowUpdate } from './helpers';
import { useCompanyFeatureFlagStore } from 'store';

const ignoreKeysForPositionHub = ['positionIds', 'positionId', 'forReviews'];

// For HIRER_SEARCH_CANDIDATE_POSITION_RELATIONS and KEYWORD_SEARCH_CPRS_FOR_POSITION
const inputVar = makeVar<
  KeywordSearchCPRsForPositionInput | EmptyObject | SearchCandidatePositionRelationsInput
>({});

export const candidatePositionRelationCacheRef = (
  candidatePositionRelationId: string | number
): string => `CandidatePositionRelation_${candidatePositionRelationId}`;

export const searchCandidatePositionRelationsInputVar = makeVarWithWindowUpdate(inputVar, [
  'forReviews',
]);

export const keywordSearchCPRsForPositionInputVar = makeVarWithWindowUpdate(
  inputVar,
  ignoreKeysForPositionHub
);

export default {
  keyFields: (
    { candidatePositionRelationId: id }: CandidatePositionRelation,
    { typename }: KeyFieldsContext
  ): string => `${typename}_${id}`,
  fields: {
    candidatePositionRelationId: {
      read(candidatePositionRelationId: string): number {
        return +candidatePositionRelationId;
      },
    },
    weighted_score: {
      read(existing: string): number | null {
        return isNil(existing) || Number.isNaN(+existing) ? null : +(+existing).toFixed(0);
      },
    },
    candidateScore: {
      read(existing: string | null, options: FieldFunctionOptions): string | null | number {
        if (existing) {
          return existing;
        }
        const useSearchlightScoreNumber =
          useCompanyFeatureFlagStore.getState().companyFeatureFlags.useSearchlightScoreNumber;

        if (!useSearchlightScoreNumber) {
          return options.readField('searchlightScoreText') as string;
        }

        const value = options.readField('weighted_score');
        return isNil(value) || Number.isNaN(+value) ? null : +(+value).toFixed(0);
      },
    },
    hirerHighlightedAnswers: {
      merge(_existing: HirerHighlight[], incoming: HirerHighlight[]): HirerHighlight[] {
        return incoming;
      },
    },
  },
};

export const fetchCPRsForPosition = getPagingQueryField<
  CandidatePositionRelation,
  CandidatePositionRelationPaging,
  KeywordSearchCPRsForPositionInput
>(
  ['positionId', 'statusTypeIds', 'attributeIds', 'keyword', 'sortField', 'sortDirection'],
  'candidatePositionRelationId'
);

export const candidatePositionRelationsForHirer = getPagingQueryField<
  CandidatePositionRelation,
  CandidatePositionRelationPaging,
  SearchCandidatePositionRelationsInput
>(
  [
    'companyId',
    'forReviews',
    'positionIds',
    'positionId',
    'statusTypeIds',
    'attributeIds',
    'keyword',
    'sortField',
    'sortDirection',
  ],
  'candidatePositionRelationId'
);
