import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { FilterData } from 'types/apollo';

interface ManagerStoreState {
  filters: Record<string, FilterData[] | FilterData>;
  employeeCount: number;
  isComparing?: boolean;
  setEmployeeCount: (count: number) => void;
  addToFilters: (type: string, selectedFilters: FilterData[] | FilterData) => void;
  setFilters: (filters: Record<string, FilterData[] | FilterData>) => void;
  setIsComparing: (isComparing: boolean) => void;
  deleteFilter: (type: string) => void;
  deleteEverything: () => void;
}

// Generic Store Creator Function
const createManagerStore = (storeName: string, includeIsComparing = false) =>
  create<ManagerStoreState>()(
    devtools(
      persist(
        set => ({
          filters: {},
          employeeCount: 0,
          isComparing: includeIsComparing ? false : undefined,
          setIsComparing: (isComparing: boolean) => set(() => ({ isComparing })),
          setEmployeeCount: (count: number) => set(() => ({ employeeCount: count })),
          addToFilters: (type: string, selectedFilters: FilterData[] | FilterData) =>
            set(state => ({
              filters: {
                ...state.filters,
                [`${type}`]: selectedFilters,
              },
            })),
          setFilters: (filters: Record<string, FilterData[] | FilterData>) =>
            set({
              filters,
            }),
          deleteFilter: (type: string) =>
            set(state => {
              const newFilters = { ...state.filters };
              delete newFilters[type];
              return {
                filters: newFilters,
              };
            }),
          deleteEverything: () =>
            set({
              filters: {},
              employeeCount: 0,
              isComparing: includeIsComparing ? false : undefined,
            }),
        }),
        { name: storeName }
      )
    )
  );

const useManagerViewStore = createManagerStore('manager-view-storage', true);
const useManagerViewComparisonStore = createManagerStore('manager-view-comparison-storage');

export { useManagerViewStore, useManagerViewComparisonStore };
