import { gql } from '@apollo/client';

import {
  behaviorProfileDetails,
  benchmarkSkillMapRelations,
  statusType,
  submittedAnswerGroups,
  hirerProfileDetails,
  draftAnswerGroup,
  submittedAnswerGroup,
  benchmarkRequestComment,
  strengthAttributes,
  referenceRequirementSyntax,
} from './sharedApiConstants';

export const FETCH_STANDALONE_BENCHMARKS_FOR_COMPANY = gql`
  query fetchStandaloneBenchmarksForCompany($input: fetchStandaloneBenchmarksForCompanyInput!) {
    fetchStandaloneBenchmarksForCompany(input: $input)
      @connection(key: "fetchStandaloneBenchmarksForCompany", filter: [["input", ["keyword", "createdByHirerId", "companyId"]]]) {
        page
        pageSize
        totalCount
        data {
          benchmarkId
          benchmarkTitle
          createdAt
          requisitionIds
          benchmarkRequests { 
            benchmarkRequestId
            benchmarkRequestKey
            mostRecentlySubmittedAnswerGroup {
              answerGroupId
            }
          } 

          createdByHirer {
            ${hirerProfileDetails}
          }
        }
    }
  }
`;

export const FETCH_BENCHMARK_REQUESTS_CREATED_BY_ME = gql`
  query fetchBenchmarkRequestsCreatedByMe($input: FetchBenchmarkRequestsCreatedByMeInput!) {
    fetchBenchmarkRequestsCreatedByMe(input: $input) {
      page
      pageSize
      totalCount
      data {
        benchmarkRequestId
        benchmarkRequestKey
        receivesNotifications
        createdAt
        uniqueCode
        benchmark {
          benchmarkId
          benchmarkTitle
        }
        statusType {
          statusTypeId
          typeName
        }
        hirerProfile {
          ${hirerProfileDetails}
        }
      }
    }
  }
`;

export const CREATE_BENCHMARK_AND_REQUESTS = gql`
  mutation createBenchmarkAndRequests($input: createBenchmarkAndRequestsInput!) {
    createBenchmarkAndRequests(input: $input) {
      benchmarkId
      benchmarkTitle
    }
  }
`;

const benchmark = `
  benchmarkId
  benchmarkKey
  benchmarkTitle
  requisitionIds 
  externalJobUrls
  isCompanyTemplate
  templateBenchmarkId
  numberOfCandidates
  hasCompletedOneReferenceCheck
  formComponentGroup {
    formComponentGroupKey
  }

  templateBenchmarkRequest {
    benchmarkRequestKey
    benchmarkRequestId
  }

  benchmarkBehaviorProfile {
    ${behaviorProfileDetails}
  }

  benchmarkPositionRelations {
    benchmarkPositionRelationsId
    position {
      positionId
      positionTitle
      positionIdentifier
    }
  }

  jobOpeningTaxonomy {
    jobOpeningTaxonomyKey
    standardJobFamilyKey
    standardJobLevelKey
    standardJobCareerTrackKey
    standardJobFamily {
      standardJobFamilyKey
      name
    }
    standardJobLevel {
      standardJobLevelKey
      name
    }
    standardJobCareerTrack {
      standardJobCareerTrackKey
      name
    }
  }

  talentModel {
    talentModelKey
    version
  }

  jobInterviewKey

  ${benchmarkSkillMapRelations}

  benchmarkRequests {
    benchmarkId
    benchmarkRequestId
    benchmarkRequestKey
    receivesNotifications
    submissionDate
    uniqueCode
    sendReminders

    hirerProfile {
      ${hirerProfileDetails}
      hirerCompanyRelations {
        hirerCompanyRelationId
        companyId
        permissions
      }
    }
    formComponentGroup {
      formComponentGroupId
    }
    ${statusType}
    ${strengthAttributes}
    mostRecentlySubmittedAnswerGroup {
      ${submittedAnswerGroups}
    }
  }

  position {
    positionTitle
    positionId
    positionIdentifier
    formComponentGroupId
    candidateFormComponentGroupId
    surveysToRelationships {
      formComponentGroupKey
      referenceRelationTypeKey
    }
    behavioralAssessmentTargetScore
    referencerResponseDays
    referenceRequirementSyntax {
      ${referenceRequirementSyntax}
    }
  }

  positions {
    positionId
    positionIdentifier
    formComponentGroupId
    candidateFormComponentGroupId
    surveysToRelationships {
      formComponentGroupKey
      referenceRelationTypeKey
    }
    referencerResponseDays
    referenceRequirementSyntax {
      ${referenceRequirementSyntax}
    }
    positionTemplate {
      positionTemplateId
      templateName
    }
  }
`;

export const FETCH_BENCHMARK_BY_ID = gql`
  query fetchBenchmarkById($input: fetchBenchmarkByIdInput!) {
    fetchBenchmarkById(input: $input) {
      ${benchmark}
    }
  }
`;

export const CREATE_BENCHMARK_REQUESTS_FOR_BENCHMARK = gql`
  mutation createBenchmarkRequestsForBenchmark($input: createBenchmarkRequestsForBenchmarkInput!) {
    createBenchmarkRequestsForBenchmark(input: $input) {
      ${benchmark}
    }
  }
`;

export const UPDATE_BENCHMARK = gql`
  mutation updateBenchmark($input: updateBenchmarkInput!) {
    updateBenchmark(input: $input) {
      ${benchmark}
    }
  }
`;

export const CREATE_BENCHMARK_POSITION_RELATION = gql`
  mutation createBenchmarkPositionRelation($input: CreateBenchmarkPositionRelationInput!) {
    createBenchmarkPositionRelation(input: $input) {
      benchmarkPositionRelationsId
      positionId
      benchmarkId
    }
  }
`;

export const FETCH_BENCHMARK_REQUEST_FOR_ME = gql`
  query fetchBenchmarkRequestsForMe($input: FetchBenchmarkRequestsForMeInput!) {
    fetchBenchmarkRequestsForMe(input: $input) {
      page
      pageSize
      totalCount
      data {
        benchmarkRequestId
        benchmarkRequestKey
        benchmarkRequestKey
        receivesNotifications
        uniqueCode
        createdAt
        statusType {
          statusTypeId
          typeName
        }
        benchmark {
          benchmarkId
          benchmarkTitle

          createdByHirer {
            ${hirerProfileDetails}
          }
        }
      }
    }
  }
`;

export const COPY_BENCHMARK_REQUEST_ANSWERS = gql`
  mutation copyBenchmarkRequestAnswers($input: CopyBenchmarkRequestAnswersInput!) {
    copyBenchmarkRequestAnswers(input: $input) {
      benchmarkRequestId
      benchmarkRequestKey
      draftAnswerGroup {
        ${draftAnswerGroup}
      }
      mostRecentlySubmittedAnswerGroup {
        ${submittedAnswerGroup}
      }
    }
  }
`;

// Open ended responses
export const CREATE_BENCHMARK_REQUEST_COMMENT = gql`
  mutation createBenchmarkRequestComment($input: CreateBenchmarkRequestCommentInput!) {
    createBenchmarkRequestComment(input: $input) {
      ${benchmarkRequestComment}
    }
  }
`;

export const UPDATE_BENCHMARK_REQUEST_COMMENT = gql`
  mutation updateBenchmarkRequestComment($input: UpdateBenchmarkRequestCommentInput!) {
    updateBenchmarkRequestComment(input: $input) {
      ${benchmarkRequestComment}
    }
  }
`;

export const DELETE_BENCHMARK_REQUEST_COMMENT = gql`
  mutation deleteBenchmarkRequestComment($input: DeleteBenchmarkRequestCommentInput!) {
    deleteBenchmarkRequestComment(input: $input) {
      benchmarkRequestCommentKey
    }
  }
`;

export const DUPLICATE_BENCHMARK = gql`
  mutation duplicateBenchmark($input: DuplicateBenchmarkInput!) {
    duplicateBenchmark(input: $input) {
      benchmarkId
       benchmarkRequests {
        benchmarkRequestId
        benchmarkRequestKey
        hirerProfile {
          hirerProfileId
        }
        ${statusType}
      }
    }
  }
`;

export const ARCHIVE_BENCHMARK = gql`
  mutation archiveBenchmark($input: archiveBenchmarkInput!) {
    archiveBenchmark(input: $input) {
      benchmarkId
      benchmarkRequests {
        benchmarkRequestId
        benchmarkRequestKey
      }
    }
  }
`;

export const FETCH_UNATTACHED_BENCHMARK_REQUEST_COMMENTS = gql`
  query fetchUnattachedBenchmarkRequestComments($input: FetchUnattachedBenchmarkRequestCommentsInput!) {
    fetchUnattachedBenchmarkRequestComments(input: $input) {
      ${benchmarkRequestComment}
    }
  }
`;

export const REFRESH_BENCHMARK = gql`
  subscription refreshBenchmark($input: RefreshBenchmarkInput!) {
    refreshBenchmark(input: $input) {
      benchmarkId
      event
    }
  }
`;

export const FETCH_SHARED_BENCHMARKS_FOR_ME = gql`
  query fetchSharedBenchmarksForMe($input: FetchSharedBenchmarksForMeInput!) {
    fetchSharedBenchmarksForMe(input: $input) {
      companyId
      page
      pageSize
      totalCount
      keyword
      data {
        benchmarkId
        benchmarkTitle
        createdAt
        requisitionIds
        benchmarkRequests { 
          benchmarkRequestId
          benchmarkRequestKey
          mostRecentlySubmittedAnswerGroup {
            answerGroupId
          }
        } 

        createdByHirer {
          ${hirerProfileDetails}
        }
      }
    }
  }
`;

export const UPDATE_BENCHMARK_REQUEST_NOTIFICATIONS = gql`
  mutation updateBenchmarkRequestNotifications($input: UpdateBenchmarkRequestNotificationsInput!) {
    updateBenchmarkRequestNotifications(input: $input) {
      benchmarkRequestId
      benchmarkRequestKey
      receivesNotifications
    }
  }
`;

export const UPSERT_ANSWER_FOR_BENCHMARK_REQUEST = gql`
  mutation upsertAnswerForBenchmarkRequest($input: UpsertAnswerForBenchmarkRequestInput!) {
    upsertAnswerForBenchmarkRequest(input: $input) {
      answerId
      answerBody
      subsectionQuestionRelation {
        subsectionQuestionRelationId
      }
    }
  }
`;

export const FETCH_BENCHMARKS_FOR_ME = gql`
  query fetchBenchmarksForMe($input: FetchBenchmarksForMeInput!) {
    fetchBenchmarksForMe(input: $input) {
      totalCount
      page
      pageSize
      keyword
      data {
        benchmarkCollaborators {
          benchmarkCollaboratorKey
          hirerProfile {
            hirerProfileId
            user {
              userId
              firstName
              lastName
              primaryEmail
            }
          }
        }
        benchmarkId
        benchmarkKey
        benchmarkTitle
        isCompanyTemplate
        createdAt
        numberOfCandidates
        createdByHirer {
          hirerProfileId
          user {
            userId
            firstName
            lastName
          }
        }
        talentModel {
          talentModelKey
          title
        }
        jobOpeningTaxonomy {
          standardJobFamily {
            name
            standardJobFamilyKey
          }
        }
      }
    }
  }
`;
