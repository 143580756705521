import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { marked } from 'marked';
import sanitizer from 'dompurify';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { I18nProvider } from '@lingui/react';
import * as Sentry from '@sentry/react';
import smoothscroll from 'smoothscroll-polyfill';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserTracing } from '@sentry/tracing';

import apolloClient from 'clients/apolloClient';
import * as serviceWorker from './serviceWorker';
import { i18n } from 'internationalization';
import './App.less';
import './index.css';
import './styles/_fontFace.less';
import './index.less';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_FE_ENV,
    attachStacktrace: true,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}

smoothscroll.polyfill();

marked.setOptions = {
  gfm: true,
  breaks: true,
  smartypants: true,
  sanitizer,
};

(async () => {
  const { default: BuildTarget } =
    process.env.REACT_APP_BUILD_TARGET === 'VanityApp'
      ? await import('./VanityApp')
      : await import('./AppWrapper');

  ReactDOM.render(
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
          <BuildTarget />
        </I18nProvider>
      </ApolloProvider>
    </BrowserRouter>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
